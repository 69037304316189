/* 
  Order of Fields
*/

export const defaultOrderModelFields = [
  ['EngineDisplacement', 'EmissionClass', 'DeliveryDate'],
  [],
  ['Quantity'],
  ['Price'],
];

export const defaultOrderVehicleFields = [
  ['EngineDisplacement', 'Mileage', 'NumOwners'],
  ['Location', 'DeliveryDate'],
  ['Quantity'],
  ['Price'],
];

export const clientFields = {
  Id: 'id',
  ItemCode: 'code',
  VehicleCode: 'vehicleCode',
  Name: 'name',
  Fin: 'fin',
  LicenseNumber: 'licenseNumber',
  GearType: 'gear_type',
  FuelType: 'fuel_type',
  Description: 'description',
  Color: 'color',
  EngineDisplacement: 'engineDisplacement',
  Mileage: 'mileage',
  NumOwners: 'owners',
  PhotoLink: 'photoLink',
  ImageLink: 'imageLink',
  SeatsNum: 'seats',
  DoorsNum: 'doors',
  Location: 'location',
  DeliveryDate: 'deliveryDate',
  Quantity: 'quantity',
  Price: 'price',
  EmissionClass: 'co_2_emission',
  Vin: 'vin',
  LuggageCapacity: 'luggageCapacity',
  Air: 'air',
  Conditioning: 'conditioning',
  FuelTank: 'fuelTank',
  KmLimit: 'kmLimit',
  MinimumDriversAge: 'minimumDriversAge',
  NextServiceDate: 'nextServiceDate',
  NextServiceKm: 'nextServiceKm',
  Notes: 'notes',
  Status: 'status',
  ImageName: 'imageName',
  EngineCode: 'engineCode',
  U_GCode: 'u_GCode',
  OldCarId: 'oldCarId',
  VehicleGroup: 'vehicleGroup',
  LicenseDate: 'licenseDate',
  FirstRegistrationDate: 'firstRegistrationDate',
  RegistrationNumber: 'registrationNumber',
  RegistrationType: 'registrationType',
  DeregistrationDate: 'deregistrationDate',
  WarrantyNumber: 'warrantyNumber',
  WarrantyDescription: 'warrantyDescription',
  WarrantyDate: 'warrantyDate',
  WarrantyMonth: 'warrantyMonth',
  WarrantyKm: 'warrantyKm',
  MileageType: 'mileageType',
  U_IDChDt: 'u_IDChDt',
  U_IDChNt: 'u_IDChNt',
  Owner: 'owner',
  OwnerName: 'ownerName',
  Sponsor: 'sponsor',
  SponsorName: 'sponsorName',
  U_CrBlck: 'u_CrBlck',
  HolderName: 'holderName',
  VehicleColor: 'vehicleColor',
  InteriorColor: 'interiorColor',
  TireSize: 'tireSize',
  EngineMake: 'engineMake',
  DoorsNumber: 'doorsNumber',
  SeatsNumber: 'seatsNumber',
  IsAbsSystem: 'isAbsSystem',
  U_UPSource: 'u_UPSource',
  AnualMileage: 'anualMileage',
  PrivousOwnersNumber: 'privousOwnersNumber',
  ProtectCode: 'protectCode',
  MMdlCode2: 'mMdlCode2',
  GrossWeight: 'grossWeight',
  NetWeight: 'netWeight',
  EmptyWeight: 'emptyWeight',
  TotalWeight: 'totalWeight',
  RoofWeight: 'roofWeight',
  MaxWeight: 'maxWeight',
  TrailerLoad: 'trailerLoad',
  TrailToLoad: 'trailToLoad',
  FrontLoad: 'frontLoad',
  RearAxleLoad: 'rearAxleLoad',
  ProductionYear: 'productionYear',
  ToSpeed: 'toSpeed',
  Acceleration: 'acceleration',
  CityCycleConsumption: 'cityCycleConsumption',
  LongDistanceConsumption: 'longDistanceConsumption',
  CO2EmissionCity: 'cO2EmissionCity',
  CO2EmissionLong: 'cO2EmissionLong',
  U_PrtclMass: 'u_PrtclMass',
  DieselParticleFilter: 'dieselParticleFilter',
  FulConCom: 'fulConCom',
  CO2EmissionCombined: 'cO2EmissionCombined',
  FuelConsumptionCombined: 'fuelConsumptionCombined',
  NoxEmission: 'noxEmission',
  PMEmission: 'pMEmission',
  THCEmission: 'tHCEmission',
  NMHCEmission: 'nMHCEmission',
  HCNoxEmission: 'hCNoxEmission',
  LeadTimeCalc: 'leadTimeCalc',
  U_TelAccNo: 'u_TelAccNo',
  LastMaintenanceDate: 'lastMaintenanceDate',
  LastMaintenanceOdometer: 'lastMaintenanceOdometer',
  NextMaintenanceDate: 'nextMaintenanceDate',
  NextMaintenanceOdometer: 'nextMaintenanceOdometer',
  CylindrsNum: 'cylindrsNum',
  U_Ownrship: 'u_Ownrship',
  LicenseMonth: 'licenseMonth',
  TaxGroupId: 'taxGroupId',
  PurchaseDate: 'purchaseDate',
  PurchaseCost: 'purchaseCost',
  SalesDate: 'salesDate',
  SalesCost: 'salesCost',
  RadioCode: 'radioCode',
  LastOdometer: 'lastOdometer',
  IsClimatisation: 'isClimatisation',
  IsParkAssist: 'isParkAssist',
  DriveSide: 'driveSide',
  PowerKW: 'powerKW',
  PowerPS: 'powerPS',
  TiresLastChangeDate: 'tiresLastChangeDate',
  SellerCode: 'sellerCode',
  FinanceBPCode: 'financeBPCode',
  InventoryStatus: 'inventoryStatus',
  DriversCab: 'driversCab',
  Wheelbase: 'wheelbase',
  PolutantLevel: 'polutantLevel',
  UsageType: 'usageType',
  KBAKey: 'kBAKey',
  BusinessCycle: 'businessCycle',
  CustomerSite: 'customerSite',
  IsSalable: 'isSalable',
  U_IDChTm: 'u_IDChTm',
  LaborType: 'laborType',
  U_MdlClassName: 'u_MdlClassName',
  VehicleImage: 'vehicleImage',
  AxlesNum: 'axlesNum',
  EngineReplacementMileage: 'engineReplacementMileage',
  ClndChngDt: 'clndChngDt',
  U_BrkFldChngDt1: 'u_BrkFldChngDt1',
  U_TechGrphChkDt: 'u_TechGrphChkDt',
  U_LastServiceVisit: 'u_LastServiceVisit',
  U_EmdStdCd: 'u_EmdStdCd',
  U_IntrType: 'u_IntrType',
  NextSecurityCheckDate: 'nextSecurityCheckDate',
  NextSeriveKm: 'nextSeriveKm',
  U_Legacy_DMS_ID: 'u_Legacy_DMS_ID',
  U_CompClassCd: 'u_CompClassCd',
  IsRegularMaintenance: 'isRegularMaintenance',
  U_DriveNoise: 'u_DriveNoise',
  IsLeatherInter: 'isLeatherInter',
  Payload: 'payload',
  ProductionDate: 'productionDate',
  WDType: 'wDType',
  U_ElctPwrRng: 'u_ElctPwrRng',
  WheelsNum: 'wheelsNum',
  RoofShape: 'roofShape',
  Damaged: 'damaged',
  U_ParkingLot: 'u_ParkingLot',
  U_NxtAccCntrlDate: 'u_NxtAccCntrlDate',
  LengthMM: 'lengthMM',
  WidthMM: 'widthMM',
  HightMM: 'hightMM',
  U_Applicable: 'u_Applicable',
  KMLimit: 'kMLimit',
  BDriverAge: 'minimumDriverAge',
  ParkingLot: 'parkingLot',
  CreatedBy: 'createdBy',
  DataSource: 'dataSource',
  BodyStyle: 'bodyStyle',
  ModelType: 'modelType',
  MakeName: 'makeName',
  MaintenscheduleInKM: 'maintenscheduleInKM',
  MaintenscheduleInMonth: 'maintenscheduleInMonth',
  PrivateOrCommercial: 'privateOrCommercial',
  MakeModelCode: 'makeModelCode',
  InventoryModelCode: 'inventoryModelCode',
  MarketingModelCode: 'marketingModelCode',
  ModelVINKey: 'modelVINKey',
  ModelRegistrationCode: 'modelRegistrationCode',
  EngineModel: 'engineModel',
  TiresSize: 'tiresSize',
  FrontTires: 'frontTires',
  RoofLoad: 'roofLoad',
  FrontAxleLoad: 'frontAxleLoad',
  TopSpeed: 'topSpeed',
  Variance: 'variance',
  Airbag: 'airbag',
  EmissionStandardCode: 'emissionStandardCode',
  EmissionSticker: 'emissionSticker',
  EfficiencyClass: 'efficiencyClass',
  CubicCapacity: 'cubicCapacity',
  MicroParticleBadge: 'microParticleBadge',
  Consumption: 'consumption',
  VendorItemNum: 'vendorItemNum',
  WrntyLimitationKm: 'wrntyLimitationKm',
  WrntyLimitationMnth: 'wrntyLimitationMnth',
  MarketSegment: 'marketSegment',
  IsActive: 'isActive',
  ValidFrom: 'validFrom',
  ValidTo: 'validTo',
  ConstructionSeries: 'constructionSeries',
  LaborChargeType: 'laborChargeType',
  ServiceModelCode: 'serviceModelCode',
  ServiceModelDesc: 'serviceModelDesc',
};

/* End of Order of Fields */

/*
  Filter Keys
*/

export const filterKeysModels = {
  Engine_Displacement: 'engineDisplacement._id',
  PriceFrom: 'price',
  PriceTo: 'price',
  U_Base_Model: 'baseModelId._id',
  U_Category: 'category._id',
  U_Color: 'color._id',
  U_EuroEmissionClass: 'euroEmissionClass._id',
  U_From_Year: 'year.code',
  U_To_Year: 'year.code',
  U_Make: 'make._id',
  U_Model: 'modelId._id',
  NumberOfSeats: 'seatsNum._id',
  NumberOfDoors: 'doorsNum._id',
  U_Sub_Category: 'subCategory._id',
  U_Fuel_Type: 'modelId.fuelType._id',
  U_Gear_Type: 'modelId.fuelType._id',
};

export const filterKeysVehicles = {
  Location: 'location._id',
  MileageFrom: 'mileage',
  MileageTo: 'mileage',
  NumberOwners: 'privousOwnersNumber._id',
  PriceFrom: 'marketPrice',
  PriceTo: 'marketPrice',
  U_Base_Model: 'baseModelId._id',
  U_Category: 'category._id',
  U_Color: 'vehicleColor._id',
  U_EuroEmissionClass: 'euroEmissionClass._id',
  U_From_Year: 'year.code',
  U_To_Year: 'year.code',
  U_Fuel_Type: 'fuelType._id',
  U_Gear_Type: 'gearType._id',
  U_Make: 'make._id',
  U_Model: 'modelId._id',
  U_Particulate_Filter: '',
  U_Sub_Category: 'subCategory._id',
  NumberOfSeats: 'doorsNum._id',
  NumberOfDoors: 'seatsNum._id',
  Engine_Displacement: 'engineDisplacement._id',
  License: 'licenseNumber',
  Vin: 'vin',
  EngineCode: '',
  DeliveryDate: '',
  WarantyDate: '',
  CurrentOwnership: '',
  HorsePower: '',
};
