import { createGlobalStyle } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'rc-swipeout/assets/index.css';
import robotoFont from '../public/fonts/Roboto-Regular.ttf';
import robotoBoldFont from '../public/fonts/Roboto-Bold.ttf';
import robotoMediumFont from '../public/fonts/Roboto-Medium.ttf';
import robotoLightFont from '../public/fonts/Roboto-Light.ttf';
import editImg from '../public/images/edit.svg';
import 'react-datepicker/dist/react-datepicker.css';
import 'devextreme/dist/css/dx.light.css';

export const MOBILE_WIDTH = 1023;

const TD_HEIGHT = 55;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Roboto;
    src: url(${robotoFont});
    font-weight: normal;
  }

  @font-face {
    font-family: Roboto;
    src: url(${robotoBoldFont});
    font-weight: bold;
  }

  @font-face {
    font-family: Roboto;
    src: url(${robotoMediumFont});
    font-weight: 500;
  }

  @font-face {
    font-family: Roboto;
    src: url(${robotoLightFont});
    font-weight: 100;
  }

  /* @mixin transtorm(radius) {
    -webkit-transform: rotate(radius);
    -moz-transform: rotate(radius);
    -ms-transform: rotate(radius);
    -o-transform: rotate(radius);
    transform: rotate(radius);
  } */

  html, body {
    height: 100%;
  }

  body {
    color: #58595b;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    &.dashboard {
      background:-webkit-linear-gradient(180deg, rgba(0,145,234,1) 0%, rgba(0,145,234,1) 16%, rgba(128,216,255,1) 55%, rgba(179,229,252,1) 84%, rgba(179,229,252,1) 100%);
      background:-o-linear-gradient(180deg, rgba(0,145,234,1) 0%, rgba(0,145,234,1) 16%, rgba(128,216,255,1) 55%, rgba(179,229,252,1) 84%, rgba(179,229,252,1) 100%);
      background:-moz-linear-gradient(180deg, rgba(0,145,234,1) 0%, rgba(0,145,234,1) 16%, rgba(128,216,255,1) 55%, rgba(179,229,252,1) 84%, rgba(179,229,252,1) 100%);
      background:linear-gradient(180deg, rgba(0,145,234,1) 0%, rgba(0,145,234,1) 16%, rgba(128,216,255,1) 55%, rgba(179,229,252,1) 84%, rgba(179,229,252,1) 100%);
    }
    &.gray-bg {
      background-color: #f7f7f7;
    }
    &.without-scroll .content-wrapper .content-block {
      height: calc(100vh - 90px);
      scrollbar-width: thin;
    }
    &.modal-open {
      overflow: hidden;
      position:fixed;
      width: 100%;
      .scrolling-block {
        overflow: hidden;
      }
    }
  }

  body.hold-transition {
    .content-wrapper, .right-side, .main-footer, .main-sidebar, .left-side {
      /* Fix for IE */
      transition: none;
      -webkit-transition: none;
      -o-transition: none;
    }
  }
  .modal-backdrop {
    z-index: 9997;
    &.mobile-modal {
      opacity: 1;
      background: #f7f7f7;
      top: 100px;
      &.dark {
        background-color: #595959;
        color: white;
        opacity: 0.9;
      }
    }
  }
  .backdropDetailsTab {
    z-index: 9999;
  }

  body.modal-open .mobile-modal.modal .modal-dialog {
    margin: 0 auto;
    padding: 5px;
  }

  #root {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  h3.title {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    color: #58595b;
    margin: 0 10px 0 0;
  }

  p {
    font-size: 14px;
  }

  .form-group {
    margin-top: 0px!important;
    padding-top: 20px;
    padding-bottom: 0;
    margin-bottom: 0px!important;
  }

  form .form-group .control-label {
    font-size: 11px;
    text-align: left;
  }
  .form-control,
  input {
    &:focus {
      box-shadow: none!important;
    }
  }

  .k-popup {
    border-radius: 5px;
  }

  .k-state-disabled {
    opacity: 1;
  }

  .k-picker-wrap {
    opacity: 1!important;
    .k-dateinput {
      order: 2;
    }
    .k-select {
      order: 1;
      justify-content: flex-start!important;
      width:19px!important;
      background: transparent!important;
    }
    input {
      cursor: pointer;
    }
  }

  .k-calendar-container {
    z-index: 10000;
    .k-calendar-header .k-title {
      color: #505050;
      font-size: 14px;
      font-weight: 500;
    }
    .k-calendar {
      border-radius: 5px;
      .k-link {
        color: #505050;
        font-size: 14px;
        font-weight: 300;
      }
      .k-range-start,
      .k-range-end,
      .k-range-mid,
      .k-range-split-start::after,
      .k-range-split-end::after {
        background-image: none!important;
        background-color: #ebf3fa!important;
      }
      .k-today {
        font-size: 14px;
        font-weight: 300;
        color: #eca821!important;
        .k-link {
          color: #eca821;
        }
      }
      .k-state-selected .k-link {
        background-color: #0091ea!important;
        border-color: #0091ea!important;
        border-radius: 50%;
        color: #fff;
      }
      .k-content tr > th {
        color: #ACACAC;
        font-size: 14px;
        font-weight: 500;
      }
      html[dir='rtl'] & {
        .k-content.k-scrollable {
          margin-right: -100px;
        }
        .k-content>table {
          margin-right: 0;
          margin-left: -17px;
        }
      }
    }
  }
  html[dir='rtl'] & {
    .k-calendar-container .k-calendar {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  form .form-group .form-control,
  form .form-group input {
    border: none;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    /* color: ${(props) => props.theme.themeColor}; */
    color: ${(props) => props.theme.blackText};
    padding-left: 0;
    font-weight: 100;
    font-size: 14px;
    overflow: visible;
    &:focus, &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .react-select__control {
    border-bottom: none !important;
  }

  .under-line {
    &.form-group {
      .form-control,
      input {
        border-bottom: 1px solid #E6E6E6 !important;
      }
    }
    .react-select__control{
        border-bottom: 1px solid #E6E6E6 !important;
    }
  }

  form .form-group .textarea {
    border: 1px solid #E6E6E6;
    padding: 5px;
    &.big {
      height: 110px;
    }
  }

  .form-control {
    padding-left: 0;
/*     &:focus {
      border-color: ${(props) => props.theme.themeColor};
      outline: 0;
      box-shadow: none;
    } */
  }

  html[dir='rtl'] & {
    .direction-ltr {
      direction: ltr!important;
      display: inline-block;
    }

    .form-control,
    .form-group .clear-btn input,
    .form-group .input-wrapper input,
    .form-group .autocomplete.clear-btn input {
      padding-right: 0;
      padding-left: 12px;
    }
    .textarea {
      padding: 5px;
    }
    .dashboard .content-block {
      direction: ltr;
    }
    form .form-group .control-label {
      text-align: right;
      width: 100%;
    }
  }
  .textarea, textarea.form-control {
    resize: none;
  }

  label {
    color: #ACACAC;
    font-weight: normal;
  }

  .table > thead > tr > th {
    font-size: 11px;
    padding-bottom: 2px;
  }

  //min height for tr
  .tr-min {
    td {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin: 0 !important;

      height: ${TD_HEIGHT}px !important;
      line-height: ${TD_HEIGHT}px !important;
      > * {
         line-height: normal;
      }

      .td-min {
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  // Flex
  &, .tree .tree-row {
    .u-flex-grow2 {
      flex-grow: 2;
    }
    .u-flex-grow3 {
      flex-grow: 3;
    }
    .u-flex-grow4 {
      flex-grow: 4;
    }
    .u-flex-grow5 {
      flex-grow: 5;
    }
  }

  /*
   * Content Wrapper - contains the main content
   */
  .content-wrapper, .right-side {
    transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    z-index: 820;
    -moz-transition: -moz-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    -o-transition: -o-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    -webkit-transition: -webkit-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    .no-margin {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .content-wrapper {
    z-index: 800;
    .content-block {
      height: calc(var(--vh, 1vh) * 100 - 40px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0;
      &.with-footer {
        height: calc(100vh - 50px);
        @media(max-width: ${MOBILE_WIDTH}px) {
          padding-bottom: 50px;
        }
      }
      &.with-fixed-paging {
        padding-bottom: 95px;
      }
      .page {
        min-height: 100%;
        background: #f8f8f8;
      }
      .damages-tab {
        margin-bottom: 0;
      }
      &.mobile-v {
        height: calc(var(--vh, 1vh) * 100 - 100px);
        &.with-tabs {
          overflow-y: hidden;
          .tab-pane {
            height: calc(var(--vh, 1vh) * 100 - 189px);
            overflow-x: auto;
            .scrolling-block {
              height: calc(var(--vh, 1vh) * 100 - (100px + 37px + 51px))
            }
          }
          .content-tab .tab-pane {
            height: auto;
          }
        }
        &.with-fixed-paging {
          padding-bottom: 70px;
        }
      }
    }
  }

  .no-padding {
    padding: 0 !important;
  }

  .no-padding-left {
    padding-left: 0 !important;
  }

  .no-padding-right {
    padding-right: 0 !important;
  }

  /* General Links */
  a {
    color: #3c8dbc;
    &:hover, &:active, &:focus {
      color: #72afd2;
      outline: none;
      text-decoration: none;
    }
  }

  /*
   * Component: Modal
   */
  /*Vertical centered modal*/
  body.modal-open .modal {
    display: flex !important;
    height: 100%;
  }
  body.modal-open .modal .modal-dialog {
    margin: auto;
    .modal-content {
      box-shadow: none;
    }
  }

  /*
   * Component: Dropdown menus
   * -------------------------
   */
  /*Dropdowns in general*/
  .dropdown-menu {
    border-color: #e6e6e6;
    box-shadow: none;
    display: block;
    opacity: 0;
    transition: all 400ms ease;
    visibility: hidden;
    > {
      li > a {
        color: #777;
        padding-top: 8px;
        padding-bottom: 8px;
        text-transform: none;
        > {
          .glyphicon, .fa, .ion {
            margin-right: 10px;
          }
        }
        &:hover {
          background-color: #e1e3e9;
          color: #333;
        }
      }
      .divider {
        background-color: #eee;
      }
    }
  }

  html[dir='rtl'] & {
    .dropdown-menu > li > a {
      text-align: right;
    }
  }

  .open > .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }

  .sidebar-collapsed {
    .main-sidebar, .sidebar-overlay {
      display: none;
    }
  }

  /* Tabs */
  .inner-tabs {
    .sticky-tabs {
      .nav-tabs {
        position: sticky;
        top: 75px;
        z-index: 9;
        width: inherit;
      }
    }
    .sticky-tabs-without-top {
      .nav-tabs {
        position: sticky;
        top: 0;
        z-index: 9;
        width: inherit;
      }
    }
  }
  .mobile-container {
    .inner-tabs {
      .sticky-tabs {
        .nav-tabs {
          top: 0;
        }
      }
    }
  }

  /* Fixed layout */
  .fixed {
    .main-header, .main-sidebar, .left-side {
      position: fixed;
    }
    .main-header {
      left: 0;
      right: 0;
      top: 0;
    }
    .content-wrapper, .right-side {
      padding-top: 64px;
    }
    &.layout-boxed .wrapper {
      max-width: 100%;
    }
  }

  .no-padding {
    padding: 0;
  }

  .height100 {
    height: 100%;
  }

  .react-spinner {
    position: absolute;
    left: 50%;
    top: 40%;
    height: 50px;
    width: 50px;
  }
  .spinner-box {
    min-height: 300px;
  }

  .react-spinner_bar {
    animation: react-spinner_spin 1.2s linear infinite;
    background-color: ${(props) => props.theme.primeColor};
    border-radius: 5px;
    height: 7.8%;
    left: -10%;
    position: absolute;
    top: 15px;
    width: 20%;
    -webkit-animation: react-spinner_spin 1.2s linear infinite;
    -moz-animation: react-spinner_spin 1.2s linear infinite;
  }

  /*
   * Box element
   */
  .box {
    position: relative;
    width: 100%;
  }

  .box-header:before, .box-body:before, .box-footer:before, .box-header:after, .box-body:after, .box-footer:after {
    content: " ";
    display: table;
  }

  .box-header:after, .box-body:after, .box-footer:after {
    clear: both;
  }


  /*
   * Buttons
   */
  .btn-blue {
    background-color: ${(props) => props.theme.themeColor};
    border: 1px solid ${(props) => props.theme.themeColor};
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
    padding: 7px 15px;
    text-align: center;
    &:hover, &:focus {
      background-color: white;
      color: #14668e;
    }
  }
  .btn, .btn:focus, .btn.focus {
    box-shadow: none;
  }

  .div-error {
    background: white;
    box-shadow: 0 0 6px 0 #d4d2d2;
    h3 {
      color: red;
      margin: 0;
      padding: 50px;
      text-align: center;
    }
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    body.without-scroll .content-wrapper .content-block {
      height: auto;
    }
  }

  @media (max-width: 767px) {
    .content-wrapper {
      margin-left: 0;
    }
  }

  @media (min-width: 768px) {
    .sidebar-collapsed {
      .content-wrapper {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 767px) {
    &.open-search {
      .content-wrapper .content-block {
        max-height: calc(var(--vh, 1vh) * 100 - 125px);
        > div {
          max-height: calc(var(--vh, 1vh) * 100 - (125px + 15px));
        }
      }
    }
  }

  @media (max-width: 767px) {
    .fixed {
      .content-wrapper {
        padding-top: 100px;
      }
    }
  }

  @keyframes react-spinner_spin {
    0% { opacity: 1; }
    100% { opacity: 0.15; }
  }

  @-moz-keyframes react-spinner_spin {
    0% { opacity: 1; }
    100% { opacity: 0.15; }
  }

  @-webkit-keyframes react-spinner_spin {
    0% { opacity: 1; }
    100% { opacity: 0.15; }
  }

  /* Add fade animation to dropdown menus by appending
   the class .animated-dropdown-menu to the .dropdown-menu ul (or ol)*/

  .open:not(.dropup) > .animated-dropdown-menu {
    animation: flipInX 0.7s both;
    backface-visibility: visible !important;
    -webkit-animation: flipInX 0.7s both;
    -o-animation: flipInX 0.7s both;
  }

  @keyframes flipInX {
    0% {
      opacity: 0;
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transition-timing-function: ease-in;
    }
    40% {
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transition-timing-function: ease-in;
    }
    60% {
      opacity: 1;
      transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    }
    80% {
      transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    100% {
      transform: perspective(400px);
    }
  }


  @-webkit-keyframes flipInX {
    0% {
      opacity: 0;
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-transition-timing-function: ease-in;
    }
    40% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      -webkit-transition-timing-function: ease-in;
    }
    60% {
      opacity: 1;
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    }
    80% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    100% {
      -webkit-transform: perspective(400px);
    }
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9996;
  }

  .add-entity {
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    margin: 17px 15px;
    color: ${(props) => props.theme.themeColor};
    &:hover, &:active, &:focus {
      color: ${(props) => props.theme.themeColorLighter};
    }
  }

  .k-calendar-container, .k-animation-container {
    z-index: 10000 !important;
  }

  /*
   * Datepicker
   */
  .dtp {
    z-index: 9999;
    .dtp-content {
      box-shadow: none;
    }
    .dtp-header {
      background: ${(props) => props.theme.themeColor} !important;
      .dtp-close {
        top: 0.2em;
        right: 0.4em;
        i {
          font-size: 0;
          font-style: normal;
          &:before {
            font-family: FontAwesome;
            color: #fff;
            content: "\\f00d";
            font-size: 17px;
          }
        }
      }
    }
    div.dtp-actual-year {
      color: #c7f3e4;
      font-weight: bold;
    }
    div.dtp-date, div.dtp-time {
      background-color: #323232;
      i {
        font-family: FontAwesome;
        font-size: 0;
        font-style: normal;
        &:before {
          color: #fff;
          font-size: 20px;
        }
      }
      .left i:before {
        content: '\\f104';
      }
      .right i:before {
        content: '\\f105';
      }
    }
    div.dtp-date {
      padding: 5px 10px;
      .dtp-actual-num {
        font-size: 2em;
      }
    }
    a {
      padding: 0.5em 0.6em 0.5em 0.6em !important;
      &.selected {
        background-color: ${(props) => props.theme.themeColor} !important;
      }
    }
    .svg-clock {
      [fill='#8BC34A'] {
        fill: ${(props) => props.theme.themeColor};
      }
      [stroke='#8BC34A'] {
        stroke: ${(props) => props.theme.themeColor};
      }
    }
    .dtp-buttons {
      text-align: center;
      .btn {
        background-color: ${(props) => props.theme.themeColor};
        color: #fff;
        margin: 0 15px;
        min-width: 100px;
        height: 30px;
        padding: 0 8px;
        line-height: 30px;
        font-weight: 400;
        font-size: 14px;
        outline-color: ${(props) => props.theme.themeColor};
        &:hover {
          background: ${(props) => props.theme.themeColor};
          opacity: 0.8;
          outline: none;
        }
      }
    }
  }

  html[dir='rtl'] & {
    .dtp {
      > .dtp-content {
        margin-left: 0;
        margin-right: -150px;
        right: 50%;
        > .dtp-date-view > header.dtp-header {
          text-align: left;
          .dtp-actual-day {
            text-align: center;
          }
          .dtp-close {
            left: 0.4em;
          }
        }
      }
      .dtp-buttons {
        direction: ltr;
      }
    }
    .react-select__menu {
      text-align: right;
    }
    #secondaryPhoneNumber,
    #phoneNumber,
    #PhoneNumber,
    #SecondaryPhoneNumber,
    .noRtlNumber {
      direction: ltr !important;
      text-align: right !important;
      unicode-bidi:embed;
    }
  }

  //scrollbar
  body {
    overflow-y: hidden;
    scrollbar-color: #505050 transparent; 
    scrollbar-width: thin;
  }
  ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
  }
  ::-webkit-scrollbar-thumb {
      background: #505050;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  // Other
  .overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  .icon-pencil {
    background-image: url(${editImg});
    width: 15px;
    height: 15px;
    background-size: cover;
    position: absolute;
    right: 10%;
  }

  .add_contact_btn {
    color: #0091ea;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
    &.disabled {
      color: #ACACAC;
      cursor: not-allowed;
    }
  }
  
  .noselect {
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  /* Contact Modal */
  .showVehicleAddModal {
    .modal-body {
      .control-label {
        padding-bottom: 20px;
      }
    }
    .btn-default {
      text-transform: capitalize;
    }
  }

  .k-timeselector{
    direction: ltr;

    .k-time-footer.k-action-buttons{
      justify-content: space-between;
      .k-button{
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }
  }

  /* .fade:not(.show) {
    opacity: 1;
  } */

  .modal-backdrop.fade {
    opacity: 0.3;
  }
`;
