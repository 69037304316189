import { axiosInstanceSalesAPI } from '~/utils/axios';

const loginUrl = 'account/login';
const refreshTokenUrl = 'account/refreshToken';
const logoutUrl = 'account/logout';

export const login = (data) => axiosInstanceSalesAPI.post(loginUrl, data).then(({ data }) => data);

export const refreshToken = (token, refreshToken) =>
  axiosInstanceSalesAPI
    .post(refreshTokenUrl, {
      token,
      refreshToken,
    })
    .then(({ data }) => data);

export const logout = () => axiosInstanceSalesAPI.post(logoutUrl).then(({ data }) => data);
