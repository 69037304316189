import jwtDecode from 'jwt-decode';
import { t } from '../utils/lang';
import { PROFILE_FETCH_USER } from './user';
import { hideAllNotifications, showWarning } from './layout';
import errorHandler from '../utils/error';
import { subscribeNotifications, unsubscribeNotifications } from './header';
import {
  setSalesToken,
  setSalesRefreshToken,
  setSalesSessionId,
  getSalesToken,
  removeSalesToken,
} from '~/helpers/cookies';
import { remove, removeSalesRoleToken, set, setSalesRoleToken } from '~/helpers/localStorage';
import { userNotificationsGetData } from './user_notifications';
import { login as loginRequest, logout as logoutRequest } from './api/auth';
import { getCompanies as getCompaniesRequest } from './api/companies';
import { loadCompanyLocalization } from './localization';
import { localizationKey } from '~/constants/storageKeys';

export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGIN_BLOCK_TOGGLE_LOADING = 'LOGIN_BLOCK_TOGGLE_LOADING';
export const GET_COMPANIES = 'GET_COMPANIES';
export const SHOW_LOGIN_ERROR = 'SHOW_LOGIN_ERROR';
export const SET_TRIAL_EXPIRED_AT = 'SET_TRIAL_EXPIRED_AT';
export const SET_REDIRECT_ROUTE = 'SET_REDIRECT_ROUTE';

export const showLoginError = (error) => (dispatch) => {
  dispatch({ type: SHOW_LOGIN_ERROR, error });
};

export const hideLoginError = () => (dispatch) => {
  dispatch({ type: SHOW_LOGIN_ERROR, error: '' });
};

export const toggleBlockLoading = (block, loading) => (dispatch) => {
  dispatch({ type: LOGIN_BLOCK_TOGGLE_LOADING, block, loading });
};

export const setTrialExpiredAt = (trialExpiredAt) => (dispatch) => {
  dispatch({ type: SET_TRIAL_EXPIRED_AT, payload: trialExpiredAt });
};

export const setRedirectRoute = (redirectRoute) => (dispatch) => {
  dispatch({ type: SET_REDIRECT_ROUTE, payload: redirectRoute });
};

export const getCompanies = () => (dispatch) => {
  dispatch(hideLoginError());
  dispatch(toggleBlockLoading('companies', true));

  getCompaniesRequest()
    .then((companies) => {
      dispatch({ type: GET_COMPANIES, result: companies });
    })
    .catch((err) => {
      dispatch({ type: GET_COMPANIES, result: [] });
      if (err.response && err.response.status === 401) {
        dispatch(showLoginError(t.t('login_page.incorrect_email_or_password')));
      } else if (err.response && err.response.status === 400) {
        dispatch(showLoginError(t.t('login_page.user_does_not_have_companies')));
      }
    })
    .then(() => {
      dispatch(toggleBlockLoading('companies', false));
    });
};

export const login = (history, email, password, companyId) => (dispatch) => {
  dispatch(hideAllNotifications());
  dispatch(hideLoginError());
  dispatch(toggleBlockLoading('general', true));

  let userInfo;
  let warnings = [];

  loginRequest({
    email,
    password,
    companyId,
  })
    .then((data) => {
      // TODO: sessionId has TTL = 30 minutes, need to refresh it!
      const { token, refreshToken, role, sessionId } = data;

      setSalesToken(token);
      setSalesRefreshToken(refreshToken);
      setSalesRoleToken(role);
      setSalesSessionId(sessionId);
      set('branches', JSON.stringify(data.branches));

      userInfo = jwtDecode(token);
      dispatch({ type: LOGGED_IN, payload: data.branches });
      dispatch({
        type: PROFILE_FETCH_USER,
        user: userInfo,
      });
      dispatch(subscribeNotifications(userInfo._id));
      dispatch(setCurrentBranchName(userInfo.salesCompany.branchName));
      warnings = data.warnings;
    })
    .then((response) => {
      const { trialExpiredAt } = userInfo || {};

      const redirectUrl = '/dashboard';
      if (warnings.length > 0) {
        warnings.forEach((el) => {
          dispatch(showWarning(t.t(`login_page.warnings.${el.code}`)));
        });
      }
      if (trialExpiredAt) {
        dispatch(setTrialExpiredAt(trialExpiredAt));
        dispatch(setRedirectRoute(redirectUrl));
      } else {
        history.push(redirectUrl);
      }
      dispatch(userNotificationsGetData(1));
      dispatch(loadCompanyLocalization(companyId));
    })
    .catch((error) => {
      if (error.response && error.response.data.type === 'DmsApiError') {
        if (error.response.data.status === 401) {
          dispatch(showLoginError(t.t(`login_page.errors.wrong_username`)));
        } else if (error.response.data.status === 503) {
          dispatch(showLoginError(t.t(`login_page.errors.erp_down`)));
        } else {
          dispatch(showLoginError(t.t(`login_page.errors.contact_admin`)));
        }
      } else {
        dispatch(showLoginError(t.t(`login_page.errors.contact_admin`)));
      }
    })
    .then(() => {
      dispatch(toggleBlockLoading('general', false));
    });
};

export const LOGOUT = 'LOGOUT';
export const logout = (history) => (dispatch) => {
  const token = getSalesToken();

  if (token) {
    dispatch({ type: LOGGED_OUT });
    logoutRequest()
      .then(() => {
        dispatch(unsubscribeNotifications());
        removeSalesToken();
        removeSalesRoleToken();
        remove(localizationKey);
        window.localStorage.removeItem('__SALES_token');
      })
      .catch((error) => errorHandler(error, dispatch));
  }
  history && window.location.pathname !== '/login' && history.push('/login');

  dispatch({
    type: LOGOUT,
  });
};

export const SET_CURRENT_BRANCH_NAME = 'SET_CURRENT_BRANCH_NAME';
export const setCurrentBranchName = (branchName) => (dispatch) =>
  dispatch({
    type: SET_CURRENT_BRANCH_NAME,
    payload: branchName,
  });
