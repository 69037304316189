/** @namespace process */

const config = window.config;
const env = process.env;

if (env.REACT_APP_TYPE) {
  config.appType = env.REACT_APP_TYPE;
}
if (env.REACT_APP_API_SERVER) {
  config.apiUrl = env.REACT_APP_API_SERVER;
}
if (env.REACT_APP_SYNC_SERVER) {
  config.syncUrl = env.REACT_APP_SYNC_SERVER;
}
if (env.REACT_APP_UTILITIES) {
  config.utilitiesUrl = env.REACT_APP_UTILITIES;
}

export default config;
