import Cookies from 'js-cookie';
import { getCurrentUserInfo } from '~/utils/user';
import {
  LOGGED_IN,
  GET_COMPANIES,
  LOGGED_OUT,
  SHOW_LOGIN_ERROR,
  LOGIN_BLOCK_TOGGLE_LOADING,
  SET_CURRENT_BRANCH_NAME,
  SET_TRIAL_EXPIRED_AT,
  SET_REDIRECT_ROUTE,
} from '../actions/login';
import { SET_IS_NOTIFICATION_SUBSCRIPTION } from '../actions/header';

const initialState = {
  isLogin: Boolean(Cookies.get('__SALES_token')),
  currentBranchName: getCurrentUserInfo()?.salesCompany?.branchName,
  companies: [],
  branches: [],
  loaders: {
    general: false,
    companies: false,
  },
  loginError: '',
  isSubscription: false,
  trialExpiredAt: null,
  redirectRoute: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGGED_IN: {
      return { ...state, isLogin: true, branches: action.payload };
    }

    case SET_IS_NOTIFICATION_SUBSCRIPTION: {
      return {
        ...state,
        isSubscription: action.payload,
      };
    }

    case GET_COMPANIES: {
      return {
        ...state,
        companies: action.result,
      };
    }

    case SHOW_LOGIN_ERROR: {
      return { ...state, loginError: action.error };
    }

    case LOGIN_BLOCK_TOGGLE_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.block]: action.loading,
        },
      };
    }

    case LOGGED_OUT: {
      return initialState;
    }

    case SET_CURRENT_BRANCH_NAME: {
      return {
        ...state,
        currentBranchName: action.payload,
      };
    }

    case SET_TRIAL_EXPIRED_AT: {
      return { ...state, trialExpiredAt: action.payload };
    }

    case SET_REDIRECT_ROUTE: {
      return { ...state, redirectRoute: action.payload };
    }

    default: {
      return state;
    }
  }
}
