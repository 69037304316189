import {
  UPDATE_CONTENT_DATA,
  LEAD_SET_DATA,
  LEAD_GET_DICTS,
  SET_LEAD_CUSTOMER,
  LEAD_SET_LOADING,
  LEAD_SET_CUSTOMER_CODE,
  LEAD_SET_ACTIVITIES,
} from '../actions/lead';

const initialState = {
  content: {
    cars: [],
    amount: 0,
  },
  customer: {},
  leadData: {},
  dictionaries: {
    titles: [
      { label: 'Mr.', value: 'Mr.' },
      { label: 'Mrs.', value: 'Mrs.' },
    ],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LEAD_SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case UPDATE_CONTENT_DATA: {
      return {
        ...state,
        content: { ...state.content, [action.name]: action.payload },
      };
    }

    case LEAD_SET_DATA:
      return { ...state, leadData: action.payload };

    case LEAD_SET_ACTIVITIES:
      return {
        ...state,
        leadData: {
          ...state.leadData,
          Activities: action.payload,
        },
      };

    case LEAD_GET_DICTS:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          ...action.payload,
        },
      };

    case SET_LEAD_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };

    case LEAD_SET_CUSTOMER_CODE:
      return {
        ...state,
        leadData: {
          ...state.leadData,
          CustomerCode: action.payload,
        },
      };

    default: {
      return state;
    }
  }
}
