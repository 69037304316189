import {
  SET_ATTACHMENT,
  ATTACHMENT_SET_ROWS_PER_PAGE,
  CHANGE_ATTACHMENT_LOADER,
  CHANGE_PROGRESS_LOADING_ATTACHMENT,
} from '../actions/attachment';

const initialState = {
  data: [],
  progressImages: [],
  loader: false,
  paging: {
    currentPage: 0,
    pageSize: 20,
    pagingTo: 20,
    pagingFrom: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ATTACHMENT: {
      return { ...state, data: action.payload };
    }

    case CHANGE_PROGRESS_LOADING_ATTACHMENT: {
      return { ...state, progressImages: action.payload };
    }

    case CHANGE_ATTACHMENT_LOADER: {
      return { ...state, loader: action.payload };
    }

    case ATTACHMENT_SET_ROWS_PER_PAGE:
      return {
        ...state,
        paging: {
          ...state.paging,
          ...action.payload,
        },
      };

    default: {
      return state;
    }
  }
}
