import axiosInstance from '../utils/axios';
import { showWarning } from './layout';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { changeNotificationsCount, CHANGE_USER_NOTIFICATIONS_COUNT } from './header';
import { getContentQuote, getApprovalRequestsForDocument, getContentOrder } from './opportunity_content';

export const USER_NOTIFICATIONS_CHANGE_PAGE = 'USER_NOTIFICATIONS_CHANGE_PAGE';

export const USER_NOTIFICATIONS_TOGGLE_LOADING = 'USER_NOTIFICATIONS_TOGGLE_LOADING ';
export const userNotificationsToggleLoading = (payload) => (dispatch) => {
  dispatch({ type: USER_NOTIFICATIONS_TOGGLE_LOADING, payload });
};

const getNameAndInitials = (data) => {
  let initials = '';
  let user = '';
  let fn = data?.FirstName;
  let ln = data?.LastName;

  initials =
    fn && ln
      ? fn[0].toUpperCase() + ln[0].toUpperCase()
      : fn
      ? fn[0].toUpperCase()
      : ln
      ? ln[0].toUpperCase()
      : '';
  user = fn && ln ? fn + ' ' + ln : fn ? fn : ln ? ln : '';

  return {
    initials,
    user,
  };
};

const getNote = (metadata = {}) => {
  return metadata.Notes;
};

const checkNotificationIsApproval = (not) => {
  let opportunityId = window.location.pathname.split('/')[2];
  let opportunityNotificationId = not?.Metadata?.LinkedToId?.Id;
  // is new notification of updated approval of currently opened opportunity
  return (
    !not.Status &&
    not.LinkedObjectType === 'ApprovalRequest' &&
    not.Action === 'Updated' &&
    opportunityId === opportunityNotificationId
  );
};

export const USER_NOTIFICATIONS_GET_DATA = 'USER_NOTIFICATIONS_GET_DATA';
export const userNotificationsGetData = (currentPage) => (dispatch, getState) => {
  if (currentPage !== -1) {
    const { paging, data } = getState().userNotifications;
    const { pageSize } = paging;

    dispatch({ type: USER_NOTIFICATIONS_TOGGLE_LOADING, payload: true });
    let result = [];

    const token = Cookies.get('__SALES_token');
    const userInfo = jwtDecode(token);
    const userId = userInfo._id;
    axiosInstance
      .get(
        `/xassales/v1/UserNotificationsListService?filter=addressee:=:${userId}&sort=createdAt:-1&currentPage=${currentPage}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
          },
        },
      )
      .then((res) => {
        let pageNum = currentPage;
        let countUnread = 0;

        result = res.data.map((not) => {
          if (!not.Status) {
            countUnread++;
          }

          if (checkNotificationIsApproval(not)) {
            dispatch(getContentQuote(not?.Metadata?.LinkedToId?.Id, 'Opportunity'));
            dispatch(getContentOrder(not?.Metadata?.LinkedToId?.Id, 'Opportunity'));
            dispatch(getApprovalRequestsForDocument(not?.Metadata?.LinkedToId?.Id));
          }

          return {
            ...not,
            id: not.Id,
            ...getNameAndInitials(not.SenderMetadata),
            title: not.Subject,
            text: not.Body,
            date: not.CreatedAt,
            isRead: not.Status,
            note: getNote(not.Metadata),
          };
        });
        if (currentPage !== 1) {
          result = [...data, ...result];
        }

        pageNum = res.data.length ? currentPage + 1 : -1;

        dispatch({ type: USER_NOTIFICATIONS_GET_DATA, payload: result });
        dispatch({ type: USER_NOTIFICATIONS_CHANGE_PAGE, payload: pageNum });
        dispatch({ type: CHANGE_USER_NOTIFICATIONS_COUNT, payload: countUnread });
      })
      .catch((err) => {
        console.log(err);
        dispatch(showWarning(err.message));
      });
  }
};

export const userNotificationsRemove = (id) => (dispatch, getState) => {
  let notifications = getState().userNotifications.data.filter((not) => {
    if (not.id === id && !not.isRead) {
      dispatch(changeNotificationsCount(-1));
    }
    return not.id !== id;
  });
  axiosInstance.delete(`/xassales/v1/UserNotificationsService/${id}`, {
    headers: {
      Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
    },
  });
  dispatch({ type: USER_NOTIFICATIONS_GET_DATA, payload: notifications });
};

const readNotification = (id) => {
  axiosInstance.patch(
    `/xassales/v1/UserNotificationsService/${id}`,
    {
      Status: true,
    },
    {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    },
  );
};

export const userNotificationsRead = (id) => (dispatch, getState) => {
  let notifications = getState().userNotifications.data;
  notifications[notifications.findIndex((item) => item.id === id)].isRead = true;
  readNotification(id);
  dispatch(changeNotificationsCount(-1));
  dispatch({ type: USER_NOTIFICATIONS_GET_DATA, payload: notifications });
};

export const userNotificationsReadAll = () => (dispatch, getState) => {
  const payload = getState().userNotifications.data.map((not) => {
    if (!not.isRead) {
      readNotification(not.id);
      dispatch(changeNotificationsCount(-1));
    }
    return {
      ...not,
      isRead: true,
    };
  });

  dispatch({ type: USER_NOTIFICATIONS_GET_DATA, payload });
};
