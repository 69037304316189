import { PDF_GET_DATA } from '../actions/pdf';

export const initialState = {
  pdf: null,
  loading: false,
  reportId: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PDF_GET_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
