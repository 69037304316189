import { axiosInstanceSalesAPI } from '~/utils/axios';
import { PageSizeAll, emptyString } from '~/constants';

const listUrl = 'VehicleColorsListService';

export const getVehicleColors = (query = emptyString) =>
  axiosInstanceSalesAPI
    .get(`${listUrl}${query}`, {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then(({ data }) => data);
