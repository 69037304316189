import {
  CHANGE_TYPE_VISIBLE,
  CHANGE_USER_NOTIFICATIONS_VISIBLE,
  CHANGE_USER_NOTIFICATIONS_COUNT,
} from '../actions/header';

const initialState = {
  visibleType: null,
  userNotificationsVisible: false,
  userNotificationsCount: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_TYPE_VISIBLE: {
      return {
        ...state,
        visibleType: action.payload,
      };
    }
    case CHANGE_USER_NOTIFICATIONS_VISIBLE: {
      return {
        ...state,
        userNotificationsVisible: action.payload,
      };
    }
    case CHANGE_USER_NOTIFICATIONS_COUNT: {
      return {
        ...state,
        userNotificationsCount: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
