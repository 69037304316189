import { getAuthorizationHeaders } from '~/actions/api';
import { emptyString } from '~/constants';
import { SAP_INTERNAL_ERROR } from '~/constants/errorCodes';
import { formatSAPError, formatDeFactoError, formatValidationError } from '~/helpers/error';
import { store } from '~/App';
import { t } from '~/utils/lang';
import { showWarning } from '~/actions/layout';
import { getCompanyIdFromCookies, getSalesSessionId } from '~/helpers/cookies';

// TODO: localization
const isRecentlyUpdatedMessage =
  'Changes were made to this page in external system. Please refresh before saving';

// ???
const HideErrorResponses = ['/xassales/v1/LeadsService/import'];

export const checkIsUnauthorizedError = (error) =>
  error.response && error.response.status === 401 && error.response.data.type !== 'DmsApiError';

export const checkIsNotException = ({ config }) => {
  const errorUrl = config.url;
  return !HideErrorResponses.includes(errorUrl);
};

export const setAuth = (config) => {
  const authHeaders = getAuthorizationHeaders();

  const { headers = {} } = config;

  config.headers = {
    ...headers,
    ...authHeaders,
  };
};

export const setSessionId = (config) => {
  const sessionId = getSalesSessionId();

  const { headers = {} } = config;

  if (sessionId) {
    config.headers = {
      ...headers,
      sessionId,
    };
  }
};

export const setCompanyId = (config) => {
  const companyId = getCompanyIdFromCookies();

  const { data = {} } = config;

  setSessionId(config);

  if (companyId) {
    config.data = {
      ...data,
      companyId,
    };
  }
};

// ???
const checkSyncActivityToSAP = (error) => {
  const errorCode = error?.response?.data?.SAPCode;
  const url = error?.response?.config?.url || emptyString;

  const isSyncActivityError =
    errorCode === SAP_INTERNAL_ERROR && url.includes('OA_WEBActivitySync');

  return isSyncActivityError;
};

export const showErrorMessage = (error) => {
  const errorMessage =
    error.response?.data?.error?.value ||
    error.response?.data?.error ||
    error.response?.data?.customMessage;
  let errorMessageSAP = error.response?.data?.SAPError;
  let errorMessageDeFacto = error.response?.data?.DeFactoError;
  const isRecentlyUpdated = error.response?.data?.isRecentlyUpdated || false;
  const description = error.response?.data?.description;
  let validationError = error.response?.data?.validationMessage;

  if (errorMessageSAP) {
    errorMessageSAP = formatSAPError(errorMessageSAP);
  }

  if (errorMessageDeFacto) {
    errorMessageDeFacto = formatDeFactoError(errorMessageDeFacto);
  }

  if (validationError) {
    validationError = formatValidationError(validationError);
  }

  let message = errorMessage || errorMessageSAP || errorMessageDeFacto || validationError;

  if (isRecentlyUpdated) {
    message = isRecentlyUpdatedMessage;
  }

  if (checkSyncActivityToSAP(error)) {
    store.dispatch(showWarning(t.t('errors.sync_activity'), 'Error'));
    return;
  }

  if (message) {
    if (description) {
      message = `${description}: ${message}`;
    }
    store.dispatch(showWarning(message, 'Error'));
    return;
  }
};
