import {
  getVehicleCategoriesList,
  createCategory as createCategoryServer,
} from './api/vehicle_categories';
import vehicleCategoriesData from '~/data/vehicle_categories.json';
import { getOperationByParam } from '~/components/opportunities/table/helper';
import { isObjectsEqual } from '~/utils/general';
import axiosInstance from '~/utils/axios';
import { getAuthorizationHeaders } from './api';
import { PageSizeAll } from '~/constants';

const keysSearch = {
  Name: 'name',
  Code: 'code',
  SearchLayout: 'searchLayout',
};

const getFilterQuery = (filterValues) => {
  const search =
    filterValues.search && filterValues.search.value
      ? `filter=$text:${filterValues.search.value}`
      : '';

  let filterString = '?';

  Object.keys(filterValues).forEach((key, i) => {
    if (filterValues[key] && filterValues[key].value && key !== 'search') {
      let filterKey = keysSearch[key] || key;
      let operation = getOperationByParam(filterValues[key].params);
      let value = filterValues[key].value;
      let isEmptyRange = null;

      switch (filterValues[key].params) {
        case 'is empty':
        case 'is not empty':
          value = null;
          break;
        default:
      }

      if (!isEmptyRange) {
        let filterPart = `${filterKey}:${operation}:${value}`;
        let filterPartExists = filterString.split(filterPart).length > 1;

        if (!filterPartExists) {
          filterString += `${filterString.length > 1 ? '&' : ''}filter=${filterPart}`;
        }
      }
    }
  });

  const pageSizeAndSort = `pageSize=all&sort=name:1`;

  filterString =
    filterString.length > 1
      ? search
        ? `${filterString}${search}&${pageSizeAndSort}`
        : `${filterString}&${pageSizeAndSort}`
      : search
      ? `?${search}&${pageSizeAndSort}`
      : `?${pageSizeAndSort}`;

  return filterString;
};

export const VEHICLE_CATEGORIES_CREATE = 'VEHICLE_CATEGORIES_CREATE';
export const createCategory = (payload) => (dispatch) => {
  dispatch({ type: VEHICLE_CATEGORIES_CREATE, payload });
  createCategoryServer(payload);
};

export const VEHICLE_CATEGORIES_DELETE = 'VEHICLE_CATEGORIES_DELETE';
export const deleteCategory = (payload) => (dispatch) => {
  dispatch({ type: VEHICLE_CATEGORIES_DELETE, payload });
  // deleteCampaignServer(payload);
};

export const VEHICLE_CATEGORIES_UPDATE = 'VEHICLE_CATEGORIES_UPDATE';
export const updateCategory = (payload) => (dispatch, getState) => {
  let vehicleCategories = getState().vehicleCategories.data;
  vehicleCategories.forEach((item, i) => {
    if (item.id === payload.id) {
      vehicleCategories[i] = payload;
    }
  });
  dispatch({ type: VEHICLE_CATEGORIES_UPDATE, payload: vehicleCategories });
  // updateCampaignServer(payload);
};

export const VEHICLE_CATEGORIES_GET_LIST = 'VEHICLE_CATEGORIES_GET_LIST';
export const VEHICLE_CATEGORIES_LOADING = 'VEHICLE_CATEGORIES_LOADING';
export const getCategories = () => (dispatch, getState) => {
  const { filterValues } = getState().vehicleCategories;
  dispatch({ type: VEHICLE_CATEGORIES_LOADING, payload: true });
  getVehicleCategoriesList(getFilterQuery(filterValues)).then((res) => {
    dispatch({ type: VEHICLE_CATEGORIES_GET_LIST, payload: res });
    dispatch({ type: VEHICLE_CATEGORIES_LOADING, payload: false });
  });
};

export const CHANGE_VEHICLE_CATEGORIES_FILTER_NAMES = 'CHANGE_VEHICLE_CATEGORIES_FILTER_NAMES';
export const VEHICLE_CATEGORIES_CHANGE_FILTERS = 'VEHICLE_CATEGORIES_CHANGE_FILTERS';
export const changeFilters = (key, value, params) => (dispatch, getState) => {
  let filterNames = getState().vehicleCategories.filterNames;
  const isKey = filterNames.includes(key);
  if (isKey && !value) {
    filterNames.filter((item) => item !== key);
  }

  if (!isKey && value) {
    filterNames.push(key);
  }

  dispatch({ type: CHANGE_VEHICLE_CATEGORIES_FILTER_NAMES, payload: filterNames });
  dispatch({ type: VEHICLE_CATEGORIES_CHANGE_FILTERS, key, value, params });
};

export const VEHICLE_CATEGORIES_CHANGE_MODAL_FIELD = 'VEHICLE_CATEGORIES_CHANGE_MODAL_FIELD';
export const changeModalField = (key, value) => (dispatch) => {
  dispatch({ type: VEHICLE_CATEGORIES_CHANGE_MODAL_FIELD, key, value });
};

export const VEHICLE_CATEGORIES_SET_DEFAULT_FILTERS_PARAMS =
  'VEHICLE_CATEGORIES_SET_DEFAULT_FILTERS_PARAMS';
export const setValueForFilter = (count) => (dispatch, getState) => {
  const { filteredData } = getState().filters;
  let payload = null;
  if (count) {
    payload = filteredData.find((item) => item.id === count || item.name === count).filterValues;
  }

  if (!payload) {
    payload = vehicleCategoriesData.filterValues;
  }

  dispatch({ type: VEHICLE_CATEGORIES_SET_DEFAULT_FILTERS_PARAMS, payload });
};

export const VEHICLE_CATEGORIES_DISABLE_NEW_FILTER = 'VEHICLE_CATEGORIES_DISABLE_NEW_FILTER';
export const disableNewFilter = (key) => (dispatch, getState) => {
  const { filteredData } = getState().filters;
  const { filterValues } = getState().vehicleCategories;

  if (key) {
    const item = filteredData.find((item) => item.id === key);
    const payload = isObjectsEqual(filterValues, item.filterValues);
    dispatch({ type: VEHICLE_CATEGORIES_DISABLE_NEW_FILTER, payload });
  } else {
    const payload = !Object.keys(filterValues).reduce((accumulator, item) => {
      return accumulator || !!filterValues[item].value;
    }, false);
    dispatch({ type: VEHICLE_CATEGORIES_DISABLE_NEW_FILTER, payload });
  }
};

export const vehicleCategoriesGetFilterCounter = (filterValues) =>
  getVehicleCategoriesList(getFilterQuery(filterValues)).then((res) => {
    return res.length;
  });

export const VEHICLE_CATEGORIES_GET_DICTIONARIES = 'VEHICLE_CATEGORIES_GET_DICTIONARIES';
export const getDictionaries = () => (dispatch) => {
  //get number of doors
  axiosInstance
    .get(`/xassales/v1/NumberOfDoorsListService?sort=name:1&pageSize=${PageSizeAll}`, {
      headers: getAuthorizationHeaders(),
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: VEHICLE_CATEGORIES_GET_DICTIONARIES, payload: { numberOfDoors: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  //get number of seats
  axiosInstance
    .get(`/xassales/v1/NumberOfSeatsListService?sort=name:1&pageSize=${PageSizeAll}`, {
      headers: getAuthorizationHeaders(),
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: VEHICLE_CATEGORIES_GET_DICTIONARIES, payload: { numberOfSeats: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  //get luggage capacities
  axiosInstance
    .get(`/xassales/v1/LuggageCapacitiesListService?sort=name:1&pageSize=${PageSizeAll}`, {
      headers: getAuthorizationHeaders(),
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: VEHICLE_CATEGORIES_GET_DICTIONARIES, payload: { luggageCapacities: data } });
    })
    .catch((err) => {
      console.log(err);
    });
};
