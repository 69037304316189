import { axiosInstanceSalesAPI } from '~/utils/axios';
import { PageSizeAll } from '~/constants';

const listUrl = 'account/companies';

export const getCompanies = () =>
  axiosInstanceSalesAPI
    .get(listUrl, {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then((response) => {
      return response?.data?.companies || [];
    });
