import { axiosInstanceSalesAPI } from '~/utils/axios';
import { formatLeadForApiByImport, formatLinkedLead } from '~/helpers/dataAdapters/lead';
import { queryParamsBuilder } from '~/helpers/filter';

const leadsUrl = 'leads';
const LeadsServiceUrl = 'LeadsService';
const LeadsListServiceUrl = 'LeadsListService';
const LinkedLeadsUrl = 'LinkedLeadsService';
const LeadDictionaries = 'LeadDictionaries';

/* API */

export const createLead = async (data) =>
  axiosInstanceSalesAPI.post(LeadsServiceUrl, data).then(({ data }) => data);

export const createLeadByImport = async (data) =>
  axiosInstanceSalesAPI
    .post(`${LeadsServiceUrl}/import`, formatLeadForApiByImport(data))
    .then((response) => response.data);

export const getLeads = async (params) =>
  axiosInstanceSalesAPI
    .get(LeadsListServiceUrl, {
      params: {
        ...queryParamsBuilder(params),
        pagingResponse: true,
      },
    })
    .then((response) => response.data);

export const getLinkedLeadsList = async (leadId) =>
  axiosInstanceSalesAPI
    .get(`${LeadsListServiceUrl}/linked/${leadId}`)
    .then((response) => response.data.map(formatLinkedLead));

export const handleLinkedLeads = async (mainleadId, data) =>
  axiosInstanceSalesAPI
    .post(`${LinkedLeadsUrl}/${mainleadId}`, data)
    .then((response) => response.data);

export const syncLeadCustomer = async (id) => {
  const url = `${leadsUrl}/${id}/syncCustomer`;

  return axiosInstanceSalesAPI.post(url).then(({ data }) => data);
};

export const updateLeadCustomer = async (id, customerData) => {
  const url = `${leadsUrl}/${id}/updateCustomer`;

  return axiosInstanceSalesAPI.patch(url, customerData).then(({ data }) => data);
};

export const getLead = async (id) =>
  axiosInstanceSalesAPI.get(`${LeadsServiceUrl}/${id}`).then(({ data }) => data);

export const updateLead = async (id, data) =>
  axiosInstanceSalesAPI.patch(`${LeadsServiceUrl}/${id}`, data).then(({ data }) => data);

export const deleteLead = async (id) =>
  axiosInstanceSalesAPI.delete(`${LeadsServiceUrl}/${id}`).then((_response) => true);

export const getLeadDictionaries = () =>
  axiosInstanceSalesAPI.get(LeadDictionaries).then(({ data }) => data);
