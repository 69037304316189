import Cookies from 'js-cookie';
import axiosInstance, { axiosInstanceSync } from '../utils/axios';
import cloneDeep from 'lodash.clonedeep';
import isEmpty from 'lodash.isempty';
import { getUserFromCookies } from './user';
import { GET_QUOTE } from './opportunity_sales_quote';
import { clearLinesToDelivery, GET_ORDER } from './opportunity_order';
import { modelCar, modelPatch } from '../models/opportunity_content';
import { changeGeneralFieldAction, opportunitySetData } from '../actions/opportunity_new';
import { changeOpportunityField } from '../actions/opportunities_list';
import {
  dischargeMakePrimary,
  getDescriptionForOpportunity,
} from '../actions/opportunity_document';
import {
  createNewQuoteWithLines,
  createNoteForLine,
  updateCurrentQuoteWithLines,
} from '../actions/quotations';
import { getEquipment } from './vehicle_search';
import { getLineType } from '../helpers/dataAdapters/line';
import { LineTypePart, LineTypeTradeIn, LineTypeUsed, PageSizeAll } from '../constants';
import { isObjectsEqual } from '~/utils/general';
import { VSLineDetailsService } from './api/sync';
import { getLinesByKitId } from './api/vehicleModelVariantKit';
import { getDocument } from '~/helpers/dataAdapters/document';
import { DocumentType as VSLineDetailsDocumentType } from '~/actions/api/sync';
import { deleteQuoteLine, updateQuoteWithLines, getQuotations } from '~/actions/api/quotations';
import { createOrderWithLines, deleteOrderLine } from '~/actions/api/orders';
import { DocumentType } from '~/constants/document';
import { changeFieldSaveBtn } from './saveBtn';
import { createNewVehicle, updateVehicleTradeIn } from './trade_in';
import {
  getLinesForUpdate,
  updateCarsData,
} from '~/components/opportunity/components/content/helpers';
import { updateCurrentOrderWithLines } from './orders';
import { getQuoteFields } from '~/helpers/dataAdapters/quotation';
import history from '~/history';
import { ApprovalStatusPending } from '~/constants/approvals';
import { setItemPrices } from '~/helpers/VSLineDetails';
import { calculateAdditionalPricesForDocument } from '~/helpers/price';
import { getItems } from './api/items';
import { addSort } from '~/helpers/dataAdapters/contentCars';

export const OPPORTUNITY_CONTENT_SET_CURRENT_DELIVERY = 'OPPORTUNITY_CONTENT_SET_CURRENT_DELIVERY';

export const CHANGE_STATE_CONTENT = 'CHANGE_STATE_CONTENT';
export const OPPORTUNITY_CONTENT_TOGGLE_SYNC_LOADER = 'OPPORTUNITY_CONTENT_TOGGLE_SYNC_LOADER';
export const opportunityChangeLoader = (field, value) => (dispatch) => {
  dispatch({ type: OPPORTUNITY_CONTENT_TOGGLE_SYNC_LOADER, field, value });
};

export const SET_CARS = 'SET_CARS';
export const setCars = (cars) => (dispatch, getState) => {
  const { selectQuote, currentOrder } = getState().opportunity.content;

  const document = currentOrder || selectQuote || {};
  const { DiscountPercentage, TaxPercentage } = document;

  cars = calculateAdditionalPricesForDocument(cars, DiscountPercentage, TaxPercentage);

  dispatch({ type: CHANGE_DATA_CARS, payload: cars });
};

// TODO:
export const CHANGE_DATA_CARS = 'CHANGE_DATA_CARS';
export const changeDataCarItems = (lineId, data) => (dispatch, getState) => {
  const { content } = getState().opportunity;
  const { cars, selectQuote, currentOrder } = content;

  const document = currentOrder || selectQuote || {};

  let payload = cars.map((item) => {
    const car = cloneDeep(item);

    if (lineId && car.lineId === lineId) {
      return {
        ...car,
        ...data,
      };
    }

    const vinItem = car.parametrs?.specification?.vin || false;
    const vinCar = data.parametrs?.specification?.vin || false;
    if (!lineId && vinItem && vinCar && vinItem === vinCar) {
      return data;
    }

    return car;
  });

  const { DiscountPercentage = 0, TaxPercentage = 0 } = document;

  payload = calculateAdditionalPricesForDocument(payload, DiscountPercentage, TaxPercentage);

  dispatch({ type: CHANGE_DATA_CARS, payload });
};

// TODO:
export const changeDataCarItemsConfiguration = (data) => (dispatch, getState) => {
  const { content } = getState().opportunity;
  const payload = content.cars.map((item) => {
    const car = cloneDeep(item);

    if (car.lineId === data.parentLineId || car.id === data.carId) {
      if (data.typeLine === 'Accessory') {
        car.accessories = car.accessories.map((acc) => {
          if (acc.id === data.id) {
            return data;
          }
          return acc;
        });
      } else {
        car.configuration = car.configuration.map((conf) => {
          if (conf.id === data.id) {
            return data;
          }
          return conf;
        });
      }
    }
    return car;
  });

  dispatch({ type: CHANGE_DATA_CARS, payload: payload });
};

export const changeConfigurationAction = (car, isDelete, lineId) => (dispatch, getState) => {
  const { content } = getState().opportunity;
  const { currentOrder } = content;

  const isOrder = !!currentOrder;

  if (isDelete && lineId) {
    isOrder ? deleteOrderLine(lineId) : deleteQuoteLine(lineId);
  }

  const payload = content.cars.map((item) => {
    if (item.id === car.id) {
      return car;
    }

    return item;
  });

  addSort(payload);

  dispatch({ type: CHANGE_DATA_CARS, payload });
};

export const CHANGE_REMOVE_CARS = 'CHANGE_REMOVE_CARS';
export const removeDataAction = (car, cars) => (dispatch) => {
  const payload = cars.filter((item) => {
    if (car.lineId) {
      return item.lineId !== car.lineId;
    }
    return item.id !== car.id;
  });

  if (car.lineId) {
    dispatch({ type: CHANGE_REMOVE_CARS, payload: car });
  }

  dispatch({ type: CHANGE_DATA_CARS, payload });
};

export const OPPORTUNITY_UPDATE_CONTENT_DATA = 'OPPORTUNITY_UPDATE_CONTENT_DATA';
export const opportunityUpdateContentData = (payload) => (dispatch) => {
  const { cars } = payload;

  addSort(cars);

  dispatch({ type: OPPORTUNITY_UPDATE_CONTENT_DATA, payload });
};

export const GET_ACCESSORIES_LIST = 'GET_ACCESSORIES_LIST';
export const getAccessories = (documentType, opportunity) => async (dispatch, getState) => {
  try {
    const items = await getItems({
      pageSize: PageSizeAll,
      filter: 'itemType:=:Accessory',
    });

    const { configuration_list, service_list } = getState().opportunity.content;
    let accessory = [];
    const { DocRate, XAS_VSLINESSERVICE1Collection } = await dispatch(
      getQuoteLinePrices(items, documentType, opportunity),
    );

    accessory = items.map((item, i) => {
      const itemPrices =
        XAS_VSLINESSERVICE1Collection.find((lineDetails) => lineDetails.ItemCode === item.Code) ||
        {};

      const model = {
        name: item.Name,
        typeLine: 'Accessory',
        id: item.Id,
        price: item.Price,
        code: item.Code,
        quantity: 1,
        unitsOfMeasurment: item.UnitOfMeasureId,
        isAssembly: item.IsToAssembly,
        isDelivered: item.IsDelivered,
      };

      setItemPrices(model, itemPrices, DocRate);

      return modelPatch(model);
    });

    dispatch({
      type: GET_ACCESSORIES_LIST,
      accessory,
      option: configuration_list,
      service: service_list,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getPathList = (documentType, opportunity) => async (dispatch, getState) => {
  try {
    // TODO: filter
    let items = await getItems({
      pageSize: PageSizeAll,
    });

    const { accessories } = getState().opportunity.content;
    const service = [];
    const option = [];

    const resData = items.filter((res) => res.ItemType === 'Option' || res.ItemType === 'Service');

    const { DocRate, XAS_VSLINESSERVICE1Collection } = await dispatch(
      getQuoteLinePrices(resData, documentType, opportunity),
    );

    items.forEach((item, i) => {
      const itemPrices =
        XAS_VSLINESSERVICE1Collection.find((lineDetails) => lineDetails.ItemCode === item.Code) ||
        {};

      const model = {
        name: item.Name,
        modelIds: item.ModelIds,
        typeLine: item.ItemType === 'Option' ? LineTypePart : item.ItemType,
        id: item.Id,
        code: item.Code,
        quantity: 1,
        unitsOfMeasurment: item.UnitOfMeasureId,
        isAssembly: item.IsToAssembly,
        isDelivered: item.IsDelivered,
      };

      setItemPrices(model, itemPrices, DocRate);

      const patch = modelPatch(model);

      if (patch.typeLine === 'Part') {
        option.push(patch);
      }

      if (patch.typeLine === 'Service') {
        service.push(patch);
      }
    });

    dispatch({ type: GET_ACCESSORIES_LIST, accessories, option, service });
  } catch (error) {
    console.error(error);
  }
};

export const OPPORTUNITY_CHANGE_SELECT_QUOTE = 'OPPORTUNITY_CHANGE_SELECT_QUOTE';
export const changeFieldsCurrentDocument = (payload, type) => (dispatch, getState) => {
  const { selectQuote = {}, currentOrder = {} } = getState().opportunity.content;

  if (type === DocumentType.Quotation && !isObjectsEqual(selectQuote, payload)) {
    payload = {
      ...selectQuote,
      ...payload,
    };

    dispatch({ type: OPPORTUNITY_CHANGE_SELECT_QUOTE, payload: payload });
    dispatch({ type: GET_QUOTE, payload: payload });
  }

  if (type === DocumentType.Order && !isObjectsEqual(currentOrder, payload)) {
    payload = {
      ...currentOrder,
      ...payload,
    };

    dispatch({ type: OPPORTUNITY_CONTENT_SET_CURRENT_ORDER, payload: payload });
    dispatch({ type: GET_ORDER, payload: payload });
  }
};

export const setOpportunityCacheSelectQuote = (id, type) => (dispatch, getState) => {
  const date = new Date();
  const postingDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  const { customer } = getState().opportunity.new;
  const { VSDocumentDetails } = getState().opportunity.content;

  const cacheQuote = {
    ...VSDocumentDetails,
    Id: '',
    IsDraft: true,
    CustomerCode: customer.CustomerId,
    PostingDate: postingDate,
    DocumentStatus: 'Open',
    LinkedObjectCode: id,
    LinkedObjectName: type,
    Lines: [],
  };

  dispatch({ type: OPPORTUNITY_CHANGE_SELECT_QUOTE, payload: cacheQuote });
};

export const OPPORTUNITY_UPDATE_QUOTES = 'OPPORTUNITY_UPDATE_QUOTES';

export const getContentQuote = (id, type) => async (dispatch, getState) => {
  const { quotes } = getState().opportunity.content;

  return getQuotations({
    filter: [`linkedObjectCode:=:${id}`, `linkedObjectName:=:${type}`],
  })
    .then((response) => {
      if (!response.data.length) {
        dispatch(opportunityChangeLoader('content', false));
        return null;
      }

      const payload = [
        ...quotes,
        ...response.data.map((item) => {
          item.type = DocumentType.Quotation;
          return item;
        }),
      ];

      let selectQuote = response.data.find(
        (item) => item.DocumentStatus !== 'Cancelled' && item.IsPrimary,
      );

      if (!selectQuote) {
        selectQuote = response.data.find(
          (item) => item.DocumentStatus !== 'Cancelled' && item.IsDraft,
        );
      }

      dispatch({ type: OPPORTUNITY_UPDATE_QUOTES, payload: payload });
      if (selectQuote) {
        dispatch({ type: OPPORTUNITY_CHANGE_SELECT_QUOTE, payload: selectQuote });
      }

      return selectQuote;
    })
    .catch((err) => {
      console.log(err);
      return;
    });
};

export const createQuotesLine = (id, item) => async (dispatch) => {
  const { data, parentLineId } = item;
  const lineType = data.typeLine || data.lineType;
  const {
    quantity,
    unitPrice,
    priceAfterDiscount,
    priceIncludingTax,
    // discount,
    accountCode,
    projectCode,
    measureUnit,
    unitsOfMeasurment,
    barCode,
    supplierCatNum,
    costingCode,
    costingCode2,
    costingCode3,
    costingCode4,
    costingCode5,
    total,
    onHand,
    // warehouseCode,
    discountPercent = 0,
    taxCode,
    taxTotal,
    taxPercent,
    taxPercentage,
  } = data;

  const req = {
    Quantity: quantity,
    UnitPrice: unitPrice,
    QuantityOnHand: onHand,
    // WarehouseId: warehouseCode,
    TaxPercentage: taxPercent || taxPercentage,
    TaxCode: taxCode,
    TaxSum: taxTotal,
    PriceAfterDiscount: priceAfterDiscount,
    PriceIncludingTax: priceIncludingTax,
    DiscountPercentage: discountPercent,
    Total: parseInt(total),
    ShipToAddress: '', //todo
    AccountCode: accountCode,
    ProjectCode: projectCode,
    MeasureUnit: measureUnit,
    UnitsOfMeasurment: unitsOfMeasurment,
    BarCode: barCode,
    SupplierCatNum: supplierCatNum,
    CostingCode: costingCode,
    CostingCode2: costingCode2,
    CostingCode3: costingCode3,
    CostingCode4: costingCode4,
    CostingCode5: costingCode5,
    QuoteId: id,
    LineType: lineType,
  };

  if (lineType === 'Model') {
    req.ModelVariantId = data.ModelVariantId;
  }
  if (lineType === 'Used' || lineType === 'Trade-in') {
    req.VehicleId = data.id;
  }
  if (lineType === 'Service' || lineType === 'Part') {
    req.ParentLineId = parentLineId;
    req.ItemId = data.id;
  }
  if (lineType === 'Accessory') {
    req.ParentLineId = parentLineId;
    req.ItemId = data.id;
  }

  return await axiosInstance
    .post(`/xassales/v1/SalesQuotesLineService`, req, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let lineId = response.data._id;
      if (item.data.comments) {
        item.data.comments.forEach((comment) => createNoteForLine(lineId, comment));
      }
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const updateQuoteLines = async (items) => {
  const promises = items.map((item) => {
    const {
      quantity,
      unitPrice,
      priceAfterDiscount,
      priceIncludingTax,
      discountPercent = 0,
      accountCode,
      projectCode,
      measureUnit,
      unitsOfMeasurment,
      barCode,
      supplierCatNum,
      costingCode,
      costingCode2,
      costingCode3,
      costingCode4,
      costingCode5,
      total,
      onHand,
      // warehouseCode,
      taxCode,
      taxTotal,
      taxPercent,
      taxPercentage,
    } = item;

    const req = {
      Quantity: quantity,
      UnitPrice: unitPrice,
      QuantityOnHand: onHand,
      // WarehouseId: warehouseCode,
      TaxPercentage: taxPercent || taxPercentage,
      TaxCode: taxCode,
      TaxSum: taxTotal,
      PriceAfterDiscount: priceAfterDiscount,
      PriceIncludingTax: priceIncludingTax,
      DiscountPercentage: discountPercent,
      Total: total,
      ShipToAddress: '', //todo
      AccountCode: accountCode,
      ProjectCode: projectCode,
      MeasureUnit: measureUnit,
      UnitsOfMeasurment: unitsOfMeasurment,
      BarCode: barCode,
      SupplierCatNum: supplierCatNum,
      CostingCode: costingCode,
      CostingCode2: costingCode2,
      CostingCode3: costingCode3,
      CostingCode4: costingCode4,
      CostingCode5: costingCode5,
    };

    return axiosInstance
      .patch(`/xassales/v1/SalesQuotesLineService/${item.lineId || item.id}`, req, {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return Promise.all(promises)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getContentData =
  (lines = [], customerCode, docType = DocumentType.Quotation) =>
  async (dispatch, getState) => {
    let promises = [];
    switch (docType) {
      case DocumentType.Quotation:
        // getQuoteLine
        promises = lines.map((line) => line);
        break;
      case DocumentType.Delivery:
        // getDeliveryLine
        promises = lines.map((line) => line);
        break;
      default:
        // getOrderLine
        promises = lines.map((line) => line);
        break;
    }
    return Promise.all(promises)
      .then((models) => {
        const cars = [];
        const configuration = [];
        const accessory = [];

        models.forEach((item, i) => {
          if (item.LineType === 'Accessory') {
            const { Id, Code, Name } = item.ItemId;

            accessory.push(
              modelPatch({
                name: Name,
                typeLine: item.LineType,
                // TODO: this is WRONG, need to use uuid()
                lineId: item.Id,
                id: Id,
                rowExternalRef: item.RowExternalRef,
                quoteRowExternalRef: item.QuoteRowExternalRef,
                parentLineId: item.ParentLineId,
                type: 'accessory',
                code: Code,
                quantity: item.Quantity,
                unitPrice: item.UnitPrice,
                totalBeforeTax: item.TotalBeforeTax || item.UnitPrice * item.Quantity,
                price: item.UnitPrice,
                discountPercent: item.DiscountPercentage,
                total: item.Total,
                taxPercentage: item.TaxPercentage,
                priceAfterDiscount: item.PriceAfterDiscount,
                priceIncludingTax: item.PriceIncludingTax,
                accountCode: item.AccountCode,
                projectCode: item.ProjectCode,
                measureUnit: item.MeasureUnit,
                unitsOfMeasurment: item.UnitsOfMeasurment,
                barCode: item.BarCode,
                supplierCatNum: item.SupplierCatNum,
                costingCode: item.CostingCode,
                costingCode2: item.CostingCode2,
                costingCode3: item.CostingCode3,
                costingCode4: item.CostingCode4,
                costingCode5: item.CostingCode5,
                isAssembly: item.IsAssembly,
                isDelivered: item.IsDelivered,
                discountTotal: item.DiscountSum || 0,
                shippingMethod: item.ShippingMethod,
                deliveryAddress: item.DeliveryAddress,
                currency: item.Currency,
                rate: item.Rate,
                localPrice: item.LocalPrice,
                localTotal: item.LocalTotal,
                accountPrice: item.AccountPrice,
                accountTotal: item.AccountTotal,
                lineWeight: item.LineWeight,
                docDiscountTaxReduction: item.DocDiscountTaxReduction,
              }),
            );
          }

          if ((item.LineType === 'Service' || item.LineType === 'Part') && item.ItemId) {
            const { Id, Code, Name, ModelId, ItemType } = item.ItemId;
            const type = ItemType === 'Service' ? 'service' : 'configuration';

            configuration.push(
              modelPatch({
                parentLineId: item.ParentLineId,
                // TODO: this is WRONG, need to use uuid()
                lineId: item.Id,
                id: Id,
                rowExternalRef: item.RowExternalRef,
                quoteRowExternalRef: item.QuoteRowExternalRef,
                modelId: ModelId,
                type: type,
                typeLine: item.LineType,
                name: Name,
                code: Code,
                quantity: item.Quantity,
                unitPrice: item.UnitPrice,
                price: item.UnitPrice,
                totalBeforeTax: item.TotalBeforeTax || item.UnitPrice * item.Quantity,
                discountPercent: item.DiscountPercentage,
                total: item.Total,
                taxPercentage: item.TaxPercentage,
                priceAfterDiscount: item.PriceAfterDiscount,
                priceIncludingTax: item.PriceIncludingTax,
                accountCode: item.AccountCode,
                projectCode: item.ProjectCode,
                measureUnit: item.MeasureUnit,
                unitsOfMeasurment: item.UnitsOfMeasurment,
                barCode: item.BarCode,
                supplierCatNum: item.SupplierCatNum,
                costingCode: item.CostingCode,
                costingCode2: item.CostingCode2,
                costingCode3: item.CostingCode3,
                costingCode4: item.CostingCode4,
                costingCode5: item.CostingCode5,
                isAssembly: item.IsAssembly,
                isDelivered: item.IsDelivered,
                discountTotal: item.DiscountSum || 0,
                parametrs: {
                  details: {
                    gear_type: item.GearType,
                    number_gears: '',
                    displacement: item.EngineDisplacement,
                    cylinders: item.CylindrsNum,
                    weight: item.EmptyWeight,
                    body_color: item.Color?.Name,
                    interior_color: '',
                    number_doors: item.DoorsNum,
                    number_seats: item.SeatsNum,
                    fuel_type: item.FuelType,
                    images: item.ItemId?.Photos || [],
                  },
                },
                shippingMethod: item.ShippingMethod,
                deliveryAddress: item.DeliveryAddress,
                currency: item.Currency,
                rate: item.Rate,
                localPrice: item.LocalPrice,
                localTotal: item.LocalTotal,
                accountPrice: item.AccountPrice,
                accountTotal: item.AccountTotal,
                lineWeight: item.LineWeight,
                docDiscountTaxReduction: item.DocDiscountTaxReduction,
              }),
            );
          }

          const getFiedsCars = (item, Description, lineType) => {
            const isNew = lineType === 'New';
            let field = isNew && item.ModelVariantId.ModelId;

            if (lineType === 'Used' || lineType === 'Trade-in') {
              field = item.VehicleId;
            }

            let equipmentField =
              lines[i].ModelVariantId?.ModelId?.ModelProperties || field.ModelId?.ModelProperties;
            const equipment = getEquipment(equipmentField);
            const { Id, MakeName, BaseModelName, CO2EmissionCity, Year, VehicleCode } = field;
            const imageLink =
              (isNew ? item.ModelVariantId : field)?.PhotoLink ||
              (isNew ? item.ModelVariantId : field)?.ImageLink ||
              '';

            const itemCode = item.ModelVariantId?.Code || field.ItemCode || VehicleCode;
            const images = (isNew ? item.ModelVariantId : field).Photos || [];

            return modelCar({
              lineType: lineType,
              description: Description,
              // TODO: this is WRONG, need to use uuid()
              lineId: item.Id,
              id: Id,
              rowExternalRef: item.RowExternalRef,
              quoteRowExternalRef: item.QuoteRowExternalRef,
              ModelVariantId: isNew && item.ModelVariantId.Id,
              modelId: item.Id,
              code: itemCode,
              make: MakeName,
              base_model: BaseModelName,
              year: Year,
              itemCode: itemCode,
              price: item.UnitPrice,
              unitPrice: item.UnitPrice,
              totalBeforeTax: item.TotalBeforeTax || item.UnitPrice * item.Quantity,
              discount: item.DiscountPercentage,
              total: item.Total,
              discountPercent: item.DiscountPercentage,
              quantity: item.Quantity,
              co_2_emission: CO2EmissionCity,
              imageLink: imageLink,
              images: images,
              received: item.Received || false,
              isReserved: item.VehicleId?.IsReserved || false,
              discountTotal: item.DiscountSum || 0,
              taxPercentage: item.TaxPercentage,
              parametrs: {
                details: {
                  images: images,
                  number_gears: '',
                  displacement: field.EngineDisplacement,
                  cylinders: field.CylindrsNum,
                  weight: field.TotalWeight || field.EmptyWeight,
                  body_color: field.Color || '',
                  interior_color: '',
                },
                environment: {
                  consumption: {
                    comb: `${item.FuelConsumptionCombined} (comb)`,
                    city: `${item.CityCycleConsumption} (city)`,
                    country: `${item.LongDistanceConsumption} (country)`,
                  },
                  emission_label: item.EmissionSticker,
                },
                equipment,
                specification: {
                  make: item.VehicleId?.Make?.Id || '',
                  firstRegistrationDate: item.VehicleId?.FirstRegistrationDate || '',
                  baseModelId: item.VehicleId?.BaseModelId?.Id || '',
                  modelId: item.VehicleId?.ModelId?.Id || '',
                  year: item.VehicleId?.Year?.Id || '',
                  month: item.VehicleId?.Month || '',
                  licenseNumber: item.VehicleId?.LicenseNumber || '',
                  isVehicleHistory: item.VehicleId?.IsVehicleHistory || '',
                  isWarranty: item.VehicleId?.IsWarranty || '',
                  powerHP: item.VehicleId?.PowerHP || '',
                  evaluationPrice: item.VehicleId?.EvaluationPrice || '',
                  marketPrice: item.VehicleId?.MarketPrice || '',
                  mileage: item.VehicleId?.Mileage || '',
                  mileageType: item.VehicleId?.MileageType || '',
                  category: item.VehicleId?.Category?.Id || '',
                  vin: item.VehicleId?.Vin || '',
                  privousOwnersNumber: item.VehicleId?.PrivousOwnersNumber || '',
                  firstOwner: item.VehicleId?.FirstOwner || '',
                  currentOwner: item.VehicleId?.CurrentOwner || '',
                  vehicleColor: item.VehicleId?.VehicleColor?.Id,
                },
              },
              isDelivered: item?.IsDelivered || false,
              vehicleCode: item.VehicleId?.VehicleCode,
              customerType: item.CustomerType,
              tradeInInvoiceNumber: item.TradeInInvoiceNumber,
              shippingMethod: item.ShippingMethod,
              deliveryAddress: item.DeliveryAddress,
              currency: item.Currency,
              rate: item.Rate,
              localPrice: item.LocalPrice,
              localTotal: item.LocalTotal,
              accountPrice: item.AccountPrice,
              accountTotal: item.AccountTotal,
              lineWeight: item.LineWeight,
              docDiscountTaxReduction: item.DocDiscountTaxReduction,
              isAssembly: item.IsAssembly,
            });
          };

          if (item.LineType === 'Model' && item.ModelVariantId) {
            cars.push(getFiedsCars(item, item.ModelVariantId.Description, 'New'));
          }
          if (item.LineType === 'Used' && item.VehicleId) {
            const { Name } = item.VehicleId;
            cars.push(getFiedsCars(item, Name, 'Used'));
          }
          if (item.LineType === 'Trade-in' && item.VehicleId) {
            const { Name } = item.VehicleId;
            cars.push(getFiedsCars(item, Name, 'Trade-in'));
          }
        });

        let carsResult = [];
        if (cars && cars.length) {
          carsResult = cars.map((item) => {
            item.accessories = accessory.filter((acces) => item.lineId === acces.parentLineId);
            item.configuration = configuration.filter(
              (acces) => item.lineId === acces.parentLineId,
            );
            return item;
          });
        }
        return carsResult;
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: OPPORTUNITY_CONTENT_TOGGLE_SYNC_LOADER, field: 'content', value: false });
      });
  };

export const saveDataQuoteAction = (id, typeQuote, data) => async (dispatch, getState) => {
  const opportunity = getState().opportunity.content;
  const { selectQuote, removeCar } = opportunity;

  if (selectQuote) {
    let newLineCars = [];
    let newLineConf = [];
    data.forEach((item) => {
      if (!item.lineId) {
        newLineCars.push({ data: item });
      } else {
        item.configuration.forEach((conf) => {
          if (!conf.lineId) {
            newLineConf.push({ parentLineId: item.lineId, data: conf });
          }
        });
        item.accessories.forEach((acces) => {
          if (!acces.lineId) {
            newLineConf.push({ parentLineId: item.lineId, data: acces });
          }
        });
      }
    });

    //set potentialAmount
    dispatch(changeOpportunityField({ key: 'amount', value: selectQuote.Total, id: id }));

    // delete cars
    removeCar.forEach((item) => deleteQuoteLine(item.lineId));
    //

    const promise = newLineCars.map((item) => {
      return dispatch(createQuotesLine(selectQuote.Id, item));
    });

    return Promise.all(promise)
      .then((res) => {
        const filterData = data.filter((item) => !item.lineId);
        filterData.forEach((item, num) => {
          if (res[num] && res[num]._id) {
            item.configuration.forEach((conf) => {
              if (!conf.lineId) {
                newLineConf.push({ parentLineId: res[num]._id, data: conf });
              }
            });
            item.accessories.forEach((acces) => {
              if (!acces.lineId) {
                newLineConf.push({ parentLineId: res[num]._id, data: acces });
              }
            });
          }
        });

        const promiseConf = newLineConf.map((item) => {
          return dispatch(createQuotesLine(selectQuote.Id, item));
        });
        return Promise.all(promiseConf)
          .then((confRes) => console.log(confRes))
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const saveDataOrderAction = (id, data) => async (dispatch, getState) => {
  const opportunity = getState().opportunity.content;
  const { currentOrder, removeCar } = opportunity;

  if (currentOrder) {
    let newLineCars = [];
    let newLineConf = [];
    data.forEach((item) => {
      if (!item.lineId) {
        newLineCars.push({ data: item });
      } else {
        item.configuration.forEach((conf) => {
          if (!conf.lineId) {
            newLineConf.push({ parentLineId: item.lineId, data: conf });
          }
        });
        item.accessories.forEach((acces) => {
          if (!acces.lineId) {
            newLineConf.push({ parentLineId: item.lineId, data: acces });
          }
        });
      }
    });

    // delete cars
    removeCar.forEach((item) => deleteOrderLine(item.lineId));

    const promise = newLineCars.map((item) => {
      return dispatch(createOrderLine(currentOrder.Id, item));
    });

    return Promise.all(promise)
      .then((res) => {
        const filterData = data.filter((item) => !item.lineId);
        filterData.forEach((item, num) => {
          if (res[num] && res[num]._id) {
            item.configuration.forEach((conf) => {
              if (!conf.lineId) {
                newLineConf.push({ parentLineId: res[num]._id, data: conf });
              }
            });
            item.accessories.forEach((acces) => {
              if (!acces.lineId) {
                newLineConf.push({ parentLineId: res[num]._id, data: acces });
              }
            });
          }
        });

        const promiseConf = newLineConf.map((item) => {
          return dispatch(createOrderLine(currentOrder.Id, item));
        });
        return Promise.all(promiseConf)
          .then((confRes) => console.log(confRes))
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const getQuoteLinePrices =
  (lines, documentType, opportunity) => async (dispatch, getState) => {
    const documentLines = lines.map((line) => ({
      LineType: getLineType(line),
      ItemCode: line.Code,
    }));

    let customerCode = opportunity?.customer?.code;

    if (!customerCode) {
      const selectQuote = getState().opportunity.content.selectQuote;
      customerCode = selectQuote.CustomerCode?.Code;
    }

    try {
      const document = getDocument(documentLines, documentType, opportunity, customerCode);
      const linesDetails = await VSLineDetailsService(document);

      dispatch(updateOpportunityDocumentSeries(linesDetails));

      return linesDetails;
    } catch (error) {
      console.error(error);
    }
  };

export const updateOpportunityDocumentSeries = (data) => (dispatch, getState) => {
  const op = getState().opportunity.new;
  const { QuoteSeries, OrderSeries, DeliverySeries, InvoiceSeries } = data || {};

  const series = {};
  if (!op.QuoteSeries) {
    series.QuoteSeries = QuoteSeries;
  }
  if (!op.OrderSeries) {
    series.OrderSeries = OrderSeries;
  }
  if (!op.DeliverySeries) {
    series.DeliverySeries = DeliverySeries;
  }
  if (!op.InvoiceSeries) {
    series.InvoiceSeries = InvoiceSeries;
  }

  if (isEmpty(series) || !op.opportunityNumber) {
    return;
  }

  return axiosInstance
    .patch(`/xassales/v1/opportunities/` + op.opportunityNumber, series, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      return response && response.data && response.data.success;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updateImageForModel = (id, file) => (dispatch) => {
  const formData = new FormData();
  formData.append('file', file, file.name);

  axiosInstance
    .post(`/xassales/v1/AttachmentsFileService`, formData, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        'Content-Type': undefined,
      },
    })
    .then((response) => {
      // console.log(response, 'response', file)
    })
    .catch((err) => {
      console.log(err);
    });
};

export const SET_CREATE_QUOTE_BTN_PARAMS = 'SET_CREATE_QUOTE_BTN_PARAMS';
export const setCreateQuoteButtonParams =
  ({ onClick = () => {}, isVisible = false, isDisabled = false }) =>
  (dispatch) => {
    dispatch({ type: SET_CREATE_QUOTE_BTN_PARAMS, onClick, isVisible, isDisabled });
  };

export const CLEAR_CONTENT_DATA = 'CLEAR_CONTENT_DATA';
export const clearContentDataAction = () => (dispatch) => {
  dispatch({ type: CLEAR_CONTENT_DATA });
};

export const createOrderFromSelectQuote =
  (isDraft = false) =>
  async (dispatch, getState) => {
    try {
      const { selectQuote, cars } = getState().opportunity.content;
      const date = new Date();
      const postingDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

      let order = {
        PostingDate: postingDate,
        CustomerCode: selectQuote.CustomerCode.Rid,
        LinkedObjectCode: selectQuote.LinkedObjectCode,
        LinkedObjectName: selectQuote.LinkedObjectName,
        DocumentStatus: 'Open',
        IsDraft: isDraft,
        IsPrimary: !isDraft,
        Total: selectQuote.Total,
        TotalTradeIn: selectQuote.TotalTradeIn,
        TaxSum: selectQuote.TaxSum,
        TaxPercentage: selectQuote.TaxPercentage,
        DiscountSum: selectQuote.DiscountSum,
        DiscountPercentage: selectQuote.DiscountPercentage,
        TotalBeforeDiscount: selectQuote.TotalBeforeDiscount,
        ApprovalStatus: isDraft ? 'Pending' : 'Approved',
        BaseQuoteId: selectQuote.Id,
        QuoteExternalRef: selectQuote.ExternalRef,
      };

      await dispatch(updateDescriptionOpportunity(cars, selectQuote.LinkedObjectCode));
      const newOrderId = await dispatch(createOrder(order));

      const lines = selectQuote.Lines;

      let carLines = [];
      let carItemsLines = [];

      lines.forEach((line) => {
        if (line.ParentLineId) {
          carItemsLines.push(line);
        } else {
          carLines.push(line);
        }
      });

      let carItemsOrderLines = [];
      for (let lineKey in carLines) {
        let line = carLines[lineKey];
        let orderLine = cloneDeep(line);

        // TODO
        if (orderLine.LineType !== 'Model') {
          delete orderLine.ModelVariantId;
        } else {
          orderLine.ModelVariantId = orderLine.ModelVariantId.Id;
        }
        //

        delete orderLine.Id;
        delete orderLine.CreatedAt;
        delete orderLine.Notes;
        delete orderLine.DataFilter;
        delete orderLine.QuoteId;

        orderLine.OrderId = newOrderId;
        orderLine.BaseQuoteId = selectQuote.Id;
        orderLine.BaseQuoteLineId = line.Id;

        const newOrderLineId = await dispatch(createOrderLineSimple(orderLine));

        carItemsLines
          .filter((itemLine) => line.Id === itemLine.ParentLineId)
          .forEach((line) => {
            let orderItemLine = cloneDeep(line);

            delete orderItemLine.Id;
            delete orderItemLine.CreatedAt;
            delete orderItemLine.Notes;
            delete orderItemLine.DataFilter;
            delete orderItemLine.QuoteId;

            orderItemLine.ItemId = orderItemLine.ItemId.Id;
            orderItemLine.OrderId = newOrderId;
            orderItemLine.ParentLineId = newOrderLineId;
            orderItemLine.BaseQuoteId = selectQuote.Id;
            orderItemLine.BaseQuoteLineId = line.Id;
            carItemsOrderLines.push(orderItemLine);
          });
      }

      let orderItemsLinePromises = carItemsOrderLines.map((itemLine) =>
        dispatch(createOrderLineSimple(itemLine)),
      );

      await Promise.all(orderItemsLinePromises);

      return Promise.resolve(newOrderId);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };

export const createOrder = (order) => async (dispatch) => {
  try {
    const orderId = await createOrderWithLines(order);

    return orderId;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createOrderLine = (id, item) => async (dispatch) => {
  const { data, parentLineId } = item;
  const lineType = data.typeLine || data.lineType;
  const {
    quantity,
    unitPrice,
    priceAfterDiscount,
    priceIncludingTax,
    // discount,
    accountCode,
    projectCode,
    measureUnit,
    unitsOfMeasurment,
    barCode,
    supplierCatNum,
    costingCode,
    costingCode2,
    costingCode3,
    costingCode4,
    costingCode5,
    total,
    onHand,
    // warehouseCode,
    discountPercent,
    taxCode,
    taxTotal,
    taxPercent,
  } = data;

  const req = {
    Quantity: quantity,
    UnitPrice: unitPrice,
    QuantityOnHand: onHand,
    // WarehouseId: warehouseCode,
    TaxPercentage: taxPercent,
    TaxCode: taxCode,
    TaxSum: taxTotal,
    PriceAfterDiscount: priceAfterDiscount,
    PriceIncludingTax: priceIncludingTax,
    DiscountPercentage: discountPercent,
    Total: total,
    ShipToAddress: '', //todo
    AccountCode: accountCode,
    ProjectCode: projectCode,
    MeasureUnit: measureUnit,
    UnitsOfMeasurment: unitsOfMeasurment,
    BarCode: barCode,
    SupplierCatNum: supplierCatNum,
    CostingCode: costingCode,
    CostingCode2: costingCode2,
    CostingCode3: costingCode3,
    CostingCode4: costingCode4,
    CostingCode5: costingCode5,
    OrderId: id,
    LineType: lineType,
  };

  if (lineType === 'Model') {
    req.ModelVariantId = data.ModelVariantId;
  }
  if (lineType === 'Used' || lineType === 'Trade-in') {
    req.VehicleId = data.id;
  }

  if (lineType === 'Service' || lineType === 'Part') {
    req.ParentLineId = parentLineId;
    req.ItemId = data.id;
  }
  if (lineType === 'Accessory') {
    req.ParentLineId = parentLineId;
    req.ItemId = data.id;
  }

  return await axiosInstance
    .post(`/xassales/v1/OrdersLineService`, req, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let lineId = response.data._id;
      if (item.data.comments) {
        item.data.comments.forEach((comment) =>
          createNoteForLine(lineId, comment, 'SalesOrderLine'),
        );
      }
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const createOrderLineSimple = (line) => async (dispatch) => {
  return await axiosInstance
    .post(`/xassales/v1/OrdersLineService`, line, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data._id;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const OPPORTUNITY_CONTENT_SET_CURRENT_ORDER = 'OPPORTUNITY_CONTENT_SET_CURRENT_ORDER';
export const getContentOrder = (id, type) => async (dispatch, getState) => {
  const { quotes } = getState().opportunity.content;
  return await axiosInstance
    .get(
      `/xassales/v1/OrdersListService?filter=linkedObjectCode:=:${id}&filter=linkedObjectName:=:${type}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    )
    .then((response) => {
      if (!response.data.length) {
        dispatch(opportunityChangeLoader('content', false));
        return null;
      }

      const payload = [
        ...quotes,
        ...response.data.map((item) => {
          item.type = DocumentType.Order;
          return item;
        }),
      ];

      let currentOrder = response.data.find(
        (item) => item.DocumentStatus !== 'Cancelled' && item.IsPrimary,
      );

      if (!currentOrder) {
        currentOrder = response.data.find(
          (item) => item.DocumentStatus !== 'Cancelled' && item.IsDraft,
        );
      }

      dispatch({ type: OPPORTUNITY_UPDATE_QUOTES, payload: payload });
      dispatch({ type: OPPORTUNITY_CONTENT_SET_CURRENT_ORDER, payload: currentOrder });

      return currentOrder;
    })
    .catch((err) => {
      console.log(err);
      return;
    });
};

export const updateOrderLines = async (items) => {
  const promises = items.map((item) => {
    const {
      quantity,
      unitPrice,
      priceAfterDiscount,
      priceIncludingTax,
      discountPercent,
      accountCode,
      projectCode,
      measureUnit,
      unitsOfMeasurment,
      barCode,
      supplierCatNum,
      costingCode,
      costingCode2,
      costingCode3,
      costingCode4,
      costingCode5,
      total,
      onHand,
      // warehouseCode,
      taxCode,
      taxTotal,
      taxPercent,
      deliveryAddress,
      received,
    } = item;

    const req = {
      DeliveryAddress: deliveryAddress,
      Quantity: quantity,
      UnitPrice: unitPrice,
      Received: received,
      QuantityOnHand: onHand,
      // WarehouseId: warehouseCode,
      TaxPercentage: taxPercent,
      TaxCode: taxCode,
      TaxSum: taxTotal,
      PriceAfterDiscount: priceAfterDiscount,
      PriceIncludingTax: priceIncludingTax,
      DiscountPercentage: discountPercent,
      Total: total,
      ShipToAddress: '', //todo
      AccountCode: accountCode,
      ProjectCode: projectCode,
      MeasureUnit: measureUnit,
      UnitsOfMeasurment: unitsOfMeasurment,
      BarCode: barCode,
      SupplierCatNum: supplierCatNum,
      CostingCode: costingCode,
      CostingCode2: costingCode2,
      CostingCode3: costingCode3,
      CostingCode4: costingCode4,
      CostingCode5: costingCode5,
    };
    return axiosInstance
      .patch(`/xassales/v1/OrdersLineService/${item.lineId}`, req, {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return Promise.all(promises)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const updateDeliveryLines = async (items) => {
  const promises = items.map((item) => {
    const {
      quantity,
      unitPrice,
      priceAfterDiscount,
      priceIncludingTax,
      discountPercent,
      accountCode,
      projectCode,
      measureUnit,
      unitsOfMeasurment,
      barCode,
      supplierCatNum,
      costingCode,
      costingCode2,
      costingCode3,
      costingCode4,
      costingCode5,
      total,
      onHand,
      // warehouseCode,
      taxCode,
      taxTotal,
      taxPercent,
      deliveryAddress,
      received,
    } = item;

    const req = {
      DeliveryAddress: deliveryAddress,
      Quantity: quantity,
      UnitPrice: unitPrice,
      Received: received,
      QuantityOnHand: onHand,
      // WarehouseId: warehouseCode,
      TaxPercentage: taxPercent,
      TaxCode: taxCode,
      TaxSum: taxTotal,
      PriceAfterDiscount: priceAfterDiscount,
      PriceIncludingTax: priceIncludingTax,
      DiscountPercentage: discountPercent,
      Total: total,
      ShipToAddress: '', //todo
      AccountCode: accountCode,
      ProjectCode: projectCode,
      MeasureUnit: measureUnit,
      UnitsOfMeasurment: unitsOfMeasurment,
      BarCode: barCode,
      SupplierCatNum: supplierCatNum,
      CostingCode: costingCode,
      CostingCode2: costingCode2,
      CostingCode3: costingCode3,
      CostingCode4: costingCode4,
      CostingCode5: costingCode5,
    };
    return axiosInstance
      .patch(`/xassales/v1/DeliveriesLineService/${item.lineId}`, req, {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return Promise.all(promises)
    .then((res) => res)
    .catch((err) => console.log(err));
};

const getRefId = (document, isNewOrder) => {
  const RefId = isNewOrder ? undefined : document.ExternalRef;

  switch (document.type) {
    case DocumentType.Quotation:
      return { U_SQRefId: RefId };
    case DocumentType.Order:
      const QuoteRefId = document.QuoteExternalRef;

      return { U_SQRefId: QuoteRefId, U_SORefId: RefId };
    case DocumentType.Delivery:
      return { U_DNRefId: RefId };
    case DocumentType.Payment:
      return { U_PARefId: RefId };
    default:
      return { U_RefId: RefId };
  }
};

const getRefLineId = (document, item, isNewOrder) => {
  const RefLineId = isNewOrder ? undefined : item.rowExternalRef;

  switch (document.type) {
    case DocumentType.Quotation:
      return { U_SQRefLineId: RefLineId };
    case DocumentType.Order:
      const QuoteRefLineId = item.quoteRowExternalRef;

      return { U_SQRefLineId: QuoteRefLineId, U_SORefLineId: RefLineId };
    case DocumentType.Delivery:
      return { U_DNRefLineId: RefLineId };
    case DocumentType.Payment:
      return { U_PARefLineId: RefLineId };
    default:
      return { U_RefLineId: RefLineId };
  }
};

export const requestIssuesForApproval =
  (document, opportunity, isNewOrder) => async (_dispatch, getState) => {
    const documentClone = cloneDeep(document);

    const companyId = getUserFromCookies().company.id;
    const customerCode = documentClone.CustomerCode?.Code;
    const { cars } = getState().opportunity.content;
    const docType = documentClone.type === DocumentType.Order || isNewOrder ? 'SO' : 'SQ';

    if (isNewOrder) {
      documentClone.type = DocumentType.Order;
      documentClone.QuoteExternalRef = documentClone.ExternalRef;
    }

    let lines = [];
    cars.forEach((car) => {
      const lineType = getLineType(car);
      if (lineType !== 'Trade-in') {
        const isUsed = lineType === LineTypeUsed;

        if (isNewOrder) {
          car.type = DocumentType.Order;
          car.quoteRowExternalRef = car.rowExternalRef;
        }

        lines.push({
          LineType: lineType,
          ItemCode: car.itemCode,
          VehicleID: isUsed ? car.vehicleCode : undefined,
          DiscountPercent: `${car.discountPercent || 0}`,
          Quantity: `${car.quantity || 0}`,
          Price: `${car.price || 0}`,
          ...getRefLineId(documentClone, car, isNewOrder),
        });

        car.accessories.forEach((acc) => {
          if (isNewOrder) {
            acc.type = DocumentType.Order;
            acc.quoteRowExternalRef = acc.rowExternalRef;
          }

          lines.push({
            LineType: getLineType(acc),
            ItemCode: acc.code,
            DiscountPercent: `${acc.discountPercent || 0}`,
            Quantity: `${acc.quantity || 0}`,
            Price: `${acc.price || 0}`,
            ...getRefLineId(documentClone, acc, isNewOrder),
          });
        });

        car.configuration.forEach((conf) => {
          if (isNewOrder) {
            conf.type = DocumentType.Order;
            conf.quoteRowExternalRef = conf.rowExternalRef;
          }

          lines.push({
            LineType: getLineType(conf),
            ItemCode: conf.code,
            DiscountPercent: `${conf.discountPercent || 0}`,
            Quantity: `${conf.quantity || 0}`,
            Price: `${conf.price || 0}`,
            ...getRefLineId(documentClone, conf, isNewOrder),
          });
        });
      }
    });

    const { data } = await axiosInstanceSync.post(
      `/VSApprovalService`,
      {
        companyId: companyId,
        ...getRefId(documentClone, isNewOrder),
        ...getDocument(lines, docType, opportunity, customerCode),
      },
      {},
    );

    return data.payload;
  };

export const getSalesUsers = () => async (dispatch, getState) => {
  return axiosInstance
    .get(`/xassales/v1/users`)
    .then(({ data }) => data)
    .catch((err) => {
      return [];
    });
};

export const updateDescriptionOpportunity = (data, opportunityId) => async (dispatch) => {
  const changeDataOpportunity = {
    key: 'description',
    value: getDescriptionForOpportunity(data),
    id: opportunityId,
  };
  await dispatch(changeOpportunityField(changeDataOpportunity));
  dispatch(changeGeneralFieldAction(changeDataOpportunity));

  return true;
};

export const SET_REFRESH_DATA_FUNCTION = 'SET_REFRESH_DATA_FUNCTION';
export const setRefreshContentDataFunction = (func) => (dispatch) => {
  dispatch({ type: SET_REFRESH_DATA_FUNCTION, payload: func });
};

export const SET_APPROVAL_REQUESTS_FOR_CURRENT_DOCUMENT =
  'SET_APPROVAL_REQUESTS_FOR_CURRENT_DOCUMENT';
export const getApprovalRequestsForDocument = (documentId) => async (dispatch, getState) => {
  return axiosInstance
    .get(`/xassales/v1/ApprovalRequestsListService?filter=documentId:=:${documentId}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      dispatch({ type: SET_APPROVAL_REQUESTS_FOR_CURRENT_DOCUMENT, payload: response.data });
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

//line notes
export const setComments = (id, comments) => (dispatch, getState) => {
  let cars = getState().opportunity.content.cars;

  cars.forEach((car) => {
    if (car.id === id) {
      car.comments = comments;
    }
    car.configuration.forEach((conf) => {
      if (conf.id === id) {
        conf.comments = comments;
      }
    });
    car.accessories.forEach((acc) => {
      if (acc.id === id) {
        acc.comments = comments;
      }
    });
  });

  dispatch({ type: CHANGE_DATA_CARS, payload: cars });
};

const typeAdapter = (lineType) => {
  switch (lineType) {
    case 'Part':
      return 'configuration';
    case 'Service':
      return 'service';
    case 'Accessory':
      return 'accessory';

    default:
      return '';
  }
};

export const getOpportunityDocument = () => (_, getState) => {
  const { selectQuote, currentOrder } = getState().opportunity.content;

  const document = currentOrder || selectQuote;

  return document;
};

export const getCarItemsByKits = (car) => async (_, getState) => {
  const { id, kitIds } = car;

  const opportunity = getState().opportunity.new;
  const { customer } = opportunity;
  try {
    const accessories = [];
    const configuration = [];
    for (const kitId of kitIds) {
      const lines = (await getLinesByKitId(kitId)).filter((line) => line.ItemId !== null);

      if (lines.length === 0) {
        continue;
      }

      const docLines = lines.map((line) => ({
        LineType: getLineType(line.ItemId),
        ItemCode: line.ItemId.Code,
      }));
      const res = await VSLineDetailsService(
        getDocument(docLines, VSLineDetailsDocumentType.Search, opportunity, customer.code),
      );
      const linesPrices = res?.XAS_VSLINESSERVICE1Collection || [];
      const docRate = res?.docRate;

      lines.forEach((line) => {
        const item = line.ItemId;
        const itemPrices = linesPrices.find((pl) => pl.ItemCode === item.Code) || {};

        const model = {
          carId: id,
          name: item.Name,
          modelIds: item.ModelIds,
          typeLine: getLineType(item),
          // for correctly icon after adding to content tab
          type: typeAdapter(getLineType(item)),
          id: item.Id,
          code: item.Code,
          quantity: line.Quantity,
          unitsOfMeasurment: item.UnitOfMeasureId,
          isAssembly: item.IsToAssembly,
          isDelivered: item.IsDelivered,
        };

        setItemPrices(model, itemPrices, docRate);

        const patch = modelPatch(model);

        if (patch.typeLine === 'Accessory') {
          accessories.push(patch);
        } else {
          configuration.push(patch);
        }
      });
    }
    return { accessories, configuration };
  } catch (error) {
    return {};
  }
};

export const SET_VS_DOCUMENT_DETAILS = 'SET_VS_DOCUMENT_DETAILS';
export const setVSDocumentDetails = (data) => (dispatch) => {
  dispatch({ type: SET_VS_DOCUMENT_DETAILS, payload: data });
};

export const createDeliveryLine = (id, item) => async (dispatch) => {
  const { data, parentLineId } = item;
  const lineType = data.typeLine || data.lineType;
  const {
    quantity,
    unitPrice,
    priceAfterDiscount,
    priceIncludingTax,
    // discount,
    accountCode,
    projectCode,
    measureUnit,
    unitsOfMeasurment,
    barCode,
    supplierCatNum,
    costingCode,
    costingCode2,
    costingCode3,
    costingCode4,
    costingCode5,
    total,
    onHand,
    // warehouseCode,
    discountPercent,
    taxCode,
    taxTotal,
    taxPercent,
  } = data;

  const req = {
    Quantity: quantity,
    UnitPrice: unitPrice,
    QuantityOnHand: onHand,
    // WarehouseId: warehouseCode,
    TaxPercentage: taxPercent,
    TaxCode: taxCode,
    TaxSum: taxTotal,
    PriceAfterDiscount: priceAfterDiscount,
    PriceIncludingTax: priceIncludingTax,
    DiscountPercentage: discountPercent,
    Total: total,
    ShipToAddress: '', //todo
    AccountCode: accountCode,
    ProjectCode: projectCode,
    MeasureUnit: measureUnit,
    UnitsOfMeasurment: unitsOfMeasurment,
    BarCode: barCode,
    SupplierCatNum: supplierCatNum,
    CostingCode: costingCode,
    CostingCode2: costingCode2,
    CostingCode3: costingCode3,
    CostingCode4: costingCode4,
    CostingCode5: costingCode5,
    OrderId: id,
    LineType: lineType,
  };

  if (lineType === 'Model') {
    req.ModelVariantId = data.ModelVariantId;
  }
  if (lineType === 'Used' || lineType === 'Trade-in') {
    req.VehicleId = data.id;
  }

  if (lineType === 'Service' || lineType === 'Part') {
    req.ParentLineId = parentLineId;
    req.ItemId = data.id;
  }
  if (lineType === 'Accessory') {
    req.ParentLineId = parentLineId;
    req.ItemId = data.id;
  }

  return await axiosInstance
    .post(`/xassales/v1/DeliveriesLineService`, req, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let lineId = response.data._id;
      if (item.data.comments) {
        item.data.comments.forEach((comment) =>
          createNoteForLine(lineId, comment, 'SalesOrderLine'),
        );
      }
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const UPDATE_CONTENT_TAB_STATE = 'UPDATE_CONTENT_TAB_STATE';
export const updateContentTabState = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_CONTENT_TAB_STATE, payload });
};

export const addItemsByKits = (cars) => async (dispatch) => {
  const carsWithItemsByKit = [];
  for (const car of cars) {
    if (!car.kitIds) {
      carsWithItemsByKit.push(car);
      continue;
    }
    const itemsByKit = await dispatch(getCarItemsByKits(car));
    carsWithItemsByKit.push({ ...car, ...itemsByKit });
  }
  return carsWithItemsByKit;
};

export const saveContent =
  (shouldToCheckIssues = true, newDocumentStatus = {}) =>
  async (dispatch, getState) => {
    const { opportunity } = getState();
    const { content, new: newOpportunity } = opportunity;
    const { opportunityNumber: opportunityId } = newOpportunity;
    const { selectQuote, currentOrder, quotes, cars, tabState } = content;

    dispatch(changeFieldSaveBtn('isDisabled', true));

    const currentDocument = currentOrder || selectQuote;

    dispatch(opportunityChangeLoader('content', true));

    //set potentialAmount
    dispatch(
      changeOpportunityField({ key: 'amount', value: currentDocument.Total, id: opportunityId }),
    );

    const newTradeIn = cars.filter(
      (car) => car.lineType === LineTypeTradeIn && car.id.includes('trade-in'),
    );

    if (newTradeIn && newTradeIn.length) {
      await dispatch(createNewVehicle(newTradeIn));
    }

    const { initCars } = tabState;
    const { updateVehicles } = updateCarsData({
      cars: getState().opportunity.content.cars,
      initCars,
    });

    if (shouldToCheckIssues && currentDocument.ApprovalStatus) {
      const approvalResult = await dispatch(
        requestIssuesForApproval(currentDocument, newOpportunity),
      );
      if (approvalResult?.XIS_VSAPRVL_SRV2Collection?.length > 0) {
        if (['Approved', 'Rejected'].includes(currentDocument.ApprovalStatus)) {
          dispatch(updateContentTabState({ approvalResult, showApprovalModalForRequest: true }));
        }
        dispatch(opportunityChangeLoader('content', false));
        return Promise.resolve();
      } else {
        dispatch(updateContentTabState({ approvalResult: null }));
      }
    }

    if (updateVehicles.length) {
      await dispatch(updateVehicleTradeIn(updateVehicles));
    }

    if (!currentDocument.Id && !currentOrder) {
      // creation exists only for quote by flow because an order create from the current quote and handle in anther method
      const newQuoteWithLines = { ...selectQuote, ...newDocumentStatus };
      const newQuoteId = await dispatch(createNewQuoteWithLines(newQuoteWithLines));

      // for case when create draft quote from the approval modal
      currentDocument.Id = newQuoteId;
    } else {
      // update case
      const { initCars } = tabState;
      // cars may be updated in "createNewVehicle"
      const linesForUpdate = getLinesForUpdate({
        cars: getState().opportunity.content.cars,
        initCars,
      });

      // TODO:
      try {
        if (currentOrder) {
          await dispatch(
            updateCurrentOrderWithLines(
              getQuoteFields(currentOrder, newDocumentStatus),
              linesForUpdate,
            ),
          );
        } else {
          await dispatch(
            updateCurrentQuoteWithLines(
              getQuoteFields(selectQuote, newDocumentStatus),
              linesForUpdate,
            ),
          );
        }
      } catch (error) {
        dispatch(opportunityChangeLoader('content', false));

        return null;
      }
    }

    // set initial state after all updates

    dispatch(updateContentTabState({ currentDocument, initCars: cloneDeep(cars) }));

    // didn't get why is need that
    if (
      selectQuote &&
      selectQuote.Id &&
      quotes &&
      quotes.length > 0 &&
      !quotes.some((item) => !item.IsDraft)
    ) {
      await dischargeMakePrimary(quotes);
      await updateQuoteWithLines(selectQuote.Id, {
        IsPrimary: true,
      });
      await dispatch(updateDescriptionOpportunity(cars, opportunityId));
    }

    await dispatch(loadOpportunityQuotes(opportunityId));

    await dispatch(opportunitySetData(opportunityId));

    dispatch(opportunityChangeLoader('content', false));

    // return id for case when create draft quote from the approval modal
    return Promise.resolve(currentDocument.Id);
  };

export const updateContentData = () => (dispatch, getState) => {
  const { opportunity } = getState();
  const { content } = opportunity;
  const { selectQuote, currentOrder, cars } = content;

  const currentDocument = currentOrder || selectQuote;

  dispatch(updateContentTabState({ currentDocument, initCars: cloneDeep(cars) }));
};

export const loadOpportunityQuotes = (opportunityId) => async (dispatch, getState) => {
  const { opportunity } = getState();
  const { new: newOpportunity } = opportunity;

  const localState = history.location.state;

  // Get quote
  dispatch(opportunityChangeLoader('quotationOrOrder', true));

  const customerCode = newOpportunity.customer?.code || '';

  const order = await dispatch(getContentOrder(opportunityId, 'Opportunity'));
  const quote = await dispatch(getContentQuote(opportunityId, 'Opportunity'));

  const currentDocument = order || quote || null;
  const isVehicleAdding = localState && localState.selectModel;
  if (!currentDocument && isVehicleAdding) {
    dispatch(setOpportunityCacheSelectQuote(opportunityId, 'Opportunity'));
  }

  if (!quote && !order && !isVehicleAdding) {
    dispatch(opportunityChangeLoader('quotationOrOrder', false));

    const {
      opportunity: { content },
    } = getState();

    dispatch(opportunityUpdateContentData({ ...content, cars: [] }));
    return;
  }

  if (currentDocument) {
    dispatch(getApprovalRequestsForDocument(currentDocument.Id));
  }

  const docLines = (order ? order?.Lines : quote?.Lines) || [];

  let cars = await dispatch(getContentData(docLines, customerCode, order ? 'order' : 'quote'));

  dispatch(
    updateContentTabState({
      initCars: cloneDeep(cars),
      currentDocument: cloneDeep(currentDocument),
      approvalResult: null,
    }),
  );

  dispatch(setCreateButtonParams());

  if (isVehicleAdding) {
    const { selectCars } = localState;
    const carsForAddingWithItemsByKit = await dispatch(addItemsByKits(selectCars));
    cars = [...cars, ...carsForAddingWithItemsByKit];
    // clearing of state, need to remake it in future (without using of state, for example)
    await dispatch(changeFieldSaveBtn('isDisabled', true));

    history.replace(`/opportunity/${opportunityId}`, null);
    await dispatch(changeFieldSaveBtn('isDisabled', false));
  }

  const {
    opportunity: { content },
  } = getState();

  await dispatch(opportunityUpdateContentData({ ...content, cars }));
  await dispatch(opportunityChangeLoader('quotationOrOrder', false));
  await dispatch(opportunityChangeLoader('content', false));
  dispatch(clearLinesToDelivery());
};

export const setCreateButtonParams = () => (dispatch, getState) => {
  const { selectQuote, currentOrder, documentApprovalRequests } = getState().opportunity.content;

  const hasPendingApprovalRequest = documentApprovalRequests.some(
    (approvalRequest) => approvalRequest.Status === ApprovalStatusPending,
  );

  const isOrderExists = currentOrder && currentOrder.ExternalRef;
  const isQuotationExists = selectQuote && selectQuote.ExternalRef;
  if (isOrderExists) {
    dispatch(
      setCreateQuoteButtonParams({
        isVisible: true,
        isDisabled: hasPendingApprovalRequest,
      }),
    );
  } else if (isQuotationExists) {
    dispatch(
      setCreateQuoteButtonParams({ isVisible: true, isDisabled: hasPendingApprovalRequest }),
    );
  } else {
    dispatch(
      setCreateQuoteButtonParams({ isVisible: true, isDisabled: hasPendingApprovalRequest }),
    );
  }
};
