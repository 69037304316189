import { GET_USERS_LIST } from '../actions/users';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS_LIST:
      return action.payload;

    default:
      return state;
  }
}
