import cloneDeep from 'lodash.clonedeep';
import { roundNumberReducer } from '~/utils/json';
import {
  getUserFromCookies,
  getSalesSessionId,
  getDefaultCustomerFromCookies,
} from '~/helpers/cookies';
import { DocumentType } from '~/actions/api/sync';

const getCurrentUserCode = () => {
  const userInfo = getUserFromCookies();
  const userCode = getSalesSessionId() ? userInfo?.username : null;
  return userCode;
};

const getSalesPersonCode = () => {
  const userInfo = getUserFromCookies();
  const salesPersonCode = getSalesSessionId() ? userInfo?.salesCompany?.salesPersonCode : null;
  return salesPersonCode;
};

export const getDocument = (
  lines,
  documentType = DocumentType.Search,
  opportunity = {},
  CustomerCode,
) => {
  // duck-test
  const isLead = Boolean(opportunity.LeadCode);

  let branchCode;
  let customerCode;
  let salesManager;
  let externalRef;

  if (isLead) {
    branchCode = opportunity.BranchCode?.Code;
    customerCode = opportunity?.CustomerCode?.Code;
    externalRef = opportunity?.ExternalRef;
  }
  if (!isLead) {
    branchCode = opportunity.branchCode;
    customerCode = opportunity.customer?.code;
    salesManager = opportunity.salesManager;
    externalRef = opportunity.externalRef;
  }

  const document = {
    DocType: documentType,
    CustomerCode: CustomerCode || customerCode || getDefaultCustomerFromCookies(),
    U_BranchCd: branchCode,
    U_SalesPersonCd: getSalesPersonCode(),
    U_SalesManagerCd: salesManager?.EmployeeId,
    U_CrrntUserCd: getCurrentUserCode(),
    U_OppId: externalRef,
    DocumentLines: lines,
  };

  return document;
};

export const getPureDocumentString = (document) => {
  const copyDocument = cloneDeep(document);

  delete copyDocument.DocumentCode;
  delete copyDocument.IsDraft;
  delete copyDocument.IsPrimary;
  delete copyDocument.ApprovalStatus;
  delete copyDocument.ExternalRef;

  copyDocument.Lines = (copyDocument?.Lines || []).map((line) => {
    delete line.DataFilter;
    delete line.RowExternalRef;
    delete line.SortOrder;

    return line;
  });

  return JSON.stringify(copyDocument, roundNumberReducer, 2);
};
