import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';
import { getDateAndTimeStampsFromDateTimeString } from '../utils/general';
import { getOutputActivity } from './activities';
import { getOpportunity } from './api/opportunities';
import { getCustomer } from '~/helpers/dataAdapters/opportunity';
import { setCurrency } from './localization';
import {
  createNote as createNoteRequest,
  updateNote as updateNoteRequest,
  deleteNote as deleteNoteRequest,
} from './api/notes';

import {
  createAddress as createAddressRequest,
  deleteAddress as deleteAddressRequest,
} from './api/addresses';

import {
  createContact as createContactAction,
  deleteContact as deleteContactAction,
} from './api/contacts';

import { changeOpportunityField as updateOpportunity } from './opportunities_list';
import { removeActivity, updateActivity } from './api/activities';
import { objectKeysPascalCaseToCamelCase } from '~/utils/object';
import { adaptActivity } from '~/helpers/dataAdapters/activities';
import set from 'lodash.set';
import { ACTIVITIES_SET_ITEMS } from '~/reducers/activities';
export const OPPORTUNITY_NEW_GET_DICTIONARY = 'OPPORTUNITY_NEW_GET_DICTIONARY';

export const OPPORTUNITY_SET_DATA = 'OPPORTUNITY_SET_DATA';
export const OPPORTUNITY_NEW_TOGGLE_LOADER = 'OPPORTUNITY_NEW_TOGGLE_LOADER';
export const opportunitySetData = (id) => async (dispatch, getState) => {
  dispatch({ type: OPPORTUNITY_NEW_TOGGLE_LOADER, payload: true });

  try {
    const opportunity = await getOpportunity(id);

    let startDateTime = getDateAndTimeStampsFromDateTimeString(opportunity.CreatedAt);
    let closeDateTime = getDateAndTimeStampsFromDateTimeString(opportunity.ExpectedCloseDate);

    const payload = {
      id: opportunity.Id,
      title: opportunity.OpportunityStage?.Name,
      saleType: opportunity.SaleType,
      opportunityNumber: opportunity.Id,
      opportunityStage: opportunity.OpportunityStage,
      amount: opportunity.PotentialAmount,
      startDate: startDateTime.date,
      dueDate: closeDateTime.date,
      startTime: startDateTime.time,
      createdBy: opportunity?.CreatedBy?.UserName,
      createdById: opportunity?.CreatedBy?.Rid,
      manager: opportunity.Manager,
      source: opportunity.Source,
      sources: opportunity.Sources,
      dueTime: closeDateTime.time,
      salesAgent: opportunity.SalesAgent,
      branch: opportunity?.Branch?.Rid,
      branchCode: opportunity?.Branch?.Code,
      opportunityCode: opportunity.OpportunityCode,
      brand: opportunity?.Brand?.Rid,
      description: opportunity.Description,
      customer: getCustomer(opportunity),
      interest: opportunity.CustomerInterest, // TODO: remove this
      comments:
        opportunity.Notes && opportunity.Notes.length
          ? opportunity.Notes.map((item, i) => ({
              id: item.Id,
              date: item.CreatedAt,
              user: 'Jayson Butler',
              text: item.NoteContent,
            }))
          : [],
      activities: [],
      content: opportunity.Contents,
      documents: opportunity.Documents,
      payments: opportunity.Payments,
      approvalStatus: opportunity.ApprovalStatus,
      quoteSeries: opportunity.QuoteSeries,
      orderSeries: opportunity.OrderSeries,
      deliverySeries: opportunity.DeliverySeries,
      invoiceSeries: opportunity.InvoiceSeries,
      externalRef: opportunity.ExternalRef,
      addresses: opportunity.Addresses,
      contacts: opportunity.Contacts,
      notes: opportunity.Notes,
      updatedAt: opportunity.UpdatedAt,
      updatedBy: opportunity.UpdatedBy,
      owner: opportunity.Owner,
      location: opportunity.Location,
      name: opportunity.Name,
      status: opportunity.Status,
      closeReason: opportunity.CloseReason,
      customerInterests: opportunity.CustomerInterests,
      customerAutoSync: opportunity.CustomerAutoSync,
    };

    const activitiesPayload = (opportunity?.Activities || []).map(adaptActivity);

    dispatch(setCurrency(payload?.customer?.currency));
    dispatch({ type: OPPORTUNITY_SET_DATA, payload });
    dispatch({ type: ACTIVITIES_SET_ITEMS, payload: activitiesPayload });
    dispatch({ type: OPPORTUNITY_NEW_TOGGLE_LOADER, payload: false });
  } catch (error) {
    console.error(error);
    dispatch({ type: OPPORTUNITY_NEW_TOGGLE_LOADER, payload: false });
  }
};

export const OPPORTUNITY_SET_CUSTOMER = 'OPPORTUNITY_SET_CUSTOMER';
export const opportunitySetCustomer = (payload) => (dispatch) => {
  dispatch({ type: OPPORTUNITY_SET_CUSTOMER, payload });
};

export const OPPORTUNITY_UPDATE_CUSTOMER = 'OPPORTUNITY_UPDATE_CUSTOMER';
export const opportunityChangeCustomerContactField = (props) => (dispatch, getState) => {
  const { contacts = [] } = getState().opportunity.new.customer;
  const payload = contacts.map((item) => {
    if (item.Id === props.id) {
      item[props.name] = props.value;
    }

    return item;
  });

  dispatch({ type: OPPORTUNITY_UPDATE_CUSTOMER, payload });
};

export const createNewOpportunityComment = (props) => async (dispatch, getState) => {
  const payload = getState().opportunity.new.comments;
  const { id, comment } = props;

  const data = {
    ObjectId: id,
    ObjectModel: 'Opportunity',
    NoteContent: comment.text,
  };

  try {
    const note = await createNoteRequest(data);

    const value = [...payload, { id: note.Id, ...comment }];
    dispatch(changeGeneralFieldAction({ key: 'comments', value }));
  } catch (error) {
    console.error(error);
  }
};

export const updateOpportunityComment = (props) => async (dispatch, getState) => {
  const payload = getState().opportunity.new.comments;
  const { comment } = props;
  const { id, text, date } = comment;

  const data = {
    Id: id,
    NoteContent: text,
    CreationDate: date,
  };

  try {
    await updateNoteRequest(id, data);
    const value = payload.map((item) => {
      if (item.id === id) {
        item = comment;
      }
      return item;
    });
    dispatch(changeGeneralFieldAction({ key: 'comments', value }));
  } catch (error) {
    console.error(error);
  }
};

export const deleteOpportunityComment = (props) => async (dispatch, getState) => {
  const payload = getState().opportunity.new.comments;
  const { id } = props;

  try {
    await deleteNoteRequest(id);
    const value = payload.filter((item) => item.id !== id);
    dispatch(changeGeneralFieldAction({ key: 'comments', value }));
  } catch (error) {
    console.error(error);
  }
};

export const changeGeneralFieldAction = (props) => (dispatch, getState) => {
  let payload = getState().opportunity.new;

  const { key, value } = props;

  const newValue = ['salesAgent', 'salesManager'].includes(key) ? { Rid: value } : value;

  set(payload, key, newValue);

  dispatch({ type: OPPORTUNITY_SET_DATA, payload });
};

export const changeGeneralFieldsAction = (fields) => (dispatch, getState) => {
  const payload = {
    ...getState().opportunity.new,
    ...fields,
  };
  dispatch({ type: OPPORTUNITY_SET_DATA, payload });
};

export const CHANGE_FIELD_DATA = 'CHANGE_FIELD_DATA';
export const changeFieldAction = (field, fields) => (dispatch, getState) => {
  const { customer } = getState().opportunity.new;
  const payload = fields
    ? { ...customer, ...fields }
    : {
        ...customer,
        [field.name]: field.value,
      };

  dispatch({ type: CHANGE_FIELD_DATA, payload });
};

export const OPPORTUNITY_UPDATE_ACTIVITY = 'OPPORTUNITY_UPDATE_ACTIVITY';
export const updateOpportunityActivity = (activity) => async (dispatch, getState) => {
  try {
    let newActivity = getOutputActivity(activity);

    let updatedActivity = await updateActivity(activity.id, newActivity);

    updatedActivity = adaptActivity(objectKeysPascalCaseToCamelCase(updatedActivity));

    const { items = [] } = getState().activities;
    let payload = [...items];

    const index = payload.findIndex((item) => item.id === updatedActivity.id);

    if (index === -1) {
      return;
    }

    payload.splice(index, 1, updatedActivity);

    dispatch({ type: ACTIVITIES_SET_ITEMS, payload });
  } catch (error) {
    console.error(error);
  }
};

export const OPPORTUNITY_CREATE_NEW_ACTIVITY = 'OPPORTUNITY_CREATE_NEW_ACTIVITY';
export const removeOpportunityActivity = (id) => async (dispatch, getState) => {
  try {
    await removeActivity(id);

    const { items = [] } = getState().activities;
    let payload = [...items];

    payload = payload.filter((item) => item.id !== id);
    dispatch({ type: ACTIVITIES_SET_ITEMS, payload });
  } catch (error) {
    console.error(error);
  }
};

const getFilterQuery = (id, filterValues) => {
  let filterString = '';
  filterValues.forEach((item, i) => {
    let filterKey = item.key;
    let operation = '='; //todo with params
    let value = item.value;
    let field = '';

    switch (filterKey) {
      case 'related':
        field = 'toUser.rid';
        break;
      case 'date':
        field = 'createdAt';
        break;
      case 'time':
        field = 'createdAt';
        break;
      case 'status':
        field = 'status';
        break;
      case 'reason':
        field = 'reason';
        break;
      default:
    }

    switch (item.params) {
      case 'contains':
        operation = 'contains';
        break;

      case 'does not contains':
        operation = 'contains';
        break;

      case 'is equal to': // operation = 'contains';
        break;

      case 'is not equal to':
        operation = '!=';
        break;

      case 'starts with':
        operation = 'contains';
        break;

      case 'ends with':
        operation = 'contains';
        break;

      case 'is empty':
        operation = '=';
        value = null;
        break;

      case 'is not empty':
        operation = '!=';
        value = null;
        break;

      default:
    }

    filterString += `&filter=${field}:${operation}:${value}`;
  });

  return `?filter=linkedTo:=:Opportunity&filter=linkedToId:=:${id}${filterString}`;
};

export const getApprovallistForOpportunity = (id, filter) => {
  const filterString = getFilterQuery(id, filter);
  return axiosInstance
    .get(`/xassales/v1/ApprovalRequestsListService${filterString}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getDictsForApprovalLog = () => {
  return axiosInstance
    .get(`/xassales/v1/users`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      const payload = response.data.map((item) => ({
        label: `${item.FirstName} ${item.LastName}`,
        value: item.Id,
      }));
      return payload;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getCampaigns = () => (dispatch, getState) => {
  const pageSize = `?pageSize=all`;
  axiosInstance
    .get(`/xassales/v1/CampaignsListService${pageSize}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((res) => {
      const campaigns = res.data.map((item) => ({
        label: item.Name,
        value: item.Id,
        channels: item.Channels?.map((c) => ({
          label: c.ChannelId?.Name,
          value: c.ChannelId?.Id,
          link: c.Link,
        })),
      }));
      dispatch({
        type: OPPORTUNITY_NEW_GET_DICTIONARY,
        payload: {
          campaigns,
        },
      });
    })
    .catch((err) => console.log(err));
};

export const SET_IS_DELIVERY_MODE = 'SET_IS_DELIVERY_MODE';
export const setIsDeliveryMode = (value) => (dispatch) => {
  dispatch({ type: SET_IS_DELIVERY_MODE, payload: value });
};

export const addAddress = (data) => async (dispatch, getState) => {
  const { new: newOpportunity } = getState().opportunity;
  const { opportunityNumber, addresses = [] } = newOpportunity;

  try {
    const { Id: addressId, IsDefault } = await createAddressRequest(data);

    const newAddresses = [
      ...addresses.map((item) => (IsDefault ? { ...item, IsDefault: false } : item)),
      {
        Rid: addressId,
        IsDefault,
      },
    ];

    dispatch(updateOpportunity({ id: opportunityNumber, key: 'addresses', value: newAddresses }));
  } catch (error) {
    console.error(error);
  }
};

export const deleteAddress = (addressId) => async (dispatch, getState) => {
  const { new: newOpportunity } = getState().opportunity;
  const { opportunityNumber, addresses = [] } = newOpportunity;

  try {
    await deleteAddressRequest(addressId);

    const upratedAddresses = addresses.filter(({ Rid }) => Rid !== addressId);

    dispatch(
      updateOpportunity({ id: opportunityNumber, key: 'addresses', value: upratedAddresses }),
    );
  } catch (error) {
    console.error(error);
  }
};

export const addContact = (data) => async (dispatch, getState) => {
  const { new: newOpportunity } = getState().opportunity;
  const { opportunityNumber, contacts = [] } = newOpportunity;

  try {
    const { Id: contactId, IsDefault } = await createContactAction(data);

    const newContacts = [
      ...contacts.map((item) => (IsDefault ? { ...item, IsDefault: false } : item)),
      {
        Rid: contactId,
        IsDefault,
      },
    ];

    dispatch(updateOpportunity({ id: opportunityNumber, key: 'contacts', value: newContacts }));
  } catch (error) {
    console.error(error);
  }
};

export const deleteContact = (contactId) => async (dispatch, getState) => {
  const { new: newOpportunity } = getState().opportunity;
  const { opportunityNumber, contacts = [] } = newOpportunity;

  try {
    await deleteContactAction(contactId);

    const newContacts = contacts.filter(({ Rid }) => Rid !== contactId);

    dispatch(updateOpportunity({ id: opportunityNumber, key: 'contacts', value: newContacts }));
  } catch (error) {
    console.error(error);
  }
};

export const addNote = (data) => async (dispatch, getState) => {
  const { new: newOpportunity } = getState().opportunity;
  const { opportunityNumber, notes = [] } = newOpportunity;

  try {
    const newNote = {
      ObjectId: opportunityNumber,
      ObjectModel: 'Opportunity',
      NoteContent: data.text,
    };

    const { Id: noteId } = await createNoteRequest(newNote);

    const newNotes = [
      ...notes,
      {
        Rid: noteId,
      },
    ];

    dispatch(updateOpportunity({ id: opportunityNumber, key: 'notes', value: newNotes }));
  } catch (error) {
    console.error(error);
  }
};

export const deleteNote = (noteId) => async (dispatch, getState) => {
  const { new: newOpportunity } = getState().opportunity;
  const { opportunityNumber, notes = [] } = newOpportunity;

  try {
    await deleteNoteRequest(noteId);

    const newNotes = notes.filter(({ Rid }) => Rid !== noteId);

    dispatch(updateOpportunity({ id: opportunityNumber, key: 'notes', value: newNotes }));
  } catch (error) {
    console.error(error);
  }
};

export const addCustomerInterest = (data) => async (dispatch, getState) => {
  const { new: newOpportunity } = getState().opportunity;
  const { opportunityNumber, customerInterests = [] } = newOpportunity;

  try {
    const newCustomerInterests = [...customerInterests, data];

    dispatch(
      updateOpportunity({
        id: opportunityNumber,
        key: 'customerInterests',
        value: newCustomerInterests,
      }),
    );
  } catch (error) {
    console.error(error);
  }
};

export const updateCustomerInterest = (data) => async (dispatch, getState) => {
  const { new: newOpportunity } = getState().opportunity;
  const { opportunityNumber, customerInterests = [] } = newOpportunity;
  const { Id: customerInterestId } = data;

  try {
    const updatedCustomerInterests = [...customerInterests];

    const index = updatedCustomerInterests.findIndex(({ Id }) => Id === customerInterestId);

    if (index === -1) {
      return;
    }

    updatedCustomerInterests.splice(index, 1, data);

    dispatch(
      updateOpportunity({
        id: opportunityNumber,
        key: 'customerInterests',
        value: updatedCustomerInterests,
      }),
    );
  } catch (error) {
    console.error(error);
  }
};

export const deleteCustomerInterest = (customerInterestId) => async (dispatch, getState) => {
  const { new: newOpportunity } = getState().opportunity;
  const { opportunityNumber, customerInterests = [] } = newOpportunity;

  try {
    const updatedCustomerInterests = customerInterests.filter(
      ({ Id }) => Id !== customerInterestId,
    );

    dispatch(
      updateOpportunity({
        id: opportunityNumber,
        key: 'customerInterests',
        value: updatedCustomerInterests,
      }),
    );
  } catch (error) {
    console.error(error);
  }
};
