import {
  VEHICLE_CATEGORY_UPDATE,
  VEHICLE_CATEGORY_GET,
  VEHICLE_CATEGORY_LOADING,
} from '~/actions/vehicle_category';

const initialState = {
  data: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VEHICLE_CATEGORY_UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case VEHICLE_CATEGORY_GET: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case VEHICLE_CATEGORY_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
