import cloneDeep from 'lodash.clonedeep';
import { emptyString } from '~/constants';
import { applyLineData } from '~/helpers/dataAdapters/line';
import { isObjectsEqual } from '~/utils/general';

export const updateCarsData = ({ cars, initCars }) => {
  const updateLines = [];
  const updateVehicles = [];

  cars
    .filter((item) => item.lineId)
    .forEach((item) => {
      const findCar = initCars.find((car) => car.id === item.id);

      if (findCar && !isObjectsEqual(item, findCar)) {
        const cloneItem = cloneDeep(item);

        const isConfiguration = isObjectsEqual(item.configuration, findCar.configuration);
        const isAccessory = isObjectsEqual(item.accessories, findCar.accessories);
        const isSpecification = isObjectsEqual(
          item.parametrs.specification,
          findCar.parametrs.specification,
        );

        if (!isConfiguration) {
          item.configuration.forEach((conf) => {
            const findConfiguration = findCar.configuration.find((c) => c.id === conf.id);
            if (!isObjectsEqual(conf, findConfiguration) && conf.lineId) {
              updateLines.push(conf);
            }
          });
        }

        if (!isAccessory) {
          item.accessories.forEach((conf) => {
            const findConfiguration = findCar.accessories.find((c) => c.id === conf.id);
            if (!isObjectsEqual(conf, findConfiguration) && conf.lineId) {
              updateLines.push(conf);
            }
          });
        }

        if (
          !isSpecification ||
          (item.lineType === 'Trade-in' && !isObjectsEqual(item.images, findCar.images))
        ) {
          updateVehicles.push(item);
        }

        const copyFindCar = cloneDeep(findCar);
        delete copyFindCar.configuration;
        delete cloneItem.configuration;
        delete cloneItem.accessories;
        delete cloneItem.parametrs.specification;
        const isCar = isObjectsEqual(cloneItem, copyFindCar);
        if (!isCar) {
          updateLines.push(item);
        }
      }
    });
  return { updateLines, updateVehicles };
};

export const getLinesForUpdate = ({ cars, initCars }) => {
  const linesForUpdate = [];
  const carsForUpdate = [];
  const carsForCreate = [];

  cars.forEach((c) => {
    if (c.lineId) {
      carsForUpdate.push(c);
    } else {
      carsForCreate.push(c);
    }
  });

  carsForUpdate.forEach((carItem) => {
    const findCar = initCars.find((car) => car.id === carItem.id);

    if (findCar && !isObjectsEqual(carItem, findCar)) {
      const cloneItem = cloneDeep(carItem);

      const isConfiguration = isObjectsEqual(carItem.configuration, findCar.configuration);
      const isAccessory = isObjectsEqual(carItem.accessories, findCar.accessories);

      if (!isConfiguration) {
        carItem.configuration.forEach((conf) => {
          const findConfiguration = findCar.configuration.find((c) => c.id === conf.id);
          if (!isObjectsEqual(conf, findConfiguration)) {
            let confLine = applyLineData(conf);
            if (!conf.lineId) {
              confLine.ParentLineId = carItem.lineId;
            }
            linesForUpdate.push(confLine);
          }
        });
      }

      if (!isAccessory) {
        carItem.accessories.forEach((conf) => {
          const findConfiguration = findCar.accessories.find((c) => c.id === conf.id);
          if (!isObjectsEqual(conf, findConfiguration)) {
            let confLine = applyLineData(conf);
            if (!conf.lineId) {
              confLine.ParentLineId = carItem.lineId;
            }
            linesForUpdate.push(confLine);
          }
        });
      }

      const copyFindCar = cloneDeep(findCar);
      delete copyFindCar.configuration;
      delete cloneItem.configuration;
      delete cloneItem.accessories;
      delete cloneItem.parametrs.specification;
      const isCar = isObjectsEqual(cloneItem, copyFindCar);
      if (!isCar) {
        linesForUpdate.push(applyLineData(carItem));
      }
    }
  });

  carsForCreate.forEach((carItem) => {
    if (!carItem.lineId) {
      let carLine = applyLineData(carItem);
      carLine.RelatedLines = [];

      carItem.configuration.forEach((conf) => {
        if (!conf.lineId) {
          carLine.RelatedLines.push(applyLineData(conf));
        }
      });
      carItem.accessories.forEach((acces) => {
        if (!acces.lineId) {
          carLine.RelatedLines.push(applyLineData(acces));
        }
      });

      linesForUpdate.push(carLine);
    }
  });

  //dirty fix for save line without DeliveryAddress === ''
  return linesForUpdate.map((item) => {
    if (item.DeliveryAddress === emptyString) {
      delete item.DeliveryAddress;
    }
    return item;
  });
};
