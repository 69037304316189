import { GET_CUSTOMER_TITLES_LIST } from '../actions/customer_titles';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_TITLES_LIST:
      return action.payload;
    default:
      return state;
  }
}
