import { getUsers } from './api/users';

export const GET_USERS_LIST = 'GET_USERS_LIST';
export const getUsersList = () => async (dispatch) => {
  try {
    const users = await getUsers();

    dispatch({ type: GET_USERS_LIST, payload: users });
  } catch (error) {
    //
  }
};
