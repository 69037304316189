import { getCompanyFromCookies } from '~/helpers/cookies';
import { SBO_SALES_AGENT_ROLE_NAME, SBO_SALES_MANAGER_ROLE_NAME } from '~/constants/roles';
import { getEmployees as getEmployeesRequest } from './api/employees';

export const GET_EMPLOYEES_LIST = 'GET_EMPLOYEES_LIST';

export const getEmployees = () => async (dispatch, getState) => {
  const data = await getEmployeesRequest();

  const { Employees = [], Parameters = [] } = data;

  const rolesParam = Parameters.find((parameter) => parameter.ParamSeriesDesc === 'Roles');
  const rolesSeriesCollection = rolesParam?.SeriesCollection || [];

  const salesManagerIdValue = rolesSeriesCollection.find(
    (role) => role.U_ParamName === SBO_SALES_MANAGER_ROLE_NAME,
  )?.U_Value;
  const salesAgentIdValue = rolesSeriesCollection.find(
    (role) => role.U_ParamName === SBO_SALES_AGENT_ROLE_NAME,
  )?.U_Value;

  if (!salesManagerIdValue) {
    console.warn('Sales Managers cannot be identified');
  }
  if (!salesAgentIdValue) {
    console.warn('Sales Agents cannot be identified');
  }

  const employees = Employees.map((item, i) => ({
    label: item.Label,
    value: `${item.Value}`,
    roleId: item.RoleID,
  }));

  const salesManagers = [];
  const salesAgents = [];

  for (const user of employees) {
    if (salesManagerIdValue && user.roleId === parseInt(salesManagerIdValue)) {
      salesManagers.push(user);
    }
    if (salesAgentIdValue && user.roleId === parseInt(salesAgentIdValue)) {
      salesAgents.push(user);
    }
  }

  const salesRoles = getCompanyFromCookies().salesRoles || {};

  // CHECKME: change to Object if no need to search by sapRoleId
  const roles = [];
  for (const salesRole in salesRoles) {
    const sapRole = salesRoles[salesRole];

    if (!sapRole) {
      continue;
    }

    const item = {
      salesId: salesRole,
      sapId: sapRole,
      sapNames: rolesSeriesCollection
        .filter(({ U_Value }) => U_Value === sapRole)
        .map(({ U_ParamName }) => U_ParamName),
    };
    roles.push(item);
  }

  dispatch({
    type: GET_EMPLOYEES_LIST,
    payload: {
      salesManagers,
      salesAgents,
      employees,
      roles,
    },
  });
};
