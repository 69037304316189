import { createSelector } from 'reselect';
import config from '~/config.js';
import { dot } from '~/helpers/localization';

export const localizationSelector = (state) => state.localization;

export const companyLocalizationSelector = createSelector(localizationSelector, 
  (localization) => {
    const companyLocalization = localization?.companyLocalization;

    const symbolsFormat = companyLocalization?.SymbolsFormat || config.symbolsFormat;
    const symbolsFormatMatch = symbolsFormat.search(/\D/gi);
    let symbolDelimiter = dot;

    if (symbolsFormatMatch !== -1) {
      symbolDelimiter = symbolsFormat[symbolsFormatMatch];
    }

    return {
      longDateFormat: companyLocalization?.LongDateFormat || config.clientDateFormat,
      shortDateFormat: companyLocalization?.ShortDateFormat || config.dateFormatShort,
      timeFormat: companyLocalization?.TimeFormat || config.clientTimeFormat,
      symbolsFormat: symbolDelimiter,
    };
});
