import cloneDeep from 'lodash.clonedeep';
import axiosInstance from '../utils/axios';

import { getCompanyIdFromCookies } from '../helpers/cookies';
import { getUsers } from './api/users';
import { getEmployees } from './employees';
import { getCampaignsList } from './api/campaigns';
import {
  getLead,
  getLinkedLeadsList,
  handleLinkedLeads,
  updateLead as updateLeadRequest,
} from './api/leads';
import { getAuthorizationHeaders, getFilterString } from './api';
import { formatLeadData } from '../helpers/dataAdapters/lead';
import { showWarning } from './layout';
import { t } from '../utils/lang';
import { adaptCustomerForLead } from '~/helpers/dataAdapters/customer';
import {
  createContact as createContactAction,
  deleteContact as deleteContactAction,
} from './api/contacts';
import {
  createNote as createNoteRequest,
  updateNote as updateNoteRequest,
  deleteNote as deleteNoteRequest,
} from './api/notes';
import {
  createAddress as createAddressRequest,
  deleteAddress as deleteAddressRequest,
} from './api/addresses';
import { adaptActivity } from '~/helpers/dataAdapters/activities';
import { ACTIVITIES_SET_ITEMS } from '~/reducers/activities';

export const LEAD_SET_DATA = 'LEAD_SET_DATA';
export const LEAD_SET_ACTIVITIES = 'LEAD_SET_ACTIVITIES';

export const updateLeadSource = (id, data) => (dispatch, getState) => {
  const lead = getState().lead.leadData || {};
  const leadSources = [...lead.Sources] || [];

  const indexOfFoundSource = leadSources.findIndex((source) => source.Id === id);
  const newSource = {
    ...leadSources[indexOfFoundSource],
    ...data,
  };

  leadSources.splice(indexOfFoundSource, 1, newSource);

  dispatch(changeGeneralFieldAction({ key: 'Sources', value: leadSources }));
};

export const updateFieldOfLead = (id, key) => async (dispatch, getState) => {
  try {
    const lead = getState().lead.leadData || {};
    const data = {
      [key]: lead[key],
    };
    await updateLeadRequest(id, data);
  } catch (error) {
    console.log(error);
  }
};

export const deleteLeadSource = (id) => (dispatch, getState) => {
  const lead = getState().lead.leadData || {};
  const leadSources = [...lead.Sources] || [];
  const updatedLeadSources = leadSources.filter((source) => source.Id !== id);
  dispatch(updateLeadField(lead.Id, 'Sources', updatedLeadSources));
};

export const UPDATE_CONTENT_DATA = 'UPDATE_CONTENT_DATA';
export const updateLeadContentData = (payload) => (dispatch, getState) => {
  dispatch({ type: UPDATE_CONTENT_DATA, name: 'cars', payload });
};

export const updateLeadContentDataAmount = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_CONTENT_DATA, name: 'amount', payload });
};

export const removeContentCar = (car, userId) => (dispatch, getState) => {
  const cars = getState().lead.content.cars;
  const payload = cars.filter((item) => item.model !== car.model);

  dispatch({ type: UPDATE_CONTENT_DATA, name: 'cars', payload });
};

export const leadRemoveVinContentData = (model, vin, userId) => (dispatch, getState) => {
  const cars = getState().lead.content.cars;

  const payload = cars.map((item) => {
    if (item.model === model) {
      return { ...item, vin: item.vin.filter((itemVin) => itemVin !== vin) };
    }
    return item;
  });

  dispatch({ type: UPDATE_CONTENT_DATA, name: 'cars', payload });
};

export const changeConfigurationContentData = (car, userId) => (dispatch, getState) => {
  const cars = getState().lead.content.cars;

  const payload = cars.map((item) => {
    if (item.model === car.model) {
      return car;
    }
    return item;
  });

  dispatch({ type: UPDATE_CONTENT_DATA, name: 'cars', payload });
};

export const leadClearContentData = () => (dispatch) => {
  dispatch({ type: UPDATE_CONTENT_DATA, name: 'cars', payload: [] });
};

export const LEAD_CHANGE_FIELD = 'LEAD_CHANGE_FIELD';
export const updateLeadCustomer = (customerId, leadId, history) => async (dispatch, getState) => {
  const payload = getState().leads.data.map((item) => {
    if (item.leadNumber === Number(leadId)) {
      return { ...item, customerId };
    }
    return item;
  });

  dispatch({ type: LEAD_CHANGE_FIELD, payload });
  history.push(`/lead/${leadId}`);
};

export const changeFielCustomerdAction = (field) => (dispatch, getState) => {
  const lead = getState().lead;
  const customer = { ...lead.customer, [field.name]: field.value };

  dispatch({ type: LEAD_SET_DATA, payload: { ...lead, customer: customer } });
};

export const checkLeadAmount = () => (dispatch, getState) => {
  const amount = getState().lead.content.amount;
  const leadEstimatedAmount = getState().lead.leadData.EstimatedAmount;

  if (amount !== leadEstimatedAmount) {
    dispatch(changeGeneralFieldAction({ key: 'EstimatedAmount', value: amount }));
  }
};

export const LEAD_SET_LOADING = 'LEAD_SET_LOADING';
export const getLeadData = (id) => async (dispatch, getState) => {
  dispatch({ type: LEAD_SET_LOADING, payload: true });
  dispatch({ type: LEAD_SET_DATA, payload: {} });

  try {
    const data = await getLead(id);
    const leadData = formatLeadData(data);
    const amount = leadData.EstimatedAmount || 0;
    const activitiesPayload = (data?.Activities || []).map(adaptActivity);

    dispatch({ type: LEAD_SET_DATA, payload: leadData });
    dispatch({ type: ACTIVITIES_SET_ITEMS, payload: activitiesPayload });
    dispatch(updateLeadContentDataAmount(amount));
    dispatch({ type: LEAD_SET_LOADING, payload: false });
    return leadData;
  } catch (error) {
    dispatch({ type: LEAD_SET_LOADING, payload: false });
    console.error(error);
  }
};

export const updateLeadField = (id, fieldName, value) => async (dispatch, getState) => {
  const payload = {
    [fieldName]: value,
  };

  try {
    const data = await updateLeadRequest(id, payload);
    dispatch({ type: LEAD_SET_DATA, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const updateLead =
  (id, data = {}) =>
  async (dispatch, getState) => {
    try {
      const {
        lead: { leadData },
      } = getState();

      const lead = await updateLeadRequest(id, data);

      const leadDataNew = {
        ...leadData,
        ...lead,
      };

      dispatch({ type: LEAD_SET_DATA, payload: leadDataNew });
    } catch (error) {
      console.error(error);
    }
  };

export const LEAD_GET_DICTS = 'LEAD_GET_DICTS';
export const getLeadDicts = () => async (dispatch, getState) => {
  try {
    await dispatch(getEmployees());

    const {
      employees: { salesAgents = [], salesManagers = [] },
    } = getState();
    const companyId = getCompanyIdFromCookies();

    const usersData = await getUsers();
    const users = usersData.map((item) => ({
      label: `${item.FirstName} ${item.LastName}`,
      value: item.Id,
      companyRelation: item.SalesCompanies.find((sc) => sc.Company === companyId),
    }));

    const agents = users.filter(
      (user) => !!salesAgents.find((sa) => sa.value === user.companyRelation?.EmployeeId),
    );
    const managers = users.filter(
      (user) => !!salesManagers.find((sa) => sa.value === user.companyRelation?.EmployeeId),
    );

    dispatch({ type: LEAD_GET_DICTS, payload: { users, agents, managers } });

    const campaignData = await getCampaignsList(getFilterString({ pageSize: 10000 }));
    const campaigns = campaignData.map((item) => ({
      label: item.campaignName,
      value: item.id,
      code: item.campaignCode,
      channels: item.channels
        ?.filter((item) => {
          return !!item.id;
        })
        .map((channel) => ({
          label: channel?.channelName,
          value: channel?.channel,
          link: channel?.link,
        })),
    }));

    dispatch({
      type: LEAD_GET_DICTS,
      payload: {
        campaigns,
      },
    });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const changeGeneralFieldAction = (props) => (dispatch, getState) => {
  let payload = getState().lead.leadData;
  payload = {
    ...payload,
    [props.key]: props.value,
  };
  dispatch({ type: LEAD_SET_DATA, payload });
};

export const createNewLeadNote = (props) => async (dispatch, getState) => {
  const { Notes, Id } = getState().lead.leadData;

  const data = {
    ObjectId: Id,
    ObjectModel: 'Lead',
    NoteContent: props.text,
  };

  try {
    const note = await createNoteRequest(data);
    const value = [...Notes, note];
    dispatch(changeGeneralFieldAction({ key: 'Notes', value }));
  } catch (error) {
    console.error(error);
  }
};

export const updateLeadNote = (Id, note) => async (dispatch, getState) => {
  try {
    const notes = cloneDeep(getState().lead.leadData.Notes);

    const { NoteContent } = note;

    const data = {
      Id,
      NoteContent,
    };

    await updateNoteRequest(Id, data);

    const index = notes.findIndex((note) => note.Id === Id);

    notes.splice(index, 1, note);

    dispatch(changeGeneralFieldAction({ key: 'Notes', value: notes }));
  } catch (error) {
    console.error(error);
  }
};

export const deleteLeadNote = (noteId) => async (dispatch, getState) => {
  const payload = getState().lead.leadData?.Notes || [];

  try {
    await deleteNoteRequest(noteId);

    const value = payload.filter((item) => item.Id !== noteId);
    dispatch(changeGeneralFieldAction({ key: 'Notes', value }));
  } catch (error) {
    console.error(error);
  }
};

export const SET_LEAD_CUSTOMER = 'SET_LEAD_CUSTOMER';
export const getLeadCustomer = (id) => async (dispatch, getState) => {
  await axiosInstance
    .get(`/xassales/v1/customers/${id}`, {
      headers: getAuthorizationHeaders(),
    })
    .then((response) => {
      const {
        Id,
        Code,
        Group,
        Name,
        Type,
        Title,
        PhoneNumber,
        SecondaryPhoneNumber,
        Email,
        Notes,
        Contacts,
        PrivacyStatus,
        Addresses,
        Address,
      } = response.data;
      const payload = {
        code: Code,
        email: Email,
        group: Group && Group.Id,
        name: Name,
        title: Title && Title.Id,
        phoneNumber: PhoneNumber,
        secondaryPhoneNumber: SecondaryPhoneNumber,
        notes: Notes,
        privacyStatus: PrivacyStatus,
        id: Id,
        type: Type,
        contacts: Contacts,
        addresses: Addresses,
        address: Address,
      };
      dispatch({ type: SET_LEAD_CUSTOMER, payload });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getLinkedLeads = () => async (dispatch, getState) => {
  try {
    const {
      lead: {
        leadData: { Id },
      },
    } = getState();
    if (!Id) {
      return [];
    }

    const linkedLeads = await getLinkedLeadsList(Id);
    if (linkedLeads.length === 0) {
      dispatch(showWarning(t.t('lead_page.linked_leads.empty_result')));
    }

    return linkedLeads;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const handleActionWithLinkedLeads = (action, leadIds) => async (dispatch, getState) => {
  try {
    const {
      lead: {
        leadData: { Id },
      },
    } = getState();
    if (!Id) {
      return [];
    }

    await handleLinkedLeads(Id, {
      Action: action,
      LeadIds: leadIds,
    });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const LEAD_SET_CUSTOMER_CODE = 'LEAD_SET_CUSTOMER_CODE';
export const leadSetCustomer = (payload) => (dispatch) => {
  dispatch({ type: LEAD_SET_CUSTOMER_CODE, payload });
};

export const UPDATE_LEAD_DATA = 'UPDATE_LEAD_DATA';
export const updateLeadData = (data) => (dispatch, getState) => {
  const leadData = getState().lead.leadData;

  const payload = {
    ...leadData,
    ...data,
    CustomerCode: adaptCustomerForLead(data.CustomerCode),
  };

  dispatch({ type: LEAD_SET_DATA, payload });
};

export const addContact = (data) => async (dispatch, getState) => {
  const leadData = getState().lead.leadData;
  const { Id, Contacts } = leadData;

  try {
    const { Id: contactId, IsDefault } = await createContactAction(data);

    const contacts = [
      ...Contacts.map((item) => (IsDefault ? { ...item, IsDefault: false } : item)),
      {
        Rid: contactId,
        IsDefault,
      },
    ];

    dispatch(
      updateLead(Id, {
        Contacts: contacts,
      }),
    );
  } catch (error) {
    console.error(error);
  }
};

export const deleteContact = (contactId) => async (dispatch, getState) => {
  const { leadData } = getState().lead;
  const { Id, Contacts } = leadData;

  try {
    await deleteContactAction(contactId);

    const contacts = Contacts.filter(({ Rid }) => Rid !== contactId);

    dispatch(
      updateLead(Id, {
        Contacts: contacts,
      }),
    );
  } catch (error) {
    console.error(error);
  }
};

export const addAddress = (data) => async (dispatch, getState) => {
  const { leadData } = getState().lead;
  const { Id, Addresses = [] } = leadData;

  try {
    const { Id: addressId, IsDefault } = await createAddressRequest(data);

    const addresses = [
      ...Addresses.map((item) => (IsDefault ? { ...item, IsDefault: false } : item)),
      {
        Rid: addressId,
        IsDefault,
      },
    ];

    dispatch(
      updateLead(Id, {
        Addresses: addresses,
      }),
    );
  } catch (error) {
    console.error(error);
  }
};

export const deleteAddress = (addressId) => async (dispatch, getState) => {
  const { leadData } = getState().lead;
  const { Id, Addresses } = leadData;

  try {
    await deleteAddressRequest(addressId);

    const addresses = Addresses.filter(({ Rid }) => Rid !== addressId);

    dispatch(
      updateLead(Id, {
        Addresses: addresses,
      }),
    );
  } catch (error) {
    console.error(error);
  }
};

export const addCustomerInterest = (data) => async (dispatch, getState) => {
  const { leadData } = getState().lead;
  const { Id: leadId, CustomerInterests = [] } = leadData;

  try {
    const customerInterests = [...CustomerInterests, data];

    dispatch(
      updateLead(leadId, {
        CustomerInterests: customerInterests,
      }),
    );
  } catch (error) {
    console.error(error);
  }
};

export const updateCustomerInterest = (data) => async (dispatch, getState) => {
  const { leadData } = getState().lead;
  const { Id: leadId, CustomerInterests = [] } = leadData;
  const { Id: customerInterestId } = data;

  try {
    const customerInterests = [...CustomerInterests];

    const index = customerInterests.findIndex(({ Id }) => Id === customerInterestId);

    if (index === -1) {
      return;
    }

    customerInterests.splice(index, 1, data);

    dispatch(
      updateLead(leadId, {
        CustomerInterests: customerInterests,
      }),
    );
  } catch (error) {
    console.error(error);
  }
};

export const deleteCustomerInterest = (customerInterestId) => async (dispatch, getState) => {
  const { leadData } = getState().lead;
  const { Id: leadId, CustomerInterests } = leadData;

  try {
    const customerInterests = CustomerInterests.filter(({ Id }) => Id !== customerInterestId);

    dispatch(
      updateLead(leadId, {
        CustomerInterests: customerInterests,
      }),
    );
  } catch (error) {
    console.error(error);
  }
};
