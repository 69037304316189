import Cookies from 'js-cookie';
import JwtDecode from 'jwt-decode';

const salesTokenKey = '__SALES_token';
const salesRefreshTokenKey = '__SALES_refresh_token';
const salesSessionIdKey = '__SALES_sessionId';

/* Common */

export const set = (key, value) => {
  if (!key || !value) {
    return;
  }

  Cookies.set(key, value);
};
export const get = (key) => {
  if (!key) {
    return;
  }

  return Cookies.get(key);
};
export const remove = (key) => {
  if (!key) {
    return;
  }

  Cookies.remove(key);
};

/* Token */

export const setSalesToken = (token) => set(salesTokenKey, token);
export const getSalesToken = () => get(salesTokenKey);
export const removeSalesToken = () => remove(salesTokenKey);

/* Refresh Token */
export const setSalesRefreshToken = (refreshToken) => set(salesRefreshTokenKey, refreshToken);
export const getSalesRefreshToken = () => get(salesRefreshTokenKey);
export const removeSalesRefreshToken = () => remove(salesRefreshTokenKey);

/* Session Id */

export const setSalesSessionId = (sessionId) => set(salesSessionIdKey, sessionId);
export const getSalesSessionId = () => get(salesSessionIdKey);

/* User, Company, etc. */

export const getUserFromCookies = () => {
  const token = Cookies.get(salesTokenKey);
  const userInfo = token ? JwtDecode(token) : {};

  return userInfo;
};

export const getCompanyFromCookies = () => {
  const userInfo = getUserFromCookies();

  return userInfo.salesCompany || {};
};

export const getCompanyIdFromCookies = () => {
  const company = getCompanyFromCookies();

  // FIXME:
  return company.id || company._id || company.companyId;
};

export const getDefaultCustomerFromCookies = () => getCompanyFromCookies().defaultCustomer;
