import { FilterOperatorV2 } from '~/constants/filter';

export const getFilterForRangeValues = (from, to, isNumber = false) => {
  if (from?.value && !to?.value) {
    return {
      value: isNumber ? parseInt(from.value) : from.value,
      params: FilterOperatorV2.gte,
    };
  }

  if (!from?.value && to?.value) {
    return {
      value: isNumber ? parseInt(to.value) : to.value,
      params: FilterOperatorV2.lte,
    };
  }

  if (from?.value && to?.value) {
    return {
      value: isNumber ? [parseInt(from.value), parseInt(to.value)] : [from.value, to.value],
      params: FilterOperatorV2.between,
    };
  }
};
