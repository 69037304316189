import { CompanyExchangeRatePosting } from '~/constants/company';
import { TAX_PERCENT_DEFAULT } from '~/constants/prices';
import { getCompanyFromCookies } from '~/helpers/cookies';

const exchangeRatePosting =
  getCompanyFromCookies().exchangeRatePosting || CompanyExchangeRatePosting.Direct;

export const calculateLinePrice = (line) => {
  const { quantity, unitPrice = 0, taxPercent = TAX_PERCENT_DEFAULT } = line;

  const totalBeforeDiscount = quantity * unitPrice;
  const discountTotal = totalBeforeDiscount - unitPrice;
  const discountPercent = (discountTotal / unitPrice) * 100;
  const totalBeforeTax = totalBeforeDiscount - discountTotal;
  const taxTotal = (totalBeforeTax * taxPercent) / 100;
  const total = totalBeforeTax + taxTotal;

  return {
    totalBeforeDiscount,
    discountSum: discountTotal,
    totalBeforeTax,
    taxTotal,
    total,
    discountPercent,
  };
};

export const calculateAdditionalPrices = (prices, lineRate = 0, docRate = 0) => {
  const { unitPrice = 0, quantity, discountPercent = 0, taxPercent = TAX_PERCENT_DEFAULT } = prices;

  let localPrice = 0;
  let localTotal = 0;
  let accountPrice = 0;
  let accountTotal = 0;

  if (exchangeRatePosting === CompanyExchangeRatePosting.Direct) {
    localPrice = lineRate !== 0 ? unitPrice * lineRate : unitPrice;
    accountPrice = docRate !== 0 ? localPrice / docRate : localPrice;
  }
  if (exchangeRatePosting === CompanyExchangeRatePosting.Indirect) {
    localPrice = lineRate !== 0 ? unitPrice / lineRate : unitPrice;
    accountPrice = docRate !== 0 ? localPrice * docRate : localPrice;
  }

  localTotal = localPrice * quantity * (1 - discountPercent / 100);
  accountTotal = accountPrice * quantity * (1 - discountPercent / 100);

  const localTax = localTotal * (taxPercent / 100);
  const localTotalWithTax = localTotal + localTax;

  prices.localPrice = localPrice;
  prices.localTotal = localTotal;
  prices.accountPrice = accountPrice;
  prices.accountTotal = accountTotal;
  prices.localTax = localTax;
  prices.localTotalWithTax = localTotalWithTax;

  return prices;
};

export const calculateAdditionalPricesForDocument = (
  lines,
  docDiscountPercentage = 0,
  docTaxPercentage = 0,
) => {
  const accountDocTotal = lines.reduce((accumulator, line) => {
    accumulator += line.accountTotal;

    return accumulator;
  }, 0);

  lines = lines.map((line) => {
    const lineWeight = line.accountTotal / accountDocTotal;
    const docDiscountTaxReduction = lineWeight * docDiscountPercentage * docTaxPercentage;

    line.lineWeight = lineWeight;
    line.docDiscountTaxReduction = docDiscountTaxReduction;

    return line;
  });

  return lines;
};
