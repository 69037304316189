import cloneDeep from 'lodash.clonedeep';
import { getCompanyFromCookies } from '~/helpers/cookies';

const initialState = {
  branchId: getCompanyFromCookies()?.branchId || null,
  salesPersons: [],
};

export const SET_SALES_PERSONS = 'SET_SALES_PERSONS';
export const SET_BRANCH_ID = 'SET_BRANCH_ID';

export default function (state = cloneDeep(initialState), action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SALES_PERSONS:
      return {
        ...state,
        salesPersons: payload,
      };
    case SET_BRANCH_ID:
      return {
        ...state,
        branchId: payload,
      };
    default:
      return state;
  }
}
