import logoImg from '../../public/images/login/blue/sdm_logo.svg';
import { getCompanyLogo as getCompanyLogoRequest } from './api/tenantLogo';

export const DASHBOARD_SET_LOGO_URL = 'DASHBOARD_SET_LOGO_URL';

export const getCompanyLogo = () => async (dispatch) => {
  let logo = logoImg;
  try {
    const logoBlob = await getCompanyLogoRequest();

    if (logoBlob) {
      logo = logoBlob;
    }
    dispatch({ type: DASHBOARD_SET_LOGO_URL, payload: logo });
  } catch (error) {
    console.error(error);
    dispatch({ type: DASHBOARD_SET_LOGO_URL, payload: logo });
  }
};
