/**
 * @readonly
 * @enum {string}
 */
export const StorageKey = {
  branches: 'branches',
  AttachmentImages: 'AttachmentImages',
  filteredData: 'filteredData',
  __SALES_token: '__SALES_token',
  __SALES_roleToken: '__SALES_roleToken',
  __SALES_parameters: '__SALES_parameters',
};

export const localizationKey = 'companyLocalization';
