import { axiosInstanceSalesAPI } from '~/utils/axios';
import { queryParamsBuilder } from '~/helpers/filter';

const url = 'CloseReasons';

export const getCloseReasons = (paging = {}, filter = {}) =>
  axiosInstanceSalesAPI
    .get(url, {
      params: {
        ...queryParamsBuilder({ filter, paging }),
        pagingResponse: Object.keys(paging).length !== 0,
      },
    })
    .then(({ data }) => data);

export const createCloseReason = (data) =>
  axiosInstanceSalesAPI.post(url, data).then(({ data }) => data);

export const updateCloseReason = (id, data) =>
  axiosInstanceSalesAPI.patch(`${url}/${id}`, data).then(({ data }) => data);

export const deleteCloseReason = (id) =>
  axiosInstanceSalesAPI.delete(`${url}/${id}`).then(() => true);
