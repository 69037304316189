import { axiosInstanceSalesAPI } from '~/utils/axios';
import { PageSizeAll } from '~/constants';

const url = 'BaseModelsListService';

export const getBaseModels = (filter = []) =>
  axiosInstanceSalesAPI
    .get(url, {
      params: {
        pageSize: PageSizeAll,
        sort: 'name:1',
        filter: filter,
      },
    })
    .then(({ data }) => data);
