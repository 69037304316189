import { t } from '../utils/lang';
import {
  PARTNERS_CHANGE_FILTER,
  // PARTNERS_RESET_FILTER,
  PARTNERS_TOGGLE_LOADING,
  CUSTOMERS_CHANGE_CONTACT,
  CUSTOMERS_ADD_CONTACT,
  CUSTOMERS_CHANGE_FIELD,
  CUSTOMERS_REMOVE_CONTACT,
  PARTNERS_SET_PAGE,
  PARTNERS_SET_ROWS_PER_PAGE,
  PARTNERS_GET_DICT,
  CUSTOMERS_CREATE_NEW,
  GET_CUSTOMERS_LIST,
  CUSTOMERS_CHANGE_PAGING,
} from '../actions/customers';

const initialState = {
  filters: {
    text: '',
    CompanyPrivate: '',
    GroupCode: '',
  },
  partners: [],
  dicts: {
    CustomerGroups: {
      values: [],
    },
    Types: {
      values: [
        { label: t.t('partners_page.types.company'), value: 'company' },
        { label: t.t('partners_page.types.private'), value: 'private' },
      ],
    },
  },
  paging: {
    currentPage: 0,
    pageSize: 20,
  },
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMERS_LIST: {
      return {
        ...state,
        partners: action.payload,
        loading: false,
      };
    }
    case CUSTOMERS_CHANGE_PAGING: {
      return {
        ...state,
        paging: {
          ...state.paging,
          ...action.payload,
        },
      };
    }

    case PARTNERS_CHANGE_FILTER: {
      return { ...state, filters: action.filters };
    }

    case CUSTOMERS_CHANGE_FIELD: {
      return {
        ...state,
        partners: action.payload,
      };
    }

    case CUSTOMERS_CREATE_NEW: {
      return {
        ...state,
        partners: [...state.partners, action.payload],
      };
    }

    case PARTNERS_SET_PAGE: {
      return {
        ...state,
        paging: {
          ...state.paging,
          currentPage: action.currentPage,
        },
      };
    }

    case PARTNERS_SET_ROWS_PER_PAGE: {
      return {
        ...state,
        paging: {
          ...state.paging,
          ...action.payload,
        },
      };
    }
    case CUSTOMERS_CHANGE_CONTACT: {
      return {
        ...state,
        partners: action.payload,
      };
    }
    case CUSTOMERS_ADD_CONTACT: {
      return {
        ...state,
        partners: action.payload,
      };
    }

    case CUSTOMERS_REMOVE_CONTACT: {
      return {
        ...state,
        partners: action.payload,
      };
    }

    case PARTNERS_GET_DICT: {
      return {
        ...state,
        dicts: {
          ...state.dicts,
          ...action.payload,
        },
      };
    }

    case PARTNERS_TOGGLE_LOADING: {
      return { ...state, loading: action.payload };
    }

    default: {
      return state;
    }
  }
}
