import Cookies from 'js-cookie';
import axiosInstance from '~/utils/axios';
import approvalRequestsData from '~/data/approval_requests.json';
import { getOperationByParam } from '~/components/opportunities/table/helper';
import { isObjectsEqual } from '~/utils/general';
import { emptyString } from '~/constants';

export const statuses = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Approved', value: 'Approved' },
  { label: 'Rejected', value: 'Rejected' },
];
const requestTypes = [
  { label: 'create', value: 'create' },
  { label: 'update', value: 'update' },
  { label: 'delete', value: 'delete' },
];
const linkedTos = [
  { label: 'Opportunity', value: 'Opportunity' },
  { label: 'Lead', value: 'Lead' },
];
const documentTypes = [
  { label: 'SalesQuote', value: 'SalesQuote' },
  { label: 'Order', value: 'Order' },
  { label: 'Delivery', value: 'Delivery' },
];

const keysSearch = {
  Description: 'description',
  RequestType: 'requestType',
  FromUser: 'fromUser.rid',
  ToUser: 'toUser.rid',
  DocumentType: 'documentType',
  DocumentId: 'documentId.documentCode',
  LinkedTo: 'linkedTo',
  Status: 'status',
  LinkedToId: 'linkedToId.opportunityCode',
  LinkedCodeExternalRef: 'linkedToId.externalRef',
  RequestDate: 'requestDate',
  ResponseDate: 'responseDate',
};

const getFilterQuery = () => (dispatch, getState) => {
  const filterValues = getState().approvalRequests.filterValues;
  const search =
    filterValues.search && filterValues.search.value
      ? `filter=$text:${filterValues.search.value}`
      : '';

  let filterString = '?';

  Object.keys(filterValues).forEach((key, i) => {
    if (filterValues[key] && filterValues[key].value && key !== 'search') {
      let filterKey = keysSearch[key] || key;
      let operation = getOperationByParam(filterValues[key].params);
      let value = filterValues[key].value;
      let isEmptyRange = null;

      switch (key) {
        case 'RequestDate':
        case 'ResponseDate':
          let from = value.dateRangeStart;
          let to = value.dateRangeEnd;
          if (from && to) {
            operation = 'between';
            value = JSON.stringify([from, to]);
          } else {
            if (from) {
              operation = '>=';
              value = from;
            } else {
              if (to) {
                operation = '<=';
                value = to;
              } else {
                value = null;
                isEmptyRange = true;
              }
            }
          }
          break;
        default:
          value = `${value}`;
      }

      switch (filterValues[key].params) {
        case 'is empty':
        case 'is not empty':
          value = null;
          break;
        default:
      }

      if (!isEmptyRange) {
        let filterPart = `${filterKey}:${operation}:${value}`;
        let filterPartExists = filterString.split(filterPart).length > 1;

        if (!filterPartExists) {
          filterString += `${filterString.length > 1 ? '&' : ''}filter=${filterPart}`;
        }
      }
    }
  });

  const pageSize = 'pageSize=all';

  filterString =
    filterString.length > 1
      ? search
        ? `${filterString}${search}&${pageSize}`
        : `${filterString}&${pageSize}`
      : search
      ? `?${search}&${pageSize}`
      : `?${pageSize}`;

  return filterString;
};

export const APPROVAL_REQUESTS_GET_DATA = 'APPROVAL_REQUESTS_GET_DATA';
export const getData = () => (dispatch) => {
  dispatch({ type: APPROVAL_REQUESTS_CHANGE_LOADER, payload: true });
  axiosInstance
    .get(`/xassales/v1/ApprovalRequestsListService${dispatch(getFilterQuery())}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      const payload = response.data.map((item) => ({
        ...item,
        DocumentCode: item.DocumentId?.Id || emptyString,
        DocumentId: item.DocumentId?.DocumentCode || emptyString,
        DocumentExternalRef: item.DocumentId?.ExternalRef || emptyString,
        LinkedToId: item.LinkedToId?.OpportunityCode || emptyString,
        LinkedCode: item.LinkedToId?.Id || emptyString,
        LinkedCodeExternalRef: item.LinkedToId?.ExternalRef || emptyString,
        FromUserRid: item.FromUser?.Rid || emptyString,
        FromUser: item.FromUser?.Name || emptyString,
        ToUserRid: item.ToUser?.Rid || emptyString,
        ToUser: item.ToUser?.Name || emptyString,
      }));

      dispatch({ type: APPROVAL_REQUESTS_GET_DATA, payload });
      dispatch({ type: APPROVAL_REQUESTS_CHANGE_LOADER, payload: false });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: APPROVAL_REQUESTS_CHANGE_LOADER, payload: false });
    });
};

export const APPROVAL_REQUESTS_GET_DICTIONARIES = 'APPROVAL_REQUESTS_GET_DICTIONARIES';
export const getDicts = () => (dispatch) => {
  //get requestTypes
  dispatch({ type: APPROVAL_REQUESTS_GET_DICTIONARIES, payload: { requestTypes } });
  //get fromUsers and get toUsers
  axiosInstance
    .get(`/xassales/v1/users`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      const payload = response.data.map((item) => ({
        label: `${item.FirstName} ${item.LastName}`,
        value: item.Id,
      }));

      dispatch({ type: APPROVAL_REQUESTS_GET_DICTIONARIES, payload: { fromUsers: payload } });
      dispatch({ type: APPROVAL_REQUESTS_GET_DICTIONARIES, payload: { toUsers: payload } });
    })
    .catch((err) => {
      console.log(err);
    });

  //get documentTypes
  dispatch({ type: APPROVAL_REQUESTS_GET_DICTIONARIES, payload: { documentTypes } });
  //get linkedTos
  dispatch({ type: APPROVAL_REQUESTS_GET_DICTIONARIES, payload: { linkedTos } });
  //get statuses
  dispatch({ type: APPROVAL_REQUESTS_GET_DICTIONARIES, payload: { statuses } });
};

export const CHANGE_APPROVAL_FILTER_NAMES = 'CHANGE_APPROVAL_FILTER_NAMES';
export const APPROVAL_REQUESTS_CHANGE_FILTERS = ' APPROVAL_REQUESTS_CHANGE_FILTERS';
export const changeFilters = (key, value, params) => (dispatch, getState) => {
  let filterNames = getState().approvalRequests.filterNames;
  const isKey = filterNames.includes(key);
  if (isKey && !value) {
    filterNames.filter((item) => item !== key);
  }

  if (!isKey && value) {
    filterNames.push(key);
  }

  dispatch({ type: CHANGE_APPROVAL_FILTER_NAMES, payload: filterNames });
  dispatch({ type: APPROVAL_REQUESTS_CHANGE_FILTERS, key, value, params });
};

export const APPROVAL_REQUESTS_CHANGE_MODAL_FIELD = 'APPROVAL_REQUESTS_CHANGE_MODAL_FIELD';
export const changeModalField = (key, value) => (dispatch) => {
  dispatch({ type: APPROVAL_REQUESTS_CHANGE_MODAL_FIELD, key, value });
};

export const APPROVAL_REQUESTS_SET_DEFAULT_FILTERS_PARAMS =
  'APPROVAL_REQUESTS_SET_DEFAULT_FILTERS_PARAMS';
export const setValueForFilter = (count) => (dispatch, getState) => {
  const { filteredData } = getState().filters;
  let payload = null;

  if (count && count.key) {
    payload = filteredData.find(
      (item) => item.id === count.key || item.name === count.key,
    ).filterValues;
  }
  if (!payload) {
    payload = approvalRequestsData.filterValues;
  }

  dispatch({ type: APPROVAL_REQUESTS_SET_DEFAULT_FILTERS_PARAMS, payload });
};

export const APPROVAL_REQUESTS_DISABLE_NEW_FILTER = 'APPROVAL_REQUESTS_DISABLE_NEW_FILTER';
export const disableNewFilter = (key) => (dispatch, getState) => {
  const { filteredData } = getState().filters;
  const { filterValues } = getState().approvalRequests;

  if (key) {
    const item = filteredData.find((item) => item.id === key);
    const payload = isObjectsEqual(filterValues, item.filterValues);
    dispatch({ type: APPROVAL_REQUESTS_DISABLE_NEW_FILTER, payload });
  } else {
    const payload = !Object.keys(filterValues).reduce((accumulator, item) => {
      return accumulator || !!filterValues[item].value;
    }, false);
    dispatch({ type: APPROVAL_REQUESTS_DISABLE_NEW_FILTER, payload });
  }
};

export const APPROVAL_REQUESTS_CHANGE_PAGING = 'APPROVAL_REQUESTS_CHANGE_PAGING';
export const changePaging = (payload) => (dispatch, getState) => {
  dispatch({ type: 'APPROVAL_REQUESTS_CHANGE_PAGING', payload });
};

export const APPROVAL_REQUESTS_CHANGE_LOADER = 'APPROVAL_REQUESTS_CHANGE_LOADER';
