import {
  SET_CUSTOMER_GENERAL_FIELDS,
  SET_CUSTOMER,
  SET_DEFAULT_CUSTOMER,
  CUSTOMER_SET_ADDRESS,
} from '../actions/customer';
import initialState from '../data/customer';

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER_GENERAL_FIELDS: {
      return {
        ...state,
        updatedGeneralValues: {
          ...state.updatedGeneralValues,
          [action.field]: action.value,
        },
      };
    }
    case SET_CUSTOMER: {
      return {
        ...state,
        customer: action.payload,
      };
    }
    case SET_DEFAULT_CUSTOMER: {
      return {
        ...state,
        defaultCustomer: action.payload,
      };
    }
    case CUSTOMER_SET_ADDRESS: {
      return {
        ...state,
        customer: {
          ...state.customer,
          addresses: action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
}
