import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';

export const getUrlIcons = (links) => {
  if (!links) {
    return links;
  }

  const promise = links.map((url) =>
    axiosInstance
      .get(url, {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
        responseType: 'blob',
      })
      .then((r) => {
        if (r.data.size) {
          return window.URL.createObjectURL(r.data);
        }
        return null;
      })
      .catch((err) => null),
  );
  return Promise.all(promise);
};

export const getUrlIcon = (url) => {
  if (!url) {
    return url;
  }

  return axiosInstance
    .get(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
      responseType: 'blob',
    })
    .then((r) => {
      if (r.data.size) {
        return window.URL.createObjectURL(r.data);
      }
      return null;
    })
    .catch((err) => null);
};

export const getDataIcon = (url) => {
  if (!url) {
    return url;
  }

  return axiosInstance
    .get(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
      responseType: 'blob',
    })
    .then((r) => {
      if (r.data.size) {
        
        return {
          data: r.data,
          link: window.URL.createObjectURL(r.data)
        };
      }
      return null;
    })
    .catch((err) => null);
};