import {
  getLeadMappingsList,
  createLeadMapping as createLeadMappingServer,
  deleteLeadMapping as deleteLeadMappingServer,
  updateLeadMapping as updateLeadMappingServer,
} from './api/lead_mappings';
import leadMappingsData from '~/data/lead_mappings.json';
import { getOperationByParam } from '~/components/opportunities/table/helper';
import { isObjectsEqual } from '~/utils/general';

const keysSearch = {
  title: 'name',
  status: 'active',
};

const getFilterQuery = (filterValues) => {
  const search =
    filterValues.search && filterValues.search.value
      ? `filter=$text:${filterValues.search.value}`
      : '';

  let filterString = '?';

  Object.keys(filterValues).forEach((key, i) => {
    if (filterValues[key] && filterValues[key].value && key !== 'search') {
      let filterKey = keysSearch[key] || key;
      let operation = getOperationByParam(filterValues[key].params);
      let value = filterValues[key].value;

      switch (key) {
        default:
          value = `${value}`;
      }

      switch (filterValues[key].params) {
        case 'is empty':
        case 'is not empty':
          value = null;
          break;
        default:
      }

      let filterPart = `${filterKey}:${operation}:${value}`;
      let filterPartExists = filterString.split(filterPart).length > 1;

      if (!filterPartExists) {
        filterString += `${filterString.length > 1 ? '&' : ''}filter=${filterPart}`;
      }
    }
  });

  const pageSizeAndSort = `pageSize=all&sort=name:1`;

  filterString =
    filterString.length > 1
      ? search
        ? `${filterString}${search}&${pageSizeAndSort}`
        : `${filterString}&${pageSizeAndSort}`
      : search
      ? `?${search}&${pageSizeAndSort}`
      : `?${pageSizeAndSort}`;

  return filterString;
};

export const LEAD_MAPPINGS_CREATE = 'LEAD_MAPPINGS_CREATE';
export const createLeadMapping = (payload) => (dispatch) => {
  dispatch({ type: LEAD_MAPPINGS_CREATE, payload });
  createLeadMappingServer(payload);
};

export const LEAD_MAPPINGS_DELETE = 'LEAD_MAPPINGS_DELETE';
export const deleteLeadMapping = (payload) => (dispatch) => {
  dispatch({ type: LEAD_MAPPINGS_DELETE, payload });
  deleteLeadMappingServer(payload);
};

export const LEAD_MAPPINGS_UPDATE = 'LEAD_MAPPINGS_UPDATE';
export const updateLeadMapping = (payload) => (dispatch, getState) => {
  let mappings = getState().leadMappings.data;
  mappings.forEach((item, i) => {
    if (item.id === payload.id) {
      mappings[i] = payload;
    }
  });
  dispatch({ type: LEAD_MAPPINGS_UPDATE, payload: mappings });
  updateLeadMappingServer(payload);
};

export const LEAD_MAPPINGS_GET_LIST = 'LEAD_MAPPINGS_GET_LIST';
export const LEAD_MAPPINGS_LOADING = 'LEAD_MAPPINGS_LOADING';
export const getLeadMappings = () => (dispatch, getState) => {
  const { filterValues } = getState().leadMappings;
  dispatch({ type: LEAD_MAPPINGS_LOADING, payload: true });
  getLeadMappingsList({}, getFilterQuery(filterValues)).then((res) => {
    dispatch({ type: LEAD_MAPPINGS_GET_LIST, payload: res });
    dispatch({ type: LEAD_MAPPINGS_LOADING, payload: false });
  });
};

export const CHANGE_LEAD_MAPPINGS_FILTER_NAMES = 'CHANGE_LEAD_MAPPINGS_FILTER_NAMES';
export const LEAD_MAPPINGS_CHANGE_FILTERS = 'LEAD_MAPPINGS_CHANGE_FILTERS';
export const changeFilters = (key, value, params) => (dispatch, getState) => {
  let filterNames = getState().leadMappings.filterNames;
  const isKey = filterNames.includes(key);
  if (isKey && !value) {
    filterNames.filter((item) => item !== key);
  }

  if (!isKey && value) {
    filterNames.push(key);
  }

  dispatch({ type: CHANGE_LEAD_MAPPINGS_FILTER_NAMES, payload: filterNames });
  dispatch({ type: LEAD_MAPPINGS_CHANGE_FILTERS, key, value, params });
};

export const LEAD_MAPPINGS_CHANGE_MODAL_FIELD = 'LEAD_MAPPINGS_CHANGE_MODAL_FIELD';
export const changeModalField = (key, value) => (dispatch) => {
  dispatch({ type: LEAD_MAPPINGS_CHANGE_MODAL_FIELD, key, value });
};

export const LEAD_MAPPINGS_SET_DEFAULT_FILTERS_PARAMS = 'LEAD_MAPPINGS_SET_DEFAULT_FILTERS_PARAMS';
export const setValueForFilter = (count) => (dispatch, getState) => {
  const { filteredData } = getState().filters;
  let payload = null;
  if (count) {
    payload = filteredData.find((item) => item.id === count || item.name === count).filterValues;
  }

  if (!payload) {
    payload = leadMappingsData.filterValues;
  }

  dispatch({ type: LEAD_MAPPINGS_SET_DEFAULT_FILTERS_PARAMS, payload });
};

export const LEAD_MAPPINGS_DISABLE_NEW_FILTER = 'LEAD_MAPPINGS_DISABLE_NEW_FILTER';
export const disableNewFilter = (key) => (dispatch, getState) => {
  const { filteredData } = getState().filters;
  const { filterValues } = getState().leadMappings;

  if (key) {
    const item = filteredData.find((item) => item.id === key);
    const payload = isObjectsEqual(filterValues, item.filterValues);
    dispatch({ type: LEAD_MAPPINGS_DISABLE_NEW_FILTER, payload });
  } else {
    const payload = !Object.keys(filterValues).reduce((accumulator, item) => {
      return accumulator || !!filterValues[item].value;
    }, false);
    dispatch({ type: LEAD_MAPPINGS_DISABLE_NEW_FILTER, payload });
  }
};
