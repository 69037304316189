import { axiosInstanceSalesAPI } from '~/utils/axios';

const VehicleCategoriesListServiceUrl = 'VehicleCategoriesListService';
const VehicleCategoriesServiceUrl = 'VehicleCategoriesService';
const VehicleSubCategoriesListServiceUrl = 'VehicleSubCategoriesListService';

/* API */

export const getVehicleCategoriesList = async (filterQuery = '') =>
  axiosInstanceSalesAPI
    .get(`${VehicleCategoriesListServiceUrl}${filterQuery}`)
    .then((response) => response.data.map(formatVehicleCategory));

export const getVehicleSubCategoriesList = async (filterQuery = '') =>
  axiosInstanceSalesAPI
    .get(`${VehicleSubCategoriesListServiceUrl}${filterQuery}`)
    .then((response) => response.data.map(formatVehicleCategory));

export const getCategory = async (id) =>
  axiosInstanceSalesAPI
    .get(`${VehicleCategoriesServiceUrl}/${id}`)
    .then((response) => formatVehicleCategory(response.data));

export const createCategory = async (data) =>
  axiosInstanceSalesAPI
    .post(`${VehicleCategoriesServiceUrl}`, formatVehicleCategoryToServer(data))
    .then((response) => response.data);

export const updateCategory = async (data) =>
  axiosInstanceSalesAPI
    .patch(`${VehicleCategoriesServiceUrl}/${data.id}`, formatVehicleCategoryToServer(data))
    .then((response) => formatVehicleCategory(response.data));

export const deleteCampaign = async (id) =>
  axiosInstanceSalesAPI
    .delete(`${VehicleCategoriesServiceUrl}/${id}`)
    .then((response) => formatVehicleCategory(response.data));

/* Data Formatters */
export const formatVehicleCategory = (category) => ({
  ...category,
  NumberOfDoors: category.NumberOfDoors?.Id,
  NumberOfSeats: category.NumberOfSeats?.Id,
  LuggageCapacity: category.LuggageCapacity?.Id,
});

const formatVehicleCategoryToServer = (category) => ({
  ...category,
});
