import { store } from '~/App';
import { getLang } from '~/utils/lang';

import config from '~/config';
import { t } from '~/utils/lang';

export const dot = '.';
export const comma = ',';

export const formatPrice = (price = 0, fractionDigits = 0) => {
  const locale = getLang();
  const state = store.getState();
  const {
    localization: { currency, defaultCurrency },
  } = state;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || defaultCurrency,
    minimumFractionDigits: price === 0 ? 0 : fractionDigits,
    maximumFractionDigits: price === 0 ? 0 : fractionDigits,
  }).format(price);
};

export const formatPriceWithCurrency = (price = 0, fractionDigits = 0, currency) => {
  const {
    localization: { defaultCurrency },
  } = store.getState();

  if (!currency) {
    currency = defaultCurrency;
  }

  let formatted;
  try {
    formatted = new Intl.NumberFormat(getLang(), {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: price === 0 ? 0 : fractionDigits,
      maximumFractionDigits: price === 0 ? 0 : fractionDigits,
    }).format(price);
  } catch (error) {
    formatted = `${currency}${new Intl.NumberFormat(getLang(), {
      style: 'decimal',
      minimumFractionDigits: price === 0 ? 0 : fractionDigits,
      maximumFractionDigits: price === 0 ? 0 : fractionDigits,
    }).format(price)}`;
  }

  return formatted;
};

export const formatPriceWithCurrencyV2 = (
  price = 0,
  currencyCode = undefined,
  fractionDigits = 0,
) => {
  const { currencies } = store.getState();

  if (price === 0) {
    fractionDigits = 0;
  }
  if (!currencyCode) {
    currencyCode = '';
  }

  const currency = currencies[currencyCode.toUpperCase()];

  const uiCode = currency?.HTMLCode || currency?.ISOCode || currencyCode;

  const formatted = `${uiCode}${new Intl.NumberFormat(getLang(), {
    style: 'decimal',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(price)}`;

  return formatted;
};

export const formatPercentage = (percent = 0, showSymbolIfZero) => {
  const percentRow = `${percent}`.slice(0, 5);
  return percent || showSymbolIfZero ? `${percentRow}%` : 0;
};

export const negativeIfTradeIn = (value, isTradeIn) => (isTradeIn && value >= 0 ? -value : value);

export const checkIsRTL = () => {
  const language = sessionStorage.getItem('locale') || t.language;

  return config.rtlLanguages.includes(language);
};
