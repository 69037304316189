export default {
  blue: {
    primeColor: '#0091ea',
    themeColor: '#0091ea',
    themeColorLighter: '#22a3f2',
    themeColorDarker: '#085a9b',
    activesColor: '#ECA821',
    jcContent: {
      ccColor: '#E4F3FC',
    },
    blackText: '#303030',
  },
  orange: {
    primeColor: '#1A5BA3',
    themeColor: '#ECA821',
    themeColorLighter: '#FCCF63',
    themeColorDarker: '#de9e04',
    activesColor: '#d89904',
    jcContent: {
      ccColor: '#FCF2DA',
    },
    blackText: '#303030',
  },
};
