import data from '../data/approval_requests';
import {
  APPROVAL_REQUESTS_GET_DATA,
  APPROVAL_REQUESTS_GET_DICTIONARIES,
  APPROVAL_REQUESTS_CHANGE_FILTERS,
  APPROVAL_REQUESTS_CHANGE_MODAL_FIELD,
  APPROVAL_REQUESTS_SET_DEFAULT_FILTERS_PARAMS,
  APPROVAL_REQUESTS_DISABLE_NEW_FILTER,
  APPROVAL_REQUESTS_CHANGE_PAGING,
  APPROVAL_REQUESTS_CHANGE_LOADER,
  CHANGE_APPROVAL_FILTER_NAMES,
} from '../actions/approval_requests';
import { GET_FILTER_NAMES } from '../actions/leads';

const initialState = data;

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FILTER_NAMES: {
      return {
        ...state,
        filterNames: action.payload,
      };
    }
    case APPROVAL_REQUESTS_CHANGE_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case APPROVAL_REQUESTS_DISABLE_NEW_FILTER: {
      return {
        ...state,
        isDisabledNewFilter: action.payload,
      };
    }

    case APPROVAL_REQUESTS_CHANGE_PAGING: {
      return {
        ...state,
        paging: {
          ...state.paging,
          ...action.payload,
        },
      };
    }

    case APPROVAL_REQUESTS_SET_DEFAULT_FILTERS_PARAMS: {
      return {
        ...state,
        filterValues: action.payload,
      };
    }

    case APPROVAL_REQUESTS_CHANGE_MODAL_FIELD: {
      return {
        ...state,
        filterModal: {
          ...state.filterModal,
          [action.key]: action.value,
        },
      };
    }

    case APPROVAL_REQUESTS_CHANGE_FILTERS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.key]: { value: action.value, params: action.params },
        },
      };
    }

    case APPROVAL_REQUESTS_GET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case APPROVAL_REQUESTS_GET_DICTIONARIES: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          ...action.payload,
        },
      };
    }

    case CHANGE_APPROVAL_FILTER_NAMES: {
      return { ...state, filterNames: action.payload };
    }

    default:
      return state;
  }
}
