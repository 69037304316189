import config from '~/config';
import { getUserFromCookies } from '~/helpers/cookies';
import { UpdatedPaymentStatusEvent, ActivityUpdatedEvent } from '~/constants/events';
import { setPaymentStatus } from './payments';
import { updateActivityEvent } from './activities';

export const CHANGE_TYPE_VISIBLE = 'CHANGE_TYPE_VISIBLE';

export const changeTypeVisibleAction = (payload) => (dispatch) => {
  dispatch({ type: CHANGE_TYPE_VISIBLE, payload });
};

export const CHANGE_USER_NOTIFICATIONS_VISIBLE = 'CHANGE_USER_NOTIFICATIONS_VISIBLE';
export const changeUserNotificationsVisible = (value) => (dispatch, getState) => {
  dispatch({ type: CHANGE_USER_NOTIFICATIONS_VISIBLE, payload: value });
};

export const CHANGE_USER_NOTIFICATIONS_COUNT = 'CHANGE_USER_NOTIFICATIONS_COUNT';
export const changeNotificationsCount = (step) => (dispatch, getState) => {
  let count = getState().header.userNotificationsCount;
  count = step > 0 ? count + 1 : step < 0 && count > 0 ? count - 1 : 0;
  dispatch({ type: CHANGE_USER_NOTIFICATIONS_COUNT, payload: count });
};

export const SET_IS_NOTIFICATION_SUBSCRIPTION = 'SET_IS_NOTIFICATION_SUBSCRIPTION';

export const getCurrentUserId = () => {
  const userInfo = getUserFromCookies();
  return userInfo._id;
};

export const getUserType = () => (dispatch, getState) => {
  const { salesManagers, salesAgents } = getState().employees;
  const userRole = getUserFromCookies()?.salesCompany?.employeeId;
  const isSalesManager = !!salesManagers.filter(({ value }) => value === userRole)[0];
  const isSalesAgent = !!salesAgents.filter(({ value }) => value === userRole)[0];

  return {
    isSalesAgent,
    isSalesManager,
  };
};

export const subscribeNotifications = (id) => (dispatch) => {
  const userId = id || getCurrentUserId();
  const es = new EventSource(`${config.apiUrl}xassales/v1/events`);
  es.addEventListener(userId, (e) => {
    const { data: eventData } = e;

    const json = JSON.parse(eventData);

    const { event, data } = json;

    switch (event) {
      case UpdatedPaymentStatusEvent: {
        const { status, error } = data;

        dispatch(
          setPaymentStatus({
            status,
            error,
          }),
        );
        break;
      }
      case ActivityUpdatedEvent: {
        dispatch(updateActivityEvent(data));
        break;
      }
      default: {
        dispatch(changeNotificationsCount(1));
        break;
      }
    }
  });
  dispatch({ type: SET_IS_NOTIFICATION_SUBSCRIPTION, payload: true });
};

export const unsubscribeNotifications = () => (dispatch) => {
  dispatch({ type: SET_IS_NOTIFICATION_SUBSCRIPTION, payload: false });
};
