import {createGlobalStyle} from 'styled-components';
import { MOBILE_WIDTH } from './indexStyled';

export const RTLGlobalStyle = createGlobalStyle`
    /*
   * RTL
   */
  html[dir='rtl'] {
    th {
      text-align: right;
      &.text-center {
        text-align: center;
      }
    }
    .pull-left {
      float: right !important;
    }
    .pull-right {
      float: left !important;
    }
    h3.title {
      margin-left: 10px;
      margin-right: 0;
      text-align: right;
    }
    .nav-tabs > li {
      float: right;
    }
    .radio label, .checkbox label {
      padding-left: 0;
      padding-right: 30px;
      input[type="radio"], input[type="checkbox"] {
        margin-left: 0;
        margin-right: -20px;
      }
    }
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1,
    .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2,
    .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3,
    .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4,
    .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5,
    .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
    .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7,
    .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8,
    .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9,
    .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
    .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11,
    .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      float: right;
    }

    .col-xs-offset-12 {
      margin-right: 100%;
      margin-left: 0;
    }
    .col-xs-offset-11 {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .col-xs-offset-10 {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .col-xs-offset-9 {
      margin-right: 75%;
      margin-left: 0;
    }
    .col-xs-offset-8 {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .col-xs-offset-7 {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .col-xs-offset-6 {
      margin-right: 50%;
      margin-left: 0;
    }
    .col-xs-offset-5 {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .col-xs-offset-4 {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .col-xs-offset-3 {
      margin-right: 25%;
      margin-left: 0;
    }
    .col-xs-offset-2 {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .col-xs-offset-1 {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .col-xs-offset-0 {
      margin-right: 0%;
      margin-left: 0;
    }

    @media (min-width: 768px) {
      .col-sm-offset-12 {
        margin-right: 100%;
        margin-left: 0;
      }
      .col-sm-offset-11 {
        margin-right: 91.66666667%;
        margin-left: 0;
      }
      .col-sm-offset-10 {
        margin-right: 83.33333333%;
        margin-left: 0;
      }
      .col-sm-offset-9 {
        margin-right: 75%;
        margin-left: 0;
      }
      .col-sm-offset-8 {
        margin-right: 66.66666667%;
        margin-left: 0;
      }
      .col-sm-offset-7 {
        margin-right: 58.33333333%;
        margin-left: 0;
      }
      .col-sm-offset-6 {
        margin-right: 50%;
        margin-left: 0;
      }
      .col-sm-offset-5 {
        margin-right: 41.66666667%;
        margin-left: 0;
      }
      .col-sm-offset-4 {
        margin-right: 33.33333333%;
        margin-left: 0;
      }
      .col-sm-offset-3 {
        margin-right: 25%;
        margin-left: 0;
      }
      .col-sm-offset-2 {
        margin-right: 16.66666667%;
        margin-left: 0;
      }
      .col-sm-offset-1 {
        margin-right: 8.33333333%;
        margin-left: 0;
      }
      .col-sm-offset-0 {
        margin-right: 0%;
        margin-left: 0;
      }
    }

    @media (min-width: 992px) {
      .col-md-offset-12 {
        margin-right: 100%;
        margin-left: 0;
      }
      .col-md-offset-11 {
        margin-right: 91.66666667%;
        margin-left: 0;
      }
      .col-md-offset-10 {
        margin-right: 83.33333333%;
        margin-left: 0;
      }
      .col-md-offset-9 {
        margin-right: 75%;
        margin-left: 0;
      }
      .col-md-offset-8 {
        margin-right: 66.66666667%;
        margin-left: 0;
      }
      .col-md-offset-7 {
        margin-right: 58.33333333%;
        margin-left: 0;
      }
      .col-md-offset-6 {
        margin-right: 50%;
        margin-left: 0;
      }
      .col-md-offset-5 {
        margin-right: 41.66666667%;
        margin-left: 0;
      }
      .col-md-offset-4 {
        margin-right: 33.33333333%;
        margin-left: 0;
      }
      .col-md-offset-3 {
        margin-right: 25%;
        margin-left: 0;
      }
      .col-md-offset-2 {
        margin-right: 16.66666667%;
        margin-left: 0;
      }
      .col-md-offset-1 {
        margin-right: 8.33333333%;
        margin-left: 0;
      }
      .col-md-offset-0 {
        margin-right: 0%;
        margin-left: 0;
      }
    }

    @media (min-width: ${MOBILE_WIDTH + 1}px) {
      .col-lg-offset-12 {
        margin-right: 100%;
        margin-left: 0;
      }
      .col-lg-offset-11 {
        margin-right: 91.66666667%;
        margin-left: 0;
      }
      .col-lg-offset-10 {
        margin-right: 83.33333333%;
        margin-left: 0;
      }
      .col-lg-offset-9 {
        margin-right: 75%;
        margin-left: 0;
      }
      .col-lg-offset-8 {
        margin-right: 66.66666667%;
        margin-left: 0;
      }
      .col-lg-offset-7 {
        margin-right: 58.33333333%;
        margin-left: 0;
      }
      .col-lg-offset-6 {
        margin-right: 50%;
        margin-left: 0;
      }
      .col-lg-offset-5 {
        margin-right: 41.66666667%;
        margin-left: 0;
      }
      .col-lg-offset-4 {
        margin-right: 33.33333333%;
        margin-left: 0;
      }
      .col-lg-offset-3 {
        margin-right: 25%;
        margin-left: 0;
      }
      .col-lg-offset-2 {
        margin-right: 16.66666667%;
        margin-left: 0;
      }
      .col-lg-offset-1 {
        margin-right: 8.33333333%;
        margin-left: 0;
      }
      .col-lg-offset-0 {
        margin-right: 0%;
        margin-left: 0;
      }
    }
  }
 `;
