import initialState from '../data/customer-contacts';
import {
  PARTNER_CONTACTS_CHANGE_MODAL_FIELDS,
  PARTNER_CONTACTS_CLEAR_MODAL_FIELDS,
  PARTNER_CONTACTS_CHANGE_FILTERS,
} from '../actions/customer-contacts';

export default function(state = initialState, action) {
  switch (action.type) {
    case PARTNER_CONTACTS_CHANGE_MODAL_FIELDS: {
      const fields = {};
      Object.keys(action.fields).forEach(field => {
        fields[field] = action.fields[field];
      });
      return {
        ...state,
        [`${action.name}Modal`]: {
          ...state[`${action.name}Modal`],
          ...fields,
        },
      };
    }

    case PARTNER_CONTACTS_CLEAR_MODAL_FIELDS: {
      return {
        ...state,
        [`${action.name}Modal`]: {
          ...initialState[`${action.name}Modal`],
        },
      };
    }
    case PARTNER_CONTACTS_CHANGE_FILTERS: {
      return {
        ...state,
        filters: {
          text: action.value,
        },
      };
    }

    default: {
      return state;
    }
  }
}
