import config from '../config';

export default {
  contacts: [],
  total: 0,
  filters: {
    text: '',
  },
  paging: {
    currentPage: 0,
    pageSize: config.pageSize,
  },
};
