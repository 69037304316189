import {
  SET_LOADING,
  SET_TRADE_IN,
  SET_LEAD_DICTIONARIES,
  SET_DICTIONARY,
} from '~/reducers/dictionaries';
import { getCompanyIdFromCookies } from '~/helpers/cookies';
import { getTradeIn } from '~/actions/api/sync';
import { getCustomerGroups } from './api/customerGroups';
import { getCreditCards } from './api/creditCards';
import { getCountries } from './api/countries';
import { getCities } from './api/cities';
import { getHouseBankAccounts } from './api/houseBankAccounts';
import { getBanks } from './api/banks';
import { getCreditCardPaymentTypes } from './api/deFacto';
import { getDevices } from './api/devices';
import { getChecklistTypes } from './api/checklists';
import { getDict } from './api/standart_getter';
import { getSaleTypes } from './api/saleTypes';
import { getLocations } from './api/locations';
import { getCompanies } from './api/companies';
import { getLeadDictionaries } from './api/leads';
import {
  getUnqualifiedReasons as getUnqualifiedReasonsAction,
  createUnqualifiedReason as createUnqualifiedReasonAction,
  updateUnqualifiedReason as updateUnqualifiedReasonAction,
  deleteUnqualifiedReason as deleteUnqualifiedReasonAction,
} from './api/unqualifiedReasons';
import {
  getCloseReasons as getCloseReasonsAction,
  createCloseReason as createCloseReasonAction,
  updateCloseReason as updateCloseReasonAction,
  deleteCloseReason as deleteCloseReasonAction,
} from './api/closeReasons';
import {
  getCustomerInterests as getCustomerInterestsAction,
  createCustomerInterest as createCustomerInterestAction,
  updateCustomerInterest as updateCustomerInterestAction,
  deleteCustomerInterest as deleteCustomerInterestAction,
} from './api/customerInterests';
import { getCampaigns } from './api/campaigns';
import { getSources } from './api/sources';
import { getSubSources } from './api/subSources';
import { emptyString, PageSizeAll } from '~/constants';
import { getUsers } from './api/users';
import { getActivityStatuses as getActivityStatusesRequest } from './api/activities';
import { objectKeysPascalCaseToCamelCase } from '~/utils/object';
import { getBranches } from './api/branches';
import { getVehiclesTestDriveDict } from './api/vehicleSearch';

/* Data Formatters */

const formatTradeIn = (tradeIn) => ({
  itemCode: tradeIn.ItemCode,
});

const formatHouseBankAccount = (houseBankAccount) => ({
  label: houseBankAccount.AccountName,
  value: houseBankAccount.Id,
});

export const formatDict = ({ Name, Id }) => ({
  id: Id,
  value: Id,
  label: Name,
});

const formatUsersDict = ({ FirstName, LastName, Id, SalesCompanies }, companyId) => ({
  id: Id,
  label: `${FirstName} ${LastName}`,
  value: Id,
  companyRelation: SalesCompanies.find((sc) => sc.Company === companyId),
});

const formatCompanyForDict = ({ id, name, branches = [] }) => ({
  label: name,
  value: id,
  branches: branches.map(({ id, name }) => ({ label: name, value: id })),
});

const formatCloseReasonsDict = ({ Name, Id, ObjectModel = emptyString }) => ({
  id: Id,
  value: Id,
  label: Name,
  objectModel: ObjectModel,
});

export const formatVehicleSubCategoryDict = ({ Name, Id, CategoryId }) => ({
  id: Id,
  value: Id,
  label: Name,
  category: CategoryId,
});

export const formatBaseModelsDict = ({ Name, Id, Make, Category, SubCategory }) => ({
  label: Name,
  value: Id,
  makeId: Make?.Id,
  categoryId: Category?.Id,
  subCategoryId: SubCategory?.Id,
});

export const formatVehicleModelsDict = ({
  Name,
  Id,
  Make,
  Category,
  SubCategory,
  BaseModelId,
  Colors,
}) => ({
  label: Name,
  value: Id,
  colors: Colors,
  subCategoryId: SubCategory?.Id || SubCategory || emptyString,
  baseModelId: BaseModelId?.Id || BaseModelId || emptyString,
  categoryId: Category?.Id || Category || emptyString,
  makeId: Make?.Id || Make || emptyString,
});

export const formatYearDict = ({ Name, Id, Code }) => ({
  id: Id,
  value: Code,
  label: Name,
});

export const formatBranches = ({ Name, Id, Code }) => ({
  id: Id,
  value: Code,
  label: Name,
});

export const formatCampaign = ({ Id, Name, Enabled }) => ({
  id: Id,
  value: Id,
  label: Name,
  isEnabled: Enabled,
});

export const formatSubSource = ({ Id, Name, SourceId }) => ({
  id: Id,
  value: Id,
  label: Name,
  sourceId: SourceId,
});

/* Actions */

export const loadTradeIn = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'tradeIn', status: true } });

  try {
    const tradeIn = await getTradeIn();
    dispatch({ type: SET_TRADE_IN, payload: formatTradeIn(tradeIn) });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'tradeIn', status: false } });
  }
};

export const loadLeadDictionaries = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'lead', status: true } });

  try {
    const leadDictionaries = await getLeadDictionaries();

    dispatch({ type: SET_LEAD_DICTIONARIES, payload: leadDictionaries });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'lead', status: false } });
  }
};

export const loadCustomerGroups = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'customerGroups', status: true } });

  try {
    const customerGroups = await getCustomerGroups();
    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'customerGroups',
        data: customerGroups.map(formatDict),
      },
    });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'customerGroups', status: false } });
  }
};

export const loadCreditCards = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'creditCards', status: true } });

  try {
    const creditCards = await getCreditCards();

    dispatch({
      type: SET_DICTIONARY,
      payload: { dictionary: 'creditCards', data: creditCards.map(formatDict) },
    });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'creditCards', status: false } });
  }
};

export const loadIndustries = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'industries', status: true } });

  try {
    const industries = await getDict('IndustriesListService');

    dispatch({
      type: SET_DICTIONARY,
      payload: { dictionary: 'industries', data: industries.map(formatDict) },
    });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'industries', status: false } });
  }
};

export const loadTerritories = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'territories', status: true } });

  try {
    const territories = await getDict('TerritoriesListService');

    dispatch({
      type: SET_DICTIONARY,
      payload: { dictionary: 'territories', data: territories.map(formatDict) },
    });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'territories', status: false } });
  }
};

export const loadCountries = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'countries', status: true } });

  try {
    const countries = await getCountries();

    dispatch({
      type: SET_DICTIONARY,
      payload: { dictionary: 'countries', data: countries.map(formatDict) },
    });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'countries', status: false } });
  }
};

export const loadCities = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'cities', status: true } });

  try {
    const cities = await getCities();

    dispatch({
      type: SET_DICTIONARY,
      payload: { dictionary: 'cities', data: cities.map(formatDict) },
    });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'cities', status: false } });
  }
};

export const loadHouseBankAccounts = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'houseBankAccounts', status: true } });

  try {
    const houseBankAccounts = await getHouseBankAccounts();

    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'houseBankAccounts',
        data: houseBankAccounts.map(formatHouseBankAccount),
      },
    });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'houseBankAccounts', status: false } });
  }
};

export const loadBanks = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'banks', status: true } });

  try {
    const banks = await getBanks();

    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'banks',
        data: banks.map(formatDict),
      },
    });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'banks', status: false } });
  }
};

export const loadCreditCardPaymentTypes = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'creditCardPaymentTypes', status: true } });

  try {
    const creditCardPaymentTypes = await getCreditCardPaymentTypes();

    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'creditCardPaymentTypes',
        data: creditCardPaymentTypes.map(formatDict),
      },
    });
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'creditCardPaymentTypes', status: false },
    });
  }
};

export const loadDevices = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'devices', status: true } });

  try {
    const devices = await getDevices();

    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'devices',
        data: devices.map(formatDict),
      },
    });
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'devices', status: false },
    });
  }
};

export const loadChecklistTypes = () => async (dispatch, getState) => {
  try {
    const types = await getChecklistTypes();

    await dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'checklistTypes',
        data: types,
      },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'checklistTypes', status: false },
    });
  }
};

export const loadSaleTypes = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'saleTypes', status: true } });

  try {
    const saleTypes = await getSaleTypes();
    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'saleTypes',
        data: saleTypes.map(formatDict),
      },
    });
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'saleTypes', status: false },
    });
  }
};

export const loadLocations = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'locations', status: true } });

  try {
    const locations = await getLocations();
    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'locations',
        data: locations.map(formatDict),
      },
    });
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'locations', status: false },
    });
  }
};

export const loadCompanies = () => async (dispatch) => {
  try {
    const companies = await getCompanies();
    const formattedCompanies = companies.map(formatCompanyForDict);
    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'companies',
        data: formattedCompanies,
      },
    });

    const currentCompanyId = getCompanyIdFromCookies();
    const currentCompanyDict = formattedCompanies.find(
      (companyDict) => companyDict.value === currentCompanyId,
    );
    if (currentCompanyDict) {
      dispatch({
        type: SET_DICTIONARY,
        payload: {
          dictionary: 'companyBranches',
          data: currentCompanyDict.branches,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'companies', status: false },
    });
  }
};

export const setUnqualifiedReasons = (unqualifiedReasons) => (dispatch) => {
  dispatch({
    type: SET_DICTIONARY,
    payload: {
      dictionary: 'unqualifiedReasons',
      data: unqualifiedReasons,
    },
  });
};

export const loadUnqualifiedReasons = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'unqualifiedReasons', status: true } });

  try {
    const unqualifiedReasons = await getUnqualifiedReasonsAction();

    dispatch(setUnqualifiedReasons(unqualifiedReasons.map(formatDict)));
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'unqualifiedReasons', status: false },
    });
  }
};

export const createUnqualifiedReason = (data) => async (dispatch, getState) => {
  try {
    const unqualifiedReason = await createUnqualifiedReasonAction(data);

    const unqualifiedReasons = getState().dictionaries.unqualifiedReasons;

    const unqualifiedReasonsNew = [...unqualifiedReasons, formatDict(unqualifiedReason)];

    dispatch(setUnqualifiedReasons(unqualifiedReasonsNew));
  } catch (error) {
    console.error(error);
  }
};

export const updateUnqualifiedReason =
  (unqualifiedReasonId, data) => async (dispatch, getState) => {
    try {
      const unqualifiedReason = await updateUnqualifiedReasonAction(unqualifiedReasonId, data);

      const unqualifiedReasons = getState().dictionaries.unqualifiedReasons;

      const { Id } = unqualifiedReason;
      const unqualifiedReasonsNew = [...unqualifiedReasons];

      const index = unqualifiedReasonsNew.findIndex(({ id }) => id === Id);
      unqualifiedReasonsNew.splice(index, 1, formatDict(unqualifiedReason));

      dispatch(setUnqualifiedReasons(unqualifiedReasonsNew));
    } catch (error) {
      console.error(error);
    }
  };

export const deleteUnqualifiedReason = (unqualifiedReasonId) => async (dispatch, getState) => {
  try {
    await deleteUnqualifiedReasonAction(unqualifiedReasonId);

    const unqualifiedReasons = getState().dictionaries.unqualifiedReasons;

    const unqualifiedReasonsNew = [...unqualifiedReasons];

    const index = unqualifiedReasonsNew.findIndex(({ id }) => id === unqualifiedReasonId);
    unqualifiedReasonsNew.splice(index, 1);

    dispatch(setUnqualifiedReasons(unqualifiedReasonsNew));
  } catch (error) {
    console.error(error);
  }
};

export const setCloseReasons = (closeReasons) => (dispatch) => {
  dispatch({
    type: SET_DICTIONARY,
    payload: {
      dictionary: 'closeReasons',
      data: closeReasons,
    },
  });
};

export const loadCloseReasons = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'closeReasons', status: true } });

  try {
    const closeReasons = await getCloseReasonsAction();

    dispatch(setCloseReasons(closeReasons.map(formatCloseReasonsDict)));
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'closeReasons', status: false },
    });
  }
};

export const createCloseReason = (data) => async (dispatch, getState) => {
  try {
    const closeReason = await createCloseReasonAction(data);

    const closeReasons = getState().dictionaries.closeReasons;

    const closeReasonsNew = [...closeReasons, formatDict(closeReason)];

    dispatch(setCloseReasons(closeReasonsNew));
  } catch (error) {
    console.error(error);
  }
};

export const updateCloseReason = (closeReasonId, data) => async (dispatch, getState) => {
  try {
    const closeReason = await updateCloseReasonAction(closeReasonId, data);

    const closeReasons = getState().dictionaries.closeReasons;

    const { Id } = closeReason;
    const closeReasonsNew = [...closeReasons];

    const index = closeReasonsNew.findIndex(({ id }) => id === Id);
    closeReasonsNew.splice(index, 1, formatDict(closeReason));

    dispatch(setCloseReasons(closeReasonsNew));
  } catch (error) {
    console.error(error);
  }
};

export const deleteCloseReason = (closeReasonId) => async (dispatch, getState) => {
  try {
    await deleteCloseReasonAction(closeReasonId);

    const closeReasons = getState().dictionaries.closeReasons;

    const closeReasonsNew = [...closeReasons];

    const index = closeReasonsNew.findIndex(({ id }) => id === closeReasonId);
    closeReasonsNew.splice(index, 1);

    dispatch(setCloseReasons(closeReasonsNew));
  } catch (error) {
    console.error(error);
  }
};

export const setCustomerInterests = (customerInterests) => (dispatch) => {
  dispatch({
    type: SET_DICTIONARY,
    payload: {
      dictionary: 'customerInterests',
      data: customerInterests,
    },
  });
};

export const loadCustomerInterests = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'customerInterests', status: true } });

  try {
    const customerInterests = await getCustomerInterestsAction({
      pageSize: PageSizeAll,
    });

    dispatch(setCustomerInterests(customerInterests.map(formatDict)));
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'customerInterests', status: false },
    });
  }
};

export const createCustomerInterest = (data) => async (dispatch, getState) => {
  try {
    const customerInterest = await createCustomerInterestAction(data);

    const customerInterests = getState().dictionaries.customerInterests;

    const customerInterestsNew = [...customerInterests, formatDict(customerInterest)];

    dispatch(setCustomerInterests(customerInterestsNew));
  } catch (error) {
    console.error(error);
  }
};

export const updateCustomerInterest = (customerInterestId, data) => async (dispatch, getState) => {
  try {
    const customerInterest = await updateCustomerInterestAction(customerInterestId, data);

    const customerInterests = getState().dictionaries.customerInterests;

    const { Id } = customerInterest;
    const customerInterestsNew = [...customerInterests];

    const index = customerInterestsNew.findIndex(({ id }) => id === Id);
    customerInterestsNew.splice(index, 1, formatDict(customerInterest));

    dispatch(setCustomerInterests(customerInterestsNew));
  } catch (error) {
    console.error(error);
  }
};

export const deleteCustomerInterest = (customerInterestId) => async (dispatch, getState) => {
  try {
    await deleteCustomerInterestAction(customerInterestId);

    const customerInterests = getState().dictionaries.customerInterests;

    const customerInterestsNew = [...customerInterests];

    const index = customerInterestsNew.findIndex(({ id }) => id === customerInterestId);
    customerInterestsNew.splice(index, 1);

    dispatch(setCustomerInterests(customerInterestsNew));
  } catch (error) {
    console.error(error);
  }
};

export const loadCampaigns = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'campaigns', status: true } });

  try {
    const campaigns = await getCampaigns();

      dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'campaigns',
        data: campaigns.map(formatCampaign),
      },
    });
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'campaigns', status: false },
    });
  }
};

export const loadSources = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'sources', status: true } });

  try {
    const sources = await getSources();

    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'sources',
        data: sources.map(formatDict),
      },
    });
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'sources', status: false },
    });
  }
};

export const loadSubSources = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'subSources', status: true } });

  try {
    const { records } = await getSubSources();

    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'subSources',
        data: records.map(formatSubSource),
      },
    });
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: { dictionary: 'subSources', status: false },
    });
  }
};

export const loadUsers = () => async (dispatch) => {
  const companyId = getCompanyIdFromCookies();

  dispatch({ type: SET_LOADING, payload: { dictionary: 'users', status: true } });
  try {
    const usersData = await getUsers();

    const users = usersData.map((item) => formatUsersDict(item, companyId));

    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'users',
        data: users,
      },
    });
  } catch (error) {
  } finally {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'users', status: true } });
  }
};

export const loadActivityStatuses = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'activityStatuses', status: true } });
  try {
    const params = {
      paging: {
        page: 1,
        count: PageSizeAll,
      },
    };
    const { records } = await getActivityStatusesRequest(params);

    dispatch({
      type: SET_DICTIONARY,
      payload: {
        dictionary: 'activityStatuses',
        data: records.map(objectKeysPascalCaseToCamelCase),
      },
    });
  } catch (error) {
  } finally {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'activityStatuses', status: true } });
  }
};

export const loadBranches = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'branches', status: true } });

  try {
    const branches = await getBranches();

    dispatch({
      type: SET_DICTIONARY,
      payload: { dictionary: 'branches', data: branches.map(formatBranches) },
    });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'branches', status: false } });
  }
};

export const loadVehiclesTestDriveDict = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: { dictionary: 'vehiclesTestDrive', status: true } });

  try {
    const vehicles = await getVehiclesTestDriveDict();

    dispatch({
      type: SET_DICTIONARY,
      payload: { dictionary: 'vehiclesTestDrive', data: vehicles },
    });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: { dictionary: 'vehiclesTestDrive', status: false } });
  }
};

export const vehiclesTestDriveAddActivity = (payload) => async (dispatch, getState) => {
  const { vehicleId, activity } = payload;

  const state = getState();
  const {
    dictionaries: { vehiclesTestDrive: vehicles },
  } = state;

  const vehicleIndex = vehicles.findIndex(({ _id }) => _id === vehicleId);

  if (vehicleIndex === -1) {
    return;
  }

  const vehicle = vehicles[vehicleIndex];
  const { activities: vehicleActivities = [] } = vehicle;

  vehicle.activities = [...vehicleActivities, activity];

  dispatch({
    type: SET_DICTIONARY,
    payload: { dictionary: 'vehiclesTestDrive', data: vehicles },
  });
};
