import { axiosInstanceSalesAPI } from '~/utils/axios';

const url = 'CustomerInterests';

export const getCustomerInterests = async (params = {}) =>
  axiosInstanceSalesAPI
    .get(url, {
      params,
    })
    .then(({ data }) => data);

export const createCustomerInterest = async (data) =>
  axiosInstanceSalesAPI.post(url, data).then(({ data }) => data);

export const updateCustomerInterest = async (id, data) =>
  axiosInstanceSalesAPI.patch(`${url}/${id}`, data).then(({ data }) => data);

export const deleteCustomerInterest = async (id) =>
  axiosInstanceSalesAPI.delete(`${url}/${id}`).then(() => true);
