import {
  getCampaign as getCampaignServer,
  getCampaignChannelsList,
  updateCampaign as updateCampagnServer,
} from './api/campaigns';

export const CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE';
export const updateCampaign = (payload) => (dispatch, getState) => {
  dispatch({ type: CAMPAIGN_UPDATE, payload });
};

export const CAMPAIGN_GET = 'CAMPAIGN_GET';
export const CAMPAIGN_LOADING = 'CAMPAIGN_LOADING';
export const getCampaign = (id) => (dispatch, getState) => {
  dispatch({ type: CAMPAIGN_LOADING, payload: true });
  getCampaignServer(id).then((res) => {
    dispatch({ type: CAMPAIGN_GET, payload: res });
    dispatch({ type: CAMPAIGN_LOADING, payload: false });
  });
};

export const CAMPAIGN_GET_DICTIONARIES = 'CAMPAIGN_GET_DICTIONARIES';
export const getCampaignChannels = () => (dispatch) => {
  getCampaignChannelsList().then((res) => {
    dispatch({ type: CAMPAIGN_GET_DICTIONARIES, payload: { channels: res } });
  });
};

const emptyChannel = {
  channel: '',
  link: '',
};

export const addChannel = () => (dispatch, getState) => {
  const campaign = getState().campaign.data;
  let result = {
    id: campaign.id,
    channels: [...campaign.channels, emptyChannel],
  };

  dispatch({
    type: CAMPAIGN_UPDATE,
    payload: result,
  });
};

export const changeChannel = (index, key, value) => (dispatch, getState) => {
  const campaign = getState().campaign.data;
  const result = {
    id: campaign.id,
    channels: campaign.channels.map((channel, i) =>
      i === index
        ? {
            ...channel,
            [key]: value,
          }
        : channel,
    ),
  };
  dispatch({
    type: CAMPAIGN_UPDATE,
    payload: result,
  });

  updateCampagnServer(result);
};

export const removeChannel = (index) => (dispatch, getState) => {
  const campaign = getState().campaign.data;
  const result = {
    id: campaign.id,
    channels: campaign.channels.filter((channel, i) => i !== index),
  };
  dispatch({
    type: CAMPAIGN_UPDATE,
    payload: result,
  });
  updateCampagnServer(result);
};
