import initialState from '../data/opportunity_customers';
import {
  CHANGE_SEARCH_FIELDS,
  CHANGE_SEARCH_RESULTS,
  OPPORTUNITY_CUSTOMERS_CHANGE_PAGING,
  OPPORTUNITY_CUSTOMERS_CHANGE_MODAL,
  OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING,
} from '../actions/opportunity_customers';
import { PARTNERS_TOGGLE_LOADING } from '~/actions/customers';

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_SEARCH_FIELDS: {
      return {
        ...state,
        searchFields: action.payload,
      };
    }
    case CHANGE_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: action.payload,
      };
    }

    case OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case OPPORTUNITY_CUSTOMERS_CHANGE_PAGING: {
      return {
        ...state,
        paging: {
          ...state.paging,
          ...action.payload,
        },
      };
    }

    case OPPORTUNITY_CUSTOMERS_CHANGE_MODAL: {
      return {
        ...state,
        contactModal: action.payload,
      };
    }

    case PARTNERS_TOGGLE_LOADING: {
      return { ...state, loading: action.payload };
    }

    default:
      return state;
  }
}
