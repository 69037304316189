const salesRoleTokenKey = '__SALES_roleToken';

/* Common */

export const set = (key, value) => {
  if (!key || !value) {
    return;
  }

  window.localStorage.setItem(key, value);
};
export const get = (key) => {
  if (!key) {
    return;
  }

  return window.localStorage.getItem(key);
};
export const remove = (key) => {
  if (!key) {
    return;
  }

  window.localStorage.removeItem(key);
};

/* Role Token */

export const setSalesRoleToken = (roleToken) => set(salesRoleTokenKey, roleToken);
export const removeSalesRoleToken = () => remove(salesRoleTokenKey);
