import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import en from '../locales/en.json';
import de from '../locales/de.json';
import he from '../locales/he.json';
import es from '../locales/es.json';
import pt from '../locales/pt.json';
import it from '../locales/it.json';
import tr from '../locales/tr.json';
import config from '../config';

class Translation {
  constructor(language) {
    this.language = language;
  }

  t(word, replace = {}) {
    const file = {
      EN: en,
      DE: de,
      HE: he,
      ES: es,
      PT: pt,
      IT: it,
      TR: tr,
    }[this.language];

    let string = word.split('.').reduce((o, i) => (o ? o[i] : ''), file);

    if (!string) {
      string = `~${word.split('.').pop()}`;
    }

    const replaceArr = Object.keys(replace);
    if (string !== '' && replaceArr.length !== 0) {
      replaceArr.forEach((key) => {
        string = string.replace(`:${key}`, replace[key]);
      });
    }
    return string;
  }
}

export const getLang = () => {
  const defaultLanguage = config.defaultLanguage || 'EN';
  const token = Cookies.get('__SALES_token');
  const userInfo = token ? jwtDecode(token) : null;
  let language = sessionStorage.getItem('locale');
  if (!language) {
    language = userInfo && userInfo.language ? userInfo.language : defaultLanguage;
  }

  return language;
};

const translation = new Translation(getLang());

export { translation as t };
