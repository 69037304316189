import { DASHBOARD_SET_LOGO_URL } from '~/actions/dashboard';
import data from '../data/dashboard';

const initialState = data;

export default function (state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_SET_LOGO_URL: {
      return {
        ...state,
        logoUrl: action.payload,
      };
    }

    default:
      return state;
  }
}
