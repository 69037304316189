import {
  getSalesToken,
  getSalesRefreshToken,
  setSalesToken,
  setSalesRefreshToken,
  setSalesSessionId,
  removeSalesToken,
  removeSalesRefreshToken,
} from '~/helpers/cookies';
import { removeSalesRoleToken, setSalesRoleToken } from '~/helpers/localStorage';
import { refreshToken as refreshTokenAction } from '~/actions/api/auth';
import {
  checkIsNotException,
  checkIsUnauthorizedError,
  setAuth,
  setSessionId,
  showErrorMessage,
} from './helpers';
import { timer } from '~/utils/logout';

export const requestInterceptor = (config) => {
  setAuth(config);
  setSessionId(config);

  return config;
};

export const responseInterceptor = (response) => {
  timer.resetTimer();

  return response;
};

export const errorInterceptor = async (error) => {
  const isUnauthorizedError = checkIsUnauthorizedError(error);
  const refreshToken = getSalesRefreshToken();

  const isTokenCanBeRefreshed = refreshToken && isUnauthorizedError;

  if (isTokenCanBeRefreshed) {
    try {
      const {
        token: tokenNew,
        refreshToken: refreshTokenNew,
        role: roleNew,
        sessionId: sessionIdNew,
      } = await refreshTokenAction(getSalesToken(), refreshToken);

      setSalesToken(tokenNew);
      setSalesRefreshToken(refreshTokenNew);
      setSalesRoleToken(roleNew);
      setSalesSessionId(sessionIdNew);

      setAuth(error.config);

      return Promise.resolve();
    } catch (error) {
      removeSalesToken();
      removeSalesRoleToken();
      removeSalesRefreshToken();

      return Promise.reject(error);
    }
  }

  if (isUnauthorizedError) {
    removeSalesToken();
    removeSalesRoleToken();

    if (window.location.pathname !== '/') {
      window.location.href = '/';
    }
  }

  const isNotException = checkIsNotException(error);

  if (isNotException) {
    showErrorMessage(error);
  }

  return Promise.reject(error);
};
