import { axiosInstanceSalesAPI } from '~/utils/axios';
import { PageSizeAll } from '~/constants';

const url = 'UnqualifiedReasons';

export const getUnqualifiedReasons = () =>
  axiosInstanceSalesAPI
    .get(url, {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then(({ data }) => data);

export const createUnqualifiedReason = (data) =>
  axiosInstanceSalesAPI.post(url, data).then(({ data }) => data);

export const updateUnqualifiedReason = (id, data) =>
  axiosInstanceSalesAPI.patch(`${url}/${id}`, data).then(({ data }) => data);

export const deleteUnqualifiedReason = (id) =>
  axiosInstanceSalesAPI.delete(`${url}/${id}`).then(() => true);
