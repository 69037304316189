import axiosInstance from '~/utils/axios';
import { PageSizeAll, emptyString } from '~/constants';
import { getSalesAPIURL } from '.';

const url = 'NumberOfSeatsListService';

export const getNumberOfSeats = (query = emptyString) =>
  axiosInstance
    .get(getSalesAPIURL(`${url}${query}`), {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then(({ data }) => data);
