import initialState from '../data/vehicle';
import {
  CHANGE_SELECTED_VEHICLE,
  VEHICLE_CHANGE_LOADER,
  CHANGE_FIELD_SELECTED_VEHICLE,
  RESET_VEHICLE_DATA,
  TOGGLE_VEHICLE_LOADER,
} from '../actions/vehicle';

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_SELECTED_VEHICLE: {
      return { ...state, model: action.payload };
    }

    case VEHICLE_CHANGE_LOADER: {
      return { ...state, [action.typeLoader]: action.payload };
    }

    case CHANGE_FIELD_SELECTED_VEHICLE: {
      return {
        ...state,
        model: {
          ...state.model,
          ...action.payload,
        },
      };
    }

    case TOGGLE_VEHICLE_LOADER: {
      return { ...state, loader: action.payload }
    }

    case RESET_VEHICLE_DATA: {
      return { ...state, model: {} };
    }

    default: {
      return state;
    }
  }
}
