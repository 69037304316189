import { filterData } from '../reducers/filters';

export const SET_FILTERS = 'SET_FILTERS';
export const setFilters = (payload) => (dispatch) => {
  dispatch({ type: SET_FILTERS, payload });
};

export const FILTERS_CHANGE_FILTERS = 'FILTERS_CHANGE_FILTERS';
export const filtersChangeFilters = (key, value, params) => (dispatch) => {
  dispatch({ type: FILTERS_CHANGE_FILTERS, key, value, params });
};

export const FILTERS_CHANGE_MODAL_FIELD = 'FILTERS_CHANGE_MODAL_FIELD';
export const filtersChangeModalField = (key, value) => (dispatch) => {
  dispatch({ type: FILTERS_CHANGE_MODAL_FIELD, key, value });
};

export const FILTERS_CHANGE_MODAL = 'FILTERS_CHANGE_MODAL';
export const filtersChangeModal = (filter) => (dispatch) => {
  dispatch({ type: FILTERS_CHANGE_MODAL, filter });
};

export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export const SET_DICTIONARIES_DATA = 'SET_DICTIONARIES_DATA';
export const FILTERS_CHANGE = 'FILTERS_CHANGE';
export const filtersChange = (options, values) => (dispatch, getState) => {
  const { data } = getState().filters;
  let filterValuesState = {};

  if (values) {
    filterValuesState = values;
  } else {
    const fieldType = options?.fields?.type;

    switch (fieldType) {
      case 'myOpportunities':
        filterValuesState = getState().opportunitiesList;
        break;
      case 'filters':
        filterValuesState = getState().filters;
        break;
      case 'myLeads':
        filterValuesState = getState().leads;
        break;
      case 'leadMappings':
        filterValuesState = getState().leadMappings;
        break;
      case 'campaigns':
        filterValuesState = getState().campaigns;
        break;
      case 'vehicleCategories':
        filterValuesState = getState().vehicleCategories;
        break;
      case 'vehicles':
        filterValuesState = getState().vehicles;
        break;
      case 'approvalRequests':
        filterValuesState = getState().approvalRequests;
        break;

      default:
        filterValuesState = getState().filters;
        break;
    }
  }

  const payload = filtersChangeAction(options, data, filterValuesState.filterValues);
  const dictionaries = payload.map((item) => ({ value: item.name, label: item.name }));

  dispatch({ type: SET_INITIAL_DATA, payload });
  dispatch({ type: FILTERS_CHANGE, payload });
  dispatch({ type: SET_DICTIONARIES_DATA, dictionaries });
};

export const FILTERS_FILTER_DATA = 'FILTERS_FILTER_DATA';
export const filtersFilterData = (options) => (dispatch) => {
  dispatch({ type: FILTERS_FILTER_DATA });
};

const filtersChangeAction = ({ index = null, fields }, data, filterValues) => {
  //add
  if (index === null) {
    const newItem = {
      id: fields.name,
      type: fields.type,
      name: fields.name,
      isPrivate: !!fields.isPrivate,
      owner: 'Jayson Butler',
      isDisplayOnDashboard: !!fields.isDisplayOnDashboard,
      notes: fields.notes || '',
      published: false,
      date: new Date().valueOf(),
      filterValues: {
        ...filterValues,
        columnState: fields.columnsState,
      },
    };
    return [...data, newItem];
  }
  //remove
  if (!fields) {
    return data.filter((item, i) => item.id !== index);
  }

  //update
  return data.map((item, i) => {
    if (item.id === index) {
      item = { ...item, ...fields };
      return {
        id: item.name,
        type: item.type,
        name: item.name,
        isPrivate: item.isPrivate,
        owner: item.owner,
        isDisplayOnDashboard: item.isDisplayOnDashboard,
        notes: item.notes,
        published: item.published,
        date: item.date,
        filterValues,
      };
    }

    return item;
  });
};

export const GET_FILTERS_DASHBOARD = 'GET_FILTERS_DASHBOARD';
export const getFiltersDashboard = (dispatch, getState) => {
  const data = getState().filters.filteredData;
  const payload = getFiltersDashboardAction(data);
  return payload;
};

const getFiltersDashboardAction = (data) => {
  return data.filter((filter, i) => filter.isDisplayOnDashboard && filter.published);
};

////// Pagination
export const PARTNERS_SET_ROWS_PER_PAGE = 'PARTNERS_SET_ROWS_PER_PAGE';
export const changePage = (page) => (dispatch, getState) => {
  const { paging } = getState().filters;

  const pagingFrom = paging.pageSize * page;
  const pagingTo = paging.pageSize;

  const payload = {
    currentPage: page,
    pagingTo,
    pagingFrom,
  };

  dispatch({ type: PARTNERS_SET_ROWS_PER_PAGE, payload });
};

export const changeRowsPerPage = (count) => (dispatch, getState) => {
  const { paging, filteredData } = getState().filters;
  const isLength = filteredData.length;

  let pagingFrom = count * paging.currentPage;
  let pagingTo = count;

  if (pagingFrom > isLength) {
    pagingFrom = 0;
  }

  if (pagingTo > isLength) {
    pagingTo = isLength;
  }

  const payload = {
    pageSize: count,
    pagingTo,
    pagingFrom,
  };

  dispatch({ type: PARTNERS_SET_ROWS_PER_PAGE, payload });
};

export const IS_FILTER_EXISTS = 'IS_FILTER_EXISTS';
export const isFilterExists = (name) => (dispatch, getState) => {
  const { filteredData, data } = getState().filters;
  let res = filteredData.filter((item, i) => item.name === name)[0];
  if (!res) {
    res = data.filter((item) => item.name === name)[0];
  }
  dispatch({ type: IS_FILTER_EXISTS });
  return !!res;
};

export const FILTER_SET_DEFAULT_FILTERS_PARAMS = 'FILTER_SET_DEFAULT_FILTERS_PARAMS';
export const setDefaultFiltersParamsAction = (options) => (dispatch) => {
  const payload = options;
  dispatch({ type: FILTER_SET_DEFAULT_FILTERS_PARAMS, payload });
};

export const FILTERS_GET_FILTER_NAMES = 'FILTERS_GET_FILTER_NAMES';
export const getFilterNamesAction = (dispatch, getState) => {
  const { data } = getState().filters;
  const payload = data
    .filter((item) => item.type === 'filter')
    .map((item) => ({ value: item.id, label: item.name, filterValue: item.filterValues }));
  dispatch({ type: FILTERS_GET_FILTER_NAMES, payload });
};

export const FILTERS_LIST_GET_FILTER_COUNTER = 'FILTERS_LIST_GET_FILTER_COUNTER';
export const filtersListGetFilterCounter = (filterValues) => (dispatch, getState) => {
  const data = getState().filters.data;
  const result = filterData(filterValues, data);
  return result.length;
};
