import { axiosInstanceSync } from '~/utils/axios';

const SyncCustomerFromWebToSBO = 'OA_WEBCustomerSync';
const SyncVehicleFromWebToSBO = 'OA_WEBVehicleSync';

/**
 * @readonly
 * @enum {string}
 */
export const DocumentType = {
  Search: 'Search',
  SQ: 'SQ',
  SO: 'SO',
};

/**
 * @typedef {Object} DocumentLine
 */

/**
 * @typedef {Object} Document
 * @property {DocumentType} DocType
 * @property {string} [CustomerCode]
 * @property {string} [U_BranchCd]
 * @property {string} [U_SalesPersonCd]
 * @property {string} [U_SalesManagerCd]
 * @property {string} [U_CrrntUserCd]
 * @property {string} [U_OppId]
 * @property {Array.<DocumentLine>} DocumentLines
 * @property {string} department
 */

/**
 * @param {Document} document
 */
export const VSLineDetailsService = async (document) => {
  return axiosInstanceSync
    .post(`/VSLineDetailsService`, document)
    .then(({ data }) => {
      return data.payload;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getTradeIn = () =>
  axiosInstanceSync
    .post(`/GetTradeInItemCode`)
    .then(({ data }) => {
      return data.payload;
    })
    .catch((error) => {
      console.error(error);
    });

export const syncCustomer = async (id, data) => {
  const url = `${SyncCustomerFromWebToSBO}/${id}`;

  return axiosInstanceSync.post(url, data).then(({ data }) => data);
};

export const syncVehicle = async (id) => {
  const url = `${SyncVehicleFromWebToSBO}/${id}`;

  return axiosInstanceSync.post(url).then(({ data }) => data);
};
