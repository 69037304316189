import { t } from '~/utils/lang';

export const formatLeadForApiByImport = (props) => ({
  LeadName: props.leadName,
  LeadStatus: 'Open',
  Source: {
    CampaignName: props.campaignName,
    CampaignCode: props.campaignCode,
    ChannelId: props.channelId,
    Link: props.link,
  },
  LeadEmail: props.customerEmail,
  LeadPhone: props.phoneNumber,
  LeadComment: props.leadComment,
  LeadLocation: props.leadLocation,
  Branch: props.branch,
  LeadCode: props.leadCode,
  LeadExtId: props.leadExtId,
  EstimatedAmount: props.estimatedAmount,
  LeadRating: props.leadRating,
  FileName: props.fileName,
});

export const formatLeadForLeadsList = (props) => ({
  id: props.Id,
  leadName: props.LeadName,
  date: props.CreatedAt,
  customerName: props.CustomerCode?.Name,
  customerId: props.CreatedBy,
  sources: props.Sources,
  estimatedAmount: props.EstimatedAmount,
  status: formatLeadStatusForLeadsList(props.MergedToLead?.LeadCode, props.LeadStatus),
  leadStage: props.LeadStage,
  leadRating: props.LeadRating,
  opportunityNumber: props.OpportunityNumber,
  leadNumber: props.LeadCode,
  activities: props.Activities || [],
  interest: props.Interests || [],
  importDate: props?.ImportInfo?.ImportDate,
  leadLocation: props?.LeadLocation?.Id,
  salesAgent: props?.SalesAgent?.Rid,
  branchCode: props?.BranchCode?.Rid,
  customerType: props?.CustomerCode?.Type,
  unqualifiedReason: props.UnqualifiedReason,
  contactMethod: props.ContactMethod,
  owner: props.Owner?.Rid,
  salesManager: props?.Manager?.Rid,
  activitiesCount: props.ActivitiesCount,
  openActivitiesCount: props.OpenActivitiesCount,
  overDueActivitiesCount: props.OverDueActivitiesCount,
  updatedAt: props.UpdatedAt,
  isOpened: props.IsOpened,
  leadPhone: props.LeadPhone,
  leadEmail: props.LeadEmail,
});

export const formatLeadStatusForLeadsList = (leadCode, leadStatus) => {
  let newLeadStatus = '';
  if (leadCode) {
    newLeadStatus = t.t('lead_page.merged_to_lead', { separate: ':', number: leadCode });
    return newLeadStatus;
  }
  return leadStatus;
};

export const formatLinkedLead = ({
  Id,
  LeadCode,
  LeadName,
  LeadRating,
  SalesAgent,
  MergedToLead,
}) => ({
  id: Id,
  leadCode: LeadCode,
  leadName: LeadName,
  leadRating: LeadRating || 0,
  agentName: SalesAgent?.AgentName,
  mergedToLead: MergedToLead,
});

export const formatLeadData = (props) => ({
  ...props,
  LeadStatus: formatLeadStatusForLead(props?.MergedToLead?.LeadCode, props.LeadStatus),
  Activities: [],
});

export const formatLeadStatusForLead = (leadCode, leadStatus) => {
  let newLeadStatus = '';
  if (leadCode) {
    newLeadStatus = t.t('lead_page.merged_to_lead', { separate: '', number: '' });
    return newLeadStatus;
  }
  return leadStatus;
};

export const formatLeadDictionary = ({ Id, Name }) => ({
  value: Id,
  label: Name,
});
