import { axiosInstanceSalesAPI } from '~/utils/axios';

const url = 'ContactsService';

export const createContact = (data) =>
  axiosInstanceSalesAPI.post(url, data).then(({ data }) => data);

export const updateContact = (contactId, data) =>
  axiosInstanceSalesAPI.patch(`${url}/${contactId}`, data).then(({ data }) => data);

export const deleteContact = (contactId) =>
  axiosInstanceSalesAPI.delete(`${url}/${contactId}`).then(({ data }) => data);
