import { axiosInstanceSalesAPI } from '~/utils/axios';
import { emptyString, PageSizeAll } from '~/constants';

const url = 'FuelTypesListService';

export const getFuelTypes = (query = emptyString) =>
  axiosInstanceSalesAPI
    .get(`${url}${query}`, {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then(({ data }) => data);
