import moment from 'moment';
import { t } from '~/utils/lang';

export const combineDateTime = (date, time) => {
  if (date && time) {
    let t = new Date(time);
    let d = new Date(date);
    let result = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      t.getHours(),
      t.getMinutes(),
      t.getSeconds(),
    );
    return result;
  } else {
    if (date) {
      return date;
    }
    if (time) {
      return time;
    }
    return undefined;
  }
};

export const filterYears = (years = []) =>
  years.filter((item) => parseInt(item.label) <= new Date().getFullYear() + 1);

export const getTextFromDate = (date, isShortFormat) => {
  let today = moment();
  let text = '';
  let daysAgo = Math.abs(moment(date).diff(today, 'days'));
  let hoursAgo = Math.abs(moment(date).diff(today, 'hours'));
  let minutesAgo = Math.abs(moment(date).diff(today, 'minutes'));

  let strH = isShortFormat ? t.t('user_notifications.hrs') : t.t('user_notifications.hours');
  let strM = isShortFormat ? t.t('user_notifications.mins') : t.t('user_notifications.minutes');

  if (daysAgo) {
    text = `${daysAgo} ${t.t('user_notifications.days')}`;
  } else {
    if (hoursAgo) {
      text = `${hoursAgo} ${strH}`;
    } else {
      if (minutesAgo) {
        text = `${minutesAgo} ${strM}`;
      }
    }
  }
  return text;
};

export const getDueInObject = (diff) => {
  const diffInSeconds = diff / 1000;

  const minutes = Math.trunc(Math.abs(diffInSeconds % 60));
  const hours = Math.trunc(Math.abs((diffInSeconds % 86400) / 3600));
  const days = Math.trunc(Math.abs(diffInSeconds / 86400));

  let result = '';

  if (days) {
    result += `${days}D `;
  }

  if (hours) {
    result += `${hours}Hrs `;
  }

  if (minutes) {
    result += `${minutes}Min `;
  }

  return {
    value: result,
    color: diff < 0 ? 'red' : 'green',
  };
};

export const toUTC = (date) => new Date(date.getTime() + date.getTimezoneOffset() * 60000);
