import config from '../config';

class Timer {
  constructor(time) {
    this.time = time;
  }

  preLogout() {
    window.location.href = '/login';
  }

  resetTimer() {
    clearTimeout(this.time);
    this.time = setTimeout(this.preLogout, config.logoutTime);
  }
}

const timer = new Timer('');
export {timer};
