import {
  USER_NOTIFICATIONS_TOGGLE_LOADING,
  USER_NOTIFICATIONS_GET_DATA,
  USER_NOTIFICATIONS_CHANGE_PAGE,
} from '../actions/user_notifications';

const initialState = {
  data: [],
  loading: false,
  paging: {
    currentPage: 1,
    pageSize: 15,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_NOTIFICATIONS_TOGGLE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case USER_NOTIFICATIONS_GET_DATA: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }

    case USER_NOTIFICATIONS_CHANGE_PAGE: {
      return {
        ...state,
        paging: {
          ...state.paging,
          currentPage: action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
}
