import {
  ADD_TO_DOCUMENTS,
  SET_DOCUMENTS,
  CHANGE_TOGGLE_LOADER,
  CHANGE_SET_DATA,
  DOCUMENTS_CLEAR_STATE,
} from '../actions/opportunity_document';

const initialState = {
  loading: false,
  documents: [],
  sendData: { type: '', message: '' },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_TOGGLE_LOADER:
      return { ...state, loading: action.payload };

    case ADD_TO_DOCUMENTS:
      return {
        ...state,
        documents: [...state.documents, ...action.payload],
      };
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case CHANGE_SET_DATA:
      return { ...state, sendData: action.payload };
    case DOCUMENTS_CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
