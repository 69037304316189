export const PaymentCategoryOtherPayments = 'Other Payments';
export const PaymentCategoryFinancing = 'Financing';
export const PaymentCategoryTradeIn = 'Trade-in';

export const PaymentTypeCash = 'cash';
export const PaymentTypeCreditCard = 'creditCard';
export const PaymentTypeCheck = 'check';
export const PaymentTypeBankTransfer = 'bankTransfer';

export const PaymentMeanTypeCash = 'Cash';
export const PaymentMeanTypeCheck = 'Check';
export const PaymentMeanTypeCreditCard = 'Credit Card';
export const PaymentMeanTypeBankTransfer = 'Bank Transfer';

export const PaymentStatusDraft = 'Draft';
export const PaymentStatusPartiallyPaid = 'Partially Paid';
export const PaymentStatusPaid = 'Paid';
export const PaymentStatusCancelled = 'Cancelled';
