import initialState from '../data/vehicles';
import {
  VEHICLES_CHANGE_FILTERS,
  CHANGE_VEHICLES_DATA,
  VEHICLES_SET_TOTAL,
  CHANGE_VEHICLES_LOADER,
  VEHICLES_SET_ROWS_PER_PAGE,
  CHANGE_SUGGESTIONS_VEHICLES,
  VEHICLES_CHANGE_FILTER_NAMES,
  VEHICLES_CHANGE_FILTER_VALUES,
  VEHICLES_CHANGE_MODAL_FIELD,
  VEHICLES_SET_DEFAULT_FILTERS_PARAMS,
  VEHICLES_DISABLE_NEW_FILTER,
} from '../actions/vehicles';

export default function (state = initialState, action) {
  switch (action.type) {
    case VEHICLES_DISABLE_NEW_FILTER: {
      return {
        ...state,
        isDisabledNewFilter: action.payload,
      };
    }

    case VEHICLES_SET_DEFAULT_FILTERS_PARAMS: {
      return {
        ...state,
        filterValues: action.payload,
      };
    }

    case VEHICLES_CHANGE_MODAL_FIELD: {
      return {
        ...state,
        filterModal: {
          ...state.filterModal,
          [action.key]: action.value,
        },
      };
    }

    case VEHICLES_CHANGE_FILTER_NAMES: {
      return { ...state, filterNames: action.payload };
    }

    case VEHICLES_CHANGE_FILTER_VALUES: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.key]: { value: action.value, params: action.params },
        },
      };
    }

    case CHANGE_VEHICLES_DATA: {
      return { ...state, vehicleCards: action.payload };
    }

    case VEHICLES_SET_TOTAL: {
      return { ...state, total: action.payload };
    }

    case CHANGE_VEHICLES_LOADER: {
      return { ...state, [action.loader]: action.payload };
    }
    case VEHICLES_CHANGE_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };
    }
    case VEHICLES_SET_ROWS_PER_PAGE:
      return {
        ...state,
        paging: {
          ...state.paging,
          ...action.payload,
        },
      };

    case CHANGE_SUGGESTIONS_VEHICLES:
      return { ...state, suggestionsDic: { ...state.suggestionsDic, ...action.payload } };

    default: {
      return state;
    }
  }
}
