import axiosInstance from '../../utils/axios';
import { getAuthorizationHeaders, getSalesAPIURL } from '.';
import { queryParamsBuilder } from '~/helpers/filter';

const path = 'SearchLayouts';

export const getSearchLayoutsFields = async (params = {}) => {
  return axiosInstance
    .get(`${getSalesAPIURL('SearchLayouts/fields')}`, {
      params: queryParamsBuilder(params),
    })
    .then(({ data }) => data);
};

export const getSearchLayouts = async (params = {}) => {
  return axiosInstance
    .get(`${getSalesAPIURL(path)}`, {
      params: queryParamsBuilder(params),
    })
    .then(({ data }) => data);
};

export const getSearchLayoutById = async (id, params = {}) => {
  return axiosInstance
    .get(`${getSalesAPIURL(`${path}/${id}`)}`, {
      params: queryParamsBuilder(params),
    })
    .then(({ data }) => data);
};

export const createSearchLayout = async (data) => {
  return axiosInstance.post(`${getSalesAPIURL(path)}`, data).then(({ data }) => data);
};

export const updateSearchLayout = async (id, data) => {
  return axiosInstance.patch(`${getSalesAPIURL(`${path}/${id}`)}`, data).then(({ data }) => data);
};

export const deleteSearchLayout = async (id) => {
  return axiosInstance
    .delete(`${getSalesAPIURL(`${path}/${id}`)}`, {
      headers: getAuthorizationHeaders(),
    })
    .then(({ data }) => data);
};
