import config from '../config.js';
import { t } from '../utils/lang';

const JCStatesDic = config.JCStatesDic.map(el => {
  return {
    value: el.value,
    label: t.t(`job_cards_page.states.${el.label}`),
  };
});
export default {
  filters: {
    text: '',
    state: [JCStatesDic[0]],
    step: [],
    status: 'my',
    date: '',
    dataFrom: '',
    dataTo: '',
  },
  jobCards: [],
  JCStatesDic: JCStatesDic,
  total: 0,
  requestCounter: 0,
  paging: {
    currentPage: 0,
    pageSize: config.pageSize,
  },
  loading: true,
};
