import { axiosInstanceSalesAPI } from '~/utils/axios';
import { queryParamsBuilder } from '~/helpers/filter';

// TODO: refactor this mess...

export const getVehicleModels = async (path, params = {}, $search) =>
  axiosInstanceSalesAPI
    .get(path, {
      params: {
        ...queryParamsBuilder(params),
        $search: $search ? $search : undefined,
        pagingResponse: true,
      },
    })
    .then(({ data }) => data);

export const getVehicleModelVariants = async (params = {}) =>
  axiosInstanceSalesAPI
    .get('VehicleModelVariantsListService', {
      params: queryParamsBuilder(params),
    })
    .then(({ data }) => data);

export const getVehicles = async (params = {}) =>
  axiosInstanceSalesAPI
    .get('VehiclesListService', {
      params: {
        ...queryParamsBuilder(params),
        pagingResponse: true,
      },
    })
    .then(({ data }) => data);

export const getVehiclesProperties = async (params = {}) =>
  axiosInstanceSalesAPI
    .get('VehiclePropertiesListService', {
      params: queryParamsBuilder(params),
    })
    .then(({ data }) => data);

export const getVehicleModelsProperties = async (params = {}) =>
  axiosInstanceSalesAPI
    .get('ModelPropertiesListService', {
      params: queryParamsBuilder(params),
    })
    .then(({ data }) => data);

export const getVehicleModelsList = async (params = {}) =>
  axiosInstanceSalesAPI
    .get('VehicleModelsListServiceShort', {
      params: queryParamsBuilder(params),
    })
    .then(({ data }) => data);

export const getVehicleOptionsCategoriesListService = async (params = {}) =>
  axiosInstanceSalesAPI
    .get('VehicleOptionsCategoriesListService', {
      params: queryParamsBuilder(params),
    })
    .then(({ data }) => data);

export const getVehiclesTestDriveDict = async (params = {}) =>
  axiosInstanceSalesAPI
    .get('VehiclesTestDriveDict', {
      params: queryParamsBuilder(params),
    })
    .then(({ data }) => data);
