import { axiosInstanceSalesAPI } from '~/utils/axios';
import { queryParamsBuilder } from '~/helpers/filter';

const ActivitiesUrl = 'Activities';
const ActivityStatusesUrl = 'ActivityStatuses';

export const createActivity = (data) =>
  axiosInstanceSalesAPI.post(ActivitiesUrl, data).then(({ data }) => data);

export const updateActivity = (id, data) =>
  axiosInstanceSalesAPI.patch(`${ActivitiesUrl}/${id}`, data).then(({ data }) => data);

export const getActivities = (queryParams = {}) =>
  axiosInstanceSalesAPI
    .get(ActivitiesUrl, {
      params: {
        ...queryParamsBuilder(queryParams),
        timestamp: new Date().toISOString(),
      },
    })
    .then(({ data }) => data);

export const removeActivity = (id) =>
  axiosInstanceSalesAPI.delete(`${ActivitiesUrl}/${id}`).then((response) => response);

export const getActivityStatuses = (queryParams = {}) =>
  axiosInstanceSalesAPI
    .get(ActivityStatusesUrl, {
      params: {
        ...queryParamsBuilder(queryParams),
        pagingResponse: true,
      },
    })
    .then(({ data }) => data);

export const createActivityStatus = (data) =>
  axiosInstanceSalesAPI.post(ActivityStatusesUrl, data).then(({ data }) => data);

export const updateActivityStatus = (id, data) =>
  axiosInstanceSalesAPI.patch(`${ActivityStatusesUrl}/${id}`, data).then(({ data }) => data);

export const removeActivityStatus = (id) =>
  axiosInstanceSalesAPI.delete(`${ActivityStatusesUrl}/${id}`).then(({ data }) => data);

export const activitiesLinkedToDictionary = (queryParams = {}) =>
  axiosInstanceSalesAPI
    .get(`${ActivitiesUrl}/linkedToDictionary`, {
      params: {
        ...queryParamsBuilder(queryParams),
      },
    })
    .then(({ data }) => data);
