import {
  CAMPAIGN_UPDATE,
  CAMPAIGN_GET,
  CAMPAIGN_LOADING,
  CAMPAIGN_GET_DICTIONARIES,
} from '~/actions/campaign';

const initialState = {
  data: {},
  loading: false,
  dictionaries: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CAMPAIGN_UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case CAMPAIGN_GET: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case CAMPAIGN_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case CAMPAIGN_GET_DICTIONARIES: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          ...action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
}
