import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';

export const GET_BRANDS_LIST = 'GET_BRANDS_LIST';

export const getBrands = () => (dispatch, getState) => {
  axiosInstance
    .get(`/xassales/v1/brands`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data &&
        response.data.length &&
        response.data.map((item, i) => ({
          label: item.Name,
          value: item.Id,
        }));
      dispatch({ type: GET_BRANDS_LIST, payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};
