import { lcFirst } from '~/utils/string';

export const formatCustomerAddressToOption = ({ Id, Full }) => ({
  value: Id,
  label: Full,
});

export const adaptCustomerFromServer = (customer) => {
  const adapted = Object.keys(customer).reduce((accumulator, key) => {
    const value = customer[key];

    accumulator[lcFirst(key)] = value;

    return accumulator;
  }, Object.create(null));

  return adapted;
};

export const adaptForOpportunity = (customer) => {
  if (!customer) {
    return {};
  }

  const {
    Id,
    Code,
    CustomerId,
    Email,
    Addresses = [],
    Address = null,
    Group,
    Name,
    PhoneNumber,
    SecondaryPhoneNumber,
    PrivacyStatus,
    Title = null,
    Type,
    Notes = null,
    ShipToAddress,
    Currency,
  } = customer;

  const adapted = {
    CustomerId: Id,
    code: Code,
    customerId: CustomerId,
    email: Email,
    addresses: Addresses.map(formatCustomerAddressToOption),
    address: Address,
    shipToAddress: ShipToAddress,
    group: Group?.Id || null,
    name: Name,
    phoneNumber: PhoneNumber,
    secondaryPhoneNumber: SecondaryPhoneNumber,
    privacyStatus: PrivacyStatus,
    title: Title,
    type: Type,
    notes: Notes,
    currency: Currency,
  };

  return adapted;
};

export const adaptCustomerForLead = ({
  Id,
  Addresses,
  BillToAddress = null,
  Code,
  CustomerId,
  Email,
  Group,
  Name,
  Notes = null,
  PhoneNumber,
  PrivacyStatus,
  SecondaryPhoneNumber = null,
  ShipToAddress = null,
  Status,
  Title = null,
  Type,
}) => ({
  Addresses: Addresses.map(formatCustomerAddressToOption),
  BillToAddress,
  Code,
  CustomerId,
  Email,
  Group: Group?.Id || Group || null,
  Name,
  Notes,
  PhoneNumber,
  PrivacyStatus,
  Rid: Id,
  SecondaryPhoneNumber,
  ShipToAddress,
  Status,
  Title,
  Type,
});
