import cloneDeep from 'lodash.clonedeep';
import { LineTypeTradeIn } from '~/constants';

export const addSort = (cars = []) => {
  cars = cars.sort((car1, _car2) => (car1.lineType === LineTypeTradeIn ? 1 : -1));

  let carIndex = 1;
  cars.forEach((car) => {
    const carSort = (carIndex++).toString();

    car.sortOrder = carSort;

    let configurationIndex = 1;
    car.configuration.forEach((_configuration) => {
      const configurationSort = (configurationIndex++).toString();

      _configuration.sortOrder = `${carSort}.${configurationSort}`;
    });

    let accessoryIndex = configurationIndex;
    car.accessories.forEach((accessory) => {
      const accessorySort = (accessoryIndex++).toString();

      accessory.sortOrder = `${carSort}.${accessorySort}`;
    });
  });

  return cars;
};

export const clearCars = (cars = []) => {
  const clone = cloneDeep(cars);

  clone.forEach((car) => {
    delete car.docRate;
    delete car.docCurrency;
    delete car.sortOrder;

    car.configuration.forEach((_configuration) => {
      delete _configuration.sortOrder;
    });
    car.accessories.forEach((accessory) => {
      delete accessory.sortOrder;
    });
  });

  return clone;
};
