import Cookies from 'js-cookie';
import config from '~/config';
import axiosInstance from '../utils/axios';
import { showWarning } from './layout';
import { updateAttachment } from './attachment';
import { getDataIcon } from '~/utils/icons';
import { initialState } from '~/reducers/pdf';

const getDocumentType = (type) => {
  switch (type) {
    case 'order':
      return 'Sales Order';
    case 'Payment Mean':
      return 'Payment Mean';
    case 'activity':
      return 'Activity';
    default:
      return 'Sales Quotation';
  }
};

export const skipCachedPdf = () => (dispatch) => {
  dispatch({ type: PDF_GET_DATA, payload: initialState });
};

const openFileInNewTab = (fileString) => {
  var byteCharacters = atob(fileString);
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var file = new Blob([byteArray], { type: 'application/pdf;base64' });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL, '_blank');
};

export const loadReportPreview = ({ documentCode, type }) => (dispatch) => {
  axiosInstance
    .post(
      `/xassales/v1/getSignedReport?DocumentType=${getDocumentType(type)}&DocKey=${documentCode}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    )
    .then((response) => {
      const data = response.data;
      if (!data.error) {
        openFileInNewTab(data.result);
      } else {
        throw data.error;
      }
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

export const PDF_GET_DATA = 'PDF_GET_DATA';
export const getPdf = ({ documentCode, type }) => (dispatch, getState) => {
  axiosInstance
    .post(
      `/xassales/v1/reports?DocumentType=${getDocumentType(type)}&DocKey=${documentCode}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    )
    .then((response) => {
      const data = response.data;
      if (!data.error) {
        dispatch({ type: PDF_GET_DATA, payload: response.data });
      } else {
        throw data.error;
      }
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

const openLink = async (link) => {
  const baseURL = config.apiUrl;

  if (baseURL && link) {
    const fullLink = baseURL + '/xassales/v1' + link;
    const loadDocument = await getDataIcon(fullLink);

    let a = document.createElement('a');
    a.href = loadDocument.link;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const saveSigned = (
  { type, opportunityId, customerName, customerEmail },
  ReportId,
  callback,
) => (dispatch, getState) => {
  axiosInstance
    .post(
      `/xassales/v1/saveSigned`,
      {
        DocumentType: getDocumentType(type),
        ReportId: ReportId.replace('.pdf', ''),
        customerEmail,
        customerName,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    )
    .then((res) => {
      if (!res.data.error) {
        let { attachment } = res.data;

        updateAttachment(attachment._id, 'Opportunity', opportunityId);
        if (callback) {
          callback(() => openLink(attachment.link));
        }
      } else {
        throw res.data.error;
      }
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

export const sendSigned = ({ type, opportunityId, customerName, customerEmail }, ReportId) => (
  dispatch,
  getState,
) => {
  axiosInstance
    .post(
      `/xassales/v1/sendSigned`,
      {
        DocumentType: getDocumentType(type),
        ReportId: ReportId.replace('.pdf', ''),
        customerEmail,
        customerName,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    )
    .then((res) => {
      if (!res.data.error) {
      } else {
        throw res.data.error;
      }
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

export const sign = (SignatureBuffer, type, mode) => (dispatch, getState) => {
  const ReportId = getState().pdf.reportId;
  axiosInstance
    .post(
      `/xassales/v1/signWithImage`,
      {
        DocumentType: getDocumentType(type),
        ReportId: ReportId.replace('.pdf', ''),
        SignatureBuffer: SignatureBuffer.replace('data:image/png;base64,', ''),
        ...mode,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    )
    .then((res) => {
      if (!res.data.error) {
        let { result } = res.data;
        dispatch({ type: PDF_GET_DATA, payload: { pdf: result, reportId: ReportId } });
      } else {
        throw res.data.error;
      }
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};
