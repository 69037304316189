import axiosInstance from '~/utils/axios';
import { getSalesAPIURL } from '.';
import { queryParamsBuilder } from '~/helpers/filter';

const opportunitiesUrl = 'opportunities';

export const getListShort = (queryParams = {}) => {
  return axiosInstance
    .get(getSalesAPIURL(opportunitiesUrl), {
      params: queryParamsBuilder(queryParams),
    })
    .then(({ data }) => data);
};

export const getOpportunity = async (id) => {
  const url = `${opportunitiesUrl}/${id}`;

  return axiosInstance.get(getSalesAPIURL(url)).then(({ data }) => data);
};

export const createOpportunity = async (data) => {
  return axiosInstance.post(getSalesAPIURL(opportunitiesUrl), data).then(({ data }) => data);
};

export const updateOpportunity = async (id, data) => {
  const url = `${opportunitiesUrl}/${id}`;

  return axiosInstance.patch(getSalesAPIURL(url), data).then(({ data }) => data);
};

export const syncOpportunityCustomer = async (id) => {
  const url = `${opportunitiesUrl}/${id}/syncCustomer`;

  return axiosInstance.post(getSalesAPIURL(url)).then(({ data }) => data);
};

export const updateOpportunityCustomer = async (id, customerData) => {
  const url = `${opportunitiesUrl}/${id}/updateCustomer`;

  return axiosInstance.patch(getSalesAPIURL(url), customerData).then(({ data }) => data);
};
