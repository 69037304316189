import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';

export const GET_OPPORTUNITY_SALE_TYPES_LIST = 'GET_OPPORTUNITY_SALE_TYPES_LIST';
export const getSaleTypesList = () => (dispatch, getState) => {
  axiosInstance
    .get(`/xassales/v1/SaleTypesListService`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let result =
        response.data && response.data.length
          ? response.data.map((item) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: GET_OPPORTUNITY_SALE_TYPES_LIST, payload: result });
    })
    .catch((err) => {
      console.log(err);
    });
};
