import { axiosInstanceSalesAPI } from '~/utils/axios';

const CampaignsListServiceUrl = 'CampaignsListService';
const CampaignsServiceUrl = 'CampaignsService';
const ChannelsListServiceUrl = 'ChannelsListService';

/* API */

export const getCampaigns = async (params = {}) =>
  axiosInstanceSalesAPI
    .get(CampaignsListServiceUrl, {
      params,
    })
    .then(({ data }) => data);

export const getCampaignsList = async (paging = {}, filterQuery = '') =>
  axiosInstanceSalesAPI
    .get(`${CampaignsListServiceUrl}${filterQuery}`, {
      params: {
        pageSize: 'all',
        ...paging,
      },
    })
    .then((response) => {
      return response.data.map(formatCampaign);
    });

export const getCampaignChannelsList = async () =>
  axiosInstanceSalesAPI
    .get(`${ChannelsListServiceUrl}?sort=name:1&pageSize=All`)
    .then((response) => response.data.map(formatChannel));

export const getCampaign = async (id) =>
  axiosInstanceSalesAPI
    .get(`${CampaignsServiceUrl}/${id}`)
    .then((response) => formatCampaign(response.data));

export const createCampaign = async (data) =>
  axiosInstanceSalesAPI
    .post(CampaignsServiceUrl, formatCampaignToServer(data))
    .then((response) => response.data);

export const updateCampaign = async (data) =>
  axiosInstanceSalesAPI
    .patch(`${CampaignsServiceUrl}/${data.id}`, formatCampaignToServer(data))
    .then((response) => formatCampaign(response.data));

export const deleteCampaign = async (id) =>
  axiosInstanceSalesAPI
    .delete(`${CampaignsServiceUrl}/${id}`)
    .then((response) => formatCampaign(response.data));

/* Data Formatters */

export const formatCampaign = (campaign) => ({
  campaignName: campaign.Name,
  campaignCode: campaign.Code,
  fromDate: campaign.FromDate,
  toDate: campaign.ToDate,
  status: campaign.Enabled,
  description: campaign.Description,
  id: campaign.Id,
  channels: campaign.Channels?.map(formatCampaignChannel),
});

const formatCampaignToServer = (campaign) => ({
  Name: campaign.campaignName,
  Code: campaign.campaignCode,
  FromDate: campaign.fromDate,
  ToDate: campaign.toDate,
  Enabled: campaign.status,
  Description: campaign.description,
  Id: campaign.id,
  Channels: campaign.channels?.map(formatCampaignChannelToServer),
});

const formatCampaignChannel = (campaignChannel) => ({
  id: campaignChannel.Id,
  channel: campaignChannel.ChannelId?.Id,
  link: campaignChannel.Link,
  channelName: campaignChannel.ChannelId?.Name,
});

const formatCampaignChannelToServer = (campaign) => ({
  Id: campaign.id,
  ChannelId: campaign.channel,
  Link: campaign.link,
});

const formatChannel = (channel) => ({
  label: channel.Name,
  value: channel.Id,
});
