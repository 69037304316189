import { getCustomer, getCustomers } from './api/customers';
import { adaptCustomerFromServer } from '~/helpers/dataAdapters/customer';
import { createAddress, updateAddress, deleteAddress } from './api/addresses';
import { changeServerCustomerField } from '~/actions/customers';
import { getDefaultCustomerFromCookies } from '~/helpers/cookies';

export const SET_CUSTOMER_GENERAL_FIELDS = 'SET_CUSTOMER_GENERAL_FIELDS';
export const setGeneralField = (field, value) => (dispatch) => {
  dispatch({ type: SET_CUSTOMER_GENERAL_FIELDS, field, value });
};

export const SET_CUSTOMER = 'SET_CUSTOMER';
export const setCustomer = (id) => async (dispatch) => {
  const customer = await getCustomer(id);

  const adaptedCustomer = adaptCustomerFromServer(customer);

  dispatch({ type: SET_CUSTOMER, payload: adaptedCustomer });
};

export const SET_DEFAULT_CUSTOMER = 'SET_DEFAULT_CUSTOMER';
export const getDefaultCustomer = () => async (dispatch) => {
  try {
    const defaultCustomerCode = getDefaultCustomerFromCookies();

    const customers = await getCustomers({
      filter: [`code:=:${defaultCustomerCode ? defaultCustomerCode : 'GenericCustomer'}`],
    });

    const customer = customers.shift() || null;

    dispatch({ type: SET_DEFAULT_CUSTOMER, payload: customer });
  } catch (error) {
    console.error(error);
  }
};

export const CUSTOMER_SET_ADDRESS = 'CUSTOMER_SET_ADDRESS';
export const createCustomerAddress = (address) => async (dispatch, getState) => {
  const { customer, lead } = getState();
  let customerUser = {};

  if (customer.customer?.id) {
    customerUser = customer?.customer;
  } else if (lead.customer?.id) {
    customerUser = lead.customer;
  }

  const newAddress = await createAddress(address);

  const payload = [
    ...(customerUser?.addresses || []),
    { ...address, Id: newAddress.Id, Full: newAddress.Full },
  ];

  dispatch({ type: CUSTOMER_SET_ADDRESS, payload });

  dispatch(
    changeServerCustomerField({
      name: 'addresses',
      value: payload.map((item) => item.Id),
      id: customerUser.id,
    }),
  );
};

export const customerEditAddress = (address) => async (dispatch, getState) => {
  const customer = getState().customer.customer;
  const updatedAddress = await updateAddress(address.Id, address);
  let payload = [];

  if (Boolean(customer.addresses?.length)) {
    payload = customer.addresses.map((ad) =>
      ad.Id === address.Id ? { ...ad, ...address, Full: updatedAddress.full } : ad,
    );
  } else {
    payload = [{ ...address, Full: updatedAddress.Full }];
  }

  dispatch({ type: CUSTOMER_SET_ADDRESS, payload });
};

export const customerDeleteAddress = async (addressId, callback) => {
  await deleteAddress(addressId);

  callback && callback();
};
