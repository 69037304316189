import {
  getCampaignsList,
  createCampaign as createCampaignServer,
  deleteCampaign as deleteCampaignServer,
  updateCampaign as updateCampaignServer,
} from './api/campaigns';
import campaignsData from '~/data/campaigns.json';
import { getOperationByParam } from '~/components/opportunities/table/helper';
import { isObjectsEqual } from '~/utils/general';
import { isBooleanValue } from '~/utils/boolean';

const keysSearch = {
  campaignName: 'name',
  campaignCode: 'code',
  fromDate: 'fromDate',
  toDate: 'toDate',
  status: 'enabled',
  description: 'description',
};

const getFilterQuery = (filterValues) => {
  const search =
    filterValues.search && filterValues.search.value
      ? `filter=$text:${filterValues.search.value}`
      : '';

  let filterString = '?';
  Object.keys(filterValues).forEach((key, i) => {
    const hasBooleanValue = isBooleanValue(filterValues[key]?.value);

    const hasValue = !!(hasBooleanValue || (filterValues[key] && filterValues[key]?.value));

    const selectedParam = filterValues[key]?.params;

    const paramsThatMayHasEmptyValue = ['is empty', 'is not empty'];
    if (
      (hasValue || (!hasValue && paramsThatMayHasEmptyValue.includes(selectedParam))) &&
      key !== 'search'
    ) {
      let filterKey = keysSearch[key] || key;
      let operation = getOperationByParam(filterValues[key].params);
      let value = filterValues[key].value;
      let isEmptyRange = null;

      switch (key) {
        case 'fromDate':
        case 'toDate':
          let from = value.dateRangeStart;
          let to = value.dateRangeEnd;
          if (from && to) {
            operation = 'between';
            value = JSON.stringify([from, to]);
          } else {
            if (from) {
              operation = '>=';
              value = from;
            } else {
              if (to) {
                operation = '<=';
                value = to;
              } else {
                value = null;
                isEmptyRange = true;
              }
            }
          }
          break;
        case 'status':
          operation = '=';
          break;
        default:
          value = `${value}`;
      }

      switch (filterValues[key].params) {
        case 'is empty':
        case 'is not empty':
          value = null;
          break;
        default:
      }

      if (!isEmptyRange) {
        let filterPart = `${filterKey}:${operation}:${value}`;
        let filterPartExists = filterString.split(filterPart).length > 1;

        if (!filterPartExists) {
          filterString += `${filterString.length > 1 ? '&' : ''}filter=${filterPart}`;
        }
      }
    }
  });

  const pageSizeAndCompany = `pageSize=all&sort=name:1`;

  filterString =
    filterString.length > 1
      ? search
        ? `${filterString}${search}&${pageSizeAndCompany}`
        : `${filterString}&${pageSizeAndCompany}`
      : search
      ? `?${search}&${pageSizeAndCompany}`
      : `?${pageSizeAndCompany}`;

  return filterString;
};

export const CAMPAIGNS_CREATE = 'CAMPAIGNS_CREATE';
export const createCampaign = (payload) => (dispatch) => {
  dispatch({ type: CAMPAIGNS_CREATE, payload });
  createCampaignServer(payload);
};

export const CAMPAIGNS_DELETE = 'CAMPAIGNS_DELETE';
export const deleteCampaign = (payload) => (dispatch) => {
  dispatch({ type: CAMPAIGNS_DELETE, payload });
  deleteCampaignServer(payload);
};

export const CAMPAIGNS_UPDATE = 'CAMPAIGNS_UPDATE';
export const updateCampaign = (payload) => (dispatch, getState) => {
  let campaigns = getState().campaigns.data;
  campaigns.forEach((item, i) => {
    if (item.id === payload.id) {
      campaigns[i] = payload;
    }
  });
  dispatch({ type: CAMPAIGNS_UPDATE, payload: campaigns });
  updateCampaignServer(payload);
};

export const CAMPAIGNS_GET_LIST = 'CAMPAIGNS_GET_LIST';
export const CAMPAIGNS_LOADING = 'CAMPAIGNS_LOADING';
export const getCampaigns = () => (dispatch, getState) => {
  const { filterValues } = getState().campaigns;
  dispatch({ type: CAMPAIGNS_LOADING, payload: true });
  getCampaignsList({}, getFilterQuery(filterValues))
    .then((res) => {
      dispatch({ type: CAMPAIGNS_GET_LIST, payload: res });
      dispatch({ type: CAMPAIGNS_LOADING, payload: false });
    })
    .catch(() => {
      dispatch({ type: CAMPAIGNS_GET_LIST, payload: [] });
      dispatch({ type: CAMPAIGNS_LOADING, payload: false });
    });
};

export const CHANGE_CAMPAIGNS_FILTER_NAMES = 'CHANGE_CAMPAIGNS_FILTER_NAMES';
export const CAMPAIGNS_CHANGE_FILTERS = 'CAMPAIGNS_CHANGE_FILTERS';
export const changeFilters = (key, value, params) => (dispatch, getState) => {
  let filterNames = getState().campaigns.filterNames;
  const isKey = filterNames.includes(key);
  if (isKey && !value) {
    filterNames.filter((item) => item !== key);
  }

  if (!isKey && value) {
    filterNames.push(key);
  }

  dispatch({ type: CHANGE_CAMPAIGNS_FILTER_NAMES, payload: filterNames });
  dispatch({ type: CAMPAIGNS_CHANGE_FILTERS, key, value, params });
};

export const CAMPAIGNS_CHANGE_MODAL_FIELD = 'CAMPAIGNS_CHANGE_MODAL_FIELD';
export const changeModalField = (key, value) => (dispatch) => {
  dispatch({ type: CAMPAIGNS_CHANGE_MODAL_FIELD, key, value });
};

export const CAMPAIGNS_SET_DEFAULT_FILTERS_PARAMS = 'CAMPAIGNS_SET_DEFAULT_FILTERS_PARAMS';
export const setValueForFilter = (count) => (dispatch, getState) => {
  const { filteredData } = getState().filters;
  let payload = null;
  if (count) {
    payload = filteredData.find((item) => item.id === count || item.name === count).filterValues;
  }

  if (!payload) {
    payload = campaignsData.filterValues;
  }

  dispatch({ type: CAMPAIGNS_SET_DEFAULT_FILTERS_PARAMS, payload });
};

export const CAMPAIGNS_DISABLE_NEW_FILTER = 'CAMPAIGNS_DISABLE_NEW_FILTER';
export const disableNewFilter = (key) => (dispatch, getState) => {
  const { filteredData } = getState().filters;
  const { filterValues } = getState().campaigns;

  if (key) {
    const item = filteredData.find((item) => item.id === key);
    const payload = isObjectsEqual(filterValues, item.filterValues);
    dispatch({ type: CAMPAIGNS_DISABLE_NEW_FILTER, payload });
  } else {
    const payload = !Object.keys(filterValues).reduce((accumulator, item) => {
      return accumulator || !!filterValues[item].value;
    }, false);
    dispatch({ type: CAMPAIGNS_DISABLE_NEW_FILTER, payload });
  }
};

export const campaignsGetFilterCounter = (filterValues) =>
  getCampaignsList({}, getFilterQuery(filterValues)).then((res) => {
    return res.length;
  });

export const CAMPAIGNS_CLEAR_FILTERS = 'CAMPAIGNS_CLEAR_FILTERS';
export const clearCampaignsFilters = () => (dispatch) =>
  dispatch({ type: CAMPAIGNS_CLEAR_FILTERS });
