import cloneDeep from 'lodash.clonedeep';

import defaultPageWithTableState from '~/data/_defaultPageWithTable.json';
import opportunitiesState from '../data/opportunities_list';

export const initialState = cloneDeep({ ...defaultPageWithTableState, ...opportunitiesState });

export const OPPORTUNITIES_LIST_TOGGLE_LOADER = 'OPPORTUNITIES_LIST_TOGGLE_LOADER';
export const OPPORTUNITIES_LIST_SET_PAGING = 'OPPORTUNITIES_LIST_SET_PAGING';
export const OPPORTUNITIES_LIST_SET_TOTAL = 'OPPORTUNITIES_LIST_SET_TOTAL';
export const OPPORTUNITIES_LIST_SET_ITEMS = 'OPPORTUNITIES_LIST_SET_ITEMS';
export const OPPORTUNITIES_LIST_SET_SAVED_FILTERS = 'OPPORTUNITIES_LIST_SET_SAVED_FILTERS';
export const OPPORTUNITIES_LIST_SET_SAVED_FILTER_ID = 'OPPORTUNITIES_LIST_SET_SAVED_FILTER_ID';
export const OPPORTUNITIES_LIST_SET_FILTERS_VALUE = 'OPPORTUNITIES_LIST_SET_FILTERS_VALUE';
export const OPPORTUNITIES_LIST_RESET_FILTERS = 'OPPORTUNITIES_LIST_RESET_FILTERS';
export const OPPORTUNITIES_LIST_SET_SEARCH = 'OPPORTUNITIES_LIST_SET_SEARCH';
export const OPPORTUNITIES_LIST_CREATE_SAVED_FILTER = 'OPPORTUNITIES_LIST_CREATE_SAVED_FILTER';
export const OPPORTUNITY_CREATE_NEW = 'OPPORTUNITY_CREATE_NEW';
export const GET_FILTER_NAMES = 'GET_FILTER_NAMES';

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case OPPORTUNITIES_LIST_TOGGLE_LOADER: {
      return { ...state, isLoading: payload };
    }

    case OPPORTUNITIES_LIST_SET_PAGING: {
      return { ...state, paging: { ...state.paging, ...payload } };
    }

    case OPPORTUNITIES_LIST_SET_TOTAL: {
      return { ...state, total: payload };
    }

    case OPPORTUNITIES_LIST_SET_ITEMS: {
      return { ...state, items: payload };
    }

    case OPPORTUNITIES_LIST_SET_SAVED_FILTERS: {
      return { ...state, savedFilters: payload };
    }

    case OPPORTUNITIES_LIST_SET_SAVED_FILTER_ID: {
      return { ...state, savedFilterId: payload, paging: { ...state.paging, page: 1 } };
    }

    case OPPORTUNITIES_LIST_SET_FILTERS_VALUE: {
      return { ...state, filters: { ...state.filters, ...payload } };
    }

    case OPPORTUNITIES_LIST_RESET_FILTERS: {
      return { ...state, filters: {} };
    }

    case OPPORTUNITIES_LIST_SET_SEARCH: {
      return { ...state, search: payload };
    }

    case OPPORTUNITIES_LIST_CREATE_SAVED_FILTER: {
      return { ...state, savedFilters: [...state.savedFilters, payload] };
    }

    case OPPORTUNITY_CREATE_NEW: {
      return {
        ...state,
        filteredData: action.payload,
        data: action.payload,
      };
    }

    case GET_FILTER_NAMES: {
      return {
        ...state,
        filterNames: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
