import { axiosInstanceSalesAPI } from '~/utils/axios';
import { PageSizeAll } from '~/constants';

const listUrl = 'CreditCardsListService';

export const getCreditCards = () =>
  axiosInstanceSalesAPI
    .get(listUrl, {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then(({ data }) => data);
