import { GET_ORDER } from '../actions/opportunity_order';

export const SET_LINES_TO_DELIVERY = 'SET_LINES_TO_DELIVERY';
export const CLEAR_LINES_TO_DELIVERY = 'CLEAR_LINES_TO_DELIVERY';

const initialState = {
  order: null,
  linesToDelivery: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ORDER: {
      return {
        ...state,
        order: payload,
      };
    }
    case SET_LINES_TO_DELIVERY: {
      return {
        ...state,
        linesToDelivery: payload,
      };
    }
    case CLEAR_LINES_TO_DELIVERY: {
      return {
        ...state,
        linesToDelivery: [],
      };
    }
    default:
      return state;
  }
}
