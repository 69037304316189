import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { logout } from './actions/login';
import { connect } from 'react-redux';
import { subscribeNotifications } from './actions/header';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    const { auth, history, dispatch } = this.props;
    if (!auth.isLogin) {
      dispatch(logout(history));
    } else {
      if (!auth.isSubscription) {
        dispatch(subscribeNotifications());
      }
    }
  }

  render() {
    const { auth, component, ...rest } = this.props;
    let Component = component;
    return (
      <Route
        {...rest}
        render={(props) => (auth.isLogin ? <Component {...props} /> : <Redirect to="/login" />)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch: dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
