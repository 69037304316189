import axios from 'axios';
import config from '~/config';
import { timer } from './logout';
import { setCompanyId, setSessionId, showErrorMessage } from './axios/helpers';
import { requestInterceptor, responseInterceptor, errorInterceptor } from './axios/interceptors';
import { SALES_API_URL } from '~/actions/api';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const { apiUrl, syncUrl } = config;

const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use(requestInterceptor);

axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

createAuthRefreshInterceptor(axiosInstance, errorInterceptor, {});

export default axiosInstance;

export const axiosInstanceSalesAPI = axios.create({
  baseURL: `${apiUrl}${SALES_API_URL}`,
});

axiosInstanceSalesAPI.interceptors.request.use(requestInterceptor);

axiosInstanceSalesAPI.interceptors.response.use(responseInterceptor, errorInterceptor);

createAuthRefreshInterceptor(axiosInstanceSalesAPI, errorInterceptor, {});

export const axiosInstanceSync = axios.create({
  baseURL: syncUrl,
});

axiosInstanceSync.interceptors.request.use((config) => {
  setSessionId(config);
  setCompanyId(config);

  return config;
});

axiosInstanceSync.interceptors.response.use(
  (response) => {
    timer.resetTimer();
    return response;
  },
  (error) => {
    showErrorMessage(error);

    return Promise.reject(error);
  },
);
