import { emptyString } from '../constants';

export const lcFirst = (str) => str.charAt(0).toLowerCase() + str.slice(1);

export const ucFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const camelCaseToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const clearValueFromMask = (string, mask = '_') =>
  string?.replace(new RegExp(mask, 'g'), emptyString);

export const stringToKey = (string) => string.toLowerCase().replace(/ /g, '');
