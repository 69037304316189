import { getDelivery, deleteDeliveryLine, updateDeliveryWithLines } from '~/actions/api/deliveries';
import {
  createDeliveryLine,
  OPPORTUNITY_CONTENT_SET_CURRENT_DELIVERY,
} from './opportunity_content';

export const GET_DELIVERY = 'GET_DELIVERY';
export const getDeliveryAction = (id) => async (dispatch) => {
  try {
    const delivery = await getDelivery(id);

    dispatch({ type: OPPORTUNITY_CONTENT_SET_CURRENT_DELIVERY, payload: delivery });
    dispatch({ type: GET_DELIVERY, payload: delivery });
    return delivery;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const changeDeliveryInformation = (props) => (dispatch, getState) => {
  const { delivery } = getState().opportunity.opportunityDelivery;
  const payload = { ...delivery, [props.key]: props.value };

  dispatch({ type: GET_DELIVERY, payload });
};

export const createDeliveryFromDraft = (deliveryId) => async (dispatch) => {
  try {
    const data = {
      IsDraft: false,
      IsPrimary: true,
    };

    await updateDeliveryWithLines(deliveryId, data);

    return Promise.resolve(true);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const saveDataDeliveryAction = (id, data) => async (dispatch, getState) => {
  const opportunity = getState().opportunity.content;
  const opportunityDelivery = getState().opportunity.opportunityDelivery;
  const { delivery } = opportunityDelivery;
  const { removeCar } = opportunity;

  if (delivery) {
    let newLineCars = [];
    let newLineConf = [];
    data.forEach((item) => {
      if (!item.lineId) {
        newLineCars.push({ data: item });
      } else {
        item.configuration.forEach((conf) => {
          if (!conf.lineId) {
            newLineConf.push({ parentLineId: item.lineId, data: conf });
          }
        });
        item.accessories.forEach((acces) => {
          if (!acces.lineId) {
            newLineConf.push({ parentLineId: item.lineId, data: acces });
          }
        });
      }
    });

    // delete cars
    removeCar.forEach((item) => deleteDeliveryLine(item.lineId));

    const promise = newLineCars.map((item) => {
      return dispatch(createDeliveryLine(delivery.Id, item));
    });

    return Promise.all(promise)
      .then((res) => {
        const filterData = data.filter((item) => !item.lineId);
        filterData.forEach((item, num) => {
          if (res[num] && res[num]._id) {
            item.configuration.forEach((conf) => {
              if (!conf.lineId) {
                newLineConf.push({ parentLineId: res[num]._id, data: conf });
              }
            });
            item.accessories.forEach((acces) => {
              if (!acces.lineId) {
                newLineConf.push({ parentLineId: res[num]._id, data: acces });
              }
            });
          }
        });

        const promiseConf = newLineConf.map((item) => {
          return dispatch(createDeliveryLine(delivery.Id, item));
        });
        return Promise.all(promiseConf)
          .then((confRes) => console.log(confRes))
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
