export const getQuoteFields = (quote, newDocumentStatus = {}, checkLines = false) => {
  let {
    TaxPercentage = 0,
    TaxSum = 0,
    DiscountSum = 0,
    DiscountPercentage = 0,
    TotalTradeIn = 0,
    Total = 0,
    TotalBeforeDiscount,

    Lines = [],
  } = quote;

  // CHECKME:
  if (checkLines && Lines.length !== 0) {
    const priceLines = [];

    for (const line of Lines) {
      const { RelatedLines = [] } = line;
      priceLines.push(line, ...RelatedLines);
    }

    TaxPercentage = 0;
    TaxSum = 0;
    DiscountSum = 0;
    DiscountPercentage = 0;
    TotalTradeIn = 0;
    Total = 0;
    TotalBeforeDiscount = 0;

    for (const line of priceLines) {
      TaxPercentage = line.TaxPercentage;
      TaxSum += line.Total - line.TotalBeforeTax;
      DiscountSum += 0;
      DiscountPercentage += 0;
      TotalTradeIn += 0;
      Total += line.Total;
      TotalBeforeDiscount += line.UnitPrice * line.Quantity;
    }
  }

  const fields = {
    ...newDocumentStatus,
    Total: Total,
    TaxSum: TaxSum,
    TaxPercentage: TaxPercentage,
    DiscountSum: DiscountSum,
    DiscountPercentage: DiscountPercentage,
    TotalBeforeDiscount,
    TotalTradeIn: TotalTradeIn,
  };

  return fields;
};
