import {
  UPDATE_PAYMENTS,
  GET_DICTIONARIES_PAYMENTS,
  CHANGE_TYPE_LOADER_ACTION,
  SET_PAYMENT_STATUS,
} from '~/actions/payments';

const initialState = {
  data: [],
  dictionaries: {
    bank: [],
    countries: [],
    houseBankAccounts: [],
  },
  isLoading: false,
  status: undefined,
  error: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PAYMENTS:
      return { ...state, data: payload };
    case GET_DICTIONARIES_PAYMENTS:
      return { ...state, dictionaries: { ...state.dictionaries, ...payload } };
    case CHANGE_TYPE_LOADER_ACTION:
      return { ...state, isLoading: payload };
    case SET_PAYMENT_STATUS:
      const { status = undefined, error = false } = payload;

      return { ...state, status: status, error: error };
    default:
      return state;
  }
}
