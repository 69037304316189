import axiosInstance from '~/utils/axios';
import { store } from '~/App';
import { getSalesAPIURL } from '.';

const OrdersService = 'OrdersService';
const OrdersLineService = 'OrdersLineService';
const OrderServiceWithLines = 'OrderService';

export const getOrder = async (id, data) => {
  const url = `${OrdersService}/${id}`;

  return axiosInstance.get(`${getSalesAPIURL(url)}`).then(({ data }) => data);
};

export const createOrderWithLines = async (data) => {
  const url = `${OrderServiceWithLines}`;

  return axiosInstance.post(`${getSalesAPIURL(url)}`, data).then(({ data }) => data);
};

export const updateOrderWithLines = async (id, data) => {
  const url = `${OrderServiceWithLines}/${id}`;

  const state = store.getState();

  const { saveBtn } = state;
  const { updatedAt } = saveBtn;

  return axiosInstance
    .patch(`${getSalesAPIURL(url)}`, {
      ...data,
      updatedAt,
    })
    .then(({ data }) => data);
};

export const getOrderLine = async (lineId) => {
  const url = `${OrdersLineService}/${lineId}`;

  return axiosInstance.get(getSalesAPIURL(url)).then((response) => {
    return response.data;
  });
};

export const deleteOrderLine = async (lineId) => {
  const url = `${OrdersLineService}/${lineId}`;

  return axiosInstance.delete(`${getSalesAPIURL(url)}`).then(({ data }) => data);
};
