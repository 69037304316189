import axiosInstance from '~/utils/axios';
import { getSalesAPIURL, getFilterArray } from '.';
import { PageSizeAll } from '~/constants';

const listUrl = 'SavedSearchesListService';
const url = 'SavedSearchesLineService';

export const getSavedSearches = async (filters = {}) =>
  axiosInstance
    .get(getSalesAPIURL(listUrl), {
      params: {
        pageSize: PageSizeAll,
        filter: getFilterArray(filters),
      },
    })
    .then(({ data }) => data);

export const createSavedSearch = async (data) =>
  axiosInstance.post(getSalesAPIURL(url), data).then(({ data }) => data);

export const updateSavedSearch = async (id, data) =>
  axiosInstance.patch(getSalesAPIURL(`${url}/${id}`), data).then(({ data }) => data);

export const deleteSavedSearch = async (id) =>
  axiosInstance.delete(getSalesAPIURL(`${url}/${id}`)).then(() => true);
