import { OPPORTUNITIES_GET_LIST } from '../actions/my_opportunities';
import data from '../data/my_opportunities';

const initialState = {
  stages: data.stages,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case OPPORTUNITIES_GET_LIST: {
      return {
        ...state,
        stages: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
