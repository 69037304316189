import { SET_COMPANY_LOCALIZATION, SET_CURRENCY } from '~/reducers/localization';
import { getCompanyLocalization } from './api/localization';

export const SET_CURRENCY_ON_DEFAULT = 'SET_CURRENCY_ON_DEFAULT';
export const setDefaultCurrency = () => (dispatch) => dispatch({ type: SET_CURRENCY_ON_DEFAULT });

export const setCurrency = (currency) => (dispatch) =>
  dispatch({ type: SET_CURRENCY, payload: currency });

export const loadCompanyLocalization = (companyId) => async (dispatch) => {
  const localization = await getCompanyLocalization(companyId);

  dispatch({ type: SET_COMPANY_LOCALIZATION, payload: localization });
};
