import axiosInstance from '~/utils/axios';
import { getSalesAPIURL } from '.';

const companyLocalizationUrl = 'localization';

export const getCompanyLocalization = (companyId) =>
  axiosInstance
    .get(getSalesAPIURL(companyLocalizationUrl), {
      params: {
        companyId,
      },
    })
    .then(({ data }) => data);
