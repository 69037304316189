import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';
import { modelCar } from '../models/opportunity_content';
import { opportunityUpdateContentData } from './opportunity_content';
import { showWarning } from './layout';
import { emptyString, month, owner } from '../constants';
import { filterYears } from '~/utils/dateTime';
import { createVehicle, updateVehicle } from './api/vehicles';

export const GET_DICTIONARIES_FOR_TRADE_IN = 'GET_DICTIONARIES_FOR_TRADE_IN';
// TODO:
export const getDictionariesForTradeIn = () => async (dispatch) => {
  const promise = [
    axiosInstance.get(`/xassales/v1/MakesListService`, {
      params: {
        sort: 'name:1',
      },
    }),
    axiosInstance.get(`/xassales/v1/VehicleCategoriesListService`, {}),
    axiosInstance.get(`/xassales/v1/VehicleColorsListService`, {
      params: {
        sort: 'name:1',
      },
    }),
    axiosInstance.get(`/xassales/v1/VehicleModelsListService`, {
      params: {
        sort: 'name:1',
      },
    }),
    axiosInstance.get(`/xassales/v1/BaseModelsListService`, {
      params: {
        sort: 'name:1',
      },
    }),
    axiosInstance.get(`/xassales/v1/YearsListService`, {
      params: {
        pageSize: 'all',
        sort: 'name:-1',
      },
    }),
  ];

  return Promise.all(promise).then((res) => {
    const data = res.map((item) =>
      item.data.map((item) => ({
        label: item.Name,
        value: item.Id,
        Make: item.Make,
        Category: item.Category,
        BaseModelId: item.BaseModelId,
      })),
    );
    const [make, category, color, model, baseModel, year] = data;
    const payload = {
      make,
      category,
      color,
      model,
      baseModel,
      year: filterYears(year),
      month,
      owner,
    };
    dispatch({ type: GET_DICTIONARIES_FOR_TRADE_IN, payload: payload });
    return payload;
  });
};

const getNameForTradeIn = (props, dictionariesTradeIn, isLine = false) => {
  const makeValue = isLine ? props.fields?.make : props?.parametrs?.specification?.make || '';
  const baseModelValue = isLine
    ? props.fields?.baseModelId
    : props?.parametrs?.specification?.baseModelId || '';
  const modelValue = isLine
    ? props.fields?.modelId
    : props?.parametrs?.specification?.modelId || '';
  const yearValue = isLine ? props.fields?.year : props?.parametrs?.specification?.year || '';

  const make = getLabelData('make', makeValue, dictionariesTradeIn);
  const baseModel = getLabelData('baseModel', baseModelValue, dictionariesTradeIn);
  const model = getLabelData('model', modelValue, dictionariesTradeIn);
  const year = getLabelData('year', yearValue, dictionariesTradeIn);

  return `${make} ${baseModel} ${model} ${year}`.replace(/\s+/g, ' ').trim();
};

export const createNewTradeIn = (fields, images, imageLink) => async (dispatch, getState) => {
  const { content } = getState().opportunity;
  const { tradeIn } = getState().dictionaries;
  const { dictionariesTradeIn } = getState().tradeIn;
  const name =
    fields.description || fields.name || getNameForTradeIn({ fields }, dictionariesTradeIn, true);
  const itemCode = tradeIn?.itemCode;
  const evaluationPrice = parseInt(fields.evaluationPrice) || 0;

  const newCar = modelCar({
    id: `trade-in-${new Date().getTime()}`,
    itemCode: itemCode,
    lineType: 'Trade-in',
    name,
    make: fields.make,
    base_model: fields.baseModel,
    year: fields.year,
    color: fields.color,
    imageLink: imageLink,
    quantity: 1,
    price: evaluationPrice,
    unitPrice: evaluationPrice,
    discount: evaluationPrice,
    totalBeforeTax: evaluationPrice,
    total: evaluationPrice,
    discountPercent: 0,
    taxPercentage: 0,
    notes: fields.notes,
    parametrs: {
      details: {
        images: images,
        body_color: fields.color || '',
      },
      specification: {
        make: fields.make,
        firstRegistrationDate: fields.firstRegistrationDate,
        baseModelId: fields.baseModelId,
        modelId: fields.modelId,
        year: fields.year,
        month: fields.month,
        licenseNumber: fields.licenseNumber,
        isVehicleHistory: fields.isVehicleHistory,
        isWarranty: fields.isWarranty,
        powerHP: fields.powerHP,
        evaluationPrice: evaluationPrice,
        marketPrice: parseInt(fields.marketPrice),
        mileage: fields.mileage,
        mileageType: fields.mileageType,
        category: fields.category,
        vin: fields.vin,
        privousOwnersNumber: fields.privousOwnersNumber,
        firstOwner: fields.firstOwner,
        currentOwner: fields.currentOwner,
        vehicleColor: fields.vehicleColor,
      },
    },
    images: images,
    vin: fields.vin ? [fields.vin] : [],
  });

  if (itemCode) {
    dispatch(opportunityUpdateContentData({ ...content, cars: [...content.cars, newCar] }));
  } else {
    dispatch(showWarning('Error ItemCode', 'Error'));
  }
};

const getLabelData = (field, value, options) => {
  let result = '';
  if (options[field]) {
    const selectData = options[field].find((item) => item.value === value);
    result = selectData ? selectData.label : '';
  }
  return result;
};

export const createNewVehicle = (props) => async (dispatch, getState) => {
  const { content, new: newOpportunity } = getState().opportunity;
  const { dictionariesTradeIn } = getState().tradeIn;
  const promise = [];
  const tradeInImages = [];

  for (let prop of props) {
    const name = prop.description || prop.name || getNameForTradeIn(prop, dictionariesTradeIn);
    const newImages = await dispatch(uploadVehicleImage(prop.images));
    const req = {};
    const { specification } = prop.parametrs;
    const data = {
      Name: name,
      Owner: newOpportunity?.customer?.CustomerId || null,
      ItemCode: prop?.itemCode || emptyString,
      Make: specification?.make || emptyString,
      BaseModelId: specification?.baseModelId || emptyString,
      ModelId: specification?.modelId || emptyString,
      Year: specification?.year || emptyString,
      Month: specification?.month || emptyString,
      LicenseNumber: specification?.licenseNumber || emptyString,
      IsVehicleHistory: specification?.isVehicleHistory || emptyString,
      IsWarranty: specification?.isWarranty || emptyString,
      PowerHP: specification?.powerHP || emptyString,
      EvaluationPrice: specification?.evaluationPrice || emptyString,
      FirstRegistrationDate: specification?.firstRegistrationDate || emptyString,
      Mileage: specification?.mileage || emptyString,
      MileageTyp: specification?.mileageType || emptyString,
      Category: specification?.category || emptyString,
      Vin: specification?.vin || emptyString,
      PrivousOwnersNumber: specification?.privousOwnersNumber || emptyString,
      FirstOwner: specification?.firstOwner || emptyString,
      CurrentOwner: specification?.currentOwner || emptyString,
      MarketPrice: specification?.marketPrice || emptyString,
      VehicleColor: specification?.vehicleColor || emptyString,
      Photos: newImages,
      ImageLink: prop.imageLink || 0,
      Notes: prop?.notes,
      NewUsed: 'U',
      /* VSWA-1867 */
      IsSalable: false,
    };

    Object.keys(data).forEach((item) => {
      if (data[item] !== emptyString) {
        req[item] = data[item];
      }
    });

    tradeInImages.push(newImages);
    promise.push(createVehicle(req));
  }

  const cars = content.cars.filter(
    (car) => !(car.lineType === 'Trade-in' && car.id.includes('trade-in')),
  );

  return Promise.all(promise)
    .then((res) => {
      const newCars = res.map((item, i) => {
        const tradeInData = props[i] || {};
        return {
          ...tradeInData,
          isAdd: true,
          id: item._id,
          images: tradeInImages[i] || [],
        };
      });
      dispatch(opportunityUpdateContentData({ ...content, cars: [...cars, ...newCars] }));
    })
    .catch((err) => {
      dispatch(opportunityUpdateContentData({ ...content, cars }));
    });
};

export const updateVehicleTradeIn = (props) => async (dispatch, getState) => {
  const { dictionariesTradeIn } = getState().tradeIn;
  const promise = [];

  for (let prop of props) {
    const req = {};
    const data = prop.parametrs.specification;
    let newImages = prop.images.filter((item) => !item.image);
    const isNewImages = prop.images.filter((item) => item.image);
    const name = prop.description || getNameForTradeIn(prop, dictionariesTradeIn);
    if (isNewImages.length) {
      const updatePhoto = (await dispatch(uploadVehicleImage(isNewImages))) || [];
      newImages = [...newImages, ...updatePhoto];
    }

    data.name = name;
    data.photos = newImages;
    data.imageLink = prop.imageLink;
    Object.keys(data).forEach((item) => {
      if (data[item] !== '') {
        const key = item[0].toUpperCase() + item.slice(1);
        req[key] = data[item];
      }
    });

    promise.push(updateVehicle(prop.id, req));
  }

  return Promise.all(promise);
};

export const uploadVehicleImage = (images) => (dispatch) => {
  if (!images) {
    return [];
  }

  const promise = images.map(({ image }) => {
    const data = new FormData();
    data.append('file', image);
    return axiosInstance.post(`/xassales/v1/AttachmentsFileService`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    });
  });

  return Promise.all(promise)
    .then(async (res) => {
      const filterResponse = res.filter((item) => {
        const error = item.data?.error;
        if (error) {
          dispatch(showWarning(error, 'Error'));
        }
        return item.data?.success;
      });
      return filterResponse.map((item) => item.data?.attachment);
    })
    .catch(() => []);
};
