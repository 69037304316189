import initialState from '../data/filters';
import {
  FILTERS_CHANGE_FILTERS,
  FILTERS_CHANGE_MODAL,
  FILTERS_CHANGE_MODAL_FIELD,
  FILTERS_CHANGE,
  FILTERS_FILTER_DATA,
  PARTNERS_SET_ROWS_PER_PAGE,
  SET_DICTIONARIES_DATA,
  SET_INITIAL_DATA,
  FILTER_SET_DEFAULT_FILTERS_PARAMS,
  FILTERS_GET_FILTER_NAMES,
  SET_FILTERS,
} from '../actions/filters';
import { filterDateRange } from '../components/opportunities/table/helper';

const localeData = JSON.parse(localStorage.getItem('filteredData'));

if (localeData && localeData.length) {
  const names = localeData.map((item) => ({ value: item.name, label: item.name }));

  initialState.data = localeData;
  initialState.filteredData = localeData;
  initialState.dictionaries = { ...initialState.dictionaries, names };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FILTERS: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case FILTERS_CHANGE_FILTERS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.key]: { value: action.value, params: action.params },
        },
        disableSaveFilterButton: false,
      };
    }
    case FILTERS_CHANGE_MODAL_FIELD: {
      return {
        ...state,
        filterModal: {
          ...state.filterModal,
          [action.key]: action.value,
        },
      };
    }

    case FILTERS_CHANGE_MODAL: {
      return {
        ...state,
        filterModal: action.filter,
      };
    }
    case FILTERS_CHANGE: {
      localStorage.setItem('filteredData', JSON.stringify(action.payload));
      return {
        ...state,
        data: action.payload,
        filteredData: filterData(state.filterValues, action.payload),
        disableSaveFilterButton: true,
      };
    }

    case FILTERS_FILTER_DATA: {
      return {
        ...state,
        filteredData: filterData(state.filterValues, state.data),
      };
    }

    case FILTERS_GET_FILTER_NAMES: {
      return {
        ...state,
        filterNames: action.payload,
      };
    }

    case FILTER_SET_DEFAULT_FILTERS_PARAMS: {
      return {
        ...state,
        ...action.payload,
        disableSaveFilterButton: true,
      };
    }

    case SET_DICTIONARIES_DATA: {
      return {
        ...state,
        dictionaries: { ...state.dictionaries, names: action.dictionaries },
      };
    }

    case SET_INITIAL_DATA: {
      return {
        ...state,
        data: action.payload,
        filteredData: action.payload,
      };
    }

    case PARTNERS_SET_ROWS_PER_PAGE: {
      return {
        ...state,
        paging: {
          ...state.paging,
          ...action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const filterData = (filters, data) => {
  const { search, name, isDisplayOnDashboard, isPrivate, date, owner, type } = filters;

  let result = data;

  if (search?.value) {
    result = result.filter(
      (item, i) =>
        item.name.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.owner.toLowerCase().search(search.toLowerCase()) !== -1,
    );
  }
  if (name?.value) {
    result = result.filter((item, i) => item.name === name);
  }
  if (isDisplayOnDashboard?.value === true || isDisplayOnDashboard?.value === false) {
    result = result.filter((item, i) => item.isDisplayOnDashboard === isDisplayOnDashboard);
  }
  if (date?.value) {
    result = filterDateRange(result, 'date', date);
  }
  if (isPrivate?.value === true || isPrivate?.value === false) {
    result = result.filter((item, i) => item.isPrivate === isPrivate);
  }
  if (owner?.value) {
    result = result.filter((item, i) => item.owner === owner);
  }
  if (type?.value) {
    result = result.filter((item, i) => item.type === type);
  }

  return result;
};
