import { axiosInstanceSalesAPI } from '~/utils/axios';
import { queryParamsBuilder } from '~/helpers/filter';

const url = 'SubSources';

export const getSubSources = (params = {}) =>
  axiosInstanceSalesAPI
    .get(url, {
      params: {
        ...queryParamsBuilder(params),
        pagingResponse: true,
      },
    })
    .then(({ data }) => data);

export const createSubSource = (data) =>
  axiosInstanceSalesAPI.post(url, data).then(({ data }) => data);

export const updateSubSource = (subSourceId, data) =>
  axiosInstanceSalesAPI.patch(`url/${subSourceId}`, data).then(({ data }) => data);

export const deleteSubSource = (subSourceId) =>
  axiosInstanceSalesAPI.delete(`url/${subSourceId}`).then(({ data }) => data);
