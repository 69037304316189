import { deleteOrderLine, getOrder, updateOrderWithLines } from '~/actions/api/orders';
import { createOrderLine, OPPORTUNITY_CONTENT_SET_CURRENT_ORDER } from './opportunity_content';
import { SET_LINES_TO_DELIVERY, CLEAR_LINES_TO_DELIVERY } from '~/reducers/opportunity_order';

export const GET_ORDER = 'GET_ORDER';
export const getOrderAction = (id) => async (dispatch) => {
  try {
    const order = await getOrder(id);

    dispatch({ type: OPPORTUNITY_CONTENT_SET_CURRENT_ORDER, payload: order });
    dispatch({ type: GET_ORDER, payload: order });
    return order;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const changeOrderInformation = (props) => (dispatch, getState) => {
  const { order } = getState().opportunity.opportunityOrder;
  const payload = { ...order, [props.key]: props.value };

  dispatch({ type: GET_ORDER, payload });
};

export const createOrderFromDraft = (orderId) => async (dispatch) => {
  try {
    const data = {
      IsDraft: false,
      IsPrimary: true,
    };

    await updateOrderWithLines(orderId, data);

    return Promise.resolve(true);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const saveDataOrderAction = (id, data) => async (dispatch, getState) => {
  const opportunity = getState().opportunity.content;
  const opportunityOrder = getState().opportunity.opportunityOrder;
  const { order } = opportunityOrder;
  const { removeCar } = opportunity;

  if (order) {
    let newLineCars = [];
    let newLineConf = [];
    data.forEach((item) => {
      if (!item.lineId) {
        newLineCars.push({ data: item });
      } else {
        item.configuration.forEach((conf) => {
          if (!conf.lineId) {
            newLineConf.push({ parentLineId: item.lineId, data: conf });
          }
        });
        item.accessories.forEach((acces) => {
          if (!acces.lineId) {
            newLineConf.push({ parentLineId: item.lineId, data: acces });
          }
        });
      }
    });

    // delete cars
    removeCar.forEach((item) => deleteOrderLine(item.lineId));

    const promise = newLineCars.map((item) => {
      return dispatch(createOrderLine(order.Id, item));
    });

    return Promise.all(promise)
      .then((res) => {
        const filterData = data.filter((item) => !item.lineId);
        filterData.forEach((item, num) => {
          if (res[num] && res[num]._id) {
            item.configuration.forEach((conf) => {
              if (!conf.lineId) {
                newLineConf.push({ parentLineId: res[num]._id, data: conf });
              }
            });
            item.accessories.forEach((acces) => {
              if (!acces.lineId) {
                newLineConf.push({ parentLineId: res[num]._id, data: acces });
              }
            });
          }
        });

        const promiseConf = newLineConf.map((item) => {
          return dispatch(createOrderLine(order.Id, item));
        });
        return Promise.all(promiseConf)
          .then((confRes) => console.log(confRes))
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const toggleLineToDelivery = (id) => (dispatch, getState) => {
  const state = getState();

  const {
    opportunity: {
      content: {
        currentOrder: { Lines = [] },
      },
      opportunityOrder: { linesToDelivery },
    },
  } = state;

  const isSwitchToTrue = !linesToDelivery.includes(id);

  const childLines = Lines.filter(({ ParentLineId }) => ParentLineId === id).map(({ Id }) => Id);

  const lines = [id, ...childLines];

  const newLinesToDelivery = linesToDelivery.filter((lineId) => !lines.includes(lineId));

  if (isSwitchToTrue) {
    newLinesToDelivery.push(...lines);
  }

  dispatch({ type: SET_LINES_TO_DELIVERY, payload: newLinesToDelivery });
};

export const clearLinesToDelivery = () => (dispatch) => {
  dispatch({ type: CLEAR_LINES_TO_DELIVERY });
};
