import { axiosInstanceSalesAPI } from '~/utils/axios';

const url = 'AddressesService';

export const createAddress = (data) =>
  axiosInstanceSalesAPI.post(url, data).then(({ data }) => data);

export const updateAddress = (id, data) =>
  axiosInstanceSalesAPI.patch(`${url}/${id}`, data).then(({ data }) => data);

export const deleteAddress = (addressId) =>
  axiosInstanceSalesAPI.delete(`${url}/${addressId}`).then(({ data }) => data);
