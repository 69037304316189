import { getUserFromCookies } from './user';
import { showWarning } from './layout';
import {
  activityTypes,
  adaptActivity,
  getUpdatedActivity,
} from '~/helpers/dataAdapters/activities';
import {
  createActivity as createActivityRequest,
  getActivities as getActivitiesRequest,
  updateActivity as updateActivityRequest,
  removeActivity as removeActivityRequest,
} from './api/activities';
import { FilterOperatorV2, SortOperator } from '~/constants/filter';
import moment from 'moment';
import {
  ACTIVITIES_CREATE_SAVED_FILTER,
  ACTIVITIES_RESET_FILTERS,
  ACTIVITIES_SET_FILTERS_VALUE,
  ACTIVITIES_SET_ITEMS,
  ACTIVITIES_SET_PAGING,
  ACTIVITIES_SET_SAVED_FILTERS,
  ACTIVITIES_SET_SAVED_FILTER_ID,
  ACTIVITIES_SET_SEARCH,
  ACTIVITIES_SET_TOTAL,
  ACTIVITIES_TOGGLE_LOADER,
} from '~/reducers/activities';
import { emptyString } from '~/constants';
import { createTableFilter, getTableFilters } from './api/tableFilters';
import { objectKeysPascalCaseToCamelCase } from '~/utils/object';

export const setIsLoading = (isLoading) => (dispatch) => {
  dispatch({ type: ACTIVITIES_TOGGLE_LOADER, payload: isLoading });
};

export const setPage = (page) => (dispatch) => {
  const payload = {
    page: page,
  };

  dispatch({ type: ACTIVITIES_SET_PAGING, payload });
};

export const setPageSize = (count) => (dispatch) => {
  const payload = {
    count: count,
  };

  dispatch({ type: ACTIVITIES_SET_PAGING, payload });
};

export const getActivitiesDashboard = (userId, date) => async (dispatch) => {
  dispatch({ type: ACTIVITIES_TOGGLE_LOADER, payload: true });

  const params = {
    filter: {
      'assignedTo.rid': {
        value: userId,
      },
      startDateTime: {
        value: {
          dateRangeStart: moment(date).startOf('day').toISOString(),
          dateRangeEnd: moment(date).endOf('day').toISOString(),
        },
        params: FilterOperatorV2.between,
      },
      isComplete: {
        value: false,
      },
    },
    sort: {
      startDateTime: SortOperator.asc,
    },
  };

  try {
    const activities = await getActivitiesRequest(params);

    const adaptActivities = (activities || []).map(adaptActivity);

    dispatch({ type: ACTIVITIES_SET_ITEMS, payload: adaptActivities });
    dispatch({ type: ACTIVITIES_SET_TOTAL, payload: adaptActivities.length });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({ type: ACTIVITIES_TOGGLE_LOADER, payload: false });
  }
};

export const getActivities = () => async (dispatch, getState) => {
  dispatch({ type: ACTIVITIES_TOGGLE_LOADER, payload: true });

  const {
    activities: { paging, filters },
  } = getState();

  const params = {
    filter: filters,
    paging,
  };

  try {
    const { records = [], total } = await getActivitiesRequest(params);

    const adaptActivities = records.map(adaptActivity);

    dispatch({ type: ACTIVITIES_SET_ITEMS, payload: adaptActivities });
    dispatch({ type: ACTIVITIES_SET_TOTAL, payload: total });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({ type: ACTIVITIES_TOGGLE_LOADER, payload: false });
  }
};

export const getSavedFilters =
  (tableName, filterId = emptyString) =>
  async (dispatch) => {
    if (!filterId) {
      return;
    }

    const savedFilters = await getTableFilters({
      filter: {
        tableName: {
          value: tableName,
        },
      },
    });

    const adaptFilters = objectKeysPascalCaseToCamelCase(savedFilters);

    const tableFilter = adaptFilters.find(({ id }) => id === filterId);

    if (!tableFilter) {
      return;
    }

    const { content } = tableFilter;

    if (!content) {
      return;
    }

    dispatch({ type: ACTIVITIES_SET_SAVED_FILTERS, payload: adaptFilters });
    dispatch(setSavedFilterId(tableFilter?.id));
    dispatch(setFilters(objectKeysPascalCaseToCamelCase(content)));
  };

export const createSavedFilter = (filter) => async (dispatch) => {
  try {
    const newFilter = await createTableFilter(filter);

    const adaptFilter = objectKeysPascalCaseToCamelCase(newFilter);

    dispatch({ type: ACTIVITIES_CREATE_SAVED_FILTER, payload: adaptFilter });
    dispatch({ type: ACTIVITIES_SET_SAVED_FILTER_ID, payload: adaptFilter.id });
  } catch (error) {
    console.error(error);
  }
};

export const setSavedFilterId = (savedFilterId) => (dispatch) => {
  dispatch({ type: ACTIVITIES_SET_SAVED_FILTER_ID, payload: savedFilterId });
};

export const setFilters =
  (filters = {}) =>
  (dispatch) => {
    dispatch({ type: ACTIVITIES_SET_FILTERS_VALUE, payload: filters });
  };

export const resetFilters = () => (dispatch) => {
  dispatch({ type: ACTIVITIES_RESET_FILTERS });
};

export const setSearch = (value) => (dispatch) => {
  dispatch({ type: ACTIVITIES_SET_SEARCH, payload: value });
};

const getActualActivityKey = (key) => {
  switch (key) {
    case 'id':
      return 'Id';
    case 'type':
      return 'ActivityType';
    case 'startDate':
      return 'StartDateTime';
    case 'startTime':
      return 'StartDateTime';
    case 'dueDate':
      return 'DueDateTime';
    case 'dueTime':
      return 'DueDateTime';
    case 'opportunityId':
    case 'entity':
      return 'ObjectId';
    default:
      return key.substring(0, 1).toUpperCase() + key.substring(1);
  }
};

export const getOutputActivity = (activity) => {
  let result = {};
  Object.keys(activity).forEach((key, i) => {
    result = {
      ...result,
      [getActualActivityKey(key)]: activity[key],
    };
  });
  if (activity.dueDate || activity.dueTime) {
    result.DueDateTime = combineDateTime(activity.startDate, activity.dueTime);
  }
  if (activity.startDate || activity.startTime) {
    result.StartDateTime = combineDateTime(activity.startDate, activity.startTime);
  }
  if (activity.type) {
    result.ActivityType = activityTypes[activity.type];
  }

  if (!result.RelatedTo) {
    result.RelatedTo = result.CustomerId;
  }

  return result;
};

const combineDateTime = (date, time) => {
  if (date && time) {
    let t = new Date(time);
    let d = new Date(date);
    let result = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      t.getHours(),
      t.getMinutes(),
      t.getSeconds(),
    );
    return result;
  } else {
    if (date) {
      return date;
    }
    if (time) {
      return time;
    }
    return undefined;
  }
};

export const addActivity = (activity) => async (dispatch, getState) => {
  const state = getState();

  const {
    customer: { defaultCustomer },
    activities,
  } = state;

  const { items = [] } = activities;
  let payload = [...items];

  const userID = getUserFromCookies()._id;
  const updatedActivity = getUpdatedActivity(activity, 'assignedTo', userID);

  const newActivity = {
    ...getOutputActivity(updatedActivity),
    ObjectModel: updatedActivity.objectId
      ? updatedActivity.objectModel || 'Opportunity'
      : undefined,
  };

  if (!newActivity.RelatedTo) {
    newActivity.RelatedTo = defaultCustomer?.Id;
  }

  try {
    let data = await createActivityRequest(newActivity);

    data = adaptActivity(objectKeysPascalCaseToCamelCase(data));

    dispatch({ type: ACTIVITIES_SET_ITEMS, payload: [data, ...payload] });

    return data;
  } catch (error) {
    dispatch(showWarning(error.message, 'Error'));
  }
};

export const updateActivity = (activity) => async (dispatch, getState) => {
  const { items = [] } = getState().activities;
  let payload = [...items];

  let newActivity = getOutputActivity(activity);

  try {
    newActivity = await updateActivityRequest(activity.id, newActivity);

    newActivity = adaptActivity(objectKeysPascalCaseToCamelCase(newActivity));

    const index = payload.findIndex((item) => item.id === newActivity.id);

    if (index === -1) {
      return;
    }

    payload.splice(index, 1, newActivity);

    dispatch({ type: ACTIVITIES_SET_ITEMS, payload });
  } catch (error) {
    dispatch(showWarning(error.message, 'Error'));
  }
};

export const updateActivityEvent = (activity) => async (dispatch, getState) => {
  const { items = [] } = getState().activities;
  const payload = [...items];

  try {
    const newActivity = adaptActivity(objectKeysPascalCaseToCamelCase(activity));

    const index = payload.findIndex((item) => item.id === newActivity.id);

    if (index === -1) {
      return;
    }

    payload.splice(index, 1, newActivity);

    dispatch({ type: ACTIVITIES_SET_ITEMS, payload });
  } catch (error) {
    dispatch(showWarning(error.message, 'Error'));
  }
};

export const removeActivity = (id, callback) => async (dispatch, getState) => {
  try {
    await removeActivityRequest(id);

    callback && callback();
  } catch (error) {
    dispatch(showWarning(error.message, 'Error'));
  }
};
