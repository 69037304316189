import { GET_DICTIONARIES_FOR_TRADE_IN } from '../actions/trade_in';

const initialState = {
  dictionariesTradeIn: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DICTIONARIES_FOR_TRADE_IN: {
      return {...state, dictionariesTradeIn: action.payload};
    }

    default: {
      return state;
    }
  }
}
