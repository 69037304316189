import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';
import { showWarning } from './layout';
import { PageSizeForRequest } from '../constants';

export const SET_ATTACHMENT = 'SET_ATTACHMENT';
export const getAttachments = (id, type) => (dispatch) => {
  dispatch(changeLoader(true));
  axiosInstance
    .get(
      `/xassales/v1/AttachmentsListService?filter=linkedObjectId:=:${id}&filter=linkedObjectType:=:${type}&pageSize=${PageSizeForRequest}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    )
    .then((res) => {
      dispatch({ type: SET_ATTACHMENT, payload: res.data });
      dispatch(changeLoader(false));
    })
    .catch(() => dispatch(changeLoader(false)));
};

export const CHANGE_PROGRESS_LOADING_ATTACHMENT = 'CHANGE_PROGRESS_LOADING_ATTACHMENT';
export const uploadImagesAttachment = (files) => (dispatch, getState) => {
  const initProgressLoad = files.map((item) => ({ id: item.id, progress: 0 }));
  dispatch({ type: CHANGE_PROGRESS_LOADING_ATTACHMENT, payload: initProgressLoad });

  const promise = files.map((item) => {
    const formData = new FormData();
    formData.append('file', item.image);

    return axiosInstance.post(`/xassales/v1/AttachmentsFileService`, formData, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
      onUploadProgress: (progressEvent) => {
        const { progressImages } = getState().attachment;
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        const payload = progressImages.map((p) => {
          if (p.id === item.id) {
            return { id: item.id, progress: percentCompleted };
          }
          return p;
        });

        dispatch({ type: CHANGE_PROGRESS_LOADING_ATTACHMENT, payload });
      },
    });
  });

  return Promise.all(promise)
    .then((res) => {
      const result = [];

      if (res.length) {
        res.forEach((r) => {
          if (r.data.success) {
            result.push(r.data.attachment);
          } else {
            result.push({
              error: true,
            });
            dispatch(showWarning(r.data.error, 'Error'));
          }
        });
      }

      return result;
    })
    .catch((err) => []);
};

export const updateAttachment = (id, LinkedObjectType, LinkedObjectId) => {
  axiosInstance.patch(
    `/xassales/v1/AttachmentsService/${id}`,
    {
      LinkedObjectId,
      LinkedObjectType,
    },
    {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    },
  );
};

export const updateAttachmentWithName = (item) => {
  axiosInstance.patch(
    `/xassales/v1/AttachmentsService/${item.id || item._id}`,
    {
      Name: item.name,
      Title: item.name,
      Description: item.description,
    },
    {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    },
  );
};

export const addNewAttachment = (id, type, data, isEdit = false) => async (dispatch) => {
  const promise = data.map((item) => {
    return axiosInstance.patch(
      `/xassales/v1/AttachmentsService/${item.id}`,
      {
        Title: item.name,
        Name: item.name,
        Description: item.description,
        LinkedObjectId: id,
        LinkedObjectType: type,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    );
  });

  Promise.all(promise).then(() => {
    if (!isEdit) {
      window.localStorage.removeItem('AttachmentImages');
    }
    dispatch(getAttachments(id, type));
  });
};

export const deleteAttachmentAction = (id) => (dispatch, getState) => {
  const { data } = getState().attachment;
  axiosInstance
    .delete(`/xassales/v1/AttachmentsService/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then(() => {
      const payload = data.filter((item) => item.Id !== id);
      dispatch({ type: SET_ATTACHMENT, payload });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const CHANGE_ATTACHMENT_LOADER = 'CHANGE_ATTACHMENT_LOADER';
export const changeLoader = (payload) => (dispatch) => {
  dispatch({ type: CHANGE_ATTACHMENT_LOADER, payload });
};

export const ATTACHMENT_SET_ROWS_PER_PAGE = 'ATTACHMENT_SET_ROWS_PER_PAGE';
export const changePage = (page) => (dispatch, getState) => {
  const { paging } = getState().attachment;

  const pagingFrom = paging.pageSize * page;
  const pagingTo = paging.pageSize;

  const payload = {
    currentPage: page,
    pagingTo,
    pagingFrom,
  };

  dispatch({ type: ATTACHMENT_SET_ROWS_PER_PAGE, payload });
};

export const changeRowsPerPage = (count) => (dispatch, getState) => {
  const { paging, data } = getState().attachment;
  const isLength = data.length;

  let pagingFrom = count * paging.currentPage;
  let pagingTo = count;

  if (pagingFrom > isLength) {
    pagingFrom = 0;
  }

  if (pagingTo > isLength) {
    pagingTo = isLength;
  }

  const payload = {
    pageSize: count,
    pagingTo,
    pagingFrom,
  };

  dispatch({ type: ATTACHMENT_SET_ROWS_PER_PAGE, payload });
};
