import { createBrowserHistory } from 'history';

const configureHistory = () => {
  const history = createBrowserHistory();

  history.goToOpportunity = function (opportunityId) {
    this.push(`/opportunity/${opportunityId}`);
  };

  history.goToOpportunityList = function (state = {}) {
    this.push({
      pathname: `/my_opportunities_list`,
      state,
    });
  };

  history.goToLeads = function (state = {}) {
    this.push({
      pathname: `/leads`,
      state,
    });
  };

  history.goToFilters = function (state = {}) {
    this.push({
      pathname: `/filters`,
      state,
    });
  };

  history.goToApprovalRequests = function (state = {}) {
    this.push({
      pathname: `/approval_requests`,
      state,
    });
  };

  history.goToLeadMappings = function (state = {}) {
    this.push({
      pathname: `/leads_import_mapping`,
      state,
    });
  };

  history.goToCampaigns = function (state = {}) {
    this.push({
      pathname: `/campaigns`,
      state,
    });
  };

  history.goToVehicleCategories = function (state = {}) {
    this.push({
      pathname: `/vehicle_categories`,
      state,
    });
  };

  history.goToVehicles = function (state = {}) {
    this.push({
      pathname: `/vehicles`,
      state,
    });
  };

  history.goToVehicleById = function (id, state = {}) {
    this.push({
      pathname: `/vehicles/${id}`,
      state,
    });
  };

  history.goToModelVariantById = function (id, state = {}) {
    this.push({
      pathname: `/model/${id}`,
      state,
    });
  };

  history.goToActivities = function (state = {}) {
    this.push({
      pathname: `/activities`,
      state,
    });
  };

  history.goToCloseReasons = function (state = {}) {
    this.push({
      pathname: `/close_reasons`,
      state,
    });
  };

  history.goToLead = function (leadId) {
    this.push(`/lead/${leadId}`);
  };

  history.goToCustomer = function (customerId) {
    this.push(`/customer/${customerId}`);
  };

  history.goToOpportunityCustomers = function (opportunityId) {
    this.push(`/opportunity/${opportunityId}/customers`);
  };

  history.goToNewOpportunityCustomer = function () {
    this.push(`/opportunity/new/customers`);
  };

  history.goToPDF = function (document, opportunityId) {
    this.push(`/sign_pdf`, {
      ...document,
      opportunityId,
    });
  };

  return history;
};

export default configureHistory();
