import { updateCustomer } from './api/customers';
import { updateContact, deleteContact } from './api/contacts';

export const PARTNER_CONTACTS_CHANGE_MODAL_FIELDS = 'PARTNER_CONTACTS_CHANGE_MODAL_FIELDS';
export const PARTNER_CONTACTS_CLEAR_MODAL_FIELDS = 'PARTNER_CONTACTS_CLEAR_MODAL_FIELDS';
export const PARTNER_CONTACTS_CHANGE_FILTERS = 'PARTNER_CONTACTS_CHANGE_FILTERS';

export const changeContactModalFields = (fields) => (dispatch) => {
  dispatch({ type: PARTNER_CONTACTS_CHANGE_MODAL_FIELDS, name: 'contact', fields });
};

export const clearContactModalFields = () => (dispatch) => {
  dispatch({ type: PARTNER_CONTACTS_CLEAR_MODAL_FIELDS, name: 'contact' });
};

export const changeContactsFilters = (value) => (dispatch) => {
  dispatch({ type: PARTNER_CONTACTS_CHANGE_FILTERS, value });
};

export const addNewContactForCustomer = async (customer, newContact, callback) => {
  const { id, contacts = [] } = customer;
  try {
    await updateCustomer(id, {
      Contacts: [...contacts, newContact],
    });
    callback && callback();
  } catch (error) {
    console.error(error);
  }
};

export const removeContactForCustomer = async (contactId, callback) => {
  try {
    await deleteContact(contactId);
    callback && callback();
  } catch (error) {
    console.error(error);
  }
};

export const updateContactForCustomer = async (contactId, data, callback) => {
  try {
    await updateContact(contactId, data);
    callback && callback();
  } catch (error) {
    console.error(error);
  }
};
