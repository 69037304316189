export const modelDocuments = (props) => {
  const model = {
    id: props.id || '',
    type: props.type || '',
    externalRef: props.externalRef || '',
    makePrimary: props.makePrimary || false,
    documentName: props.documentName || '',
    documentCode: props.documentCode || '',
    customerName: props.customerName || '',
    createdBy: props.createdBy || '',
    creationDate: props.creationDate || '',
    expirationDate: props.expirationDate || '',
    status: props.status || '',
    notes: props.notes || '',
    isSigned: props.isSigned || false,
    total: props.total || '',
  };

  return { ...props, ...model };
};
