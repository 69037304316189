import {
  LEAD_MAPPINGS_CREATE,
  LEAD_MAPPINGS_DELETE,
  LEAD_MAPPINGS_UPDATE,
  LEAD_MAPPINGS_GET_LIST,
  LEAD_MAPPINGS_LOADING,
  CHANGE_LEAD_MAPPINGS_FILTER_NAMES,
  LEAD_MAPPINGS_CHANGE_FILTERS,
  LEAD_MAPPINGS_CHANGE_MODAL_FIELD,
  LEAD_MAPPINGS_DISABLE_NEW_FILTER,
  LEAD_MAPPINGS_SET_DEFAULT_FILTERS_PARAMS,
} from '~/actions/lead_mappings';
import initialState from '~/data/lead_mappings.json';

export default function (state = initialState, action) {
  switch (action.type) {
    case LEAD_MAPPINGS_DISABLE_NEW_FILTER: {
      return {
        ...state,
        isDisabledNewFilter: action.payload,
      };
    }
    case LEAD_MAPPINGS_CHANGE_MODAL_FIELD: {
      return {
        ...state,
        filterModal: {
          ...state.filterModal,
          [action.key]: action.value,
        },
      };
    }
    case LEAD_MAPPINGS_CHANGE_FILTERS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.key]: { value: action.value, params: action.params },
        },
      };
    }
    case CHANGE_LEAD_MAPPINGS_FILTER_NAMES: {
      return { ...state, filterNames: action.payload };
    }
    case LEAD_MAPPINGS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case LEAD_MAPPINGS_CREATE: {
      return {
        ...state,
        data: [
          ...state.data,
          {
            ...action.payload,
            id: state.data.length,
          },
        ],
      };
    }
    case LEAD_MAPPINGS_DELETE: {
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    }
    case LEAD_MAPPINGS_GET_LIST:
    case LEAD_MAPPINGS_UPDATE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case LEAD_MAPPINGS_SET_DEFAULT_FILTERS_PARAMS: {
      return {
        ...state,
        filterValues: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
