import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';

export const GET_CUSTOMER_GROUPS_LIST = 'GET_CUSTOMER_GROUPS_LIST';
export const getGroupsList = () => (dispatch, getState) => {
  axiosInstance
    .get(`/xassales/v1/CustomerGroupsListService`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let result =
        response.data &&
        response.data.map((item) => ({
          label: item.Name,
          value: item.Id,
        }));
      dispatch({ type: GET_CUSTOMER_GROUPS_LIST, payload: result });
    })
    .catch((err) => {
      console.log(err);
    });
};