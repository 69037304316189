import { queryParamsBuilder } from '~/helpers/filter';
import { axiosInstanceSalesAPI } from '~/utils/axios';

const url = 'vehicles';

export const getVehicles = async (params = {}) =>
  axiosInstanceSalesAPI
    .get('VehiclesListService', {
      params: {
        ...queryParamsBuilder(params),
        pagingResponse: true,
      },
    })
    .then(({ data }) => data);

export const createVehicle = (data) =>
  axiosInstanceSalesAPI.post(url, data).then(({ data }) => data);

export const updateVehicle = (id, data) =>
  axiosInstanceSalesAPI.patch(`${url}/${id}`, data).then(({ data }) => data);
