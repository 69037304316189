export const SET_LOADING = 'SET_LOADING';
export const SET_TRADE_IN = 'SET_TRADE_IN';
export const SET_LEAD_DICTIONARIES = 'SET_LEAD_DICTIONARIES';
export const SET_DICTIONARY = 'SET_DICTIONARY';

const initialState = {
  loading: {
    tradeIn: false,
    lead: false,
    customerGroups: false,
    creditCards: false,
    countries: false,
    houseBankAccounts: false,
    banks: false,
    creditCardPaymentTypes: false,
    devices: false,
    checklistTypes: false,
    saleTypes: false,
    locations: false,
    companies: false,
    companyBranches: false,
    unqualifiedReasons: false,
    closeReasons: false,
    sources: false,
    subSources: false,
    customerInterests: false,
    vehicleColors: false,
    branches: false,
  },
  tradeIn: null,
  lead: {
    statuses: [],
    stages: [],
    unqualifiedReasons: [],
    contactMethods: [],
  },
  customerGroups: [],
  creditCards: [],
  countries: [],
  cities: [],
  houseBankAccounts: [],
  banks: [],
  creditCardPaymentTypes: [],
  devices: [],
  checklistTypes: [],
  saleTypes: [],
  locations: [],
  companies: [],
  companyBranches: [],
  unqualifiedReasons: [],
  closeReasons: [],
  campaigns: [],
  sources: [],
  subSources: [],
  customerInterests: [],
  vehicleColors: [],
  users: [],
  activityStatuses: [],
  branches: [],
};

export default function (state = initialState, action) {
  const { type: actionType, payload } = action;

  switch (actionType) {
    case SET_LOADING: {
      const { dictionary, status } = payload;

      return {
        ...state,
        loading: {
          ...state.loading,
          [dictionary]: status,
        },
      };
    }
    case SET_TRADE_IN: {
      return {
        ...state,
        tradeIn: {
          ...payload,
        },
        loading: {
          ...state.loading,
          tradeIn: false,
        },
      };
    }
    case SET_LEAD_DICTIONARIES: {
      return {
        ...state,
        lead: {
          ...payload,
        },
        loading: {
          ...state.loading,
          lead: false,
        },
      };
    }
    case SET_DICTIONARY: {
      const { dictionary, data } = payload;

      return {
        ...state,
        [dictionary]: [...data],
        loading: {
          ...state.loading,
          [dictionary]: false,
        },
      };
    }
    default: {
      return state;
    }
  }
}
