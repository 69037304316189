import React, { Suspense } from 'react';
import { GlobalStyle } from './indexStyled';
import { RTLGlobalStyle } from './rtlStyled';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { DragDropContextProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch';
import { Router, Switch, Route } from 'react-router-dom';
import Spinner from 'react-spinner';
import history from './history';
import configureStore from './store';
import themes from './theme.js';
import config from './config.js';
import PrivateRoute from './privateRoute';

const Login = React.lazy(() => import('./pages/login'));
const Dashboard = React.lazy(() => import('./pages/dashboard'));
const Customers = React.lazy(() => import('./pages/customers'));
const MyOpportunities = React.lazy(() => import('./pages/opportunities/grid'));
const MyOpportunitiesTable = React.lazy(() => import('./pages/opportunities/list'));
const CustomerPage = React.lazy(() => import('./pages/customer'));
const About = React.lazy(() => import('./pages/about'));
// const VehiclesPage = React.lazy(() => import('./pages/vehicles'));
const VehiclePage = React.lazy(() => import('./pages/vehicle'));
const FiltersPage = React.lazy(() => import('./pages/filters'));
const AttachmentPage = React.lazy(() => import('./pages/attachment'));
const VehicleSearchPage = React.lazy(() => import('./pages/vehicle_search'));
const OpportunityCustomersPage = React.lazy(() => import('./pages/opportunity_customers'));
const OpportunityNewPage = React.lazy(() => import('./pages/opportunity_new'));
const OpportunitySalesQuotePage = React.lazy(() => import('./pages/opportunity_sales_quote'));
const OpportunityOrderPage = React.lazy(() => import('./pages/opportunity_order'));
const OpportunityDeliveryPage = React.lazy(() => import('./pages/opportunity_delivery'));
const ChecklistPage = React.lazy(() => import('./pages/checklist'));
const ChecklistsPage = React.lazy(() => import('./pages/checklists'));
const ActivitiesPage = React.lazy(() => import('./pages/activities'));
const ProfilePage = React.lazy(() => import('./pages/profile'));
// const ModelsPage = React.lazy(() => import('./pages/models'));
const ModelPage = React.lazy(() => import('./pages/model'));
const LeadsPage = React.lazy(() => import('./pages/leads'));
const LeadPage = React.lazy(() => import('./pages/lead'));
const LeadCustomersPage = React.lazy(() => import('./pages/lead_customers'));
const CampaignPage = React.lazy(() => import('./pages/campaign'));
const CampaignsPage = React.lazy(() => import('./pages/campaigns'));
const ApprovalRequests = React.lazy(() => import('./pages/approval_requests'));
const PDFPage = React.lazy(() => import('./pages/pdf'));
const LeadsImportPage = React.lazy(() => import('./pages/leads_import'));
const SourcesPage = React.lazy(() => import('./pages/sources'));
const LeadsMappingPage = React.lazy(() => import('./pages/leads_mapping'));
const UnqualifiedReasonsPage = React.lazy(() => import('./pages/unqualified_reasons'));
const CloseReasonsPage = React.lazy(() => import('./pages/close_reasons'));
const CustomerInterestsPage = React.lazy(() => import('./pages/customer_interests'));
const VehicleCategoriesPage = React.lazy(() => import('./pages/vehicle_categories'));
const VehicleCategoryPage = React.lazy(() => import('./pages/vehicle_category'));
const ActivityStatusesPage = React.lazy(() => import('./pages/activity_statuses'));

export const { store, persistor } = configureStore(history);

export default function App() {
  return (
    <DragDropContextProvider backend={MultiBackend(HTML5toTouch)}>
      <ThemeProvider theme={themes[config.theme]}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <Suspense fallback={<Spinner />}>
                <Switch>
                  <PrivateRoute exact path="/" component={Dashboard} />
                  <Route exact path="/login/:id" component={Login} />
                  <Route exact path="/login" component={Login} />
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute exact path="/leads" component={LeadsPage} />
                  <PrivateRoute exact path="/lead/:id" component={LeadPage} />
                  <PrivateRoute exact path="/lead/:id/customers" component={LeadCustomersPage} />
                  <PrivateRoute exact path="/lead/:id/attachments" component={AttachmentPage} />
                  <PrivateRoute exact path="/campaigns" component={CampaignsPage} />
                  <PrivateRoute exact path="/sources" component={SourcesPage} />
                  <PrivateRoute exact path="/my_opportunities" component={MyOpportunities} />
                  <PrivateRoute
                    exact
                    path="/my_opportunities_list"
                    component={MyOpportunitiesTable}
                  />
                  <PrivateRoute exact path="/customers" component={Customers} />
                  <PrivateRoute exact path="/customer/:id" component={CustomerPage} />
                  <PrivateRoute exact path="/about" component={About} />
                  {/* <PrivateRoute exact path="/models" component={ModelsPage} /> */}
                  <PrivateRoute exact path="/model/:id" component={ModelPage} />
                  {/* <PrivateRoute exact path="/vehicles" component={VehiclesPage} /> */}
                  <PrivateRoute exact path="/vehicles/:id" component={VehiclePage} />
                  <PrivateRoute exact path="/vehicles_search" component={VehicleSearchPage} />
                  <PrivateRoute
                    exact
                    path="/vehicles_search/:id/:type"
                    component={VehicleSearchPage}
                  />
                  <PrivateRoute exact path="/filters" component={FiltersPage} />
                  <PrivateRoute exact path="/activities" component={ActivitiesPage} />
                  <PrivateRoute exact path="/new_activities" component={ActivitiesPage} />
                  <PrivateRoute exact path="/opportunity/:id" component={OpportunityNewPage} />
                  <PrivateRoute
                    exact
                    path="/opportunity/:id/sales_quote/:quoteId"
                    component={OpportunitySalesQuotePage}
                  />
                  <PrivateRoute
                    exact
                    path="/opportunity/:id/order/:orderId"
                    component={OpportunityOrderPage}
                  />
                  <PrivateRoute
                    exact
                    path="/opportunity/:id/delivery/:deliveryId"
                    component={OpportunityDeliveryPage}
                  />
                  <PrivateRoute
                    exact
                    path="/opportunity/:id/sales_quote/:quoteId/:duplicate"
                    component={OpportunitySalesQuotePage}
                  />
                  <PrivateRoute
                    exact
                    path="/opportunity/:id/attachments"
                    component={AttachmentPage}
                  />
                  <PrivateRoute
                    exact
                    path="/opportunity/:id/customers"
                    component={OpportunityCustomersPage}
                  />
                  <PrivateRoute
                    exact
                    path="/opportunity/:id/checklists"
                    component={ChecklistsPage}
                  />
                  <PrivateRoute
                    exact
                    path="/opportunity/:id/checklist/:typeId"
                    component={ChecklistPage}
                  />
                  <Route exact path="/profile" component={ProfilePage} />
                  <PrivateRoute exact path="/approval_requests" component={ApprovalRequests} />
                  <PrivateRoute exact path="/sign_pdf" component={PDFPage} />
                  <PrivateRoute exact path="/leads_import" component={LeadsImportPage} />
                  <PrivateRoute exact path="/campaign/:id" component={CampaignPage} />
                  <PrivateRoute
                    exact
                    path="/vehicle_categories"
                    component={VehicleCategoriesPage}
                  />
                  <PrivateRoute
                    exact
                    path="/vehicle_categories/:id"
                    component={VehicleCategoryPage}
                  />

                  {/* SETTINGS */}

                  <PrivateRoute exact path="/leads_import_mapping" component={LeadsMappingPage} />
                  <PrivateRoute
                    exact
                    path="/unqualified_reasons"
                    component={UnqualifiedReasonsPage}
                  />
                  <PrivateRoute exact path="/close_reasons" component={CloseReasonsPage} />
                  <PrivateRoute exact path="/activity_close_reasons" component={CloseReasonsPage} />
                  <PrivateRoute
                    exact
                    path="/customer_interests"
                    component={CustomerInterestsPage}
                  />
                  <PrivateRoute exact path="/activity_statuses" component={ActivityStatusesPage} />
                </Switch>
              </Suspense>
            </Router>
          </PersistGate>
        </Provider>
      </ThemeProvider>
      <GlobalStyle theme={themes[config.theme]} />
      <RTLGlobalStyle theme={themes[config.theme]} />
    </DragDropContextProvider>
  );
}
