import { SET_INIT_VALUE, CHANGE_FIELD, SET_ACTION_FIELDS } from '../actions/saveBtn';

const initialState = {
  isDisabled: false,
  updatedAt: null,
  isVisible: false,
  onClick: null,
};

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case SET_INIT_VALUE: {
      return initialState;
    }

    case CHANGE_FIELD: {
      const { field, value } = action;

      const newState = {
        [field]: value,
      };

      if (field === 'isDisabled' && value === true) {
        newState.updatedAt = new Date().toISOString();
      }

      return { ...state, ...newState };
    }

    case SET_ACTION_FIELDS: {
      const { onClick, isVisible, isDisabled } = action;
      return { ...state, onClick, isVisible, isDisabled };
    }

    default: {
      return state;
    }
  }
}
