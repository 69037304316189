import { axiosInstanceSalesAPI } from '~/utils/axios';

const DeliveriesListService = 'DeliveriesListService';
const DeliveriesService = 'DeliveriesService';
const DeliveryServiceWithLines = 'DeliveryService';
const DeliveriesLineService = 'DeliveriesLineService';

export const getDelivery = async (id) => {
  const url = `${DeliveriesService}/${id}`;

  return axiosInstanceSalesAPI.get(`${url}`).then(({ data }) => data);
};

export const getDeliveriesList = async (linkedObjectCode, linkedObjectName) => {
  const filter = [
    `linkedObjectCode:=:${linkedObjectCode}`,
    `linkedObjectName:=:${linkedObjectName}`,
  ];

  return axiosInstanceSalesAPI
    .get(`${DeliveriesListService}`, {
      params: {
        filter,
      },
    })
    .then(({ data }) => data);
};

export const createDeliveryFromOrder = async (orderId, orderLineIds) => {
  return axiosInstanceSalesAPI
    .post(`${`${DeliveriesService}/fromOrder`}`, {
      OrderId: orderId,
      OrderLineIds: orderLineIds,
    })
    .then((response) => {
      return response.data._id;
    });
};

export const updateDeliveryWithLines = async (id, data) => {
  const url = `${DeliveryServiceWithLines}/${id}`;

  return axiosInstanceSalesAPI.patch(url, data).then((response) => {
    return response.data._id;
  });
};

export const deleteDeliveryLine = async (lineId) => {
  const url = `${DeliveriesLineService}/${lineId}`;

  return axiosInstanceSalesAPI.delete(url).then((response) => {
    return response.data._id;
  });
};

export const getDeliveryLine = async (lineId) => {
  const url = `${DeliveriesLineService}/${lineId}`;

  return axiosInstanceSalesAPI.get(url).then(({ data }) => data);
};
