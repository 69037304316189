import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';
import leadsData from '../data/leads.json';
import { getCompanyIdFromCookies } from '../helpers/cookies';
import { isObjectsEqual } from '~/utils/general';
import { combineDateTime } from '~/utils/dateTime';
import { createLead, createLeadByImport, getLeads } from './api/leads';
import { getSavedSearches, createSavedSearch } from './api/savedSearches';
import { getCurrentUserId, getUserType } from './header';
import initialState from '../data/leads';
import { formatLeadForLeadsList } from '~/helpers/dataAdapters/lead';
import { getUsers } from './api/users';
import { CustomerInterestLevel } from '~/constants/customerInterests';
import { FilterOperatorV2, SortOperator } from '~/constants/filter';
import isEmpty from 'lodash.isempty';

const formatLead = (lead) => ({
  value: lead.Id,
  label: lead.LeadExtId,
  externalRef: lead.LeadExtId,
  type: 'Lead',
  customerId: lead.CustomerCode.Rid,
});

export const LEADS_LIST_TOGGLE_LOADER = 'LEADS_LIST_TOGGLE_LOADER';
export const setIsLoading = (isLoading) => (dispatch) => {
  dispatch({ type: LEADS_LIST_TOGGLE_LOADER, payload: isLoading });
};

export const LEADS_LIST_CHANGE_FILTERS = 'LEADS_LIST_CHANGE_FILTERS';
export const leadsListChangeFilters = (filter) => (dispatch) => {
  dispatch({ type: LEADS_LIST_CHANGE_FILTERS, filter });

  dispatch(disabledNewFilterAction(null));
};

export const LEADS_LIST_CHANGE_MODAL_FIELD = 'LEADS_LIST_CHANGE_MODAL_FIELD';
export const leadsListChangeModalField = (key, value) => (dispatch) => {
  dispatch({ type: LEADS_LIST_CHANGE_MODAL_FIELD, key, value });
};

export const LEADS_LIST_CHANGE_LEAD_MODAL_FIELD = 'LEADS_LIST_CHANGE_LEAD_MODAL_FIELD';
export const leadsListChangeLeadModalField = (key, value) => (dispatch) => {
  dispatch({ type: LEADS_LIST_CHANGE_LEAD_MODAL_FIELD, key, value });
};

export const LEADS_LIST_CLEAR_LEAD_MODAL_FIELDS = 'LEADS_LIST_CLEAR_LEAD_MODAL_FIELDS';
export const leadsListClearLeadModalFields = () => (dispatch) => {
  const leadDataParams = JSON.parse(JSON.stringify(initialState));
  const payload = leadDataParams.leadModal;

  dispatch({ type: LEADS_LIST_CLEAR_LEAD_MODAL_FIELDS, payload });
};

export const LEADS_LIST_CHANGE_MODAL = 'LEADS_LIST_CHANGE_MODAL';
export const leadsListChangeModal = (filter) => (dispatch) => {
  dispatch({ type: LEADS_LIST_CHANGE_MODAL, filter });
};

export const CHANGE_PAGING = 'CHANGE_PAGING';
export const onChangePage = (page) => (dispatch, getState) => {
  const { paging } = getState().leads;

  const pagingFrom = paging.pageSize * page;
  const pagingTo = paging.pageSize;

  const payload = {
    currentPage: page,
    pagingTo,
    pagingFrom,
  };

  dispatch({ type: CHANGE_PAGING, payload });
  dispatch(getData());
};

export const GET_FILTER_NAMES = 'GET_FILTER_NAMES';
export const getFilterNamesAction = (type) => (dispatch, getState) => {
  const { data = [] } = getState().filters;
  const payload = data
    .filter((item) => item.type === type)
    .map((item) => ({ value: item.id, label: item.name }));

  dispatch({ type: GET_FILTER_NAMES, payload });
};

export const SET_VALUE_FOR_FILTER = 'SET_VALUE_FOR_FILTER';
export const setValueForFilter = (count) => (dispatch, getState) => {
  const { data } = getState().filters;
  let payload = null;

  if (count) {
    payload = data.find((item) => item.id === count || item.name === count).filterValues;
  }

  if (!payload) {
    payload = leadsData.filterValues;
  }

  dispatch({ type: LEADS_SET_DEFAULT_FILTERS_PARAMS, payload });
};

export const LEADS_SET_DEFAULT_FILTERS_PARAMS = 'LEADS_SET_DEFAULT_FILTERS_PARAMS';
export const setDefaultFiltersParamsAction = (options) => (dispatch) => {
  const payload = options.data.content;
  dispatch({ type: LEADS_SET_DEFAULT_FILTERS_PARAMS, payload });
};

export const onChangeRowsPerPage = (count) => (dispatch, getState) => {
  const { paging, data } = getState().leads;
  const isLength = data.length;

  let pagingFrom = count * paging.currentPage;
  let pagingTo = count;

  if (pagingFrom > isLength) {
    pagingFrom = 0;
  }

  if (pagingTo > isLength) {
    pagingTo = isLength;
  }

  const payload = {
    pageSize: count,
    pagingTo,
    pagingFrom,
  };

  dispatch({ type: CHANGE_PAGING, payload });
  dispatch(getData());
};

export const LEADS_LIST_GET_FILTER_COUNTER = 'LEADS_LIST_GET_FILTER_COUNTER';
export const leadsListGetFilterCounter = (filterValues) => (dispatch, getState) => {
  return getState().leads.data.length;
};

export const LEADS_LIST_SET_FILTER_PARAMS = 'LEADS_LIST_SET_FILTER_PARAMS';
export const leadsListSetFilterParams = (item) => (dispatch, getState) => {
  const { filterValues } = getState().leads;
  const payload = { ...filterValues, ...item };

  dispatch({ type: LEADS_LIST_SET_FILTER_PARAMS, payload });
};

export const LEADS_LIST_RESET_FILTER_PARAMS = 'LEADS_LIST_RESET_FILTER_PARAMS';
export const leadsListResetFilterParams = () => (dispatch) => {
  dispatch({ type: LEADS_LIST_RESET_FILTER_PARAMS, payload: null });
};

export const LEADS_DISABLED_NEW_FILTER = 'LEADS_DISABLED_NEW_FILTER';
export const disabledNewFilterAction = (key) => (dispatch, getState) => {
  const { data } = getState().filters;
  const { filterValues } = getState().leads;

  if (key) {
    const item = data.find((item) => item.id === key);
    const payload = isObjectsEqual(filterValues, item.filterValues);
    dispatch({ type: LEADS_DISABLED_NEW_FILTER, payload });
  } else {
    const isDisabledFilter = !Object.keys(filterValues).some((key) =>
      Boolean(filterValues[key].value?.length),
    );

    dispatch({ type: LEADS_DISABLED_NEW_FILTER, payload: isDisabledFilter });
  }
};

export const changeLeadModalVisible = (value) => (dispatch) => {
  dispatch(leadsListChangeLeadModalField('show', value));
};

export const LEAD_CREATE_NEW = 'LEAD_CREATE_NEW';
export const createNewLead = (props) => async (dispatch, getState) => {
  const userType = dispatch(getUserType());
  const userId = getCurrentUserId();

  const agentsInfo = {
    SalesAgent: userType.isSalesAgent ? userId : '',
    SalesManager: userType.isSalesManager ? userId : '',
  };

  const { data: leads, totalLeads } = getState().leads;

  const source = {
    Rid: props.campaign,
    CampaignCode: props.campaignCode,
    ChannelId: props.channelId,
    SubSourceId: props.subSourceId,
    Link: props.link,
    Main: true,
    AdId: props.adId,
    AdName: props.adName,
    Population: props.population,
    Url: props.url,
  };

  const data = {
    LeadName: props.leadName,
    LeadStatus: 'Open',
    Source: source,
    Sources: [source],
    LeadEmail: props.customerEmail,
    LeadPhone: props.phoneNumber,
    LeadLocation: props.location ? props.location : null,
    LeadCode: props.leadCode,
    LeadExtId: props.leadExtId,
    CreatedAt: combineDateTime(props.creationDate, props.creationTime),
    EstimatedAmount: props.estimatedAmount || 0,
    LeadRating: props.leadRating,
    LeadComment: props.leadComment,
    ...agentsInfo,
  };

  if (props.customerInterest) {
    data.CustomerInterests = [
      {
        CustomerInterest: props.customerInterest,
        Level: CustomerInterestLevel.Medium,
      },
    ];
  }

  try {
    const lead = await createLead(data);

    if (props.interest && lead.Id) {
      const copyInterests = {
        ...props.interest,
      };
      delete copyInterests.Id;
      createSavedSearch({
        ...copyInterests,
        ObjectModelId: lead.Id,
        ObjectModel: 'Lead',
      });
    }
    dispatch({
      type: LEAD_CREATE_NEW,
      payload: {
        list: [formatLeadForLeadsList(lead), ...leads],
        totalLeads: totalLeads + 1,
      },
    });

    return lead;
  } catch (error) {
    dispatch({ type: LEAD_CREATE_NEW, payload: { list: leads, totalLeads } });
    console.error(error);
  }
};

export const changeLeadField = (id, data) => (dispatch, getState) => {
  const { data: leads, totalLeads } = getState().leads;

  const list = leads.map((item) => {
    if (item.id === id) {
      item[data.name] = data.value;
    }
    return item;
  });

  dispatch({ type: LEAD_CREATE_NEW, payload: { list, totalLeads } });
};

export const LEAD_CREATE_NEW_ACTIVITY = 'LEAD_CREATE_NEW_ACTIVITY';

export const addActivity = (leadNumber, activity) => (dispatch, getState) => {
  const { data, totalLeads } = getState().leads;

  const list = data.map((item) => {
    if (item.leadNumber === leadNumber) {
      activity = { ...activity, id: new Date().getTime() };
      return { ...item, activities: [...item.activities, activity] };
    }
    return item;
  });

  dispatch({ type: LEAD_CREATE_NEW_ACTIVITY, payload: { list, totalLeads } });
};

export const LEAD_CREATE_NEW_COMMENT = 'LEAD_CREATE_NEW_COMMENT';
export const createNewLeadComment = (props) => (dispatch, getState) => {
  let { data: list, totalLeads } = getState().leads;

  const { id, comment } = props;

  list.forEach((opp, i) => {
    if (opp.leadNumber === id) {
      list[i].comments.push({ ...comment, id: `${list[i].comments.length}` });
    }
  });

  dispatch({ type: LEAD_CREATE_NEW_COMMENT, payload: { list, totalLeads } });
};

export const LEAD_UPDATE_COMMENT = 'LEAD_UPDATE_COMMENT';
export const updateOpportunityComment = (props) => (dispatch, getState) => {
  let { data: list, totalLeads } = getState().leads;

  const { id, comment } = props;

  list.forEach((opp, i) => {
    if (opp.opportunityNumber === id) {
      list[i].comments.forEach((act, j) => {
        if (act.id === comment.id) {
          list[i].comments[j] = {
            ...list[i].comments[j],
            ...comment,
          };
        }
      });
    }
  });

  dispatch({ type: LEAD_UPDATE_COMMENT, payload: { list, totalLeads } });
};

export const getLeadsByCustomer = async (customerId) => {
  try {
    const filter = {
      'customerCode.rid': {
        value: customerId,
      },
    };

    const leads = await getLeads(filter);

    return (leads || []).map(formatLead);
  } catch (error) {
    console.error(error);
  }
};

// TODO:
export const LEADS_GET_DATA = 'LEADS_GET_DATA';
export const getData =
  (ids = []) =>
  async (dispatch, getState) => {
    const { filterValues, paging, sort = {} } = getState().leads;
    const filter = filterValues;

    dispatch(setIsLoading(true));
    if (ids.length !== 0) {
      filter['_id'] = ids;
    }

    const params = {
      paging: {
        page: paging.currentPage + 1,
        count: paging.pageSize,
      },
      filter: {
        ...filter,
        leadStatus: {
          params: FilterOperatorV2.not,
          value: 'Closed',
        },
      },
      sort: isEmpty(sort) ? { createdAt: SortOperator.desc } : sort,
    };

    try {
      const { records = [], total = 0 } = await getLeads(params);
      const payload = records.map((lead) => formatLeadForLeadsList(lead));
      dispatch({ type: LEADS_GET_DATA, payload: { list: payload, total } });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const LEADS_GET_DICTS = 'LEADS_GET_DICTS';
export const getDicts = () => async (dispatch, getState) => {
  try {
    const {
      employees: { salesAgents },
    } = getState();
    const companyId = getCompanyIdFromCookies();

    const usersData = await getUsers();
    const users = usersData.map((item) => ({
      label: `${item.FirstName} ${item.LastName}`,
      value: item.Id,
      companyRelation: item.SalesCompanies.find((sc) => sc.Company === companyId),
    }));

    const agents = users.filter(
      (user) => !!salesAgents.find((sa) => sa.value === user.companyRelation?.EmployeeId),
    );

    dispatch({ type: LEADS_GET_DICTS, payload: { agents } });
  } catch (error) {
    console.log(error);
  }

  //statuses
  dispatch({
    type: LEADS_GET_DICTS,
    payload: {
      statuses: [
        { label: 'Open', value: 'Open' },
        { label: 'Copied to Opportunity', value: 'Copied to Opportunity' },
        { label: 'Closed', value: 'Closed' },
      ],
    },
  });

  //campaigns
  const pageSize = `?pageSize=10000`;
  axiosInstance
    .get(`/xassales/v1/CampaignsListService${pageSize}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((res) => {
      const campaigns = res.data.map((item) => ({
        enabled: item.Enabled,
        label: item.Name,
        value: item.Id,
        channels: item.Channels?.map((c) => ({
          label: c.ChannelId?.Name,
          value: c.ChannelId?.Id,
          link: c.Link,
        })),
      }));
      dispatch({
        type: LEADS_GET_DICTS,
        payload: {
          campaigns,
        },
      });
    })
    .catch((err) => console.log(err));

  const interests = await getSavedSearches({ ObjectModelId: null });
  dispatch({
    type: LEADS_GET_DICTS,
    payload: {
      interests: interests.map((item) => ({ label: item.Name, value: item.Id })),
    },
  });
};

export const createNewLeadByImport = (leadForImport) => async (dispatch, getState) => {
  try {
    return await createLeadByImport(leadForImport);
  } catch (error) {
    throw new Error({
      name: 'ImportError',
      message:
        error?.response?.data?.validationMessage ||
        error?.response?.data?.customMessage ||
        error.message,
    });
  }
};

export const LEADS_CHANGE_SORT = 'LEADS_CHANGE_SORT';
export const leadsChangeSort = (payload) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEADS_CHANGE_SORT, payload });
  } catch (error) {
    console.error(error);
  }
};

export const updateCampaignDict = (payload) => (dispatch, getState) => {
  try {
    const {
      dictionaries: { campaigns },
    } = getState().leads;

    campaigns.map((campaign) => {
      if (payload.id === campaign.value) {
        if (payload.hasOwnProperty('campaignName')) {
          campaign.label = payload.campaignName;
        }

        if (payload.hasOwnProperty('status')) {
          campaign.enabled = payload.status;
        }
      }
      return campaign;
    });

    let payloadCampaigns = {
      campaigns,
    };

    dispatch({ type: LEADS_GET_DICTS, payloadCampaigns });
  } catch (error) {
    console.error(error);
  }
};
