import data from '../data/opportunity_content';
import {
  CHANGE_DATA_CARS,
  OPPORTUNITY_UPDATE_CONTENT_DATA,
  OPPORTUNITY_CONTENT_TOGGLE_SYNC_LOADER,
  GET_ACCESSORIES_LIST,
  OPPORTUNITY_UPDATE_QUOTES,
  OPPORTUNITY_CHANGE_SELECT_QUOTE,
  CHANGE_REMOVE_CARS,
  SET_CREATE_QUOTE_BTN_PARAMS,
  CHANGE_STATE_CONTENT,
  CLEAR_CONTENT_DATA,
  OPPORTUNITY_CONTENT_SET_CURRENT_ORDER,
  OPPORTUNITY_CONTENT_SET_CURRENT_DELIVERY,
  SET_REFRESH_DATA_FUNCTION,
  SET_APPROVAL_REQUESTS_FOR_CURRENT_DOCUMENT,
  UPDATE_CONTENT_TAB_STATE,
  SET_VS_DOCUMENT_DETAILS,
} from '../actions/opportunity_content';

const initialState = data;

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_DATA_CARS:
      return {
        ...state,
        cars: action.payload,
      };

    case CHANGE_STATE_CONTENT:
      return { ...state, ...action.payload };
    case CHANGE_REMOVE_CARS:
      return { ...state, removeCar: [...state.removeCar, action.payload] };

    case OPPORTUNITY_UPDATE_CONTENT_DATA:
      return {
        ...action.payload,
        quoteCreateButtonParams: state.quoteCreateButtonParams,
        tabState: state.tabState,
      };

    case OPPORTUNITY_CHANGE_SELECT_QUOTE:
      return { ...state, selectQuote: action.payload };

    case OPPORTUNITY_CONTENT_SET_CURRENT_ORDER:
      return { ...state, currentOrder: action.payload };

    case OPPORTUNITY_CONTENT_SET_CURRENT_DELIVERY:
      return { ...state, currentDelivery: action.payload };

    case OPPORTUNITY_UPDATE_QUOTES:
      return { ...state, quotes: action.payload };

    case OPPORTUNITY_CONTENT_TOGGLE_SYNC_LOADER:
      return { ...state, loaders: { ...state.loaders, [action.field]: action.value } };

    case GET_ACCESSORIES_LIST:
      return {
        ...state,
        accessories: action.accessory,
        configuration_list: action.option,
        service_list: action.service,
      };

    case SET_CREATE_QUOTE_BTN_PARAMS:
      return {
        ...state,
        quoteCreateButtonParams: {
          isVisible: action.isVisible,
          onClick: action.onClick,
          isDisabled: action.isDisabled,
        },
      };

    case SET_REFRESH_DATA_FUNCTION:
      return { ...state, refreshDataFunction: action.payload };

    case SET_APPROVAL_REQUESTS_FOR_CURRENT_DOCUMENT:
      return { ...state, documentApprovalRequests: action.payload };

    case CLEAR_CONTENT_DATA:
      return initialState;

    case SET_VS_DOCUMENT_DETAILS:
      return { ...state, VSDocumentDetails: action.payload };

    case UPDATE_CONTENT_TAB_STATE: {
      return { ...state, tabState: { ...state.tabState, ...action.payload } };
    }

    default:
      return state;
  }
}
