import { GET_DELIVERY } from '../actions/opportunity_delivery';

const initialState = {
  delivery: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DELIVERY: {
      return {
        ...state,
        delivery: payload,
      };
    }

    default:
      return state;
  }
}
