import axiosInstance from '~/utils/axios';
import { getSalesAPIURL } from '.';
import { PageSizeAll } from '~/constants';

const listUrl = 'SaleTypesListService';

export const getSaleTypes = async () =>
  axiosInstance
    .get(getSalesAPIURL(listUrl), {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then(({ data }) => data);
