import { axiosInstanceSalesAPI } from '~/utils/axios';
import { PageSizeAll } from '~/constants';

const listUrl = 'CountriesListService';

export const getCountries = () =>
  axiosInstanceSalesAPI
    .get(listUrl, {
      params: {
        pageSize: PageSizeAll,
        sort: 'name:1',
      },
    })
    .then(({ data }) => data);
