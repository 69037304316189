export const spaceChar = ' ';
export const emptyString = '';
export const commaChar = ',';
export const slashChar = '/';
export const hyphenChar = '-';
export const ellipsisChar = '...';

export const month = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
];

export const owner = [
  { label: 'Lease', value: 'Lease' },
  { label: 'Private', value: 'Private' },
];

export const ApprovalStatus = {
  Approved: 'Approved',
  Rejected: 'Rejected',
};

export const DocumentType = {
  SalesQuote: 'SalesQuote',
  Order: 'Order',
};

/* SAP constants */

export const LineTypeTradeIn = 'Trade-in';
export const LineTypeNew = 'New';
export const LineTypeUsed = 'Used';
export const LineTypeVin = 'Vin';
export const LineTypePart = 'Part';
export const LineTypeAccessory = 'Accessory';
export const LineTypeService = 'Service';

export const LineTypes = {
  TradeIn: LineTypeTradeIn,
  New: LineTypeNew,
  Used: LineTypeUsed,
  Vin: LineTypeVin,
  Parts: LineTypePart,
  Accessory: LineTypeAccessory,
  Service: LineTypeService,
};

export const ApprovalRequest = 'ApprovalRequest';
export const Opportunity = 'Opportunity';
export const Lead = 'Lead';
export const ActivityReminder = 'ActivityReminder';

export const tYES = 'tYES';
export const tNO = 'tNO';

export const PageSizeForRequest = 10000;
export const PageSizeAll = 'all';

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.mmmZ';

export const DEFAULT_CURRENCY = 'ILS';
