import cloneDeep from 'lodash.clonedeep';
import { updateOrderWithLines, deleteOrderLine, createOrderWithLines } from './api/orders';
import { updateDescriptionOpportunity } from './opportunity_content';
import { LineTypeTradeIn } from '~/constants';
import { syncVehicle } from './api/sync';

export const updateCurrentOrderWithLines = (updatesForOrder, linesForUpdate) => async (
  _dispatch,
  getState,
) => {
  const { currentOrder, removeCar } = getState().opportunity.content;
  const dataForUpdate = {
    ...updatesForOrder,
    Lines: linesForUpdate,
  };

  const promises = removeCar.map((item) => deleteOrderLine(item.lineId));
  await Promise.all(promises);

  const tradeIn = linesForUpdate.filter(({ LineType, Id }) => LineType === LineTypeTradeIn && !Id);
  const syncPromises = tradeIn.map(({ VehicleId }) => syncVehicle(VehicleId));
  await Promise.all(syncPromises);

  await updateOrderWithLines(currentOrder.Id, dataForUpdate);
};

export const createOrderFromCurrentQuote = (isDraft = false) => async (dispatch, getState) => {
  try {
    const {
      selectQuote,
      cars,
      VSDocumentDetails,
    } = getState().opportunity.content;

    const date = new Date();
    const postingDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    let order = {
      ...VSDocumentDetails,
      PostingDate: postingDate,
      CustomerCode: selectQuote.CustomerCode.Rid,
      LinkedObjectCode: selectQuote.LinkedObjectCode,
      LinkedObjectName: selectQuote.LinkedObjectName,
      DocumentStatus: 'Open',
      IsDraft: isDraft,
      IsPrimary: !isDraft,
      Total: selectQuote.Total,
      TotalTradeIn: selectQuote.TotalTradeIn,
      TaxSum: selectQuote.TaxSum,
      TaxPercentage: selectQuote.TaxPercentage,
      DiscountSum: selectQuote.DiscountSum,
      DiscountPercentage: selectQuote.DiscountPercentage,
      TotalBeforeDiscount: selectQuote.TotalBeforeDiscount,
      ApprovalStatus: isDraft ? 'Pending' : 'Approved',
      BaseQuoteId: selectQuote.Id,
      QuoteExternalRef: selectQuote.ExternalRef,
      Lines: [],
    };

    const lines = selectQuote.Lines;

    let carLines = [];
    let carItemsLines = [];

    lines.forEach((line) => {
      if (line.ParentLineId) {
        carItemsLines.push(line);
      } else {
        carLines.push(line);
      }
    });

    const tradeIn = carLines.filter(({ LineType }) => LineType === LineTypeTradeIn);
    const syncPromises = tradeIn.map(({ VehicleId }) => syncVehicle(VehicleId.Id));
    await Promise.all(syncPromises);

    for (let lineKey in carLines) {
      let line = carLines[lineKey];
      let orderLine = cloneDeep(line);

      orderLine.QuoteRowExternalRef = orderLine.RowExternalRef;

      if (orderLine.ModelVariantId) {
        orderLine.ModelVariantId = orderLine.ModelVariantId.Id;
      }
      if (orderLine.VehicleId) {
        orderLine.VehicleId = orderLine.VehicleId.Id;
      }

      delete orderLine.Id;
      delete orderLine.CreatedAt;
      delete orderLine.Notes;
      delete orderLine.DataFilter;
      delete orderLine.QuoteId;
      delete orderLine.RowExternalRef;

      orderLine.BaseQuoteId = selectQuote.Id;
      orderLine.BaseQuoteLineId = line.Id;
      orderLine.RelatedLines = [];

      carItemsLines
        .filter((itemLine) => line.Id === itemLine.ParentLineId)
        .forEach((line) => {
          let orderItemLine = cloneDeep(line);

          orderItemLine.QuoteRowExternalRef = orderItemLine.RowExternalRef;

          delete orderItemLine.Id;
          delete orderItemLine.CreatedAt;
          delete orderItemLine.Notes;
          delete orderItemLine.DataFilter;
          delete orderItemLine.QuoteId;
          delete orderItemLine.RowExternalRef;
          delete orderItemLine.ParentLineId;

          orderItemLine.ItemId = orderItemLine.ItemId.Id;
          orderItemLine.BaseQuoteId = selectQuote.Id;
          orderItemLine.BaseQuoteLineId = line.Id;

          orderLine.RelatedLines.push(orderItemLine);
        });

      order.Lines.push(orderLine);
    }

    const newOrderId = await createOrderWithLines(order);

    await dispatch(updateDescriptionOpportunity(cars, selectQuote.LinkedObjectCode));

    return Promise.resolve(newOrderId);
  } catch (error) {
    return Promise.reject(error);
  }
};
