import initialState from '../data/models';
import {
  MODELS_SET_DATA,
  MODELS_CHANGE_FILTERS,
  MODELS_SET_ROWS_PER_PAGE,
  CHANGE_VALUE_LOADER,
  CHANGE_SUGGESTIONS,
} from '../actions/models';

export default function (state = initialState, action) {
  switch (action.type) {
    case MODELS_SET_DATA: {
      return { ...state, data: action.payload };
    }
    case CHANGE_VALUE_LOADER: {
      return { ...state, [action.typeLoader]: action.payload };
    }
    case MODELS_CHANGE_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };
    }
    case MODELS_SET_ROWS_PER_PAGE:
      return {
        ...state,
        paging: {
          ...state.paging,
          ...action.payload,
        },
      };

    case CHANGE_SUGGESTIONS:
      return { ...state, suggestionsDic: { ...state.suggestionsDic, ...action.payload } };

    default: {
      return state;
    }
  }
}
