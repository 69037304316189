import { axiosInstanceSalesAPI } from '~/utils/axios';
import { slashChar } from '~/constants';

const DeFactoBaseUrl = 'deFacto';
const DeFactoCreditCardTypes = 'creditCardTypes';

export const getCreditCardPaymentTypes = () => {
  const url = [DeFactoBaseUrl, DeFactoCreditCardTypes].join(slashChar);

  return axiosInstanceSalesAPI.get(url).then(({ data }) => data);
};
