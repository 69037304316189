import { GET_QUOTE } from '../actions/opportunity_sales_quote';

const initialState = {
  quote: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_QUOTE: {
      return {
        ...state,
        quote: action.payload,
      };
    }

    default:
      return state;
  }
}
