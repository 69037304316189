// TODO: simplify

import { TAX_PERCENT_DEFAULT } from '~/constants/prices';
import { calculateAdditionalPrices } from './price';

export const setPrices = (item, prices, docRate = 0) => {
  const {
    Quantity,
    UnitPrice,
    Price,
    LineTotal,
    TaxPercentagePerRow,
    TaxTotal,
    DiscountPercent,
    Currency,
    Rate,
  } = prices;

  const totalBeforeDiscount = Quantity * UnitPrice;

  const discountTotal = totalBeforeDiscount * (DiscountPercent / 100);
  const totalBeforeTax = totalBeforeDiscount - discountTotal;

  item.quantity = Quantity;
  item.price = Quantity * UnitPrice;
  item.unitPrice = UnitPrice;
  item.priceAfterDiscount = Price;
  item.discountPercent = DiscountPercent;
  item.totalBeforeTax = totalBeforeTax;
  item.total = LineTotal;
  item.taxPercent = TaxPercentagePerRow;
  item.tax = TaxTotal;

  item.currency = Currency;
  item.rate = Rate;

  calculateAdditionalPrices(item, Rate, docRate);

  return item;
};

export const setItemPrices = (item, prices, docRate = 0) => {
  const quantity = item.Quantity || item.quantity;
  const {
    Price,
    GrossTotal,
    UnitPrice,
    PriceAfterVAT,
    DiscountPercent,
    TaxPercentagePerRow,
    AccountCode,
    ProjectCode,
    MeasureUnit,
    BarCode,
    SupplierCatNum,
    CostingCode,
    CostingCode2,
    CostingCode3,
    CostingCode4,
    CostingCode5,
    TotalBeforeTax,

    Currency,
    Rate,
  } = prices;

  const totalBeforeTax = TotalBeforeTax || quantity * UnitPrice;

  item.price = Price;
  item.total = GrossTotal;
  item.unitPrice = UnitPrice;
  item.quantity = quantity;
  item.totalBeforeTax = totalBeforeTax;
  item.priceAfterDiscount = Price;
  item.priceIncludingTax = PriceAfterVAT;
  item.discountPercent = DiscountPercent;
  item.taxPercentage = TaxPercentagePerRow;
  item.accountCode = AccountCode;
  item.projectCode = ProjectCode;
  item.measureUnit = MeasureUnit;
  item.barCode = BarCode;
  item.supplierCatNum = SupplierCatNum;
  item.costingCode = CostingCode;
  item.costingCode2 = CostingCode2;
  item.costingCode3 = CostingCode3;
  item.costingCode4 = CostingCode4;
  item.costingCode5 = CostingCode5;

  item.currency = Currency;
  item.rate = Rate;

  calculateAdditionalPrices(item, Rate, docRate);

  return item;
};

export const setCarPricesSearch = (car, prices) => {
  // "prices" can comes as "{}" if no VeItemCode/VehicleId...
  const {
    AvilblToDt,
    AvilblToDtDesc,
    Location,
    Price = 0,
    Available,
    AvilblDesc,
    UnitPrice = 0,
    Quantity,
    PriceAfterVAT = 0,
    DiscountPercent = 0,
    TaxPercentagePerRow = TAX_PERCENT_DEFAULT, // CHECKME:
    AccountCode,
    ProjectCode,
    MeasureUnit,
    UnitsOfMeasurment,
    BarCode,
    SupplierCatNum,
    CostingCode,
    CostingCode2,
    CostingCode3,
    CostingCode4,
    CostingCode5,
    GrossTotal = 0,
    TotalBeforeTax = 0,

    Currency,
    Rate,
  } = prices;

  const totalBeforeTax = TotalBeforeTax || Quantity * UnitPrice;

  car.deliveryDate = AvilblToDt;
  car.deliveryDateDescription = AvilblToDtDesc;
  car.location = Location;
  car.price = Price;
  car.quantity = Available;
  car.quantityDescription = AvilblDesc;
  car.unitPrice = UnitPrice;
  car.priceAfterDiscount = Price;
  car.priceIncludingTax = PriceAfterVAT;
  car.discountPercentage = DiscountPercent;
  car.taxPercentage = TaxPercentagePerRow;
  car.accountCode = AccountCode;
  car.projectCode = ProjectCode;
  car.measureUnit = MeasureUnit;
  car.unitsOfMeasurment = UnitsOfMeasurment;
  car.barCode = BarCode;
  car.supplierCatNum = SupplierCatNum;
  car.costingCode = CostingCode;
  car.costingCode2 = CostingCode2;
  car.costingCode3 = CostingCode3;
  car.costingCode4 = CostingCode4;
  car.costingCode5 = CostingCode5;
  car.discountPercent = DiscountPercent;
  car.total = GrossTotal; // CHECKME: LineTotal
  car.totalBeforeTax = totalBeforeTax;

  car.currency = Currency;
  car.rate = Rate;

  car.VSLineDetails = prices;

  return car;
};
