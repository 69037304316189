import { DocumentType } from '~/constants/document';
import { t } from '~/utils/lang';

const codePlaceholder = '---';

export const formatDelivery = (delivery) => {
  const status = delivery.DocumentStatus;
  const code = delivery.DocumentCode;

  const formatted = {
    id: delivery.Id,
    documentCode: code,
    externalRef: delivery.ExternalRef,
    type: DocumentType.Delivery,
    isDraft: delivery.IsDraft,
    makePrimary: delivery.IsPrimary || false,
    documentName: `${t.t('button_plus.delivery')} ${code || codePlaceholder}`,
    customerName: delivery.CustomerCode?.Name,
    customerEmail: delivery.CustomerCode?.Email,
    createdBy: delivery.CreatedBy?.UserName,
    creationDate: delivery.PostingDate,
    expirationDate: delivery.ValidUntil,
    status: status,
    approvalStatus: delivery.ApprovalStatus,
    notes: delivery.Notes,
    total: delivery.Total,
    lines: delivery.Lines,
  };

  return formatted;
};
