export const CHANGE_FIELD = 'CHANGE_FIELD';
export const SET_INIT_VALUE = 'SET_INIT_VALUE';
export const SET_ACTION_FIELDS = 'SET_ACTION_FIELDS';

export const changeFieldSaveBtn = (field, value) => (dispatch) => {
  dispatch({ type: CHANGE_FIELD, field, value });
};

export const setInitValueForSaveBtn = () => (dispatch) => {
  dispatch({ type: SET_INIT_VALUE });
};

export const setSaveButtonParams = (onClick, isVisible, isDisabled) => (dispatch) => {
  dispatch({ type: SET_ACTION_FIELDS, onClick, isVisible, isDisabled });
};
