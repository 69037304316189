export const initialState = {
  paging: {
    page: 1,
    pageSize: 20,
  },
  filterValues: {
    search: { value: '', params: null },
    name: { value: '', params: null },
    edit: {},
  },
  filterNames: [],
  filterName: { value: '', label: '' },
};

export const SET_PAGING = 'SET_PAGING';
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES';
export const SET_FILTER_NAMES = 'SET_FILTER_NAMES';

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PAGING: {
      return { ...state, paging: { ...state.paging, ...action.payload } };
    }

    case SET_FILTER_VALUES: {
      return {
        ...state,
        filterValues: action.payload,
      };
    }

    case SET_FILTER_NAMES: {
      return {
        ...state,
        filterNames: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
