import axiosInstance from '~/utils/axios';
import { getSalesAPIURL } from '.';
import { adaptTableFiltersForServer } from '~/helpers/filter';

const url = 'TableFilters';

export const getTableFilters = (params) => {
  const { filter, ...otherParams } = params;

  const adaptFilter = adaptTableFiltersForServer(filter);

  return axiosInstance
    .get(getSalesAPIURL(url), {
      params: {
        ...otherParams,
        filter: adaptFilter,
      },
    })
    .then(({ data }) => data);
};

export const createTableFilter = (data) =>
  axiosInstance.post(getSalesAPIURL(url), data).then(({ data }) => data);

export const updateTableFilter = (id, data) =>
  axiosInstance.patch(`${getSalesAPIURL(url)}/${id}`, data).then(({ data }) => data);

export const deleteTableFilter = (id) =>
  axiosInstance.delete(`${getSalesAPIURL(url)}/${id}`).then(() => true);
