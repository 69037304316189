import { lcFirst } from '~/utils/string';

/**
 * @readonly
 * @enum {string}
 */
export const activityTypes = {
  call: 'Conversation',
  task: 'Task',
  meeting: 'Meeting',
  testDrive: 'TestDrive'
};

// ???
export const getUpdatedActivity = (obj, requiredKey, newValue) => {
  const object = { ...obj };
  object[requiredKey] = obj[requiredKey] ? obj[requiredKey] : newValue;
  return object;
};

export const adaptActivity = (activity) => {
  let result = {};

  Object.keys(activity).forEach((key, i) => {
    result[lcFirst(key)] = activity[key]?.Rid ? activity[key].Rid : activity[key];
  });

  if (result.startDateTime) {
    result = {
      ...result,
      startDate: result.startDateTime,
      startTime: result.startDateTime,
    };
  }

  if (result.dueDateTime) {
    result = {
      ...result,
      dueDate: result.dueDateTime,
      dueTime: result.dueDateTime,
    };
  }

  if (result.activityType) {
    result = {
      ...result,
      type: getClientActivityType(result.activityType),
    };
  }

  return result;
};

export const getClientActivityType = (type) => {
  let result = type;
  Object.values(activityTypes).forEach((value, i) => {
    if (value === type) {
      result = Object.keys(activityTypes)[i];
    }
  });
  return result;
};
