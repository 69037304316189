import { OPPORTUNITY_CHANGE_SELECT_QUOTE } from './opportunity_content';
import { createQuotesLine } from './opportunity_content';
import cloneDeep from 'lodash.clonedeep';
import { createQuoteWithLines, getQuote } from './api/quotations';

export const GET_QUOTE = 'GET_QUOTE';
export const getQuoteAction = (id) => async (dispatch) => {
  try {
    const quotation = await getQuote(id);

    dispatch({ type: OPPORTUNITY_CHANGE_SELECT_QUOTE, payload: quotation });
    dispatch({ type: GET_QUOTE, payload: quotation });
    return quotation;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createDuplicateQuoteAction = (data, customerCode) => async (
  dispatch,
  getState,
) => {
  const selectQuote = getState().opportunity?.opportunitySalesQuote?.quote;

  if (!selectQuote) {
    return false;
  }

  if (!customerCode) {
    customerCode = selectQuote.CustomerCode?.Rid;
  }

  const duplicationId = await dispatch(duplicateQuote(selectQuote, customerCode));

  for (let i = 0; i <= data.length - 1; i++) {
    let carItemsLines = [];
    let car = data[i];
    let carLine = selectQuote.Lines.find((line) => line.Id === car.lineId);

    let duplicationCarLine = carLine
      ? {
          data: {
            id: carLine.ModelId || carLine.VehicleId,
            typeLine: carLine.LineType,
            quantity: carLine.Quantity,
            price: carLine.Price,
            priceAfterDiscount: carLine.PriceAfterDiscount,
            priceIncludingTax: carLine.PriceIncludingTax,
            // discount,
            accountCode: carLine.AccountCode,
            projectCode: carLine.ProjectCode,
            measureUnit: carLine.MeasureUnit,
            unitsOfMeasurment: carLine.UnitsOfMeasurment,
            barCode: carLine.BarCode,
            supplierCatNum: carLine.SupplierCatNum,
            costingCode: carLine.CostingCode,
            costingCode2: carLine.CostingCode2,
            costingCode3: carLine.CostingCode3,
            costingCode4: carLine.CostingCode4,
            costingCode5: carLine.CostingCode5,
            total: carLine.Total,
            // warehouseCode,
            discountPercent: carLine.DiscountPercent,
            taxCode: carLine.TaxCode,
            taxTotal: carLine.TaxTotal,
            taxPercent: carLine.TaxPersent,
          },
        }
      : { data: car };
    let duplicationCarLineResult = await dispatch(
      createQuotesLine(duplicationId, duplicationCarLine),
    );
    let duplicationCarLineId = duplicationCarLineResult._id;

    car.configuration.forEach((conf) => {
      let confLine = selectQuote.Lines.find((line) => line.Id === car.lineId);
      carItemsLines.push(
        confLine
          ? {
              parentLineId: duplicationCarLineId,
              data: { id: confLine.ItemId, typeLine: confLine.LineType },
            }
          : { parentLineId: duplicationCarLineId, data: conf },
      );
    });
    car.accessories.forEach((acces) => {
      let accesLine = selectQuote.Lines.find((line) => line.Id === car.lineId);
      carItemsLines.push(
        accesLine
          ? {
              parentLineId: duplicationCarLineId,
              data: { id: accesLine.ItemId, typeLine: accesLine.LineType },
            }
          : { parentLineId: duplicationCarLineId, data: acces },
      );
    });

    const promises = carItemsLines.map((item) => {
      return dispatch(createQuotesLine(duplicationId, item));
    });

    await Promise.all(promises);
  }

  return true;
};

export const duplicateQuote = (quote, customerCode) => async (dispatch) => {
  let duplication = cloneDeep(quote);

  delete duplication.Id;
  delete duplication.CreatedAt;
  delete duplication.CreatedBy;
  delete duplication.Lines;
  delete duplication.DocumentName;
  delete duplication.Notes;
  delete duplication.ExternalRef;
  delete duplication.DocumentCode;

  const date = new Date();
  duplication.PostingDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  duplication.CustomerCode = customerCode;
  duplication.IsPrimary = false;

  try {
    const quotationId = await createQuoteWithLines(duplication);

    return quotationId;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const changeQuoteInformation = (props) => (dispatch, getState) => {
  const { quote } = getState().opportunity.opportunitySalesQuote;
  const payload = { ...quote, [props.key]: props.value };

  dispatch({ type: GET_QUOTE, payload });
};
