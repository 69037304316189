import { GET_OPPORTUNITY_SALE_TYPES_LIST } from '../actions/opportunity_saletypes';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OPPORTUNITY_SALE_TYPES_LIST:
      return action.payload;
    default:
      return state;
  }
}
