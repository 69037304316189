import { GET_EMPLOYEES_LIST } from '../actions/employees';

const initialState = {
  employees: [],
  salesAgents: [],
  salesManagers: [],
  roles: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEES_LIST:
      return action.payload;
    default:
      return state;
  }
}
