const initialState = {};

export const SET_CURRENCIES = 'SET_CURRENCIES';

const currenciesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENCIES:
      return {
        ...payload,
      };
    default:
      return state;
  }
};

export default currenciesReducer;
