import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';
import { PaymentCategoryOtherPayments } from '~/constants/payments';

export const UPDATE_PAYMENTS = 'UPDATE_PAYMENTS';
export const updatePaymentsList = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_PAYMENTS, payload: payload });
};
export const getPayments = (linkedObjectId, linkedObjectName) => (dispatch) => {
  return axiosInstance
    .get(
      `/xassales/v1/PaymentsListService?filter=linkedObjectId:=:${linkedObjectId}&filter=linkedObjectName:=:${linkedObjectName}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    )
    .then((res) => {
      const payload = res.data.sort((item) => {
        return item.Category === PaymentCategoryOtherPayments ? 1 : -1;
      });

      dispatch({ type: UPDATE_PAYMENTS, payload: payload });
      return payload;
    })
    .catch((err) => []);
};

export const CHANGE_TYPE_LOADER_ACTION = 'CHANGE_TYPE_LOADER_ACTION';
export const changeTypePaymentLoader = (payload) => (dispatch) => {
  dispatch({ type: CHANGE_TYPE_LOADER_ACTION, payload });
};

export const GET_DICTIONARIES_PAYMENTS = 'GET_DICTIONARIES_PAYMENTS';
export const setBanksDictionary = (data) => (dispatch) => {
  dispatch({ type: GET_DICTIONARIES_PAYMENTS, payload: { bank: data } });
};

export const loadBanks = (queryParams) =>
  axiosInstance
    .get(`/xassales/v1/BanksListService?pagingResponse=true&${queryParams}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((res) => {
      return {
        data: res.data.records.map((item) => ({
          label: item.Name,
          value: item.Id,
        })),
        total: res.data.total,
      };
    });

export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';
// { status, error }
export const setPaymentStatus = (data) => (dispatch) => {
  dispatch({ type: SET_PAYMENT_STATUS, payload: data });
};
