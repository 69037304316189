/**
 * @readonly
 * @enum {string}
 */
export const FilterOperator = {
  colon: ':',
  equals: ':=:',
  moreThan: ':>:',
  lessThan: ':<:',
  contains: ':contains:',
  or: ':or:',
  between: ':between:',
};

/**
 * @readonly
 * @enum {string}
 */
export const FilterOperatorV2 = {
  equal: '$equal',
  not: '$not',
  in: '$in',
  or: '$or',
  and: '$and',
  between: '$between',
  like: '$like',
  gt: '$gt',
  gte: '$gte',
  lt: '$lt',
  lte: '$lte',
};

/**
 * @readonly
 * @enum {string}
 */
export const SortOperator = {
  asc: 'asc',
  desc: 'desc',
};
