import { getCategory as getCategoryServer } from './api/vehicle_categories';

export const VEHICLE_CATEGORY_UPDATE = 'VEHICLE_CATEGORY_UPDATE';
export const updateCategory = (payload) => (dispatch, getState) => {
  dispatch({ type: VEHICLE_CATEGORY_UPDATE, payload });
};

export const VEHICLE_CATEGORY_GET = 'VEHICLE_CATEGORY_GET';
export const VEHICLE_CATEGORY_LOADING = 'VEHICLE_CATEGORY_LOADING';
export const getCategory = (id) => (dispatch, getState) => {
  dispatch({ type: VEHICLE_CATEGORY_LOADING, payload: true });
  getCategoryServer(id).then((res) => {
    dispatch({ type: VEHICLE_CATEGORY_GET, payload: res });
    dispatch({ type: VEHICLE_CATEGORY_LOADING, payload: false });
  });
};
