import {
  VEHICLE_CATEGORIES_CREATE,
  VEHICLE_CATEGORIES_DELETE,
  VEHICLE_CATEGORIES_UPDATE,
  VEHICLE_CATEGORIES_GET_LIST,
  VEHICLE_CATEGORIES_LOADING,
  CHANGE_VEHICLE_CATEGORIES_FILTER_NAMES,
  VEHICLE_CATEGORIES_CHANGE_FILTERS,
  VEHICLE_CATEGORIES_CHANGE_MODAL_FIELD,
  VEHICLE_CATEGORIES_DISABLE_NEW_FILTER,
  VEHICLE_CATEGORIES_SET_DEFAULT_FILTERS_PARAMS,
  VEHICLE_CATEGORIES_GET_DICTIONARIES,
} from '~/actions/vehicle_categories';
import initialState from '~/data/vehicle_categories.json';

export default function (state = initialState, action) {
  switch (action.type) {
    case VEHICLE_CATEGORIES_DISABLE_NEW_FILTER: {
      return {
        ...state,
        isDisabledNewFilter: action.payload,
      };
    }
    case VEHICLE_CATEGORIES_CHANGE_MODAL_FIELD: {
      return {
        ...state,
        filterModal: {
          ...state.filterModal,
          [action.key]: action.value,
        },
      };
    }
    case VEHICLE_CATEGORIES_CHANGE_FILTERS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.key]: { value: action.value, params: action.params },
        },
      };
    }
    case CHANGE_VEHICLE_CATEGORIES_FILTER_NAMES: {
      return { ...state, filterNames: action.payload };
    }
    case VEHICLE_CATEGORIES_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case VEHICLE_CATEGORIES_CREATE: {
      return {
        ...state,
        data: [
          ...state.data,
          {
            ...action.payload,
            id: state.data.length,
          },
        ],
      };
    }
    case VEHICLE_CATEGORIES_DELETE: {
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    }
    case VEHICLE_CATEGORIES_GET_DICTIONARIES: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          ...action.payload,
        },
      };
    }
    case VEHICLE_CATEGORIES_GET_LIST:
    case VEHICLE_CATEGORIES_UPDATE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case VEHICLE_CATEGORIES_SET_DEFAULT_FILTERS_PARAMS: {
      return {
        ...state,
        filterValues: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
