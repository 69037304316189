import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';

export const GET_OPPORTUNITY_STAGES_LIST = 'GET_OPPORTUNITY_STAGES_LIST';
export const getStagesList = () => async (dispatch) => {
  return axiosInstance
    .get(`/xassales/v1/OpportunityStagesListService?sort=code:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let result = response.data;
      dispatch({ type: GET_OPPORTUNITY_STAGES_LIST, payload: result });
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
};
