import { emptyString } from '~/constants';
import { t } from '~/utils/lang';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const HIDE_ALL_NOTIFICATIONS = 'HIDE_ALL_NOTIFICATIONS';
export const LAYOUT_CHANGE_BACK_BUTTON_VISIBLE = 'LAYOUT_CHANGE_BACK_BUTTON_VISIBLE';

const warningDefaultMessage = 'An Error occurred...';

export const layoutChangeHistoryStepsVisible = (value) => (dispatch, getState) => {
  const main = getState().main;
  const payload = {
    ...main,
    historySteps: value === '-' ? main.historySteps - 1 : value ? main.historySteps + 1 : 0,
  };
  dispatch({ type: LAYOUT_CHANGE_BACK_BUTTON_VISIBLE, payload });
};

export const hideAllNotifications = (notification) => (dispatch) => {
  dispatch({ type: HIDE_ALL_NOTIFICATIONS });
};

export const hideNotification = (notification) => (dispatch) => {
  dispatch({ type: HIDE_NOTIFICATION, notification });
};

export const showWarning =
  (message = warningDefaultMessage, headline) =>
  (dispatch) => {
    dispatch({
      type: ADD_NOTIFICATION,
      notificationType: 'warning',
      headline: headline || t.t('notifications.warning'),
      message,
    });
  };

export const showSuccess =
  (message = emptyString, headline) =>
  (dispatch) => {
    dispatch({
      type: ADD_NOTIFICATION,
      notificationType: 'success',
      headline: headline || t.t('notifications.success'),
      message,
    });
  };
