import axiosInstance from '~/utils/axios';
import { getSalesAPIURL } from '.';
import { PageSizeAll } from '~/constants';

const path = 'MakesListService';

export const getMakesAll = (filter = []) =>
  axiosInstance
    .get(getSalesAPIURL(path), {
      params: {
        pageSize: PageSizeAll,
        sort: 'name:1',
        filter: filter,
      },
    })
    .then(({ data }) => data);
