import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';
import { showWarning } from './layout';
import cloneDeep from 'lodash.clonedeep';

export const MODEL_CHANGE_LOADER = 'MODEL_CHANGE_LOADER';
export const changeLoaderAction = (typeLoader, payload) => (dispatch) => {
  dispatch({ type: MODEL_CHANGE_LOADER, typeLoader, payload });
};

export const CHANGE_SELECTED_MODEL = 'CHANGE_SELECTED_MODEL';
export const changeSelectModal = (modelId) => (dispatch, getState) => {
  const models = getState().models.data;
  const payload = models.find((item) => item.Code === modelId);

  dispatch({ type: CHANGE_SELECTED_MODEL, payload });
};

export const getModelData = (id) => (dispatch) => {
  dispatch(changeLoaderAction('loader', true));
  axiosInstance
    .get(`/xassales/v1/VehicleModelVariantsService/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      if (response.data) {
        let payload = response.data;

        payload = {
          ...(payload.ModelId || {}),
          VehicleModelId: payload.Id || '',
          Year: payload.Year?.Id,
          Make: payload.ModelId?.Make || '',
          Class: payload.ModelId?.SubCategory || '',
          BaseModelId: payload.ModelId?.BaseModelId || '',
          BaseModelDescription: payload.ModelId?.BaseModelName || '',
          FromYear: payload.ModelId?.FromYear || '',
          ToYear: payload.ModelId?.ToYear || '',
          EfficiencyClass: payload.ModelId?.EfficiencyClass || '',
          WDType: payload.ModelId?.WDType || '',
          FuelType: payload.ModelId?.FuelType || '',
          DoorsNum: payload.ModelId?.DoorsNum || '',
          GearType: payload.ModelId?.GearType || '',
          ModelType: payload.ModelId?.ModelType || '',
          Category: payload.ModelId?.Category || '',
          standardOptions: payload.ModelId?.StandardOptions || [],
          Photos: payload.Photos || [],
          PhotoLink: payload.PhotoLink || '',
        };
        dispatch({ type: CHANGE_SELECTED_MODEL, payload });
        dispatch(changeLoaderAction('loader', false));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(changeLoaderAction('loader', false));
    });
};

export const CHANGE_FIELD_SELECTED_MODEL = 'CHANGE_FIELD_SELECTED_MODEL';
export const changeFieldModel = (field, value) => (dispatch) => {
  const payload = { [field]: value };
  dispatch({ type: CHANGE_FIELD_SELECTED_MODEL, payload });
  dispatch(updateModelField(payload));
};

const updateModelField = (payload) => (dispatch, getState) => {
  const { model } = getState().model;
  axiosInstance
    .patch(`/xassales/v1/VehicleModelsService/${model.Id}`, payload, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .catch((err) => {
      console.log(err);
    });
};

export const uploadModelImage = (images) => async (dispatch, getState) => {
  const { model } = getState().model;
  let numMain = null;
  let updateWithName = [];
  const promise = images.map((image, i) => {
    if (image.isMain) {
      numMain = i;
    }

    const data = new FormData();
    data.append('file', image.image);
    data.append('name', image.name);
    data.append('Title', image.name);
    data.append('Description', image.description);

    return axiosInstance.post(`/xassales/v1/AttachmentsFileService`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    });
  });

  return Promise.all(promise).then(async (res) => {
    const attach = res
      .filter((item) => {
        const error = item.data?.error;
        if (error) {
          dispatch(showWarning(error, 'Error'));
        }
        return item.data?.success;
      })
      .map((item) => {
        const formData = item.config?.data || null;
        return {
          ...(item.data?.attachment || {}),
          title: formData ? formData.get('Title') : '',
          description: formData ? formData.get('Description') : '',
        };
      });

    if (numMain !== null && attach[numMain]) {
      attach[numMain].isMain = true;
    }

    updateWithName = attach.filter((item) => item._id && item.description);
    if (updateWithName.length) {
      updateWithName.forEach((item) => {
        axiosInstance.patch(
          `/xassales/v1/AttachmentsService/${item.id}`,
          {
            Title: item.name,
            Description: item.description,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
            },
          },
        );
      });
    }

    let data = cloneDeep(model.Photos) || [];
    const payload = { ...model, Photos: [...data, ...attach] };
    attach.forEach((item) => data.push(item._id));
    await dispatch(updateModelPhotos(data, payload));
  });
};

export const deleteImage = (props) => (dispatch, getState) => {
  const { model } = getState().model;

  let data = cloneDeep(model.Photos) || [];
  const promise = props.map((prop) => {
    data = data.filter((item) => item._id !== prop.id);

    return axiosInstance.delete(`/xassales/v1/AttachmentsService/${prop.id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    });
  });
  return Promise.all(promise).then(async (res) => {
    const payload = { ...model, Photos: data };
    await dispatch(updateModelPhotos(data, payload));
  });
};

export const updateModelPhotos = (data, payload) => async (dispatch, getState) => {
  const { model } = getState().model;
  const isMainPhoto = payload.Photos.findIndex((item) => item.isMain);
  const photoLink = isMainPhoto === -1 ? '' : isMainPhoto;
  payload = { ...payload, PhotoLink: photoLink };

  return axiosInstance
    .patch(
      `/xassales/v1/VehicleModelVariantsService/${model.VehicleModelId}`,
      {
        Photos: data,
        PhotoLink: photoLink,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    )
    .then(() => {
      dispatch({ type: CHANGE_SELECTED_MODEL, payload });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateModelDataImage = (props) => (dispatch, getState) => {
  const { model } = getState().model;
  const promise = props.map((item) => {
    const data = {
      Name: item.name,
      Title: item.name,
      Description: item.description,
    };
    return axiosInstance.patch(`/xassales/v1/AttachmentsService/${item.id}`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    });
  });

  return Promise.all(promise).then(async (res) => {
    const photos = model.Photos.map((item) => {
      const findItem = props.find((p) => p.id === item._id);
      if (findItem) {
        item = {
          ...item,
          name: findItem.name,
          description: findItem.description,
          isMain: findItem.isMain,
        };
      }
      return item;
    });
    const updateData = model.Photos || [];
    const payload = { ...model, Photos: photos };
    await dispatch(updateModelPhotos(updateData, payload));
  });
};

export const MODEL_GET_DICTIONARIES = 'MODEL_GET_DICTIONARIES';
export const getDictionaries = () => (dispatch, getState) => {
  //get efficiency Classes
  axiosInstance
    .get(`/xassales/v1/EfficiencyClassesListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { efficiencyClasses: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  //get WDTypes
  axiosInstance
    .get(`/xassales/v1/WDTypesListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { WDTypes: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  // get modelTypes
  axiosInstance
    .get(`/xassales/v1/ModelTypesListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { modelTypes: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  //get basemodels
  axiosInstance
    .get(`/xassales/v1/BaseModelsListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { baseModel: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  //get make
  axiosInstance
    .get(`/xassales/v1/MakesListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { make: data } });
    })
    .catch((err) => {
      console.log(err);
    });
  //get fuelType
  axiosInstance
    .get(`/xassales/v1/FuelTypesListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { fuelType: data } });
    })
    .catch((err) => {
      console.log(err);
    });
  //get gearType
  axiosInstance
    .get(`/xassales/v1/GearTypesListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { gearType: data } });
    })
    .catch((err) => {
      console.log(err);
    });
  //get years
  axiosInstance
    .get(`/xassales/v1/YearsListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { years: data } });
    })
    .catch((err) => {
      console.log(err);
    });
  //get number of doors
  axiosInstance
    .get(`/xassales/v1/NumberOfDoorsListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { numberOfDoors: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  //get subCategories
  axiosInstance
    .get(`/xassales/v1/VehicleSubCategoriesListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { subCategories: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  //category
  axiosInstance
    .get(`/xassales/v1/VehicleCategoriesListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { categories: data } });
    })
    .catch((err) => {
      console.log(err);
    });
  // get engineDisplacements
  axiosInstance
    .get(`/xassales/v1/EngineDisplacementsListService?sort=name:1`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: MODEL_GET_DICTIONARIES, payload: { engineDisplacements: data } });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const RESET_MODEL_DATA = 'RESET_MODEL_DATA';
export const resetModelData = () => (dispatch) => {
  dispatch({ type: RESET_MODEL_DATA });
};
