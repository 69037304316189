import { getSalesToken } from '~/helpers/cookies';
import { lcFirst } from '~/utils/string';

export const SALES_API_URL = 'xassales/v1';

export const getAuthorizationHeaders = () => ({
  Authorization: `Bearer ${getSalesToken()}`,
});

export const getSalesAPIURL = (path) => `${SALES_API_URL}/${path}`;

export const getFilterString = (filters) => {
  let filterStr = '?';

  for (let key in filters) {
    if (filterStr !== '?') {
      filterStr += '&';
    }

    filterStr += `filter=${key}:=:${filters[key]}`;
  }

  return filterStr === '?' ? '' : filterStr;
};

export const getFilterArray = (filters) => {
  const filterArray = Object.entries(filters)
    .map(([key, value]) => (value ? `filter=${lcFirst(key)}:=:${value}` : ''))
    .filter((item) => item);
  return filterArray;
};
