import { createQuoteWithLines, updateQuoteWithLines, deleteQuoteLine } from './api/quotations';
import {
  getNotes as getNotesAction,
  createNote as createNoteAction,
  updateNote as updateNoteAction,
  deleteNote as deleteNoteAction,
} from './api/notes';
import { applyLineData } from '~/helpers/dataAdapters/line';
import { formatNote } from '~/helpers/dataAdapters/note';

const salesQuoteLineModel = 'SalesQuoteLine';

export const getNotesByLineId = async (lineId) => {
  try {
    const notes = await getNotesAction({
      filter: [`objectModel:=:${salesQuoteLineModel}`, `objectId:=:${lineId}`],
    });
    const formattedNotes = notes.map(formatNote);

    return formattedNotes;
  } catch (error) {
    console.error(error);
  }
};

export const createNoteForLine = async (lineId, note) => {
  const data = {
    ObjectId: lineId,
    ObjectModel: salesQuoteLineModel,
    NoteContent: note.text,
  };

  try {
    const note = await createNoteAction(data);
    const formattedNote = formatNote(note);

    return formattedNote;
  } catch (error) {
    console.error(error);
  }
};

export const updateNote = async (id, note) => {
  const data = {
    Id: note.id,
    CreationDate: note.date,
    NoteContent: note.text,
  };

  try {
    const note = await updateNoteAction(id, data);
    const formattedNote = formatNote(note);

    return formattedNote;
  } catch (error) {
    console.error(error);
  }
};

export const deleteNote = async (id) => {
  try {
    await deleteNoteAction(id);
  } catch (error) {
    console.error(error);
  }
};

export const getLines = () => (dispatch, getState) => {
  const { cars } = getState().opportunity.content;

  const lines = [];

  cars.forEach((item) => {
    if (!item.isAdd) {
      return;
    }

    let carLine = applyLineData(item);
    carLine.RelatedLines = [];

    item.configuration.forEach((conf) => {
      if (!conf.lineId) {
        carLine.RelatedLines.push(applyLineData(conf));
      }
    });
    item.accessories.forEach((accessory) => {
      if (!accessory.lineId) {
        carLine.RelatedLines.push(applyLineData(accessory));
      }
    });

    lines.push(carLine);
  });

  return lines;
};

export const createNewQuoteWithLines = (quote, notEditable) => async (dispatch, getState) => {
  if (!notEditable) {
    const { cars, VSDocumentDetails } = getState().opportunity.content;

    quote = {
      ...quote,
      ...VSDocumentDetails,
    };

    cars.forEach((item) => {
      if (!item.isAdd) {
        return;
      }

      let carLine = applyLineData(item);
      carLine.RelatedLines = [];

      item.configuration.forEach((conf) => {
        if (!conf.lineId) {
          carLine.RelatedLines.push(applyLineData(conf));
        }
      });
      item.accessories.forEach((accessory) => {
        if (!accessory.lineId) {
          carLine.RelatedLines.push(applyLineData(accessory));
        }
      });

      quote.Lines.push(carLine);
    });
  }

  const newQuoteId = await createQuoteWithLines(quote);

  return newQuoteId;
};

export const updateCurrentQuoteWithLines =
  (updatesForQuote, linesForUpdate) => async (_dispatch, getState) => {
    const { selectQuote, removeCar } = getState().opportunity.content;

    const dataForUpdate = {
      ...updatesForQuote,
      Lines: linesForUpdate,
    };

    const promises = removeCar.map((item) => deleteQuoteLine(item.lineId));
    await Promise.all(promises);

    await updateQuoteWithLines(selectQuote.Id, dataForUpdate);
  };
