import config from '../config.js';
import moment from 'moment';
import isEqual from 'lodash.isequal';
import { store } from '~/App';
import { companyLocalizationSelector } from '~/reducers/selectors/localization.js';

export const toFixedNumber = (num, x, base) => {
  const pow = Math.pow(base || 10, x);
  return +(Math.round(num * pow) / pow);
};

export const toClientFormatPriceMasked = (price) => (price || price === 0 ? `$${price}` : price);

export const isObjectsEqual = (obj1, obj2) =>
  typeof obj1 === 'object' && typeof obj2 === 'object' && isEqual(obj1, obj2);

export const cleanFormat = (num, point = '.') => {
  if (num && typeof num === 'string') {
    let p = num.split(point);
    const isNegative = p[0][0] === '-' ? '-' : '';
    p[0] = parseFloat(p[0].replace(/\D/g, '')) || 0;
    p[0] = `${isNegative}${p[0]}`;
    return `${p.join('.')}`;
  }
  return num;
};

export const toClientFormatNumber = (num) => {
  if (typeof num === 'string') {
    num = num.replace(/[\s.,%]/g, '');
  }

  return num || num === 0 ? new Intl.NumberFormat('en-EN').format(num) : num;
};

export const toClientFormatMileage = (mileage) => {
  return mileage ? `${mileage} KM` : mileage;
};

export const toClientFormatDate = (date, format) => {
  const state = store.getState();
  const localization = companyLocalizationSelector(state);

  const formatDate = format ? format : localization.longDateFormat;

  return date ? moment(new Date(date)).format(formatDate) : '';
};

export const toServerFormatDate = (date) => {
  const state = store.getState();
  const localization = companyLocalizationSelector(state);

  return date ? moment(date, localization.longDateFormat).format(config.serverDateFormat) : '';
};

export const toClientFormatTime = (time) => {
  const state = store.getState();
  const localization = companyLocalizationSelector(state);

  const timeInClientFormat = moment(new Date(time)).format(localization.timeFormat);
  return time ? timeInClientFormat : '';
};

export const toServerFormatTime = (time) => {
  const clientFormatSplit = config.dateTimeFormat.split(' ');
  const timeInServerFormat = moment(time, clientFormatSplit[1]).format(config.serverTimeFormat);
  return time ? timeInServerFormat : '';
};

export const toDateTimeFormat = (dateTime) => {
  if (!dateTime) {
    return;
  }

  const state = store.getState();
  const localization = companyLocalizationSelector(state);

  const { longDateFormat, timeFormat } = localization;

  const dateTimeMoment = moment(dateTime);

  return `${dateTimeMoment.format(longDateFormat)} ${dateTimeMoment.format(timeFormat)}`;
};

export const isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};

export const isFieldAgent = () => {
  if (isMobile()) {
    const width =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const height =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    return (width <= 480 && height <= 853) || (width <= 853 && height <= 480);
  }
  return false;
};

export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const truncateStr = (str, length) => {
  if (!str) {
    return str;
  }
  const re = new RegExp('^(.{' + length + '}).+', 'g');
  return str.replace(re, '$1…');
};

export const pick = (o, ...props) => {
  return Object.assign({}, ...props.map((prop) => ({ [prop]: o[prop] })));
};

export const isEmptyObject = (obj = {}) => {
  return Object.keys(obj).length === 0;
};

export const toCamelCase = (str) => {
  return str.toLowerCase().replace(/(?:(^.)|(\s+.)|_.)/g, (match) => {
    return match.charAt(match.length - 1).toUpperCase();
  });
};

export const emptyFunction = () => {};

export const getFileExtension = (str) => {
  const parts = str.split('.');
  if (parts.length > 1) {
    return '.' + parts[parts.length - 1];
  }
  return '';
};

export const isFileIsImage = (str) => {
  const ext = getFileExtension(str).toLowerCase();
  return ['.png', '.jpeg', '.jpg', '.gif', '.webp', '.bmp', '.exif', 'svg'].includes(ext);
};

export const paginate = (arr = [], skip = 0, pageSize = config.pageSize) => {
  return {
    values: arr.slice(skip, skip + pageSize),
    total: arr.length,
  };
};

export const getDateAndTimeStampsFromDateTimeString = (timeString) => {
  let date = null;
  let time = null;
  if (timeString) {
    let dateTime = timeString.split('T');
    date = new Date(dateTime[0]).valueOf();
    time = new Date(timeString).valueOf() - date;
  }
  return {
    date,
    time,
  };
};

export const getDateTimeStringFromStamps = (date, time) => {
  let D = new Date(date);
  let dd = new Date(D.getFullYear(), D.getMonth(), D.getDate()).valueOf();
  let T = new Date(time);
  let dt = new Date(T.getFullYear(), T.getMonth(), T.getDate()).valueOf();
  let tt = T.valueOf() - dt;
  return new Date(dd + tt).toISOString();
};

export const dateIsToday = (date) => {
  let dateNow = toClientFormatDate(Date.now().valueOf());
  let dateSrc = toClientFormatDate(date);
  return dateNow === dateSrc;
};

export const datesIsEqual = (date1, date2) => {
  let date1Src = toClientFormatDate(date1);
  let date2Src = toClientFormatDate(date2);
  return date1Src === date2Src;
};

export const twoDecimalPlaces = (value) => {
  const isNum = Number(value);
  if (isNum) {
    return parseInt(value * 100) / 100;
  }
  return value;
};

export const isTabletView = () => window.innerWidth < 1200;
