import axiosInstance from '~/utils/axios';
import { getSalesAPIURL } from '.';
import { PageSizeAll, emptyString } from '~/constants';

const url = 'EngineDisplacementsListService';

export const getEngineDisplacements = (query = emptyString) =>
  axiosInstance
    .get(getSalesAPIURL(`${url}${query}`), {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then(({ data }) => data);
