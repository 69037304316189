import { FilterOperator } from '~/constants/filter';

export const filterName = (array, objectValue, params) => {
  const name = Object.keys(objectValue)[0];
  const value = objectValue[name];

  if (!value) {
    return array;
  }

  const reg = new RegExp(value, 'igm');
  return array.filter((item) => item[name] && item[name].match(reg));
};

export const filterBranch = (array, rid, key) => {
  if (!rid) {
    return array;
  }
  return array.filter((item) => item[key] && item[key].Rid && item[key].Rid === rid);
};

export const filterCustomerName = (array, objectValue, params) => {
  const name = Object.keys(objectValue)[0];
  const value = objectValue[name];

  if (!value && params !== 'is empty' && params !== 'is not empty') {
    return array;
  }

  const reg = new RegExp(value, 'igm');

  let newArray = array;
  if (params) {
    switch (params) {
      case 'contains':
        newArray = array.filter((item) => item[name] && String(item[name]).match(reg));
        break;
      case 'does not contains':
        newArray = array.filter((item) => item[name] && !String(item[name]).match(reg));
        break;
      case 'is equal to':
        newArray = array.filter((item) => item[name] === value);
        break;
      case 'is not equal to':
        newArray = array.filter((item) => item[name] !== value);
        break;
      case 'starts with':
        newArray = array.filter(
          (item) => item[name] && item[name][0].toLowerCase() === value[0].toLowerCase(),
        );
        break;
      case 'ends with':
        newArray = array.filter((item) => {
          const endSymbol = item[name] && item[name].slice(-1).toLowerCase();
          return endSymbol === value.slice(-1).toLowerCase();
        });
        break;
      case 'is empty':
        newArray = array.filter((item) => !item[name]);
        break;
      case 'is not empty':
        newArray = array.filter((item) => item[name]);
        break;
      default:
        newArray = [];
    }
  }

  return newArray;
};

const getDateWithoutTime = (value) => {
  return new Date(new Date(value).toJSON().slice(0, 10).replace(/-/g, '/')).valueOf();
};

export const filterDateRange = (array, dateKey = 'date', params) => {
  let newArray = array;
  if (params) {
    if (params.dateRangeStart) {
      newArray = newArray.filter(
        (item) => item[dateKey] && getDateWithoutTime(item[dateKey]) > params.dateRangeStart,
      );
    }
    if (params.dateRangeEnd) {
      newArray = newArray.filter(
        (item) => item[dateKey] && getDateWithoutTime(item[dateKey]) <= params.dateRangeEnd,
      );
    }
  }
  return newArray;
};

export const filterDate = (array, objectValue, params) => {
  const name = Object.keys(objectValue)[0];
  const value = Number(objectValue[name]);

  if (!value && params !== 'is null' && params !== 'is not null') {
    return array;
  }

  let newArray = array;
  if (params) {
    switch (params) {
      case 'is equal to':
        newArray = array.filter((item) => item[name] && item[name] === value);
        break;
      case 'is not equal to':
        newArray = array.filter((item) => item[name] && item[name] !== value);
        break;
      case 'is after or equal to':
        newArray = array.filter((item) => item[name] && item[name] >= value);
        break;
      case 'is after':
        newArray = array.filter((item) => item[name] && item[name] > value);
        break;
      case 'is before':
        newArray = array.filter((item) => item[name] && item[name] < value);
        break;
      case 'is before or equal to':
        newArray = array.filter((item) => item[name] && item[name] <= value);
        break;
      case 'is null':
        newArray = array.filter((item) => !item[name]);
        break;
      case 'is not null':
        newArray = array.filter((item) => item[name]);
        break;
      default:
        newArray = [];
    }
  }

  return newArray;
};

export const filterAmount = (array, objectValue, params) => {
  const name = Object.keys(objectValue)[0];
  const value = Number(objectValue[name]);

  if (!value && params !== 'is null' && params !== 'is not null') {
    return array;
  }

  const reg = new RegExp(value, 'igm');
  let newArray = array;
  if (params) {
    switch (params) {
      case 'is equal to':
        newArray = array.filter((item) => item[name] && Number(item[name]) === value);
        break;
      case 'is not equal to':
        newArray = array.filter((item) => item[name] && Number(item[name]) !== value);
        break;
      case 'is greater than or equal to':
        newArray = array.filter((item) => item[name] && Number(item[name]) >= value);
        break;
      case 'is greater than':
        newArray = array.filter((item) => item[name] && Number(item[name]) > value);
        break;
      case 'is less than or equal to':
        newArray = array.filter((item) => item[name] && Number(item[name]) <= value);
        break;
      case 'is less than':
        newArray = array.filter((item) => item[name] && Number(item[name]) < value);
        break;
      case 'is null':
        newArray = array.filter((item) => !item[name] || !Number(item[name]));
        break;
      case 'is not null':
        newArray = array.filter((item) => item[name] && Number(item[name]));
        break;
      default:
        newArray = array.filter((item) => item[name] && String(item[name]).match(reg));
    }
  }

  return newArray;
};

export const filterSearch = (array, filterValues = {}) => {
  const names = Object.keys(filterValues).filter((item) => item !== 'search');
  const value = filterValues?.search?.value;

  if (!value) {
    return array;
  }

  return array.filter((item) => {
    let isTrue = false;

    names.forEach((name) => {
      isTrue =
        isTrue ||
        (item[name] && String(item[name]).toLowerCase().search(value.toLowerCase()) !== -1);
    });

    return isTrue;
  });
};

export const sortData = (data, structure) => {
  let sortedStructure = structure.filter((item) => item.sortedBy);
  let result = data;
  sortedStructure.forEach((column, i) => {
    switch (column.type) {
      case 'number':
        result = sortOnNumber(result, column.key, column.sortedBy);
        break;
      case 'string':
        result = sortOnString(result, column.key, column.sortedBy);
        break;
      case 'boolean':
      case 'bool':
        result = sortOnBoolean(result, column.key, column.sortedBy);
        break;
      case 'includedString':
        result = sortOnIncludedString(result, column.key, column.includedField, column.sortedBy);
        break;
      case 'date':
        result = sortOnDate(result, column.key, column.sortedBy);
        break;
      default:
    }
  });
  return result;
};

const sortOnDate = (array, field, type) => {
  return array.sort((a, b) => {
    const aa = a[field] ? new Date(a[field]).valueOf() : 0;
    const bb = b[field] ? new Date(b[field]).valueOf() : 0;

    if (type === 'asc') {
      return aa - bb;
    } else {
      return bb - aa;
    }
  });
};

const sortOnNumber = (array, field, type) => {
  return array.sort((a, b) => {
    const aa = Number(a[field]) || 0;
    const bb = Number(b[field]) || 0;

    if (type === 'asc') {
      return aa - bb;
    } else {
      return bb - aa;
    }
  });
};

const sortOnString = (array, field, type) => {
  const data = array.sort((a, b) => {
    const aa = a[field] ? typeof a[field] === 'string' && a[field].toLowerCase() : '';
    const bb = b[field] ? typeof b[field] === 'string' && b[field].toLowerCase() : '';

    if (aa < bb) return -1;
    if (aa > bb) return 1;
    return 0;
  });

  if (type === 'asc') {
    return data;
  } else {
    return data.reverse();
  }
};

const sortOnIncludedString = (array, field1, field2, type) => {
  const data = array.sort((a, b) => {
    const aa = a[field1] && a[field1][field2] ? a[field1][field2].toLowerCase() : '';
    const bb = a[field1] && a[field1][field2] ? a[field1][field2].toLowerCase() : '';

    if (aa < bb) return -1;
    if (aa > bb) return 1;
    return 0;
  });

  if (type === 'asc') {
    return data;
  } else {
    return data.reverse();
  }
};

const sortOnBoolean = (array, field, type) => {
  const data = array.sort((a, b) => {
    const aa = a[field];
    const bb = b[field];

    if (!aa && bb) return -1;
    if (aa && !bb) return 1;
    return 0;
  });

  if (type === 'asc') {
    return data;
  } else {
    return data.reverse();
  }
};

export const arrayParametrsCustomerName = [
  'contains',
  'does not contains',
  'is equal to',
  'is not equal to',
  'starts with',
  'ends with',
  'is empty',
  'is not empty',
];

export const arrayParametrsOpportunityNumber = [
  'contains',
  'does not contains',
  'is equal to',
  'is not equal to',
  'starts with',
  'ends with',
  'is empty',
  'is not empty',
];

export const arrayParametrsAmount = [
  'is equal to',
  'is not equal to',
  'is greater than or equal to',
  'is greater than',
  'is less than or equal to',
  'is less than',
  'is null',
  'is not null',
];

export const arrayParametrsDate = [
  'is equal to',
  'is not equal to',
  'is after or equal to',
  'is after',
  'is before',
  'is before or equal to',
  'is null',
  'is not null',
];

export const getOperationByParam = (param) => {
  switch (param) {
    case 'contains':
      return 'contains';

    case 'does not contains':
      return '!contains';

    case 'is equal to':
      return '=';

    case 'is not equal to':
      return '!=';

    case 'starts with':
      return 'starts';

    case 'ends with':
      return 'ends';

    case 'is null':
    case 'is empty':
      return 'empty';

    case 'is not empty':
    case 'is not null':
      return '!empty';

    case 'is greater than or equal to':
      return '>=';
    case 'is greater than':
      return '>';
    case 'is less than or equal to':
      return '<=';
    case 'is less than':
      return '<';
    case FilterOperator.moreThan:
      return '>';

    default:
      return '=';
  }
};

export const filterByField = (items, fieldName, filter = {}) => {
  const { value } = filter;

  if (!value) {
    return items;
  }

  if (Array.isArray(value) && value.length !== 0) {
    return items.filter((item) => filter.value.includes(item[fieldName]));
  }

  if (typeof value === 'string') {
    return items.filter((item) => item[fieldName]?.includes(filter.value));
  }

  return items;
};
