export default {
  activeTab: 1,
  customer: {},
  defaultCustomer: null,
  updatedGeneralValues: {},
  loaders: {
    tabsBlock: true,
    generalTab: false,
    vehiclesTab: false,
    vehiclesDicts: false,
    contact_modal: false,
    vehicle_modal: false,
  },
  error: null,
};
