import axiosInstance from '~/utils/axios';
import { PageSizeForRequest } from '~/constants';
import { VSLineDetailsService } from './api/sync';
import { getDocument } from '~/helpers/dataAdapters/document';

export const MODELS_CHANGE_FILTERS = 'MODELS_CHANGE_FILTERS';
export const MODELS_SET_DATA = 'MODELS_SET_DATA';
export const CHANGE_VALUE_LOADER = 'CHANGE_VALUE_LOADER';
export const changeLoaderAction = (typeLoader, payload) => (dispatch) => {
  dispatch({ type: CHANGE_VALUE_LOADER, typeLoader, payload });
};

const getPriceForCars = (payload) => async (dispatch) => {
  const lines = payload.map((car) => ({
    LineType: 'New',
    ItemCode: car.Code,
  }));

  if (lines.length) {
    try {
      const document = getDocument(lines, 'Search');
      const linesDetails = await VSLineDetailsService(document);
      console.log('linesDetails: ', linesDetails);

      payload = (linesDetails.XAS_VSLINESSERVICE1Collection || []).map((item, i) => ({
        ...payload[i],
        Price: item.Price,
      }));
      dispatch({ type: MODELS_SET_DATA, payload });
      dispatch(changeLoaderAction('priceLoader', false));
    } catch (error) {
      dispatch({ type: MODELS_SET_DATA, payload });
      dispatch(changeLoaderAction('priceLoader', false));
    }
  } else {
    dispatch(changeLoaderAction('priceLoader', false));
  }
};

export const CHANGE_SUGGESTIONS = 'CHANGE_SUGGESTIONS';
export const getListsOptions = () => (dispatch) => {
  //get make
  axiosInstance
    .get(`/xassales/v1/MakesListService?sort=name:1&pageSize=${PageSizeForRequest}`)
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: CHANGE_SUGGESTIONS, payload: { manufacturers: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  //get models
  axiosInstance
    .get(`/xassales/v1/VehicleModelsListService?sort=name:1&pageSize=${PageSizeForRequest}`)
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: CHANGE_SUGGESTIONS, payload: { models: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  //get years
  axiosInstance
    .get(`/xassales/v1/YearsListService?sort=name:1&pageSize=${PageSizeForRequest}`)
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: CHANGE_SUGGESTIONS, payload: { years: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  //get category
  axiosInstance
    .get(`/xassales/v1/VehicleCategoriesListService?sort=name:1&pageSize=${PageSizeForRequest}`)
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: CHANGE_SUGGESTIONS, payload: { categories: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  // get FuelTypes
  axiosInstance
    .get(`/xassales/v1/fuelTypes?sort=name:1&pageSize=${PageSizeForRequest}`)
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: CHANGE_SUGGESTIONS, payload: { fuelTypes: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  // get GearTypes
  axiosInstance
    .get(`/xassales/v1/gearTypes?sort=name:1&pageSize=${PageSizeForRequest}`)
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: CHANGE_SUGGESTIONS, payload: { gearTypes: data } });
    })
    .catch((err) => {
      console.log(err);
    });

  //get NumberOfDoors numberOfDoors
  axiosInstance
    .get(`/xassales/v1/numberOfDoors?sort=name:1&pageSize=${PageSizeForRequest}`)
    .then((response) => {
      let data =
        response.data && response.data.length
          ? response.data.map((item, i) => ({
              label: item.Name,
              value: item.Id,
            }))
          : [];
      dispatch({ type: CHANGE_SUGGESTIONS, payload: { numberOfDoors: data } });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const modelsChangeFilters = (key, value) => (dispatch) => {
  dispatch({ type: MODELS_CHANGE_FILTERS, key, value });
};

export const MODELS_SET_ROWS_PER_PAGE = 'MODELS_SET_ROWS_PER_PAGE';
export const changePage = (page) => (dispatch, getState) => {
  const { paging } = getState().models;

  const pagingFrom = paging.pageSize * page;
  const pagingTo = paging.pageSize;

  const payload = {
    currentPage: page,
    pagingTo,
    pagingFrom,
  };

  dispatch({ type: MODELS_SET_ROWS_PER_PAGE, payload });
};

export const changeRowsPerPage = (count) => (dispatch, getState) => {
  const { paging, data } = getState().models;
  const isLength = data.length;

  let pagingFrom = count * paging.currentPage;
  let pagingTo = count;

  if (pagingFrom > isLength) {
    pagingFrom = 0;
  }

  if (pagingTo > isLength) {
    pagingTo = isLength;
  }

  const payload = {
    pageSize: count,
    pagingTo,
    pagingFrom,
  };

  dispatch({ type: MODELS_SET_ROWS_PER_PAGE, payload });
};

export const getModelVariants = () => async (dispatch) => {
  try {
    dispatch(changeLoaderAction('modelsLoader', true));

    const params = {
      $paging: {
        page: 1,
        count: 10000,
      },
    };

    const apiResponse = await axiosInstance.get(`/xassales/v1/VehicleModelVariantsListService`, {
      params,
    });

    if (!apiResponse.data && !apiResponse.data.length) {
      return;
    }

    let payload = apiResponse.data.map((item) => {
      return {
        Code: item?.Code || '',
        Id: item?.Id || '',
        Description: item?.Description || '',
        FuelType: item.ModelId?.FuelType || '',
        GearType: item.ModelId?.GearType || '',
        PhotoLink: item?.PhotoLink || '',
        Image: item?.Photos || [],
        Category: item.Category?.Name || '',
        SubCategory: item.SubCategory?.Name || '',
        BaseModel: item.BaseModelId?.Name || '',
        NumberDoors: item.ModelId?.DoorsNum || '',
        Price: 0,
      };
    });
    dispatch(getPriceForCars(payload));
  } catch (error) {
    console.log(error);
    dispatch({ type: MODELS_SET_DATA, payload: [] });
  } finally {
    dispatch(changeLoaderAction('modelsLoader', false));
  }
};
