import { twoDecimalPlaces } from '../utils/general';

export const modelCar = (props = {}) => {
  const parametrsDetails = {
    gear_type: '',
    number_gears: '',
    displacement: '',
    cylinders: '',
    weight: '',
    body_color: '',
    interior_color: '',
    number_doors: '',
    number_seats: '',
    fuel_type: '',
    images: [],
    ...(props?.parametrs?.details || {}),
  };

  const parametrsEnvironment = {
    fuel: '',
    consumption: {
      comb: '0 (comb)',
      city: '0 (city)',
      country: '0 (country)',
    },
    emission_label: '',
    ...(props?.parametrs?.environment || {}),
  };

  const parametrsSpecification = {
    make: '',
    firstRegistrationDate: '',
    baseModel: '',
    model: '',
    year: '',
    month: '',
    licenseNumber: '',
    isVehicleHistory: false,
    isWarranty: false,
    powerHP: '',
    evaluationPrice: '',
    marketPrice: '',
    mileage: '',
    mileageType: '',
    category: '',
    vin: '',
    privousOwnersNumber: '',
    firstOwner: '',
    currentOwner: '',
    ...(props?.parametrs?.specification || {}),
  };

  const model = {
    make: props.make || '',
    base_model: props.base_model || '',
    model_desc: props.model_desc || '',
    year: props.year || '',
    itemCode: props.itemCode || '',
    price: props.price || 0,
    discount: props.discount || 0,
    total: props.total || 0,
    discountPercent: twoDecimalPlaces(props.discountPercent || 0),
    deliveryDate: props.deliveryDate || '',
    gear_type: props.gear_type || '',
    fuel_type: props.fuel_type || '',
    co_2_emission: props.co_2_emission || '',
    color: props.color || '',
    location: props.location || '',
    seats: props.seats || '',
    doors: props.doors || '',
    quantity: props.quantity || '',
    orderFields: props.orderFields || {},
    items: props.items || [],
    images: props.images || [],
    category: props.category || null,
    subCategory: props.subCategory || null,
    parametrs: {
      details: parametrsDetails,
      environment: parametrsEnvironment,
      equipment: props.parametrs?.equipment || [],
      specification: parametrsSpecification,
    },
    details: {
      itemCode: '',
      quantity: '',
      onHand: '',
      unitPrice: '',
      warehouseCode: '',
      discountPercent: '',
      taxCode: '',
      taxLiable: '',
      taxTotal: '',
      taxPercent: '',
      location: '',
      received: props.received || false,
      ...(props?.details || {}),
    },
    configuration: props.configuration || [],
    accessories: props.accessories || [],
    vin: props.vin || [],
    searchParams: props.searchParams || {},
    comments: props.comments || [],
    rowExternalRef: props.rowExternalRef || '',
    quoteRowExternalRef: props.quoteRowExternalRef,
    isDelivered: props.isDelivered || '',
    vehicleCode: props.vehicleCode || '',
    customerType: props.customerType,
    tradeInInvoiceNumber: props.tradeInInvoiceNumber,
    shippingMethod: props.shippingMethod,
    deliveryAddress: props.deliveryAddress,
    currency: props.currency,
    rate: props.rate,
    localPrice: props.localPrice || 0,
    localTotal: props.localTotal || 0,
    accountPrice: props.accountPrice || 0,
    accountTotal: props.accountTotal || 0,
    lineWeight: props.lineWeight || 0,
    docDiscountTaxReduction: props.docDiscountTaxReduction || 0,
  };

  return { ...props, ...model };
};

export const modelPatch = (props) => {
  const model = {
    name: props.name || '',
    modelId: props.modelId || '',
    typeLine: props.typeLine || '',
    id: props.id || '',
    price: props.price || 0,
    code: props.code || '',
    quantity: props.quantity || '',
    total: props.total || 0,
    unitPrice: props.unitPrice || 0,
    totalBeforeTax: props.totalBeforeTax || 0,
    type: props.type || '',
    priceAfterDiscount: props.priceAfterDiscount || '',
    priceIncludingTax: props.priceIncludingTax || '',
    discountPercentage: props.discountPercentage || 0,
    taxPercentage: props.taxPercentage || '',
    accountCode: props.accountCode || '',
    projectCode: props.projectCode || null,
    measureUnit: props.measureUnit || null,
    unitsOfMeasurment: props.unitsOfMeasurment || '',
    barCode: props.barCode || null,
    supplierCatNum: props.supplierCatNum || null,
    costingCode: props.costingCode || null,
    costingCode2: props.costingCode2 || null,
    costingCode3: props.costingCode3 || null,
    costingCode4: props.costingCode4 || null,
    costingCode5: props.costingCode5 || null,
    rowExternalRef: props.rowExternalRef || '',
    deliveryAddress: props.deliveryAddress,
    currency: props.currency,
    rate: props.rate,
    localPrice: props.localPrice || 0,
    localTotal: props.localTotal || 0,
    accountPrice: props.accountPrice || 0,
    accountTotal: props.accountTotal || 0,
    lineWeight: props.lineWeight || 0,
    docDiscountTaxReduction: props.docDiscountTaxReduction || 0,
  };

  return { ...props, ...model };
};
