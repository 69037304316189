import defaultPageWithTableState from '~/data/_defaultPageWithTable.json';
import cloneDeep from 'lodash.clonedeep';

export const initialState = cloneDeep(defaultPageWithTableState);

export const ACTIVITIES_TOGGLE_LOADER = 'ACTIVITIES_TOGGLE_LOADER';
export const ACTIVITIES_SET_PAGING = 'ACTIVITIES_SET_PAGING';
export const ACTIVITIES_SET_TOTAL = 'ACTIVITIES_SET_TOTAL';
export const ACTIVITIES_SET_ITEMS = 'ACTIVITIES_SET_ITEMS';
export const ACTIVITIES_SET_SAVED_FILTERS = 'ACTIVITIES_SET_SAVED_FILTERS';
export const ACTIVITIES_SET_SAVED_FILTER_ID = 'ACTIVITIES_SET_SAVED_FILTER_ID';
export const ACTIVITIES_SET_FILTERS_VALUE = 'ACTIVITIES_SET_FILTERS_VALUE';
export const ACTIVITIES_RESET_FILTERS = 'ACTIVITIES_RESET_FILTERS';
export const ACTIVITIES_SET_SEARCH = 'ACTIVITIES_SET_SEARCH';
export const ACTIVITIES_CREATE_SAVED_FILTER = 'ACTIVITIES_CREATE_SAVED_FILTER';

export default function (state = cloneDeep(initialState), action) {
  const { type, payload } = action;

  switch (type) {
    case ACTIVITIES_TOGGLE_LOADER: {
      return { ...state, isLoading: payload };
    }

    case ACTIVITIES_SET_PAGING: {
      return { ...state, paging: { ...state.paging, ...payload } };
    }

    case ACTIVITIES_SET_TOTAL: {
      return { ...state, total: payload };
    }

    case ACTIVITIES_SET_ITEMS: {
      return { ...state, items: payload };
    }

    case ACTIVITIES_SET_SAVED_FILTERS: {
      return { ...state, savedFilters: payload };
    }

    case ACTIVITIES_SET_SAVED_FILTER_ID: {
      return { ...state, savedFilterId: payload, paging: { ...state.paging, page: 1 } };
    }

    case ACTIVITIES_SET_FILTERS_VALUE: {
      return { ...state, filters: payload };
    }

    case ACTIVITIES_RESET_FILTERS: {
      return { ...state, filters: {} };
    }

    case ACTIVITIES_SET_SEARCH: {
      return { ...state, search: payload };
    }

    case ACTIVITIES_CREATE_SAVED_FILTER: {
      return { ...state, savedFilters: [...state.savedFilters, payload] };
    }
    default:
      return state;
  }
}
