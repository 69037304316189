import data from '../data/opportunity_new';
import {
  OPPORTUNITY_SET_DATA,
  CHANGE_FIELD_DATA,
  OPPORTUNITY_CREATE_NEW_ACTIVITY,
  OPPORTUNITY_UPDATE_ACTIVITY,
  OPPORTUNITY_UPDATE_CUSTOMER,
  OPPORTUNITY_NEW_TOGGLE_LOADER,
  OPPORTUNITY_NEW_GET_DICTIONARY,
  SET_IS_DELIVERY_MODE,
  OPPORTUNITY_SET_CUSTOMER,
} from '../actions/opportunity_new';

const initialState = data;

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case OPPORTUNITY_NEW_GET_DICTIONARY: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          ...payload,
        },
      };
    }
    case OPPORTUNITY_NEW_TOGGLE_LOADER: {
      return {
        ...state,
        loading: payload,
      };
    }
    case OPPORTUNITY_UPDATE_ACTIVITY:
    case OPPORTUNITY_CREATE_NEW_ACTIVITY: {
      return {
        ...state,
        activities: payload,
      };
    }
    case OPPORTUNITY_SET_DATA:
      return {
        ...data,
        ...payload,
        dictionaries: state.dictionaries,
      };
    case OPPORTUNITY_SET_CUSTOMER: {
      return {
        ...state,
        customer: payload,
      };
    }
    case CHANGE_FIELD_DATA:
      return {
        ...state,
        customer: payload,
      };
    case OPPORTUNITY_UPDATE_CUSTOMER:
      return {
        ...state,
        customer: { ...state.customer, ...payload, loading: false },
      };

    case SET_IS_DELIVERY_MODE: {
      return {
        ...state,
        isDeliveryMode: payload,
      };
    }

    default:
      return state;
  }
}
