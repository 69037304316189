import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';
import errorHandler from '../utils/error';
import { showSuccess } from './layout';
import { t } from '../utils/lang';
import { getAuthorizationHeaders } from './api';
import { setSalesToken } from '~/helpers/cookies';
import { setSalesRoleToken } from '~/helpers/localStorage';
import { setCurrentBranchName } from '~/actions/login';

export const PROFILE_FETCH_USER = 'PROFILE_FETCH_USER';
export const PROFILE_TOGGLE_LOADING = 'PROFILE_TOGGLE_LOADING';
export const PROFILE_UPDATE_LAYOUT = 'PROFILE_UPDATE_LAYOUT';

export const getUserFromCookies = () => {
  const token = Cookies.get('__SALES_token');
  const userInfo = token ? jwtDecode(token) : {};
  return userInfo;
};

export const getProfile = (cb) => (dispatch) => {
  dispatch({ type: PROFILE_TOGGLE_LOADING, loading: true });
  axiosInstance
    .get(`/xassales/v1/profile`, {
      headers: getAuthorizationHeaders(),
    })
    .then((response) => {
      dispatch({
        type: PROFILE_FETCH_USER,
        user: response.data,
      });
      if (typeof cb === 'function') {
        cb();
      }
    })
    .catch((error) => {
      errorHandler(error, dispatch);
    })
    .then(() => {
      dispatch({ type: PROFILE_TOGGLE_LOADING, loading: false });
    });
};

export const changeLanguage = (lang) => (dispatch, getState) => {
  const token = Cookies.get('__SALES_token') || localStorage.getItem('__SALES_token');
  const userInfoData = jwtDecode(token);
  userInfoData.language = lang;
  dispatch({
    type: 'PROFILE_FETCH_USER',
    user: userInfoData,
  });
  sessionStorage.setItem('locale', lang);
  window.location.reload();
};

const keys = {
  FirstName: 'firstName',
  LastName: 'lastName',
  Email: 'email',
  UserName: 'username',
  Language: 'language',
  Manager: 'manager',
};

const formPayload = (payload = {}) => {
  const key = Object.keys(payload)[0];
  const value = Object.values(payload)[0];
  return { [keys[key]]: value };
};

const getIsChanged = (userInfo, payload) => {
  const key = Object.keys(payload)[0];
  const src = userInfo[key]?.Id ? userInfo[key].Id : userInfo[key];
  return src !== payload[key];
};

export const changeProfileField = (payload) => (dispatch, getState) => {
  const userInfo = getState().profile.user_profile;
  const isChanged = getIsChanged(userInfo, payload);

  if (isChanged) {
    axiosInstance
      .patch(`/xassales/v1/profile`, formPayload(payload), {
        headers: getAuthorizationHeaders(),
      })
      .then((response) => {
        dispatch({
          type: PROFILE_FETCH_USER,
          user: {
            ...userInfo,
            ...payload,
          },
        });
        dispatch(showSuccess(t.t('profile_page.profile_updated')));
        if (payload.Language && userInfo.Language !== payload.Language) {
          dispatch(changeLanguage(payload.Language));
        }
      })
      .catch((error) => errorHandler(error, dispatch));
  }
};

export const changeDefaultBranch = (companyId, branchId) => (dispatch) => {
  const response = {
    companyId,
    branchId,
  };
  axiosInstance
    .post(`/xassales/v1/changeCurrentBranch`, response, {
      headers: getAuthorizationHeaders(),
    })
    .then((res) => {
      const { data } = res;
      const { Token, Role } = data;
      setSalesToken(Token);
      setSalesRoleToken(Role);

      const userInfo = jwtDecode(Token);
      dispatch(setCurrentBranchName(userInfo.salesCompany.branchName));
      dispatch(showSuccess(t.t('profile_page.profile_updated')));
    })
    .catch((err) => {
      errorHandler(err, dispatch);
    });
};
