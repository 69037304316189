import { PAGE_SIZE_DEFAULT, PAGE_DEFAULT } from '~/constants/paging';

/**
 * @typedef {Object} Paging
 * @property {number} pageSize - Default value - 50
 * @property {number} currentPage - Default value - 1
 */

/**
 *
 * @param {Object | undefined} paging
 * @returns {Paging}
 */
export const getPaging = (paging = {}) => {
  const { pageSize = PAGE_SIZE_DEFAULT, currentPage } = paging;

  return {
    pagingResponse: true,
    pageSize,
    currentPage: currentPage ? currentPage + 1 : PAGE_DEFAULT,
  };
};
