/**
 * @readonly
 * @enum {string}
 */
export const LeadStatus = {
  Open: 'Open',
  Closed: 'Closed',
  OnHold: 'On Hold',
  Merged: 'Merged to Lead',
};

/**
 * @readonly
 * @enum {string}
 */
export const LeadStage = {
  New: 'New',
  Contacted: 'Contacted',
  Nurturing: 'Nurturing',
  Unqualified: 'Unqualified',
  Converted: 'Converted',
  NotConverted: 'NotConverted',
};

export const LinkedLeadActions = {
  split: 'split',
  merge: 'merge',
};
