import initialState from '~/data/vehicle_search';

import {
  CHANGE_VALUE_FOR_SEARCH_FIELD,
  CHANGE_VALUE_FOR_NEW_TAB,
  CHANGE_VALUE_FOR_USED_TAB,
  CHANGE_VALUE_FOR_ADVANCED,
  VEHICLE_SEARCH_CHANGE_PAGING,
  RESET_VALUES,
  VEHICLE_SEARCH_GET_DICTIONARIES,
  VEHICLE_SEARCH_GET_DATA,
  VEHICLE_SEARCH_TOGGLE_LOADING,
  VEHICLE_SEARCH_TOGGLE_SYNC_LOADER,
  SET_CUSTOMER_INTEREST,
  VEHICLE_SEARCH_GET_MODELS,
  VEHICLE_SEARCH_GET_MODELS_VARIANTS,
  CLEAR_VEHICLE_SEARCH_GET_MODELS_VARIANTS,
  VEHICLE_SEARCH_GET_VEHICLES,
  VEHICLE_SEARCH_GET_VEHICLES_LOADER,
  LOAD_SEARCH_LAYOUTS_FIELDS,
  SET_SEARCH_LAYOUTS,
  SET_VEHICLE_OPTION_CATEGORIES,
  SET_VEHICLE_PROPERTIES,
  CLEAR_TABS,
} from '../actions/vehicle_search';

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case VEHICLE_SEARCH_TOGGLE_SYNC_LOADER: {
      return {
        ...state,
        syncLoader: payload,
      };
    }

    case CHANGE_VALUE_FOR_SEARCH_FIELD: {
      return {
        ...state,
        headerSearch: { ...state.headerSearch, [action.field]: action.value },
      };
    }

    case CHANGE_VALUE_FOR_NEW_TAB: {
      return {
        ...state,
        newTabs: { ...state.newTabs, ...payload },
      };
    }

    case VEHICLE_SEARCH_TOGGLE_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }

    case VEHICLE_SEARCH_GET_DATA: {
      return {
        ...state,
        loading: false,
        cars: payload,
      };
    }

    case VEHICLE_SEARCH_GET_MODELS: {
      return {
        ...state,
        models: payload,
      };
    }

    case VEHICLE_SEARCH_GET_MODELS_VARIANTS: {
      const { data, total = state.totalModelsVariants } = payload;
      return {
        ...state,
        loading: false,
        modelsVariants: data,
        totalModelsVariants: total,
      };
    }
    case VEHICLE_SEARCH_GET_VEHICLES: {
      const { modelId, isAppend, data, total } = payload;
      return {
        ...state,
        loading: false,
        vehicles: {
          ...state.vehicles,
          [modelId]: {
            list: isAppend ? [...state.vehicles[modelId].list, ...data] : data,
            total,
          },
        },
      };
    }
    case VEHICLE_SEARCH_GET_VEHICLES_LOADER: {
      return {
        ...state,
        vehicleLoaders: {
          [payload.modelId]: payload.value,
        },
      };
    }
    case CLEAR_VEHICLE_SEARCH_GET_MODELS_VARIANTS: {
      return {
        ...state,
        loading: false,
        modelsVariants: [],
      };
    }

    case CHANGE_VALUE_FOR_USED_TAB: {
      return {
        ...state,
        usedTabs: { ...state.usedTabs, ...payload },
      };
    }

    case CHANGE_VALUE_FOR_ADVANCED: {
      return {
        ...state,
        checkbox: payload,
      };
    }

    case CLEAR_TABS: {
      return {
        ...state,
        newTabs: initialState.newTabs,
        usedTabs: initialState.usedTabs,
      };
    }

    case RESET_VALUES: {
      return initialState;
    }

    case VEHICLE_SEARCH_CHANGE_PAGING: {
      return {
        ...state,
        paging: {
          ...state.paging,
          ...payload,
        },
      };
    }

    case VEHICLE_SEARCH_GET_DICTIONARIES: {
      return {
        ...state,
        options: {
          ...state.options,
          ...payload,
        },
      };
    }

    case SET_CUSTOMER_INTEREST: {
      return {
        ...state,
        customerInterest: {
          ...state.customerInterest,
          ...payload,
        },
      };
    }

    case LOAD_SEARCH_LAYOUTS_FIELDS: {
      return {
        ...state,
        fields: payload,
      };
    }

    case SET_SEARCH_LAYOUTS: {
      return {
        ...state,
        searchLayouts: payload,
      };
    }

    case SET_VEHICLE_OPTION_CATEGORIES: {
      return {
        ...state,
        vehicleOptionCategories: payload,
      };
    }

    case SET_VEHICLE_PROPERTIES: {
      return {
        ...state,
        vehicleProperties: payload,
      };
    }

    default: {
      return state;
    }
  }
}
