export const initialState = {
  data: [],
  paging: {
    currentPage: 0,
    pageSize: 20,
    pagingTo: 20,
    pagingFrom: 0,
  },
  loader: false,
  filterValues: {
    search: { value: '', params: null },
    name: { value: '', params: null },
    edit: {},
  },
  isDisabledNewFilter: true,
  filterNames: [],
  filterModal: {
    name: '',
    isDisplayOnDashboard: true,
    isPrivate: false,
    notes: '',
  },
};

export const CHANGE_CHANNELS_PAGING = 'CHANGE_CHANNELS_PAGING';
export const SET_CHANNELS_LOADER = 'SET_CHANNELS_LOADER';
export const SET_CHANNELS = 'SET_CHANNELS';
export const GET_CHANNELS_FILTER_NAMES = 'GET_CHANNELS_FILTER_NAMES';
export const CHANNELS_SET_DEFAULT_FILTERS_PARAMS = 'CHANNELS_SET_DEFAULT_FILTERS_PARAMS';
export const CHANNELS_DISABLED_NEW_FILTER = 'CHANNELS_DISABLED_NEW_FILTER';
export const CHANNELS_LIST_CHANGE_FILTERS = 'CHANNELS_LIST_CHANGE_FILTERS';
export const CHANNELS_LIST_CHANGE_MODAL_FIELD = 'CHANNELS_LIST_CHANGE_MODAL_FIELD';

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CHANNELS: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case SET_CHANNELS_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case CHANGE_CHANNELS_PAGING: {
      return { ...state, paging: { ...state.paging, ...action.payload } };
    }

    case GET_CHANNELS_FILTER_NAMES: {
      return {
        ...state,
        filterNames: action.payload,
      };
    }

    case CHANNELS_SET_DEFAULT_FILTERS_PARAMS: {
      return {
        ...state,
        filterValues: action.payload,
      };
    }

    case CHANNELS_DISABLED_NEW_FILTER: {
      return {
        ...state,
        isDisabledNewFilter: action.payload,
      };
    }

    case CHANNELS_LIST_CHANGE_FILTERS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.key]: { value: action.value, params: action.params },
        },
      };
    }

    case CHANNELS_LIST_CHANGE_MODAL_FIELD: {
      return {
        ...state,
        filterModal: {
          ...state.filterModal,
          [action.key]: action.value,
        },
      };
    }

    default: {
      return state;
    }
  }
}
