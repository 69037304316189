import { axiosInstanceSalesAPI } from '../../utils/axios';

const url = 'customers';
const listUrl = 'CustomersListService';
const getOrCreateFromLeadUrl = 'customers/getOrCreateFromLead';
const syncFromDocumentUrl = 'customers/syncFromDocument';

export const getCustomer = async (id) => {
  return axiosInstanceSalesAPI.get(`${url}/${id}`).then(({ data }) => data);
};

export const getCustomers = async (params = {}) => {
  return axiosInstanceSalesAPI
    .get(listUrl, {
      params,
    })
    .then(({ data }) => data);
};

export const createCustomer = async (data) => {
  return axiosInstanceSalesAPI.post(url, data).then(({ data }) => data);
};

export const updateCustomer = async (id, data) => {
  return axiosInstanceSalesAPI.patch(`${url}/${id}`, data).then(({ data }) => data);
};

export const getOrCreateFromLeadCustomer = async (name, leadId) => {
  return axiosInstanceSalesAPI
    .post(getOrCreateFromLeadUrl, {
      name,
      leadId,
    })
    .then(({ data }) => data);
};

/**
 * @typedef SyncFromDocumentData
 * @type {object}
 * @property {string} objectId - Mongo Id
 * @property {string} objectModel - enum: 'Opportunity', 'Lead'
 */

/**
 * @param {SyncFromDocumentData} data
 * @returns {object}
 */
export const syncFromDocument = async (data) =>
  axiosInstanceSalesAPI.post(syncFromDocumentUrl, data).then(({ data }) => data);
