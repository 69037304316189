import axiosInstance from '~/utils/axios';
import { getSalesAPIURL } from '.';

const url = 'Notes';

export const getNotes = (params) =>
  axiosInstance
    .get(getSalesAPIURL(url), {
      params: params,
    })
    .then(({ data }) => data);

export const createNote = (data) =>
  axiosInstance.post(getSalesAPIURL(url), data).then(({ data }) => data);

export const updateNote = (id, data) =>
  axiosInstance.patch(`${getSalesAPIURL(url)}/${id}`, data).then(({ data }) => data);

export const deleteNote = (id) =>
  axiosInstance.delete(`${getSalesAPIURL(url)}/${id}`).then(() => true);
