import { PARTNERS_TOGGLE_LOADING, getActualCustomer } from './customers';
import { getPaging } from '~/helpers/paging';
import { getCustomers } from './api/customers';
import { getOrConditionsString } from '~/helpers/filter';
import { updateContact } from './api/contacts';
import cloneDeep from 'lodash.clonedeep';

export const CHANGE_SEARCH_FIELDS = 'CHANGE_SEARCH_FIELDS';
export const CHANGE_SEARCH_RESULTS = 'CHANGE_SEARCH_RESULTS';
export const OPPORTUNITY_CUSTOMERS_CHANGE_PAGING = 'OPPORTUNITY_CUSTOMERS_CHANGE_PAGING';
export const OPPORTUNITY_CUSTOMERS_CHANGE_MODAL = 'OPPORTUNITY_CUSTOMERS_CHANGE_MODAL';
export const OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING = 'OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING';

const customerKeys = {
  customerName: 'name',
  phoneNumber: 'phoneNumber',
  email: 'email',
  customerId: 'customerId',
};

const clearPhone = (value) => value.replaceAll('_', '');

export const changeSearchFields = (name, value) => (dispatch, getState) => {
  const { searchFields } = getState().opportunityCustomers;
  const payload = { ...searchFields, [name]: value };
  dispatch({ type: CHANGE_SEARCH_FIELDS, payload });
};

export const clearAllSearchFields = () => (dispatch) => {
  const payload = { customerId: '', customerName: '', phoneNumber: '', email: '' };
  dispatch({ type: CHANGE_SEARCH_FIELDS, payload });
  dispatch({ type: CHANGE_SEARCH_RESULTS, payload: [] });
};

export const changeSearchResults = (paging) => async (dispatch, getState) => {
  const { searchFields } = getState().opportunityCustomers;

  const fields = {};
  for (const [key, value] of Object.entries(searchFields)) {
    if (key === 'phoneNumber') {
      fields[customerKeys[key]] = clearPhone(value);
    }
    fields[customerKeys[key]] = value;
  }

  const params = {
    filter: [getOrConditionsString(fields)],
    ...getPaging(paging),
  };

  dispatch({ type: PARTNERS_TOGGLE_LOADING, payload: true });

  try {
    const customers = await getCustomers(params);

    let data = customers.records;
    if (data.length) {
      data = data.map((customer, i) => getActualCustomer(customer));
    }
    const total = customers.total;
    dispatch({ type: CHANGE_SEARCH_RESULTS, payload: data });
    dispatch({ type: OPPORTUNITY_CUSTOMERS_CHANGE_PAGING, payload: { total } });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({ type: PARTNERS_TOGGLE_LOADING, payload: false });
  }
};

export const changePaging = (payload) => (dispatch, getState) => {
  dispatch({ type: 'OPPORTUNITY_CUSTOMERS_CHANGE_PAGING', payload });
  dispatch(changeSearchResults({ ...getState().opportunityCustomers.paging, ...payload }));
};

export const changeContactModalFields = (fields) => (dispatch, getState) => {
  const contactModal = getState().opportunityCustomers.contactModal;
  const payload = {
    ...contactModal,
    ...fields,
  };
  dispatch({ type: OPPORTUNITY_CUSTOMERS_CHANGE_MODAL, payload });
};

export const updateCustomerContact = (payload) => async (dispatch, getState) => {
  try {
    const { customerId, contactId, data } = payload;

    await updateContact(contactId, data);

    const newSearchResults = cloneDeep(getState().opportunityCustomers.searchResults);

    const customer = newSearchResults.find(({ id }) => id === customerId);
    const contactIndex = customer.contacts.findIndex(({ Id }) => Id === contactId);
    const contact = customer.contacts[contactIndex];

    const newContact = {
      ...contact,
      ...data,
    };

    customer.contacts.splice(contactIndex, 1, newContact);

    dispatch({ type: CHANGE_SEARCH_RESULTS, payload: newSearchResults });
  } catch (error) {
    console.error(error);
  }
};
