import { emptyString } from '~/constants';
import { lcFirst } from '~/utils/string';
import transform from 'lodash.transform';
import isEqual from 'lodash.isequal';
import isObject from 'lodash.isobject';

/**
 * Remove keys with <Empty string> value
 * @param {Object} obj
 * @returns {Object}
 */
export const removeEmptyStringFields = (obj) => {
  return JSON.parse(
    JSON.stringify(obj, (_key, value) => {
      if (value === emptyString) {
        return undefined;
      }

      return value;
    }),
  );
};

export const objectKeysPascalCaseToCamelCase = (input) => {
  if (typeof input !== 'object' || input === null) {
    return input;
  }
  if (Array.isArray(input)) {
    return input.map(objectKeysPascalCaseToCamelCase);
  }
  return Object.keys(input).reduce(function (newObj, key) {
    let val = input[key];
    let newVal = typeof val === 'object' ? objectKeysPascalCaseToCamelCase(val) : val;
    newObj[lcFirst(key)] = newVal;
    return newObj;
  }, {});
};

export const difference = (object1, object2) => {
  const changes = (object, base) => {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  };
  return changes(object1, object2);
};
