import Cookies from 'js-cookie';
import cloneDeep from 'lodash.clonedeep';
import { showWarning } from './layout';
import axiosInstance from '~/utils/axios';
import errorHandler from '~/utils/error';

export const VEHICLE_SET_ACTIVE_TAB = 'VEHICLE_SET_ACTIVE_TAB';
export const VEHICLE_GET_DICTS = 'VEHICLE_GET_DICTS';
export const VEHICLE_SET_OVERVIEW_UPDATED_FIELD = 'VEHICLE_SET_OVERVIEW_UPDATED_FIELD';

export const VEHICLE_SHOW_ERROR = 'VEHICLE_SHOW_ERROR';
export const VEHICLE_BLOCK_TOGGLE_LOADING = 'VEHICLE_BLOCK_TOGGLE_LOADING';
export const VEHICLE_SET_DEFAULT_ACTIVE_TAB = 'VEHICLE_SET_DEFAULT_ACTIVE_TAB';

export const CHANGE_SELECTED_VEHICLE = 'CHANGE_SELECTED_VEHICLE';
export const VEHICLE_CHANGE_LOADER = 'VEHICLE_CHANGE_LOADER';

export const toggleBlockLoading = (block, loading) => (dispatch) => {
  dispatch({ type: VEHICLE_BLOCK_TOGGLE_LOADING, block, loading });
};

export const setActiveTab = (activeTab) => (dispatch) => {
  dispatch({ type: VEHICLE_SET_ACTIVE_TAB, activeTab });
};

export const setDefaultActiveTab = () => (dispatch) => {
  dispatch({ type: VEHICLE_SET_DEFAULT_ACTIVE_TAB });
};

export const getVehiclePageDicts = () => (dispatch) => {
  dispatch(toggleBlockLoading('overviewBlock', true));
  axiosInstance
    .get(`/xassales/v1/dmsApi/VehiclePageDicts`)
    .then((response) => {
      dispatch({ type: VEHICLE_GET_DICTS, dicts: response.data });
    })
    .catch((error) => errorHandler(error, dispatch))
    .then(() => {
      dispatch(toggleBlockLoading('overviewBlock', false));
    });
};

export const getVehicle = (vehicleId) => (dispatch) => {
  dispatch(changeVehicleLoader(true));
  axiosInstance
    .get(`/xassales/v1/vehicles/${vehicleId}`)
    .then((vehicle) => {
      dispatch({ type: CHANGE_SELECTED_VEHICLE, payload: vehicle.data });
      dispatch(changeVehicleLoader(false));
    })
    .catch((error) => dispatch(changeVehicleLoader(false)));
};

export const CHANGE_FIELD_SELECTED_VEHICLE = 'CHANGE_FIELD_SELECTED_VEHICLE';
export const changeFieldVehicle = (field, value) => (dispatch) => {
  const payload = { [field]: value };
  dispatch({ type: CHANGE_FIELD_SELECTED_VEHICLE, payload });
  dispatch(updateVehicleField(payload));
};

const updateVehicleField = (payload) => (dispatch, getState) => {
  let { model } = getState().vehicle;
  axiosInstance
    .patch(`/xassales/v1/vehicles/${model.Id}`, payload, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {})
    .catch((err) => {
      console.log(err);
    });
};

export const uploadVehicleImage = (images) => async (dispatch, getState) => {
  const { model } = getState().vehicle;
  let numMain = null;
  let updateWithName = [];
  const promise = images.map((image, i) => {
    if (image.isMain) {
      numMain = i;
    }

    const data = new FormData();
    data.append('file', image.image);
    data.append('name', image.name);
    data.append('Title', image.name);
    data.append('Description', image.description);

    return axiosInstance.post(`/xassales/v1/AttachmentsFileService`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    });
  });

  return Promise.all(promise).then(async (res) => {
    const attach = res
      .filter((item) => {
        const error = item.data?.error;
        if (error) {
          dispatch(showWarning(error, 'Error'));
        }
        return item.data?.success;
      })
      .map((item) => {
        const formData = item.config?.data || null;
        return {
          ...(item.data?.attachment || {}),
          title: formData ? formData.get('Title') : '',
          description: formData ? formData.get('Description') : '',
        };
      });

    if (numMain !== null && attach[numMain]) {
      attach[numMain].isMain = true;
    }

    updateWithName = attach.filter((item) => item._id && item.description);
    if (updateWithName.length) {
      updateWithName.forEach((item) => {
        axiosInstance.patch(
          `/xassales/v1/AttachmentsService/${item._id}`,
          {
            Title: item.name,
            Description: item.description,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
            },
          },
        );
      });
    }

    let data = cloneDeep(model.Photos) || [];
    const payload = { ...model, Photos: [...data, ...attach] };
    attach.forEach((item) => data.push(item._id));
    await dispatch(updateVehiclePhotos(data, payload));
  });
};

export const deleteImage = (props) => (dispatch, getState) => {
  const { model } = getState().vehicle;

  let data = cloneDeep(model.Photos) || [];
  const promise = props.map((prop) => {
    data = data.filter((item) => item._id !== prop.id);

    return axiosInstance.delete(`/xassales/v1/AttachmentsService/${prop.id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    });
  });
  return Promise.all(promise).then(async (res) => {
    const payload = { ...model, Photos: data };
    await dispatch(updateVehiclePhotos(data, payload));
  });
};

export const updateVehicleDataImage = (props) => (dispatch, getState) => {
  const { model } = getState().vehicle;
  const promise = props.map((item) => {
    const data = {
      Name: item.name,
      Title: item.name,
      Description: item.description,
    };
    return axiosInstance.patch(`/xassales/v1/AttachmentsService/${item.id}`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    });
  });

  return Promise.all(promise).then(async (res) => {
    const photos = model.Photos.map((item) => {
      const findItem = props.find((p) => p.id === item._id);
      if (findItem) {
        item = {
          ...item,
          name: findItem.name,
          description: findItem.description,
          isMain: findItem.isMain,
        };
      }
      return item;
    });
    const updateData = model.Photos || [];
    const payload = { ...model, Photos: photos };
    await dispatch(updateVehiclePhotos(updateData, payload));
  });
};

export const updateVehiclePhotos = (data, payload) => async (dispatch, getState) => {
  const { model } = getState().vehicle;
  const isMainPhoto = payload.Photos.findIndex((item) => item.isMain);
  const photoLink = isMainPhoto === -1 ? '' : isMainPhoto;
  payload = { ...payload, PhotoLink: photoLink };

  return axiosInstance
    .patch(
      `/xassales/v1/vehicles/${model.Id}`,
      {
        Photos: data,
        PhotoLink: photoLink,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    )
    .then((response) => {
      dispatch({ type: CHANGE_SELECTED_VEHICLE, payload });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const TOGGLE_VEHICLE_LOADER = 'TOGGLE_VEHICLE_LOADER';
export const changeVehicleLoader = (payload) => (dispatch) => {
  dispatch({ type: TOGGLE_VEHICLE_LOADER, payload });
};

export const RESET_VEHICLE_DATA = 'RESET_VEHICLE_DATA';
export const resetVehicleData = () => (dispatch) => {
  dispatch({ type: RESET_VEHICLE_DATA });
};
