import { axiosInstanceSalesAPI } from '~/utils/axios';

const LeadMappingsListServiceUrl = 'CampaignMappingsListService';
const LeadMappingsServiceUrl = 'CampaignMappingsService';

/* API */

export const getLeadMappingsList = async (paging = {}, filterQuery = '') =>
  axiosInstanceSalesAPI
    .get(`${LeadMappingsListServiceUrl}${filterQuery}`, {
      params: {
        ...paging,
      },
    })
    .then((response) => response.data.map(formatLeadMapping));

export const getLeadMapping = async (id) =>
  axiosInstanceSalesAPI
    .get(`${LeadMappingsServiceUrl}/${id}`)
    .then((response) => formatLeadMapping(response.data));

export const createLeadMapping = async (data) =>
  axiosInstanceSalesAPI
    .post('LeadMappingsServiceUrl', formatLeadMappingToServer(data))
    .then((response) => response.data);

export const updateLeadMapping = async (data) =>
  axiosInstanceSalesAPI
    .patch(`${LeadMappingsServiceUrl}/${data.id}`, formatLeadMappingToServer(data))
    .then((response) => response.data);

export const deleteLeadMapping = async (id) =>
  axiosInstanceSalesAPI.delete(`${LeadMappingsServiceUrl}/${id}`).then((response) => response.data);

/* Data Formatters */

const formatLeadMapping = (campaign) => ({
  id: campaign.Id,
  title: campaign.Name,
  status: campaign.Active,
  mapping: campaign.Map.map(({ Column, Field }) => ({
    column: Column,
    field: Field,
  })),
});

const formatLeadMappingToServer = (campaign) => ({
  Id: campaign.id,
  Name: campaign.title,
  Active: campaign.status,
  Map: campaign.mapping.map(({ column, field }) => ({
    Column: column,
    Field: field,
  })),
});
