import cloneDeep from 'lodash.clonedeep';
import defaultPageWithTableState from '~/data/_defaultPageWithTable.json';

export const initialState = cloneDeep(defaultPageWithTableState);

export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_PAGING = 'SET_PAGING';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_ITEMS = 'SET_ITEMS';
export const SET_SAVED_FILTERS = 'SET_SAVED_FILTERS';
export const SET_SAVED_FILTER_ID = 'SET_SAVED_FILTER_ID';
export const SET_FILTERS_VALUE = 'SET_FILTERS_VALUE';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_SEARCH = 'SET_SEARCH';
export const CREATE_SAVED_FILTER = 'CREATE_SAVED_FILTER';

const closeReasonsReducer = (state = cloneDeep(initialState), action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_LOADING: {
      return { ...state, isLoading: payload };
    }

    case SET_PAGING: {
      return { ...state, paging: { ...state.paging, ...payload } };
    }

    case SET_TOTAL: {
      return { ...state, total: payload };
    }

    case SET_ITEMS: {
      return { ...state, items: payload };
    }

    case SET_SAVED_FILTERS: {
      return { ...state, savedFilters: payload };
    }

    case SET_SAVED_FILTER_ID: {
      return { ...state, savedFilterId: payload, paging: { ...state.paging, page: 1 } };
    }

    case SET_FILTERS_VALUE: {
      return { ...state, filters: { ...state.filters, ...payload } };
    }

    case RESET_FILTERS: {
      return { ...state, filters: {} };
    }

    case SET_SEARCH: {
      return { ...state, search: payload };
    }

    case CREATE_SAVED_FILTER: {
      return { ...state, savedFilters: [...state.savedFilters, payload] };
    }

    default: {
      return state;
    }
  }
};

export default closeReasonsReducer;
