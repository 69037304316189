import { lcFirst } from '~/utils/string';
import { adaptActivity } from './activities';

export const formatCustomerAddressToOption = ({ Rid, Full }) => ({
  value: Rid,
  label: Full,
});

export const getCustomer = (opportunity) => {
  const { CustomerCode } = opportunity;

  if (!CustomerCode) {
    return {};
  }

  const {
    Rid,
    Code,
    CustomerId,
    Email,
    Addresses = [],
    Address = null,
    Group = null,
    Name,
    PhoneNumber,
    SecondaryPhoneNumber,
    PrivacyStatus,
    Title = null,
    Type,
    Notes = null,
    ShipToAddress,
    Currency,
    Channel,
  } = CustomerCode;

  const customer = {
    CustomerId: Rid,
    code: Code,
    customerId: CustomerId,
    email: Email,
    addresses: Addresses.map(formatCustomerAddressToOption),
    address: Address,
    shipToAddress: ShipToAddress,
    group: Group,
    name: Name,
    phoneNumber: PhoneNumber,
    secondaryPhoneNumber: SecondaryPhoneNumber,
    privacyStatus: PrivacyStatus,
    title: Title,
    type: Type,
    notes: Notes,
    currency: Currency,
    channel: Channel,
  };

  return customer;
};

export const adaptOpportunityForClient = (data) => {
  const adaptPayload = {};

  for (let key in data) {
    const keyInCamelCase = lcFirst(key);
    adaptPayload[keyInCamelCase] = data[key];
  }

  return adaptPayload;
};

export const formatEnumToDictionary = (item) => ({
  label: item,
  id: item,
  value: item,
});

export const formatOpportunitiesShort = (item) => ({
  id: item.Id,
  activities: item.Activities.map(adaptActivity),
  status: item.Status,
  startDate: item.CreatedAt,
  customerName: item.CustomerName,
  dueDate: item.ExpectedCloseDate,
  opportunityNumber: item.ExternalRef,
  quotationCode: item.QuotationCode,
  orderCode: item.OrderCode,
  deliveryCode: item.DeliveryCode,
  title: item.OpportunityStage,
  amount: item.PotentialAmount,
  brand: item.Brand?.Rid,
  branch: item.Branch?.Rid,
  customerCurrency: item.CustomerCode.Currency,
  owner: item.Owner?.Rid,
  salesAgent: item.SalesAgent?.Rid,
  salesManager: item.Manager?.Rid,
  activitiesCount: item.ActivitiesCount,
  openActivitiesCount: item.OpenActivitiesCount,
  overDueActivitiesCount: item.OverDueActivitiesCount,
  updatedAt: item.UpdatedAt,
  isOpened: item.IsOpened,
});
