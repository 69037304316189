import axiosInstance from '~/utils/axios';
import { getSalesAPIURL } from '.';

const listUrl = 'ItemsListService';

export const getItems = async (params = {}) =>
  axiosInstance
    .get(getSalesAPIURL(listUrl), {
      params: params,
    })
    .then(({ data }) => data);
