import { axiosInstanceSalesAPI } from '~/utils/axios';
import { PageSizeAll } from '~/constants';

const listUrl = 'DevicesListService';

export const getDevices = async () =>
  axiosInstanceSalesAPI
    .get(listUrl, {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then(({ data }) => data);
