import { emptyString, LineTypeNew, LineTypePart, LineTypes } from '~/constants';

export const getLineType = (line) => {
  const { lineType, typeLine, ItemType } = line;

  let type = lineType || typeLine || ItemType;

  if (type === 'Model') {
    type = LineTypeNew;
  }
  if (type === 'Option') {
    type = LineTypePart;
  }

  if (!Object.values(LineTypes).includes(type)) {
    throw new Error(`Unknown LineType: "${type}"`);
  }

  return type;
};

export const applyLineData = (line) => {
  let lineType = line.typeLine || line.lineType;

  // line don't can have lineType is New because this is enum value on the server and have such values
  // enum: [ 'Model', 'Used', 'Trade-in', 'Service', 'Part', 'Accessory' ]
  // 'New' is lineType in SAP
  if (lineType === 'New') {
    lineType = 'Model';
  }

  const {
    lineId,
    quantity,
    unitPrice,
    priceAfterDiscount,
    discountPercent,
    accountCode,
    projectCode,
    measureUnit,
    unitsOfMeasurment,
    barCode,
    supplierCatNum,
    costingCode,
    costingCode2,
    costingCode3,
    costingCode4,
    costingCode5,
    total,
    totalBeforeTax = 0,
    tax = 0,
    onHand,
    taxCode,
    taxTotal,
    taxPercent,
    taxPercentage,
    isAssembly,
    isReserved,
    customerType,
    tradeInInvoiceNumber,
    shippingMethod,
    deliveryAddress,
    received,
    localPrice,
    localTotal,
    accountPrice,
    accountTotal,
    lineWeight,
    docDiscountTaxReduction,

    currency,
    rate,

    sortOrder,
  } = line;

  const data = {
    Id: lineId,
    Quantity: quantity,
    UnitPrice: unitPrice,
    QuantityOnHand: onHand,
    TaxPercentage: taxPercent || taxPercentage,
    TaxCode: taxCode,
    TaxSum: taxTotal,
    PriceAfterDiscount: priceAfterDiscount,
    PriceIncludingTax: totalBeforeTax + tax,
    DiscountPercentage: discountPercent,
    Total: total,
    TotalBeforeTax: totalBeforeTax,
    ShipToAddress: '',
    AccountCode: accountCode,
    ProjectCode: projectCode,
    MeasureUnit: measureUnit,
    UnitsOfMeasurment: unitsOfMeasurment,
    BarCode: barCode,
    SupplierCatNum: supplierCatNum,
    CostingCode: costingCode,
    CostingCode2: costingCode2,
    CostingCode3: costingCode3,
    CostingCode4: costingCode4,
    CostingCode5: costingCode5,
    Received: received,
    LineType: lineType,
    IsAssembly: isAssembly,
    IsReserved: isReserved,
    CustomerType: customerType,
    TradeInInvoiceNumber: tradeInInvoiceNumber,
    ShippingMethod: shippingMethod,
    DeliveryAddress: deliveryAddress,
    LocalPrice: localPrice,
    LocalTotal: localTotal,
    AccountPrice: accountPrice,
    AccountTotal: accountTotal,
    LineWeight: lineWeight,
    DocDiscountTaxReduction: docDiscountTaxReduction,

    Currency: currency,
    Rate: rate,

    SortOrder: sortOrder,
  };

  if (lineType === 'Model') {
    data.ModelVariantId = line.ModelVariantId || line.modelVariantId;
  }
  if (lineType === 'Used' || lineType === 'Trade-in') {
    data.VehicleId = line.id;
  }
  if (['Service', 'Part', 'Accessory'].includes(lineType)) {
    data.ItemId = line.id;
  }
  if (data.DeliveryAddress === emptyString) {
    delete data.DeliveryAddress;
  }

  return data;
};
