import {
  CAMPAIGNS_CREATE,
  CAMPAIGNS_DELETE,
  CAMPAIGNS_UPDATE,
  CAMPAIGNS_GET_LIST,
  CAMPAIGNS_LOADING,
  CHANGE_CAMPAIGNS_FILTER_NAMES,
  CAMPAIGNS_CHANGE_FILTERS,
  CAMPAIGNS_CHANGE_MODAL_FIELD,
  CAMPAIGNS_DISABLE_NEW_FILTER,
  CAMPAIGNS_SET_DEFAULT_FILTERS_PARAMS,
  CAMPAIGNS_CLEAR_FILTERS,
} from '~/actions/campaigns';
import initialState from '~/data/campaigns.json';

export default function (state = initialState, action) {
  switch (action.type) {
    case CAMPAIGNS_DISABLE_NEW_FILTER: {
      return {
        ...state,
        isDisabledNewFilter: action.payload,
      };
    }
    case CAMPAIGNS_CHANGE_MODAL_FIELD: {
      return {
        ...state,
        filterModal: {
          ...state.filterModal,
          [action.key]: action.value,
        },
      };
    }
    case CAMPAIGNS_CHANGE_FILTERS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.key]: { value: action.value, params: action.params },
        },
      };
    }
    case CHANGE_CAMPAIGNS_FILTER_NAMES: {
      return { ...state, filterNames: action.payload };
    }
    case CAMPAIGNS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case CAMPAIGNS_CREATE: {
      return {
        ...state,
        data: [
          ...state.data,
          {
            ...action.payload,
            id: state.data.length,
          },
        ],
      };
    }
    case CAMPAIGNS_DELETE: {
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    }
    case CAMPAIGNS_GET_LIST:
    case CAMPAIGNS_UPDATE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case CAMPAIGNS_SET_DEFAULT_FILTERS_PARAMS: {
      return {
        ...state,
        filterValues: action.payload,
      };
    }

    case CAMPAIGNS_CLEAR_FILTERS: {
      return {
        ...state,
        filterValues: initialState.filterValues,
      };
    }

    default: {
      return state;
    }
  }
}
