import { SET_CURRENCY_ON_DEFAULT } from '~/actions/localization';
import { localizationKey } from '~/constants/storageKeys';
import { set } from '~/helpers/localStorage';

export const SET_LOADING = 'SET_LOADING';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_COMPANY_LOCALIZATION = 'SET_COMPANY_LOCALIZATION';

const initialState = {
  loading: false,
  defaultCurrency: 'ILS',
  currency: 'ILS',
  companyLocalization: {},
};

export default function (state = initialState, action) {
  const { type: actionType, payload } = action;

  switch (actionType) {
    case SET_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }

    case SET_CURRENCY: {
      return {
        ...state,
        currency: payload,
      };
    }

    case SET_CURRENCY_ON_DEFAULT: {
      return {
        ...state,
        currency: state.defaultCurrency,
      };
    }

    case SET_COMPANY_LOCALIZATION: {
      set(localizationKey, JSON.stringify(payload));
      return {
        ...state,
        companyLocalization: payload,
      };
    }

    default: {
      return state;
    }
  }
}
