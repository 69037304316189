import axiosInstance from '~/utils/axios';
import {
  defaultOrderModelFields,
  defaultOrderVehicleFields,
  clientFields,
  filterKeysModels,
  filterKeysVehicles,
} from '../components/vehicleSearch/defaultKeys';
import { updateOpportunityDocumentSeries, setVSDocumentDetails } from './opportunity_content';
import { modelCar } from '../models/opportunity_content';
import { OPPORTUNITY_NEW_GET_DICTIONARY } from './opportunity_new';
import { PageSizeForRequest } from '~/constants';
import { toClientFormatDate } from '~/utils/general';
import { getAuthorizationHeaders } from './api';
import { VSLineDetailsService } from './api/sync';
import { getDocument } from '~/helpers/dataAdapters/document';
import { setCarPricesSearch } from '~/helpers/VSLineDetails';
import { FilterTab } from '~/pages/vehicle_search/constants';
import { SortOperator } from '~/constants/filter';
import {
  getVehicleModels,
  getVehicleModelsList,
  getVehicleModelsProperties,
  getVehicleOptionsCategoriesListService,
  getVehicles,
  getVehiclesProperties,
} from './api/vehicleSearch';
import { objectKeysPascalCaseToCamelCase } from '~/utils/object';
import {
  createSearchLayout,
  deleteSearchLayout,
  getSearchLayouts,
  getSearchLayoutsFields,
  updateSearchLayout,
} from './api/searchLayout';
import { SearchLayoutObjectModel } from '~/constants/searchLayout';
import { getFilterForRangeValues } from '~/helpers/vehicleSearch';
import { getVehicleCategoriesList, getVehicleSubCategoriesList } from './api/vehicle_categories';
import {
  formatBaseModelsDict,
  formatDict,
  formatVehicleModelsDict,
  formatVehicleSubCategoryDict,
  formatYearDict,
} from './dictionaries';
import { getMakesAll } from './api/makes';
import { getFuelTypes } from './api/fuelTypes';
import { getGearTypes } from './api/gearTypes';
import { getVehicleColors } from './api/vehicleColors';
import { getBaseModels } from './api/baseModels';
import { getYears } from './api/years';
import { getNumberOfDoors } from './api/numberOfDoors';
import { getNumberOfSeats } from './api/numberOfSeats';
import { getEngineDisplacements } from './api/engineDisplacements';

const parseOrderFieldToClient = (order) =>
  order.map((col, i) => col.map((row, j) => clientFields[row]));

export const CHANGE_VALUE_FOR_SEARCH_FIELD = 'CHANGE_VALUE_FOR_SEARCH_FIELD';
export const changeValueForSearchFieldAction = (field, value) => (dispatch) => {
  dispatch({ type: CHANGE_VALUE_FOR_SEARCH_FIELD, field, value });
};

export const CHANGE_VALUE_FOR_NEW_TAB = 'CHANGE_VALUE_FOR_NEW_TAB';
export const changeValueForNewTabAction = (payload) => (dispatch) => {
  dispatch({ type: CHANGE_VALUE_FOR_NEW_TAB, payload });
};

export const CHANGE_VALUE_FOR_USED_TAB = 'CHANGE_VALUE_FOR_USED_TAB';
export const changeValueForUsedTabAction = (payload) => (dispatch) => {
  dispatch({ type: CHANGE_VALUE_FOR_USED_TAB, payload });
};

export const CLEAR_TABS = 'CLEAR_TABS';
export const clearTabsAction = () => (dispatch) => {
  dispatch({ type: CLEAR_TABS });
};

export const CHANGE_VALUE_FOR_ADVANCED = 'CHANGE_VALUE_FOR_ADVANCED';
export const changeValueForAdvancedAction = (value, count) => (dispatch, getState) => {
  const checkboxes = getState().vehicleSearch.checkbox;
  const payload = checkboxes.map((item, i) => {
    if (i === count) {
      return { ...item, ...value };
    }
    return item;
  });
  dispatch({ type: CHANGE_VALUE_FOR_ADVANCED, payload });
};

export const clearAllValueForAdvancedAction = () => (dispatch, getState) => {
  const checkboxes = getState().vehicleSearch.checkbox;
  const payload = checkboxes.map((item) => {
    let box = {};
    Object.keys(item).forEach((key) => {
      box[key] = false;
    });
    return box;
  });

  dispatch({ type: CHANGE_VALUE_FOR_ADVANCED, payload });
  dispatch({ type: VEHICLE_SEARCH_GET_DATA, payload: [] });
};

export const VEHICLE_SEARCH_TOGGLE_LOADER = 'VEHICLE_SEARCH_TOGGLE_LOADER';
export const VEHICLE_SEARCH_TOGGLE_SYNC_LOADER = 'VEHICLE_SEARCH_TOGGLE_SYNC_LOADER';
export const VEHICLE_SEARCH_GET_DATA = 'VEHICLE_SEARCH_GET_DATA';
export const VEHICLE_SEARCH_GET_MODELS = 'VEHICLE_SEARCH_GET_MODELS';
export const VEHICLE_SEARCH_GET_MODELS_VARIANTS = 'VEHICLE_SEARCH_GET_MODELS_VARIANTS';
export const CLEAR_VEHICLE_SEARCH_GET_MODELS_VARIANTS = 'CLEAR_VEHICLE_SEARCH_GET_MODELS_VARIANTS';
export const VEHICLE_SEARCH_GET_VEHICLES = 'VEHICLE_SEARCH_GET_VEHICLES';
export const VEHICLE_SEARCH_GET_VEHICLES_LOADER = 'VEHICLE_SEARCH_GET_VEHICLES_LOADER';
export const VEHICLE_SEARCH_TOGGLE_LOADING = 'VEHICLE_SEARCH_TOGGLE_LOADING';

export const getFilterQuery = (filterValues, checkboxes = [], keys) => {
  const filters = {};

  Object.entries(filterValues).forEach(([key, item]) => {
    const filterKey = keys[key] || key;

    if (['U_From_Year', 'U_To_Year'].includes(key)) {
      filters[filterKey] = getFilterForRangeValues(
        filterValues.U_From_Year,
        filterValues.U_To_Year,
      );
    } else if (['MileageFrom', 'MileageTo'].includes(key)) {
      filters[filterKey] = getFilterForRangeValues(
        filterValues.MileageFrom,
        filterValues.MileageTo,
        true,
      );
    } else if (['PriceFrom', 'PriceTo'].includes(key)) {
      filters[filterKey] = getFilterForRangeValues(
        filterValues.PriceFrom,
        filterValues.PriceTo,
        true,
      );
    } else {
      filters[filterKey] = item;
    }
  });

  const checkboxFilters = {};

  checkboxes.forEach((checkboxFilter) => {
    Object.entries(checkboxFilter).forEach(([key, item]) => {
      checkboxFilters[key] = {
        value: item,
      };
    });
  });

  let srcFilterValues = {
    ...filters,
    ...checkboxFilters,
  };

  return srcFilterValues;
};

const syncQuery = (data, searchType) => async (dispatch, getState) => {
  const { opportunity: opportunityState, lead: leadState } = getState();

  const { new: opportunity } = opportunityState;
  const { leadData: lead } = leadState;

  let opportunityOrLead;
  if (opportunity.id) {
    opportunityOrLead = opportunity;
  }
  if (lead.Id) {
    opportunityOrLead = lead;
  }

  const isNewSearchType = searchType === 'New';

  let cars = isNewSearchType ? data : data.filter((item) => item.vehicleCode && item.code);

  let lines = [];

  if (cars && cars.length) {
    lines = cars.map((car) => ({
      LineType: searchType,
      ItemCode: car.code,
      VehicleID: !isNewSearchType ? car.vehicleCode : undefined,
    }));
  }

  if (lines.length) {
    try {
      dispatch({ type: VEHICLE_SEARCH_TOGGLE_SYNC_LOADER, payload: true });

      const document = getDocument(lines, 'Search', opportunityOrLead);
      const linesDetails = await VSLineDetailsService(document);

      dispatch(
        setVSDocumentDetails({
          DF_BranchNetCode: linesDetails?.DF_BranchNetCode,
          Currency: linesDetails?.DocCurrency,
          Rate: linesDetails?.DocRate,
        }),
      );

      const linesDetailsCars = linesDetails?.XAS_VSLINESSERVICE1Collection || [];

      let resultCars = [];

      if (isNewSearchType) {
        resultCars = linesDetailsCars.map((item, index) => {
          const car = setCarPricesSearch({ ...cars[index] }, item);

          return modelCar(car);
        });
      } else {
        resultCars = data.map((car) => {
          const findCarDetails = linesDetailsCars.find(
            (line) => line.ItemCode === car.itemCode && line.VehicleID === car.vehicleCode,
          );

          const adaptCar = setCarPricesSearch({ ...car }, findCarDetails || {});

          return modelCar(adaptCar);
        });
      }

      if (resultCars && resultCars.length) {
        const filterValues = isNewSearchType
          ? getState().vehicleSearch.newTabs
          : getState().vehicleSearch.usedTabs;

        if (filterValues.DeliveryDate?.value) {
          resultCars = resultCars.filter(
            (car) =>
              toClientFormatDate(car.deliveryDate) ===
              toClientFormatDate(filterValues.DeliveryDate),
          );
        }

        dispatch({
          type: VEHICLE_SEARCH_GET_MODELS_VARIANTS,
          payload: { data: resultCars },
        });
      }
      dispatch({ type: VEHICLE_SEARCH_TOGGLE_SYNC_LOADER, payload: false });

      dispatch(updateOpportunityDocumentSeries(linesDetails));
    } catch (error) {
      console.log(error);
      dispatch({ type: VEHICLE_SEARCH_TOGGLE_SYNC_LOADER, payload: false });
    }
  } else {
    dispatch({ type: VEHICLE_SEARCH_TOGGLE_SYNC_LOADER, payload: false });
  }
};

export const vehicleSearchAction = (typeSearch) => async (dispatch, getState) => {
  const { vehicleSearch: vehicleSearchState } = getState();
  const {
    headerSearch: { search },
    newTabs,
    usedTabs,
    checkbox,
    paging,
  } = vehicleSearchState;

  let filters = {};

  if (typeSearch === FilterTab.newTab) {
    filters = getFilterQuery(newTabs, checkbox, filterKeysModels);
  } else {
    filters = getFilterQuery(usedTabs, checkbox, filterKeysVehicles);
  }

  const path =
    typeSearch === FilterTab.newTab ? 'VehicleModelVariantsListService' : 'VehiclesListService';

  dispatch({ type: VEHICLE_SEARCH_TOGGLE_LOADING, payload: true });

  if (typeSearch !== FilterTab.newTab) {
    filters = {
      ...filters,
      isSalable: {
        value: true,
      },
      newUsed: {
        value: 'U',
      },
    };
  }

  const params = {
    filter: filters,
    sort: { name: SortOperator.asc },
    paging: {
      page: paging.currentPage + 1,
      count: paging.pageSize,
    },
  };

  try {
    const { records = [], total = 0 } = await getVehicleModels(path, params, search);

    const data = getDataResponseSearch(typeSearch, records);

    const adaptResponse = objectKeysPascalCaseToCamelCase(data);

    dispatch({ type: VEHICLE_SEARCH_GET_MODELS_VARIANTS, payload: { data: adaptResponse, total } });

    const searchType = typeSearch === FilterTab.newTab ? 'New' : 'Used';

    dispatch(syncQuery(adaptResponse, searchType));
  } catch (error) {
    console.error('error: ', error);
  } finally {
    dispatch({ type: VEHICLE_SEARCH_TOGGLE_LOADING, payload: false });
  }
};

export const loadVehicles =
  ({ modelId, typeSearch, requestParams: { filter, paging = null }, isAppend = false }) =>
  async (dispatch) => {
    const params = {
      filter,
      paging,
    };

    dispatch({ type: VEHICLE_SEARCH_GET_VEHICLES_LOADER, payload: { modelId, value: true } });

    const { records = [], total = 0 } = await getVehicles(params);
    const adaptResponse = objectKeysPascalCaseToCamelCase(records);
    const searchType = typeSearch === FilterTab.newTab ? 'New' : 'Used';

    dispatch(syncVehicleQuery({ cars: adaptResponse, total }, searchType, modelId, isAppend));
  };

const syncVehicleQuery =
  (response, searchType, modelId, isAppend) => async (dispatch, getState) => {
    const { cars = [], total } = response;
    const { opportunity: opportunityState, lead: leadState } = getState();
    const { new: opportunity } = opportunityState;
    const { leadData: lead } = leadState;

    let opportunityOrLead;

    if (opportunity.id) {
      opportunityOrLead = opportunity;
    }
    if (lead.Id) {
      opportunityOrLead = lead;
    }

    let lines = [];

    if (cars.length) {
      lines = cars.map((car) => ({
        LineType: searchType,
        ItemCode: car.itemCode,
        VehicleID: car.vehicleCode,
      }));
    }

    const { selectQuote, currentOrder } = getState().opportunity.content;

    const opportunityDocument = currentOrder || selectQuote;

    if (lines.length) {
      try {
        const document = getDocument(lines, 'Search', opportunityOrLead);
        const linesDetails = await VSLineDetailsService(document);

        let resultCars = (linesDetails?.XAS_VSLINESSERVICE1Collection || []).map((item, index) => {
          const car = setCarPricesSearch({ ...cars[index] }, item, opportunityDocument?.Rate);
          return modelCar(car);
        });

        dispatch({
          type: VEHICLE_SEARCH_GET_VEHICLES,
          payload: { data: resultCars, total, modelId, isAppend },
        });

        dispatch(updateOpportunityDocumentSeries(linesDetails));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: VEHICLE_SEARCH_GET_VEHICLES_LOADER, payload: { modelId, value: false } });
      }
    } else {
      dispatch({ type: VEHICLE_SEARCH_GET_VEHICLES_LOADER, payload: { modelId, value: false } });
    }
  };

export const clearVehicleModelVariants = () => (dispatch) => {
  dispatch({ type: CLEAR_VEHICLE_SEARCH_GET_MODELS_VARIANTS, payload: [] });
};

// TODO:
export const getVehiclesListForTestDrive = () => (dispatch) => {
  axiosInstance
    .get(`/xassales/v1/VehiclesListService?pageSize=100`, {
      headers: getAuthorizationHeaders(),
    })
    .then(({ data: response }) => {
      const data = getDataResponseSearch('usedTab', response)
        .map((car) => ({
          label: car.name ? car.name : car.code,
          value: car.ModelVariantId,
        }))
        .filter((car) => car.label);
      dispatch({ type: OPPORTUNITY_NEW_GET_DICTIONARY, payload: { vehicles: data } });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const RESET_VALUES = 'RESET_VALUES';
export const resetAllParameters = () => (dispatch) => {
  dispatch({ type: RESET_VALUES });
};

export const VEHICLE_SEARCH_GET_DICTIONARIES = 'VEHICLE_SEARCH_GET_DICTIONARIES';
export const getDictionaries = (tab) => async (dispatch, getState) => {
  const { vehicleSearch } = getState();
  const { options = {} } = vehicleSearch;

  // TODO add new filters for all requests
  const newOrUsedFilter = tab === 'New' ? ['newOrUsed:=:N'] : [];

  const query = `?sort=name:-1&pageSize=${PageSizeForRequest}`;

  const params = {
    sort: {
      name: SortOperator.asc,
    },
    filter: {
      newOrUsed: {
        value: tab === 'New' ? 'N' : null,
      },
    },
    pagingResponse: false,
  };

  const payload = {
    ...options,
  };

  const promises = [];

  const getPromise = (request, field, formatter = formatDict, requestParams = query) =>
    new Promise(async (resolve, reject) => {
      try {
        const items = await request(requestParams);
        payload[field] = items.map(formatter);

        resolve();
      } catch (error) {
        reject(error);
      }
    });

  // if (options.categories.length === 0) {
    promises.push(getPromise(getVehicleCategoriesList, 'categories'));
  // }

  // if (options.subCategories.length === 0) {
    promises.push(
      getPromise(getVehicleSubCategoriesList, 'subCategories', formatVehicleSubCategoryDict),
    );
  // }

  // if (options.makes.length === 0) {
    promises.push(getPromise(getMakesAll, 'makes', undefined, newOrUsedFilter));
  // }

  // if (options.fuelType.length === 0) {
    promises.push(getPromise(getFuelTypes, 'fuelType'));
  // }

  // if (options.gearType.length === 0) {
    promises.push(getPromise(getGearTypes, 'gearType'));
  // }

  // if (options.colors.length === 0) {
    promises.push(getPromise(getVehicleColors, 'colors'));
  // }

  // if (options.baseModels.length === 0) {
    promises.push(getPromise(getBaseModels, 'baseModels', formatBaseModelsDict, newOrUsedFilter));
  // }

  // if (options.model.length === 0) {
    promises.push(getPromise(getVehicleModelsList, 'model', formatVehicleModelsDict, params));
  // }

  // if (options.years.length === 0) {
    promises.push(getPromise(getYears, 'years', formatYearDict));
  // }

  // if (options.numberOfDoors.length === 0) {
    promises.push(getPromise(getNumberOfDoors, 'numberOfDoors'));
  // }

  // if (options.numberOfSeats.length === 0) {
    promises.push(getPromise(getNumberOfSeats, 'numberOfSeats'));
  // }

  // if (options.engine_displacement.length === 0) {
    promises.push(getPromise(getEngineDisplacements, 'engine_displacement'));
  // }

  // TODO: catch - ???
  await Promise.allSettled(promises);

  dispatch({
    type: VEHICLE_SEARCH_GET_DICTIONARIES,
    payload: payload,
  });
};

export const setModelsDictionary = (data) => (dispatch) => {
  dispatch({ type: VEHICLE_SEARCH_GET_DICTIONARIES, payload: { model: data } });
};

export const loadModels = async (filter) => {
  const params = {
    sort: {
      name: SortOperator.asc,
    },
    filter: {
      ...filter,
    },
    pagingResponse: true,
  };

  const data = await getVehicleModelsList(params);

  const result = {
    data: (data.records || []).map((item) => ({
      label: item.Name,
      value: item.Id,
      baseModelId: item.BaseModelId?.Id,
      colors: item.Colors,
      subCategoryId: item.SubCategory?.Id,
      categoryId: item.Category?.Id,
      makeId: item.Make?.Id,
    })),
    total: data.total,
  };

  return result;
};

export const getYearsBounds = (yearsArr) => {
  let years = yearsArr.map((y) => parseInt(y.label));

  return {
    min: years.length ? Math.min(...years) : 2000,
    max: years.length ? Math.max(...years) : 2020,
  };
};

// Pagination need rewrite on new page structure
export const VEHICLE_SEARCH_CHANGE_PAGING = 'VEHICLE_SEARCH_CHANGE_PAGING';
export const changeRowsPerPage =
  (count, activeTab, isNeedRefresh = false) =>
  (dispatch, getState) => {
    const { paging, cars } = getState().vehicleSearch;
    const isLength = cars.length;

    let pagingFrom = count * paging.currentPage;
    let pagingTo = count;

    if (pagingFrom > isLength) {
      pagingFrom = 0;
    }

    if (pagingTo > isLength) {
      pagingTo = isLength;
    }

    const payload = {
      pageSize: count,
      pagingTo,
      pagingFrom,
    };

    dispatch({ type: VEHICLE_SEARCH_CHANGE_PAGING, payload });

    if (isNeedRefresh) {
      dispatch(vehicleSearchAction(activeTab));
    }
  };

export const changePage =
  (page, activeTab, isNeedRefresh = false) =>
  (dispatch, getState) => {
    const { paging } = getState().vehicleSearch;

    const pagingFrom = paging.pageSize * page;
    const pagingTo = paging.pageSize;

    const payload = {
      currentPage: page,
      pagingTo,
      pagingFrom,
    };

    dispatch({ type: VEHICLE_SEARCH_CHANGE_PAGING, payload });

    if (isNeedRefresh) {
      dispatch(vehicleSearchAction(activeTab));
    }
  };

export const getEquipment = (properties) => {
  let propertiesByCategories = {};
  let categoriesSort = {};
  if (!properties) {
    return properties;
  }

  properties.forEach((item) => {
    let itemCategory = item?.CategoryId?.Name || null;
    let categorySort = item?.CategoryId?.Code || null;
    let categoryType = item?.CategoryId?.Type || null;

    if (itemCategory) {
      if (!propertiesByCategories[itemCategory]) {
        propertiesByCategories[itemCategory] = [];
        categoriesSort[itemCategory] = {
          code: categorySort,
          type: categoryType,
        };
      }

      let name = item?.Name || null;
      name = name ? name : item?.name || null;

      let value = item?.Value || null;
      value = value ? value : item?.value || null;

      let sort = item?.Sort || null;

      propertiesByCategories[itemCategory] = [
        ...propertiesByCategories[itemCategory],
        { label: name, value: value, sort: sort },
      ];
    }
  });

  //sort properties
  Object.keys(propertiesByCategories).forEach((category) => {
    propertiesByCategories[category].sort((a, b) => {
      return parseInt(a.sort) - parseInt(b.sort);
    });
  });

  //sort categories
  const propertiesByCategoriesResult = [];
  Object.keys(categoriesSort)
    .sort((a, b) => categoriesSort[a].code - categoriesSort[b].code)
    .forEach((category) => {
      propertiesByCategoriesResult.push({
        name: category,
        type: categoriesSort[category].type,
        items: propertiesByCategories[category],
      });
    });

  return propertiesByCategoriesResult;
};

const getDataResponseSearch = (type, response = []) => {
  let result = [];

  if (type === FilterTab.newTab) {
    result = response.map((item) => {
      let clientItemFields = {};
      Object.keys(clientFields).forEach((itemKey) => {
        clientItemFields[clientFields[itemKey]] = item[itemKey];
      });

      const car = {
        ...clientItemFields,

        isAdd: true,

        // TODO: this is WRONG, need to use uuid()
        lineId: item.Id,
        id: item.Id,
        Name: item.Make.Name,
        ModelName: item.ModelId.BaseModelName,
        ModelId: item.ModelId,
        ModelVariantId: item.Id,
        make: item.Make,
        base_model: item.BaseModelId?.Id,
        model_desc: item.Description,
        description: item.Description,
        year: item.Year?.Code || item.Year,
        code: item.Code || '',
        itemCode: item.Code || '',
        orderFields: parseOrderFieldToClient(
          item.Category?.SearchLayout ? item.Category?.SearchLayout : defaultOrderModelFields,
        ),
        category: item.Category,
        subCategory: item.SubCategory,
        images: item.Photos || [],
        imageLink: item.PhotoLink || '',
        kitIds: item.KitIds,
        color: item.Colors,
        bodyColor: item.Color,
        interiorColor: item.InteriorColor,
        parametrs: {
          details: {
            gear_type: item.GearType,
            number_gears: '',
            displacement: item.EngineDisplacement,
            cylinders: item.CylindrsNum,
            weight: item.EmptyWeight,
            body_color: item.Colors,
            interior_color: '',
            number_doors: item.DoorsNum,
            number_seats: item.SeatsNum,
            fuel_type: item.FuelType,
            images: item.Photos || [],
          },
          environment: {
            fuel: item.ModelId.FuelType,
            consumption: {
              comb: `${item.ModelId.FuelConsumptionCombined} (comb)`,
              city: `${item.ModelId.CityCycleConsumption} (city)`,
              country: `${item.ModelId.LongDistanceConsumption} (country)`,
            },
            emission_label: item.EmissionSticker,
          },
          equipment: getEquipment(item.ModelId.ModelProperties || []),
        },
      };

      return modelCar(car);
    });
  } else {
    result = response.map((item) => {
      let clientItemFields = {};
      Object.keys(clientFields).forEach((itemKey) => {
        clientItemFields[clientFields[itemKey]] = item[itemKey];
      });

      return modelCar({
        ...clientItemFields,

        isAdd: true,

        type: 'Used',
        // TODO: this is WRONG, need to use uuid()
        lineId: item.Id,
        id: item.Id,
        modelId: item.ModelId.Id,
        ModelVariantId: item.Id,
        name: item.Name || '',
        make: item.Make?.Id || '',
        year: item.Year?.Code,
        code: item.ItemCode || '',
        itemCode: item.ItemCode || '',
        vehicleCode: item.VehicleCode || '',
        base_model: item.BaseModelId?.Id,
        isReserved: item.IsReserved,
        category: item.Category,
        subCategory: item.SubCategory,
        orderFields: parseOrderFieldToClient(
          item.Category?.SearchLayout ? item.Category?.SearchLayout : defaultOrderVehicleFields,
        ),
        images: item.Photos || [],
        imageLink: item.PhotoLink || '',
        items: [
          {
            vin: item.Vin,
            note: item.Notes,
          },
        ],
        parametrs: {
          details: {
            displacement: item.EngineDisplacement,
            cylinders: item.CylindrsNum,
            weight: item.EmptyWeight,
            body_color: item.Colors,
            interior_color: '',
            gear_type: item.GearType,
            number_doors: item.DoorsNum,
            number_seats: item.SeatsNum,
            fuel_type: item.FuelType,
            images: item.Photos || [],
          },
          equipment: getEquipment(item.VehicleProperties),
        },
      });
    });
  }

  return result;
};

/* Customer Interest */

export const SET_CUSTOMER_INTEREST = 'SET_CUSTOMER_INTEREST';
export const setCustomerInterest = (data) => (dispatch) => {
  dispatch({ type: SET_CUSTOMER_INTEREST, payload: data });
};

export const LOAD_SEARCH_LAYOUTS_FIELDS = 'LOAD_SEARCH_LAYOUTS_FIELDS';
export const loadSearchLayoutsFields =
  (params = {}) =>
  async (dispatch) => {
    const fields = await getSearchLayoutsFields(params);
    dispatch({ type: LOAD_SEARCH_LAYOUTS_FIELDS, payload: fields });
  };

export const SET_SEARCH_LAYOUTS = 'SET_SEARCH_LAYOUTS';
export const loadSearchLayouts =
  (params = {}) =>
  async (dispatch) => {
    const searchLayouts = await getSearchLayouts(params);
    dispatch({ type: SET_SEARCH_LAYOUTS, payload: searchLayouts });
  };

export const UPDATE_SEARCH_LAYOUT = 'UPDATE_SEARCH_LAYOUT';
export const updateSearchLayoutAction = (layout) => async (dispatch, getState) => {
  const { vehicleSearch } = getState();
  const { searchLayouts = [] } = vehicleSearch;

  let changedLayout = {};
  const layouts = [...searchLayouts];

  try {
    if (layout.IsDefault) {
      const newLayout = { ...layout };
      delete newLayout.IsDefault;

      changedLayout = await createSearchLayout(newLayout);
    } else {
      changedLayout = await updateSearchLayout(layout.Id, layout);
    }

    const matchIndex = searchLayouts.find((item) => item.Id === changedLayout.Id);

    if (matchIndex === -1) {
      layouts.push(changedLayout);
    } else {
      layouts.splice(matchIndex, 0, changedLayout);
    }

    dispatch({ type: SET_SEARCH_LAYOUTS, payload: layouts });
  } catch (error) {
    console.error('error: ', error);
  }
};

export const DELETE_SEARCH_LAYOUT = 'DELETE_SEARCH_LAYOUT';
export const deleteSearchLayoutAction = (id) => async (dispatch, getState) => {
  const { vehicleSearch } = getState();
  const { searchLayouts = [] } = vehicleSearch;

  try {
    await deleteSearchLayout(id);

    const newLayouts = searchLayouts.filter((item) => item.Id !== id);

    dispatch({ type: SET_SEARCH_LAYOUTS, payload: newLayouts });
  } catch (error) {
    console.error('error: ', error);
  }
};

export const SET_VEHICLE_OPTION_CATEGORIES = 'SET_VEHICLE_OPTION_CATEGORIES';
export const getVehicleOptionsCategoriesListServiceAction = () => async (dispatch) => {
  try {
    const categories = await getVehicleOptionsCategoriesListService();

    dispatch({ type: SET_VEHICLE_OPTION_CATEGORIES, payload: categories });
  } catch (error) {
    console.error('error: ', error);
  }
};

export const SET_VEHICLE_PROPERTIES = 'SET_VEHICLE_PROPERTIES';
export const getVehiclePropertiesAction = (params, objectModel) => async (dispatch, getState) => {
  try {
    let properties = [];

    if (objectModel === SearchLayoutObjectModel.Vehicle) {
      properties = await getVehiclesProperties(params);
    }

    if (objectModel === SearchLayoutObjectModel.VehicleModel) {
      properties = await getVehicleModelsProperties(params);
    }

    const adaptProperties = {};

    properties.forEach((property) => {
      adaptProperties[property.CategoryId.Name] = [
        ...(adaptProperties[property.CategoryId.Name] || []),
        property,
      ];
    });

    dispatch({ type: SET_VEHICLE_PROPERTIES, payload: adaptProperties });
  } catch (error) {
    console.error('error: ', error);
  }
};
