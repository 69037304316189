import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';
import { filterKeysVehicles } from '../components/vehicleSearch/defaultKeys';
import { VSLineDetailsService } from './api/sync';
import { getDocument } from '~/helpers/dataAdapters/document';
import { isObjectsEqual } from '~/utils/general';
import vehiclesData from '~/data/vehicles';
import { getOperationByParam } from '~/components/opportunities/table/helper';
import { formatVehicleCategory } from './api/vehicle_categories';
import { getSalesData } from './api/getSalesData';
import { formatLabelValue } from '~/helpers/dataAdapters/formatLabelValue';
import { getVehicles } from './api/vehicles';

const keysSearch = {
  Description: 'name',
  Price: 'Price',
  Type: 'Type',
  Status: 'Status',
  FuelType: 'FuelType',
  GearType: 'GearType',
  Mileage: 'mileage',
  NumHands: 'NumHands',
};

const getFilterQueryTable = (filterValues) => {
  const search =
    filterValues.search && filterValues.search.value
      ? `filter=$text:${filterValues.search.value}`
      : '';

  let filterString = '?';
  Object.keys(filterValues).forEach((key, i) => {
    if (filterValues[key] && filterValues[key].value !== '' && key !== 'search') {
      let filterKey = keysSearch[key] || key;
      let operation = getOperationByParam(filterValues[key].params);
      let value = filterValues[key].value;

      switch (filterValues[key].params) {
        case 'is empty':
        case 'is not empty':
          value = null;
          break;
        default:
      }

      let filterPart = `${filterKey}:${operation}:${value}`;
      let filterPartExists = filterString.split(filterPart).length > 1;

      if (!filterPartExists) {
        filterString += `${filterString.length > 1 ? '&' : ''}filter=${filterPart}`;
      }
    }
  });

  const pageSize = `pageSize=all`;

  filterString =
    filterString.length > 1
      ? search
        ? `${filterString}${search}&${pageSize}`
        : `${filterString}&${pageSize}`
      : search
      ? `?${search}&${pageSize}`
      : `?${pageSize}`;

  return filterString;
};

export const getFilterQuery = () => (dispatch, getState) => {
  const { text, make, model, year_from, year_to, categories } = getState().vehicles.filters;

  const search = text ? `filter=$text:${text}` : '';
  const filterValues = {
    U_Category: categories && categories.map((category) => category?.value || null),
    U_From_Year: year_from && year_from.map((year) => parseInt(year?.label || null)),
    U_To_Year: year_to && year_to.map((year) => parseInt(year?.label || null)),
    U_Make: make && make.map((make) => make?.value || null),
    U_Model: model && model.map((model) => model?.value || null),
  };

  let filterString = '?';

  Object.keys(filterValues).forEach((key, i) => {
    if (filterValues[key] && filterValues[key].length) {
      let filterKey = filterKeysVehicles[key] || key;
      let operation = 'in';
      let value = JSON.stringify(filterValues[key]);

      switch (key) {
        case 'U_From_Year':
        case 'U_To_Year':
          let yearFrom = filterValues.U_From_Year[0];
          let yearTo = filterValues.U_To_Year[0];
          if (yearFrom && yearTo) {
            operation = 'between';
            value = JSON.stringify([yearFrom, yearTo]);
          } else {
            if (yearFrom) {
              operation = '>=';
              value = yearFrom;
            } else {
              if (yearTo) {
                operation = '<=';
                value = yearTo;
              }
            }
          }
          break;
        default:
      }
      let filterPart = `${filterKey}:${operation}:${value}`;
      let filterPartExists = filterString.split(filterPart).length > 1;

      if (!filterPartExists) {
        filterString += `${filterString.length > 1 ? '&' : ''}filter=${filterPart}`;
      }
    }
  });

  const pageSize = 'pageSize=all';

  filterString =
    filterString.length > 1
      ? search
        ? `${filterString}${search}&${pageSize}`
        : `${filterString}&${pageSize}`
      : `?${pageSize}`;

  return filterString;
};

export const CHANGE_VEHICLES_LOADER = 'CHANGE_VEHICLES_LOADER';
export const changeLoaderAction = (loader, payload) => (dispatch) => {
  dispatch({ type: CHANGE_VEHICLES_LOADER, loader, payload });
};

export const CHANGE_VEHICLES_DATA = 'CHANGE_VEHICLES_DATA';
export const VEHICLES_SET_TOTAL = 'VEHICLES_SET_TOTAL';
export const getVehiclesData = () => async (dispatch, getState) => {
  const { paging } = getState().vehicles;
  const { currentPage, pageSize } = paging;

  dispatch(changeLoaderAction('loader', true));

  try {
    const { records: vehicles = [], total = 0 } = await getVehicles({
      paging: { page: currentPage + 1, count: pageSize },
    });

    const payload = vehicles.map((item) => {
      return {
        Code: item.ItemCode || '',
        VehicleCode: item.VehicleCode || '',
        Id: item.Id || '',
        Description: item.Name || '',
        FuelType: item.FuelType?.Name || '',
        GearType: item.GearType?.Name || '',
        Mileage: item.Mileage || '',
        PhotoLink: item.PhotoLink || '',
        Image: item.Photos || [],
        Status: item.Status || '',
        Type: item.Type || '', // todo not in object vehicles
        Hands: '', // todo not in object vehicles
        Price: 0,
      };
    });
    dispatch({ type: CHANGE_VEHICLES_DATA, payload });
    dispatch({ type: VEHICLES_SET_TOTAL, payload: total });
    dispatch(changeLoaderAction('loader', false));

    dispatch(getPriceForCars(payload));
  } catch (error) {
    dispatch(changeLoaderAction('loader', false));
  }
};

const getPriceForCars = (cars) => async (dispatch) => {
  dispatch(changeLoaderAction('priceLoader', true));

  const lines = cars
    .filter((car) => car.Code && car.VehicleCode)
    .map((car) => ({
      LineType: 'Used',
      ItemCode: car.Code,
      VehicleID: car.VehicleCode,
    }));

  if (lines.length === 0) {
    dispatch(changeLoaderAction('priceLoader', false));
    return;
  }

  try {
    const document = getDocument(lines, 'Search');
    const linesDetails = await VSLineDetailsService(document);

    for (const lineDetail of linesDetails?.XAS_VSLINESSERVICE1Collection || []) {
      const item = cars.find((item) => item.Code === lineDetail.ItemCode);

      item.Price = lineDetail.Price;
    }

    dispatch({ type: CHANGE_VEHICLES_DATA, payload: cars });
    dispatch(changeLoaderAction('priceLoader', false));
  } catch (error) {
    dispatch(changeLoaderAction('priceLoader', false));
  }
};

export const CHANGE_SUGGESTIONS_VEHICLES = 'CHANGE_SUGGESTIONS_VEHICLES';
export const getListsOptions = () => (dispatch) => {
  getSalesData('MakesListService').then((response) => {
    const data = response.map(formatLabelValue);
    dispatch({
      type: CHANGE_SUGGESTIONS_VEHICLES,
      payload: { manufacturers: data },
    });
  });

  getSalesData('VehicleModelsListService').then((response) => {
    const data = response.map(formatLabelValue);
    dispatch({
      type: CHANGE_SUGGESTIONS_VEHICLES,
      payload: { models: data },
    });
  });

  getSalesData('YearsListService').then((response) => {
    const data = response.map(formatLabelValue);
    dispatch({
      type: CHANGE_SUGGESTIONS_VEHICLES,
      payload: { years: data },
    });
  });

  getSalesData('VehicleCategoriesListService').then((response) => {
    const data = response.map(formatVehicleCategory);
    dispatch({
      type: CHANGE_SUGGESTIONS_VEHICLES,
      payload: { categories: data },
    });
  });
};

export const VEHICLES_CHANGE_FILTERS = 'VEHICLES_CHANGE_FILTERS';
export const vehiclesChangeFilters = (key, value) => (dispatch) => {
  dispatch({ type: VEHICLES_CHANGE_FILTERS, key, value });
};

export const VEHICLES_SET_ROWS_PER_PAGE = 'VEHICLES_SET_ROWS_PER_PAGE';
export const changePage = (page) => (dispatch, getState) => {
  const { paging } = getState().filters;

  const pagingFrom = paging.pageSize * page;
  const pagingTo = paging.pageSize;

  const payload = {
    currentPage: page,
    pagingTo,
    pagingFrom,
  };

  dispatch({ type: VEHICLES_SET_ROWS_PER_PAGE, payload });
};

export const changeRowsPerPage = (count) => (dispatch, getState) => {
  const { paging, filteredData } = getState().filters;
  const isLength = filteredData.length;

  let pagingFrom = count * paging.currentPage;
  let pagingTo = count;

  if (pagingFrom > isLength) {
    pagingFrom = 0;
  }

  if (pagingTo > isLength) {
    pagingTo = isLength;
  }

  const payload = {
    pageSize: count,
    pagingTo,
    pagingFrom,
  };

  dispatch({ type: VEHICLES_SET_ROWS_PER_PAGE, payload });
};

export const VEHICLES_CHANGE_FILTER_NAMES = 'VEHICLES_CHANGE_FILTER_NAMES';
export const VEHICLES_CHANGE_FILTER_VALUES = 'VEHICLES_CHANGE_FILTER_VALUES';
export const changeFilters = (key, value, params) => (dispatch, getState) => {
  let filterNames = getState().vehicles.filterNames;
  const isKey = filterNames.includes(key);
  if (isKey && !value) {
    filterNames.filter((item) => item !== key);
  }

  if (!isKey && value) {
    filterNames.push(key);
  }

  dispatch({ type: VEHICLES_CHANGE_FILTER_NAMES, payload: filterNames });
  dispatch({ type: VEHICLES_CHANGE_FILTER_VALUES, key, value, params });
};

export const VEHICLES_CHANGE_MODAL_FIELD = 'APPROVAL_REQUESTS_CHANGE_MODAL_FIELD';
export const changeModalField = (key, value) => (dispatch) => {
  dispatch({ type: VEHICLES_CHANGE_MODAL_FIELD, key, value });
};

export const VEHICLES_SET_DEFAULT_FILTERS_PARAMS = 'APPROVAL_REQUESTS_SET_DEFAULT_FILTERS_PARAMS';
export const setValueForFilter = (count) => (dispatch, getState) => {
  const { filteredData } = getState().filters;
  let payload = null;

  if (count) {
    payload = filteredData.find((item) => item.id === count || item.name === count).filterValues;
  }

  if (!payload) {
    payload = vehiclesData.filterValues;
  }

  dispatch({ type: VEHICLES_SET_DEFAULT_FILTERS_PARAMS, payload });
};
export const VEHICLES_DISABLE_NEW_FILTER = 'APPROVAL_REQUESTS_DISABLE_NEW_FILTER';
export const disableNewFilter = (key) => (dispatch, getState) => {
  const { filteredData } = getState().filters;
  const { filterValues } = getState().vehicles;

  if (key) {
    const item = filteredData.find((item) => item.id === key);
    const payload = isObjectsEqual(filterValues, item.filterValues);
    dispatch({ type: VEHICLES_DISABLE_NEW_FILTER, payload });
  } else {
    const payload = !Object.keys(filterValues).reduce((accumulator, item) => {
      return accumulator || !!filterValues[item].value;
    }, false);
    dispatch({ type: VEHICLES_DISABLE_NEW_FILTER, payload });
  }
};

export const vehiclesGetFilterCounter = (filterValues) => {
  return axiosInstance
    .get(`/xassales/v1/VehiclesListService${getFilterQueryTable(filterValues)}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      return response.data && response.data.length;
    });
};
