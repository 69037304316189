import { axiosInstanceSalesAPI } from '~/utils/axios';
import { getFilterString } from '.';
import { PageSizeAll } from '~/constants';

const listTypesUrl = 'ChecklistTypesListService';
const listObjectTaskUrl = 'ChecklistObjectTasksListService';
const createObjectTaskUrl = 'ChecklistObjectTasksService';

const updateChecklistTaskLineUrl = 'ChecklistObjectTaskLinesService';

export const getChecklistTypes = () =>
  axiosInstanceSalesAPI
    .get(listTypesUrl, {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then(({ data }) => data);

export const getChecklistObjectTasks = (filters) =>
  axiosInstanceSalesAPI
    .get(listObjectTaskUrl + getFilterString(filters), {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then(({ data }) => data);

export const createChecklistObject = (data) =>
  axiosInstanceSalesAPI.post(createObjectTaskUrl, data).then(({ data }) => data);

export const updateChecklistObject = (id, data) =>
  axiosInstanceSalesAPI.patch(`${createObjectTaskUrl}/${id}`, data).then(({ data }) => data);

export const updateChecklistObjectTaskLine = (task) =>
  axiosInstanceSalesAPI
    .patch(`${updateChecklistTaskLineUrl}/${task.Id}`, task)
    .then(({ data }) => data);

export const deleteChecklistTasksByType = (checklistId, typeId) =>
  axiosInstanceSalesAPI
    .delete(`${createObjectTaskUrl}/${checklistId}/by-type/${typeId}`)
    .then(({ data }) => data);
