import { PROFILE_FETCH_USER, PROFILE_UPDATE_LAYOUT, PROFILE_TOGGLE_LOADING } from '../actions/user';

const initialState = {
  user_profile: {},
  loading: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PROFILE_FETCH_USER: {
      return { ...state, user_profile: action.user };
    }

    case PROFILE_UPDATE_LAYOUT: {
      return {
        ...state,
        user_profile: {
          ...state.user_profile,
          dashboardSettings: JSON.stringify(action.layouts),
        },
      };
    }

    case PROFILE_TOGGLE_LOADING: {
      return { ...state, loading: action.loading };
    }

    default: {
      return state;
    }
  }
}
