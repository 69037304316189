export const SET_CHECKLIST_TASKS = 'SET_CHECKLIST_TASKS';
export const SET_CHECKLIST_LOADER = 'SET_CHECKLIST_LOADER';
export const SET_LOADER_FOR_TASK = 'SET_LOADER_FOR_TASK';
export const REMOVE_LOADER_FOR_TASK = 'REMOVE_LOADER_FOR_TASK';

const initialState = {
  checklistTasks: [],
  loaders: {
    checkLists: false,
    loadingChecklistIds: []
  }
};

export default function(state = initialState, action) {
  switch (action.type) {

    case SET_CHECKLIST_TASKS:
      return {
        ...state,
        checklistTasks: action.payload
      };

    case SET_CHECKLIST_LOADER:
      const { loaderName, value } = action.payload
      state.loaders[loaderName] = value;
      return {
        ...state
      }

    case SET_LOADER_FOR_TASK:
      const { id } = action.payload;
      if (!state.loaders.loadingChecklistIds.includes(id)) {
        state.loaders.loadingChecklistIds.push(id);
      }

      return {
        ...state
      }

    case REMOVE_LOADER_FOR_TASK:
      const { removeId } = action.payload;
      state.loaders.loadingChecklistIds = state.loaders.loadingChecklistIds.filter(taskId => taskId !== removeId);

      return {
        ...state
      }


    default:
      return state;
  }
}
