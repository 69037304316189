import axiosInstance from '../utils/axios';
import Cookies from 'js-cookie';
import cloneDeep from 'lodash.clonedeep';
import {
  getQuotations,
  getQuote,
  getQuoteLine,
  updateQuoteWithLines,
} from '~/actions/api/quotations';
import { getOrderLine, updateOrderWithLines } from '~/actions/api/orders';
import {
  createOrder,
  createOrderLineSimple,
  updateDescriptionOpportunity,
} from './opportunity_content';
import { modelDocuments } from '../models/opportunity_document';
import { getDeliveriesList } from './api/deliveries';
import { formatDelivery } from '~/helpers/dataAdapters/delivery';
import { DocumentType } from '~/constants/document';
import { t } from '~/utils/lang';

export const DOCUMENTS_CLEAR_STATE = 'DOCUMENTS_CLEAR_STATE';
export const clearDocuments = () => (dispatch) => {
  dispatch({ type: DOCUMENTS_CLEAR_STATE });
};

export const ADD_TO_DOCUMENTS = 'ADD_TO_DOCUMENTS';
export const CHANGE_TOGGLE_LOADER = 'CHANGE_TOGGLE_LOADER';
export const getQuotesForDocument = (id, type) => async (dispatch) => {
  dispatch({ type: CHANGE_TOGGLE_LOADER, payload: true });
  return getQuotations({
    filter: [`linkedObjectCode:=:${id}`, `linkedObjectName:=:${type}`],
  })
    .then((response) => {
      const payload = [];
      if (response.data.length) {
        // Should to create a draft quote only by save. Filter need that no show not saved quotes.
        // TODO: should to remake quote creation on vehicle_search.js page (270 line);
        response.data
          .filter((doc) => doc.Lines.length > 0)
          .forEach((item, i) => {
            const status = item.IsDraft ? 'Draft' : item.DocumentStatus || '';
            payload.push(
              modelDocuments({
                id: item.Id,
                documentCode: item.DocumentCode,
                externalRef: item.ExternalRef,
                type: DocumentType.Quotation,
                isDraft: item.IsDraft,
                makePrimary: item.IsPrimary || false,
                documentName: `${t.t('button_plus.quote')} ${item.DocumentCode}`,
                customerName: item.CustomerCode?.Name || '',
                customerEmail: item.CustomerCode?.Email || '',
                createdBy: item.CreatedBy?.UserName || '',
                creationDate: item.PostingDate || '',
                expirationDate: item.ValidUntil || '',
                status: status,
                approvalStatus: item.ApprovalStatus || '',
                notes: '',
                isSigned: item.IsSigned || false,
                total: item.Total || '',
                lines: item.Lines || '',
              }),
            );
          });
      }

      dispatch({ type: ADD_TO_DOCUMENTS, payload });
      dispatch({ type: CHANGE_TOGGLE_LOADER, payload: false });
    })
    .catch((err) => {
      dispatch({ type: CHANGE_TOGGLE_LOADER, payload: false });
    });
};

export const getOrdersForDocuments = (id, type) => async (dispatch) => {
  dispatch({ type: CHANGE_TOGGLE_LOADER, payload: true });
  await axiosInstance
    .get(
      `/xassales/v1/OrdersListService?filter=linkedObjectCode:=:${id}&filter=linkedObjectName:=:${type}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
        },
      },
    )
    .then((response) => {
      const payload = [];
      if (response.data.length) {
        response.data.forEach((item, i) => {
          const status = item.IsDraft ? 'Draft' : item.DocumentStatus || '';
          payload.push(
            modelDocuments({
              id: item.Id,
              documentCode: item.DocumentCode,
              externalRef: item.ExternalRef,
              type: DocumentType.Order,
              isDraft: item.IsDraft,
              makePrimary: item.IsPrimary || false,
              documentName: `${t.t('button_plus.order')} ${item.DocumentCode}`,
              customerName: item.CustomerCode?.Name || '',
              customerEmail: item.CustomerCode?.Email || '',
              createdBy: item.CreatedBy?.UserName || '',
              creationDate: item.PostingDate || '',
              expirationDate: item.ValidUntil || '',
              status: status,
              approvalStatus: item.ApprovalStatus || '',
              notes: '',
              isSigned: item.IsSigned || false,
              total: item.Total || '',
              lines: item.Lines || '',
            }),
          );
        });
      }

      dispatch({ type: ADD_TO_DOCUMENTS, payload });
      dispatch({ type: CHANGE_TOGGLE_LOADER, payload: false });
    })
    .catch((err) => {
      dispatch({ type: CHANGE_TOGGLE_LOADER, payload: false });
    });
};

export const ADD_DELIVERIES_TO_DOCUMENTS = 'ADD_DELIVERIES_TO_DOCUMENTS';
export const getDeliveriesForDocuments = (id, type) => async (dispatch) => {
  dispatch({ type: CHANGE_TOGGLE_LOADER, payload: true });

  try {
    const deliveries = await getDeliveriesList(id, type);

    dispatch({ type: ADD_TO_DOCUMENTS, payload: deliveries.map(formatDelivery) });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({ type: CHANGE_TOGGLE_LOADER, payload: false });
  }
};

export const opportunityChangeMakePrimary =
  (opportunityId, id, type) => async (dispatch, getState) => {
    const { documents } = getState().opportunity.documents;
    let isUpdate = null;
    const selectDoc = documents.find((item) => item.id === id);

    await dischargeMakePrimary(documents);
    if (type === 'quote') {
      isUpdate = await updateQuoteWithLines(id, { IsPrimary: true });
      await dispatch(getDescriptionModels(selectDoc, 'quote', opportunityId));
    }
    if (type === 'order') {
      isUpdate = await updateOrderWithLines(id, { IsPrimary: true });
      await dispatch(getDescriptionModels(selectDoc, 'order', opportunityId));
    }

    if (isUpdate) {
      const payload = documents.map((item) => {
        item.makePrimary = id === item.id;
        return item;
      });
      dispatch({ type: SET_DOCUMENTS, payload });
    }
  };

export const CHANGE_SET_DATA = 'CHANGE_SET_DATA';
export const opportunitySetData = (payload) => (dispatch, getState) => {
  dispatch({ type: CHANGE_SET_DATA, payload });
};

export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const opportunityChangeStatusQuote = (id, type, status) => (dispatch, getState) => {
  const { documents } = getState().opportunity.documents;

  const updateCallback = () => {
    if (type === 'quote') {
      updateQuoteWithLines(id, {
        ApprovalStatus: status,
        DocumentStatus: status,
        IsPrimary: false,
      });
    }

    const payload = documents.map((doc) => {
      if (doc.id === id) {
        doc.status = status;
        doc.approvalStatus = status;
        doc.makePrimary = false;
      }
      return doc;
    });
    dispatch({ type: SET_DOCUMENTS, payload });
  };

  updateCallback();
};

export const getQuoteById = (id) => async (dispatch) => {
  dispatch({ type: CHANGE_TOGGLE_LOADER, payload: true });

  try {
    const quotation = await getQuote(id);

    dispatch({ type: CHANGE_TOGGLE_LOADER, payload: false });

    return quotation;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const copyQuoteToOrder = (quoteId) => async (dispatch, getState) => {
  try {
    const quote = await dispatch(getQuoteById(quoteId));

    const date = new Date();
    const postingDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    let order = {
      PostingDate: postingDate,
      CustomerCode: quote.CustomerCode.Rid,
      LinkedObjectCode: quote.LinkedObjectCode,
      LinkedObjectName: quote.LinkedObjectName,
      DocumentStatus: 'Open',
      IsDraft: true,
      IsPrimary: false,
      Total: quote.Total,
      TaxSum: quote.TaxSum,
      TaxPercentage: quote.TaxPercentage,
      DiscountSum: quote.DiscountSum,
      DiscountPercentage: quote.DiscountPercentage,
      TotalBeforeDiscount: quote.TotalBeforeDiscount,
    };

    const newOrderId = await dispatch(createOrder(order));

    const lines = quote.Lines;

    let carLines = [];
    let carItemsLines = [];
    lines.forEach((line) => {
      if (line.ParentLineId) {
        carItemsLines.push(line);
      } else {
        carLines.push(line);
      }
    });

    let carItemsOrderLines = [];
    for (let lineKey in carLines) {
      let line = carLines[lineKey];
      let orderLine = cloneDeep(line);

      delete orderLine.Id;
      delete orderLine.CreatedAt;
      delete orderLine.Notes;
      delete orderLine.DataFilter;
      delete orderLine.QuoteId;
      orderLine.OrderId = newOrderId;

      const newOrderLineId = await dispatch(createOrderLineSimple(orderLine));

      carItemsLines
        .filter((itemLine) => line.Id === itemLine.ParentLineId)
        .forEach((line) => {
          const orderItemLine = cloneDeep(line);
          delete orderItemLine.Id;
          delete orderItemLine.CreatedAt;
          delete orderItemLine.Notes;
          delete orderItemLine.DataFilter;
          delete orderItemLine.QuoteId;
          orderItemLine.ItemId = orderItemLine.ItemId.Id;
          orderItemLine.OrderId = newOrderId;
          orderItemLine.ParentLineId = newOrderLineId;
          carItemsOrderLines.push(orderItemLine);
        });
    }

    let orderItemsLinePromises = carItemsOrderLines.map((itemLine) =>
      dispatch(createOrderLineSimple(itemLine)),
    );
    await Promise.all(orderItemsLinePromises);

    return Promise.resolve(newOrderId);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const dischargeMakePrimary = async (quotes) => {
  const prevMakePrimaryQuotes = quotes.filter((q) => q.IsPrimary || q.makePrimary);
  if (prevMakePrimaryQuotes && prevMakePrimaryQuotes.length) {
    const promise = prevMakePrimaryQuotes.map((item) => {
      const id = item.Id || item.id;
      const data = { IsPrimary: false };

      if (item.type === 'quote') {
        return updateQuoteWithLines(id, data);
      }
      if (item.type === 'order') {
        return updateOrderWithLines(id, data);
      }

      return () => {};
    });
    return Promise.all(promise)
      .then(() => {
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  }
};

export const getDescriptionForOpportunity = (props) => {
  let resultName = '';
  if (props && props.length) {
    props.forEach((item, i, array) => {
      resultName += `${item.description || item.name || ''}${array.length - 1 === i ? '' : ','} `;
    });
  }

  return resultName;
};

export const getDescriptionModels = (doc, type, opportunityId) => (dispatch) => {
  const description = [];
  const lines = doc.lines;

  if (!lines) {
    return;
  }

  let promises = [];
  if (type === 'quote') {
    promises = lines.map((item) => getQuoteLine(item.Id));
  } else {
    promises = lines.map((item) => getOrderLine(item.Id));
  }

  return Promise.all(promises).then(async (res) => {
    res.forEach((item) => {
      if (item.ModelId) {
        description.push({ description: item.ModelId.Description });
      }

      if (item.VehicleId) {
        description.push({ name: item.VehicleId.Name });
      }
    });

    return await dispatch(updateDescriptionOpportunity(description, opportunityId));
  });
};
