import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';
import {
  CHANGE_SEARCH_RESULTS,
  OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING,
} from './opportunity_customers';
import { createCustomer, getCustomers, updateCustomer } from './api/customers';
import { lcFirst, ucFirst } from '~/utils/string';
import { getPaging } from '~/helpers/paging';
import { adaptOpportunityForClient } from '~/helpers/dataAdapters/opportunity';
import { FilterOperator } from '~/constants/filter';

export const PARTNERS_GET_LIST = 'PARTNERS_GET_LIST';
export const PARTNERS_CHANGE_FILTER = 'PARTNERS_CHANGE_FILTER';
export const PARTNERS_RESET_FILTER = 'PARTNERS_RESET_FILTER';
export const PARTNERS_TOGGLE_LOADING = 'PARTNERS_TOGGLE_LOADING';
export const PARTNERS_SET_PAGE = 'PARTNERS_SET_PAGE';
export const PARTNERS_SET_ROWS_PER_PAGE = 'PARTNERS_SET_ROWS_PER_PAGE';
export const PARTNERS_GET_DICT = 'PARTNERS_GET_DICT';

export const GET_CUSTOMERS_LIST = 'GET_CUSTOMERS_LIST';
export const getCustomersList =
  (paging = {}) =>
  async (dispatch, getState) => {
    const { filters } = getState().customers;
    const params = [
      {
        field: '$text',
        operator: FilterOperator.colon,
        value: filters?.text,
      },
      {
        field: 'type',
        operator: FilterOperator.equals,
        value: filters?.CompanyPrivate?.[0]?.value,
      },
      {
        field: 'group',
        operator: FilterOperator.equals,
        value: filters?.GroupCode?.[0]?.value,
      },
    ];

    const queryParams = {
      ...getPaging(paging),
      filter: params.reduce((accumulator, { field, operator, value }) => {
        if (value) {
          accumulator.push(`${field}${operator}${value}`);
        }

        return accumulator;
      }, []),
    };

    dispatch({ type: PARTNERS_TOGGLE_LOADING, payload: true });

    try {
      const customers = await getCustomers(queryParams);

      let data = customers.records;
      if (data.length) {
        data = data.map((customer, i) => getActualCustomer(customer));
      }
      const total = customers.total;
      dispatch({ type: GET_CUSTOMERS_LIST, payload: data });
      dispatch({ type: CUSTOMERS_CHANGE_PAGING, payload: { total } });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: PARTNERS_TOGGLE_LOADING, payload: false });
    }
  };

export const CUSTOMERS_CHANGE_PAGING = 'CUSTOMERS_CHANGE_PAGING';
export const changePaging = (payload) => (dispatch, getState) => {
  dispatch({ type: 'CUSTOMERS_CHANGE_PAGING', payload });
  dispatch(getCustomersList({ ...getState().customers.paging, ...payload }));
};

export const loadCustomers = (queryParams) =>
  axiosInstance
    .get(`/xassales/v1/CustomersListService?sort=name:1&pagingResponse=true&${queryParams}`, {
      Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
    })
    .then((res) => {
      return {
        data:
          res.data.records && res.data.records.length
            ? res.data.records.map((item, i) => ({
                label: item.Name || item.Code,
                value: item.Id,
              }))
            : [],

        total: res.data.total,
      };
    });

export const getCustomersDicts = () => (dispatch) => {
  axiosInstance
    .get(`/xassales/v1/CustomerGroupsListService`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('__SALES_token')}`,
      },
    })
    .then((response) => {
      let data = response.data;
      if (data.length) {
        data = data.map((item) => ({ label: item.Name, value: item.Id }));
      }
      dispatch({ type: PARTNERS_GET_DICT, payload: { CustomerGroups: { values: data } } });
    });
};

export const changeSearchText = (text) => (dispatch, getState) => {
  const { filters } = getState().customers;
  dispatch({ type: PARTNERS_CHANGE_FILTER, filters: { ...filters, text } });
};

export const changeFilters = (filtersObj) => (dispatch, getState) => {
  const { filters } = getState().customers;
  dispatch({ type: PARTNERS_CHANGE_FILTER, filters: { ...filters, ...filtersObj } });
};

export const CUSTOMERS_CREATE_NEW = 'CUSTOMERS_CREATE_NEW';
export const createNewCustomer = (fields) => async (dispatch) => {
  try {
    dispatch({ type: PARTNERS_TOGGLE_LOADING, payload: true });
    dispatch({ type: OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING, payload: true });

    const { customerId, customerName, phoneNumber, email } = fields;

    let newCustomer = {
      Name: customerName,
      PhoneNumber: phoneNumber,
      Email: email,
      Addresses: [],
      Contacts: [],
      Status: 'new',
      CustomerId: customerId,
      Type: 'supplier',
    };

    const customer = await createCustomer(newCustomer);

    const payload = adaptOpportunityForClient(customer);

    dispatch({ type: CUSTOMERS_CREATE_NEW, payload });
    dispatch({ type: CHANGE_SEARCH_RESULTS, payload: [payload] });
    dispatch({ type: PARTNERS_TOGGLE_LOADING, payload: false });
    dispatch({ type: OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING, payload: false });

    return customer?.Id;
  } catch (error) {
    dispatch({ type: PARTNERS_TOGGLE_LOADING, payload: false });
    dispatch({ type: OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING, payload: false });
    return false;
  }
};

export const CUSTOMERS_CHANGE_CONTACT = 'CUSTOMERS_CHANGE_CONTACT';

export const changeCustomerContact =
  (customerKey, contactKey, fieldName, value) => (dispatch, getState) => {
    const { partners } = getState().customers;
    let payload = partners;
    partners.forEach((customer, i) => {
      if (customer.id === customerKey) {
        customer.contacts.forEach((contact, j) => {
          if (contact.id === contactKey) {
            payload[i].contacts[j][fieldName] = value;
            dispatch({ type: CUSTOMERS_CHANGE_CONTACT, payload });
          }
        });
      }
    });
  };

export const CUSTOMERS_ADD_CONTACT = 'CUSTOMERS_ADD_CONTACT';

export const addCustomerContact = (customerKey, contactModal) => (dispatch, getState) => {
  const { partners } = getState().customers;
  const payload = partners;
  const newContact = {
    ...contactModal,
  };
  partners.forEach((customer, i) => {
    if (customer.id === customerKey) {
      payload[i].contacts.push(newContact);
      dispatch({ type: CUSTOMERS_ADD_CONTACT, payload });
    }
  });
};

export const CUSTOMERS_REMOVE_CONTACT = 'CUSTOMERS_REMOVE_CONTACT';

export const removeCustomerContact = (customerKey, contactKey) => (dispatch, getState) => {
  const { partners } = getState().customers;

  let payload = partners.map((customer, i) => {
    let partner = customer;
    if (customer.id === customerKey) {
      partner.contacts = partner.contacts.filter((item) => item.id !== contactKey);
    }
    return partner;
  });

  dispatch({ type: CUSTOMERS_REMOVE_CONTACT, payload });
};

export const CUSTOMERS_CHANGE_FIELD = 'CUSTOMERS_CHANGE_FIELD';

export const changeCustomerField = (customerKey, field, fields) => (dispatch, getState) => {
  const { partners } = getState().customers;

  let payload = partners.map((customer, i) => {
    let partner = customer;
    if (customer.id === customerKey) {
      partner = fields
        ? {
            ...partner,
            fields,
          }
        : {
            ...partner,
            [field.name]: field.value,
          };
    }
    return partner;
  });

  dispatch({ type: CUSTOMERS_CHANGE_FIELD, payload });
};

export const getActualCustomer = (customer) => {
  let result = {};
  Object.keys(customer).forEach((key, i) => {
    result = {
      ...result,
      [getClientCustomerKey(key)]: customer[key],
    };
  });
  return result;
};

const getClientCustomerKey = (key) => lcFirst(key);

const getActualCustomerKey = (key) => ucFirst(key);

export const CUSTOMER_CHANGE_SERVER_FIELD = 'CUSTOMER_CHANGE_SERVER_FIELD';
export const changeServerCustomerField = (props) => async (dispatch, getState) => {
  const { name, value, id } = props;
  const actualKey = getActualCustomerKey(name);

  await updateCustomer(id, {
    [actualKey]: value,
  });
};

export const changeServerCustomerFields = async (props) => {
  const { payload, id } = props;
  let formattedPayload = {
    name: payload.name,
    phoneNumber: payload.phoneNumber,
    email: payload.email,
    id: payload.id,
    title: payload.title,
    secondaryPhoneNumber: payload.secondaryPhoneNumber,
    address: payload.address,
    group: payload.group,
    type: payload.type,
    privacyStatus: payload.privacyStatus,
    notes: payload.notes,
  };
  let result = {};
  Object.keys(formattedPayload).forEach((customerKey, i) => {
    const value = payload[customerKey];
    const actualKey = getActualCustomerKey(customerKey);

    result = {
      ...result,
      [actualKey]: value,
    };
  });

  await updateCustomer(id, result);
};
