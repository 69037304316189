/**
 * @readonly
 * @enum {string}
 */
export const SearchLayoutObjectModel = {
  Vehicle: 'Vehicle',
  UsedVehicle: 'usedVehicle',
  VehicleModel: 'VehicleModel',
  VSLineDetails: 'VSLineDetails',
  VehicleModelVariant: 'VehicleModelVariant',
};

/**
 * @readonly
 */
export const SearchLayoutMatrix = {
  [`new${SearchLayoutObjectModel.Vehicle}`]: {
    columns: 5,
    rows: 1,
  },
  [`used${SearchLayoutObjectModel.Vehicle}`]: {
    columns: 4,
    rows: 3,
  },
  [SearchLayoutObjectModel.VehicleModel]: {
    columns: 5,
    rows: 1,
  },
  [SearchLayoutObjectModel.VehicleModelVariant]: {
    columns: 4,
    rows: 3,
  },
};
