import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import loginPage from './loginPage';
import dashboard from './dashboard';
import layout from './layout';
import localization from './localization';
import dictionaries from './dictionaries';
import profile from './profile';
import myOpportunities from './my_opportunities';
import customer from './customer';
import customers from './customers';
import customerContacts from './customer-contacts';
import customerVehicles from './customer-vehicles';
import about from './about';
import vehicles from './vehicles';
import vehicle from './vehicle';
import filters from './filters';
import opportunitiesList from './opportunities_list';
import attachment from './attachment';
import vehicleSearch from './vehicle_search';
import opportunityChecklist from './opportunity_checklist';
import opportunities from './opportunities';
import opportunityCustomers from './opportunity_customers';
import opportunityNew from './opportunity_new';
import opportunityContent from './opportunity_content';
import opportunityDocument from './opportunity_document';
import opportunitySalesQuote from './opportunity_sales_quote';
import opportunityOrder from './opportunity_order';
import opportunityDelivery from './opportunity_delivery';
import activities from './activities';
import header from './header';
import models from './models';
import model from './model';
import leads from './leads';
import lead from './lead';
import campaigns from './campaigns';
import saveBtn from './saveBtn';
import brands from './brands';
import employees from './employees';
import stages from './opportunity_stages';
import titles from './customer_titles';
import groups from './customer_groups';
import saleTypes from './opportunity_saletypes';
import userNotifications from './user_notifications';
import approvalRequests from './approval_requests';
import tradeIn from './trade_in';
import pdf from './pdf';
import payments from './payments';
import users from './users';
import channels from './sources';
import leadMappings from './lead_mappings';
import campaign from './campaign';
import vehicleCategories from './vehicle_categories';
import vehicleCategory from './vehicle_category';
import unqualifiedReasons from './unqualified_reasons';
import closeReasons from './closeReasons';
import customerInterests from './customerInterests';
import currencies from './currencies';
import salesPersons from './salesPersons';
import activityStatuses from './activity_statuses';

const appReducer = combineReducers({
  auth: loginPage,
  header,
  dashboard: dashboard,
  main: layout,
  localization: localization,
  dictionaries: dictionaries,
  profile: profile,
  myOpportunities,
  customers,
  customer,
  customerTitles: titles,
  customerGroups: groups,
  partnerContacts: customerContacts,
  partnerVehicles: customerVehicles,
  vehicles: vehicles,
  about: about,
  vehicle,
  brands,
  filters,
  models,
  employees,
  model,
  leads,
  lead,
  campaigns,
  campaign,
  opportunityStages: stages,
  opportunitySaleTypes: saleTypes,
  opportunitiesList,
  attachment,
  vehicleSearch,
  opportunities,
  opportunityCustomers,
  opportunity: combineReducers({
    checklist: opportunityChecklist,
    new: opportunityNew,
    content: opportunityContent,
    documents: opportunityDocument,
    payments,
    opportunitySalesQuote: opportunitySalesQuote,
    opportunityOrder,
    opportunityDelivery,
  }),
  activities,
  saveBtn,
  userNotifications,
  approvalRequests,
  tradeIn,
  pdf,
  users,
  channels,
  leadMappings,
  vehicleCategories,
  vehicleCategory,
  unqualifiedReasons,
  closeReasons,
  customerInterests,
  currencies,
  salesPersons,
  activityStatuses,
});

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    storage.removeItem('persist:root');

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
