import { axiosInstanceSalesAPI } from '~/utils/axios';

const KitLineListService = 'VehicleModelVariantKitLinesListService';

export const getLinesByKitId = async (kitId) =>
  axiosInstanceSalesAPI
    .get(KitLineListService, {
      params: {
        filter: [`modelVariantKitId:=:${kitId}`],
      },
    })
    .then((response) => {
      return response.data;
    });
