import axiosInstance from '~/utils/axios';
import { store } from '~/App';
import { getSalesAPIURL } from '.';

const listUrl = 'SalesQuotesListService';
const SalesQuotesService = 'SalesQuotesService';
const SalesQuotesLineService = 'SalesQuotesLineService';
const SalesQuoteServiceWithLines = 'SalesQuotationService';

export const getQuotations = (params = {}) =>
  axiosInstance.get(getSalesAPIURL(listUrl), {
    params: params,
  });

export const getQuote = async (id) => {
  const url = `${SalesQuotesService}/${id}`;

  return axiosInstance.get(getSalesAPIURL(url)).then(({ data }) => data);
};

export const createQuoteWithLines = async (data) => {
  const url = `${SalesQuoteServiceWithLines}`;

  return axiosInstance.post(`${getSalesAPIURL(url)}`, data).then((response) => {
    return response.data._id;
  });
};

export const updateQuoteWithLines = async (id, data) => {
  const url = `${SalesQuoteServiceWithLines}/${id}`;

  const state = store.getState();

  const { saveBtn } = state;
  const { updatedAt } = saveBtn;

  return axiosInstance
    .patch(`${getSalesAPIURL(url)}`, {
      ...data,
      updatedAt,
    })
    .then((response) => {
      return response.data._id;
    });
};

export const getQuoteLine = async (lineId) => {
  const url = `${SalesQuotesLineService}/${lineId}`;

  return axiosInstance.get(getSalesAPIURL(url)).then(({ data }) => data);
};

export const deleteQuoteLine = async (lineId) => {
  const url = `${SalesQuotesLineService}/${lineId}`;

  return axiosInstance.delete(`${getSalesAPIURL(url)}`).then((response) => {
    return response.data._id;
  });
};
