import config from '../config';

export default {
  filters: {
    text: '',
    make: [],
    model: [],
    year: [],
    status: [],
    extra: {
      Year: '',
      ToYear: '',
    },
  },
  vehicles: [
    {
      Code: '123456',
      Name: null,
      DocEntry: 410,
      CarName: 'Mazda',
      Model: 'CX-5',
      Year: 2017,
      Price: 26300,
      Type: 'Crossover',
      Status: 'Used',
      FuelType: 'Diesel',
      GearType: 'Automatic',
      Mileage: 700,
      NumHands: 2,
      Image: '/images/car1.jpg',
    },
    {
      Code: '123456',
      Name: null,
      DocEntry: 410,
      CarName: 'Mazda',
      Model: 'CX-5',
      Year: 2017,
      Price: 26000,
      Type: 'Crossover',
      Status: 'Used',
      FuelType: 'Diesel',
      GearType: 'Automatic',
      Mileage: 1200,
      NumHands: 1,
      Image: '/images/car2.jpg',
    },
  ],
  suggestionsDic: {},
  manufacturersDic: [],
  modelsDic: [],
  yearsDic: [],
  vehicleModal: {
    U_OwnBP: '',
    U_OwnBPN: '',
    U_VCode: '',
    U_ECode: '',
    U_SpnsrBP: '',
    U_SpnsrBPN: '',
    U_Mnfctr: '',
    U_LicNum: '',
    U_MdlCode: '',
    U_MdlYear: '',
    U_Status: '',
    U_CarType: '',
    U_MdlClassName: '',
    extra: {
      Year: '',
      ToYear: '',
    },
    errors: {},
  },
  total: 0,
  paging: {
    currentPage: 0,
    pageSize: config.pageSize,
  },
};
