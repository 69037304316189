import isEmpty from 'lodash.isempty';
import { emptyString } from '~/constants';
import { FilterOperator, FilterOperatorV2, SortOperator } from '~/constants/filter';
import { removeEmptyStringFields } from '~/utils/object';

export const datesFields = [
  'startDate',
  'dueDate',
  'date',
  'importDate',
  'startDateTime',
  'dueDateTime',
  'completeDate',
  'createdAt',
  'updatedAt',
];

export const numberFields = ['activitiesCount', 'overDueActivitiesCount', 'openActivitiesCount'];

export const getOrConditionsString = (conditions) => {
  const fields = removeEmptyStringFields(conditions);

  const orString = `$or:${Object.entries(fields)
    .reduce((accumulator, [key, value]) => {
      accumulator.push(`${key}:contains:${value}`);
      return accumulator;
    }, [])
    .join(',')}`;

  return orString;
};

export const adaptTableFiltersForServer = (filterObject) =>
  Object.entries(filterObject).reduce((accumulator = [], [key, filter]) => {
    const isDateRange = ['startDate', 'dueDate'].includes(key);

    if (
      isDateRange &&
      Boolean(filter?.value?.dateRangeStart) &&
      Boolean(filter?.value?.dateRangeEnd)
    ) {
      accumulator.push(
        `${key}${filter?.params || FilterOperator.between}[${filter.value.dateRangeStart},${
          filter.value.dateRangeEnd
        }]`,
      );
      return accumulator;
    }

    if (Array.isArray(filter.value) && filter.value.length !== 0) {
      accumulator.push(`${key}${filter?.params || FilterOperator.equals}${filter.value}`);
      return accumulator;
    }

    if (Boolean(filter.value) && !isDateRange) {
      accumulator.push(`${key}${filter?.params || FilterOperator.equals}${filter.value}`);
      return accumulator;
    }

    return [];
  }, []);

export const queryParamsBuilder = (queryParams) => {
  const { filter = {}, $search, pagingResponse, ...otherParams } = queryParams;
  const { paging, sort = { createdAt: SortOperator.asc } } = otherParams || {};

  const params = {
    _paging: null,
    _sort: {},
    _filters: {},
  };

  params._filters = Object.entries(filter).reduce((accumulator = {}, [key, filter]) => {
    const { params, value } = filter;

    const isDateRange = datesFields.includes(key);
    const isNumberFiled = numberFields.includes(key);

    // TODO: remove
    if (key === 'search' && value) {
      accumulator['$text'] = value;
      return accumulator;
    }

    // search by MileageFrom
    if (key === 'MileageFrom') {
      accumulator[key] = {
        [FilterOperatorV2.gte]: value,
      };
    }

    // search by MileageTo
    if (key === 'MileageTo') {
      accumulator[key] = {
        [FilterOperatorV2.lte]: value,
      };
    }

    // search by Date
    if (isDateRange) {
      if (Boolean(value?.dateRangeStart) && Boolean(value?.dateRangeEnd)) {
        accumulator[key] = {
          [FilterOperatorV2.between]: [
            new Date(value.dateRangeStart).toISOString(),
            new Date(value.dateRangeEnd).toISOString(),
          ],
        };

        return accumulator;
      }
      if (Boolean(params) && Boolean(value)) {
        accumulator[key] = {
          [params]: Array.isArray(value)
            ? value.map((_value) => new Date(_value).toISOString())
            : new Date(value).toISOString(),
        };

        return accumulator;
      }
    }

    // search by array values
    if (Array.isArray(value) && value.length !== 0 && !params) {
      accumulator[key] = {
        [FilterOperatorV2.in]: value,
      };

      return accumulator;
    }

    if (isNumberFiled && value) {
      const numberValue = parseInt(value);
      accumulator[key] = numberValue || emptyString;
      return accumulator;
    }

    // default search
    if (value !== undefined && value !== null && !isDateRange) {
      if (params) {
        if ([FilterOperatorV2.or, FilterOperatorV2.and].includes(params)) {
          accumulator[params] = {
            ...(accumulator?.[params] || {}),
            [key]: value,
          };
        } else {
          accumulator[key] = {
            ...(accumulator?.[key] || {}),
            [params]: value,
          };
        }
      } else {
        accumulator[key] = value;
      }
      return accumulator;
    }

    return accumulator;
  }, {});

  params._sort = Object.entries(sort).reduce((accumulator = {}, [key, sortValue]) => {
    accumulator[key] = sortValue;
    return accumulator;
  }, {});

  return {
    $sort: isEmpty(params._sort) ? null : params._sort,
    $filter: isEmpty(params._filters) ? null : params._filters,
    $paging: paging,
    $search,
    pagingResponse:
      typeof pagingResponse === 'boolean'
        ? pagingResponse
        : Boolean(paging) && Object.keys(paging).length !== 0,
  };
};
