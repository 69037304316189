import initialState from '../data/leads';
import {
  LEADS_LIST_CHANGE_FILTERS,
  LEADS_LIST_CHANGE_MODAL,
  LEADS_LIST_CHANGE_MODAL_FIELD,
  LEADS_LIST_CHANGE_LEAD_MODAL_FIELD,
  LEADS_LIST_CLEAR_LEAD_MODAL_FIELDS,
  CHANGE_PAGING,
  LEADS_SET_DEFAULT_FILTERS_PARAMS,
  GET_FILTER_NAMES,
  LEADS_DISABLED_NEW_FILTER,
  LEAD_CREATE_NEW,
  LEAD_CREATE_NEW_ACTIVITY,
  LEAD_CREATE_NEW_COMMENT,
  LEAD_UPDATE_COMMENT,
  LEADS_GET_DATA,
  LEADS_GET_DICTS,
  LEADS_LIST_SET_FILTER_PARAMS,
  LEADS_LIST_RESET_FILTER_PARAMS,
  LEADS_LIST_TOGGLE_LOADER,
  LEADS_CHANGE_SORT,
} from '../actions/leads';

export default function (state = initialState, action) {
  switch (action.type) {
    case LEADS_LIST_TOGGLE_LOADER: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case LEADS_LIST_CHANGE_FILTERS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          ...action.filter,
        },
      };
    }
    case LEADS_LIST_CHANGE_MODAL_FIELD: {
      return {
        ...state,
        filterModal: {
          ...state.filterModal,
          [action.key]: action.value,
        },
      };
    }

    case LEADS_LIST_CHANGE_LEAD_MODAL_FIELD: {
      return {
        ...state,
        leadModal: {
          ...state.leadModal,
          [action.key]: action.value,
        },
      };
    }
    case LEADS_LIST_CLEAR_LEAD_MODAL_FIELDS: {
      return {
        ...state,
        leadModal: action.payload,
      };
    }

    case LEADS_LIST_CHANGE_MODAL: {
      return {
        ...state,
        filterModal: action.filter,
      };
    }

    case LEADS_SET_DEFAULT_FILTERS_PARAMS: {
      return {
        ...state,
        filterValues: action.payload,
      };
    }

    case LEAD_CREATE_NEW:
    case LEAD_CREATE_NEW_ACTIVITY:
    case LEAD_CREATE_NEW_COMMENT:
    case LEAD_UPDATE_COMMENT:
    case LEADS_GET_DATA: {
      const { list, total } = action.payload;
      return {
        ...state,
        totalLeads: total,
        data: list,
      };
    }

    case LEADS_GET_DICTS: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          ...action.payload,
        },
      };
    }

    case GET_FILTER_NAMES: {
      return {
        ...state,
        filterNames: action.payload,
      };
    }

    case LEADS_DISABLED_NEW_FILTER: {
      return {
        ...state,
        isDisabledNewFilter: action.payload,
      };
    }

    case CHANGE_PAGING: {
      return { ...state, paging: { ...state.paging, ...action.payload } };
    }

    case LEADS_LIST_SET_FILTER_PARAMS: {
      return {
        ...state,
        filterValues: action.payload,
      };
    }

    case LEADS_LIST_RESET_FILTER_PARAMS: {
      return {
        ...state,
        filterValues: initialState.filterValues,
      };
    }

    case LEADS_CHANGE_SORT: {
      return {
        ...state,
        sort: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
