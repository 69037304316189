/**
 * @readonly
 * @enum {string}
 */
export const DocumentType = {
  Quotation: 'quote',
  Order: 'order',
  Delivery: 'delivery',
  Payment: 'payment',
};

/**
 * @readonly
 * @enum {string}
 */
export const DocumentStatus = {
  Cancelled: 'Cancelled',
  Closed: 'Closed',
};
