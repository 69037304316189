import axiosInstance from '~/utils/axios';
import { getSalesAPIURL } from '.';
import { PageSizeAll } from '~/constants';

export const getDict = (listUrl) =>
  axiosInstance
    .get(getSalesAPIURL(listUrl), {
      params: {
        pageSize: PageSizeAll,
      },
    })
    .then(({ data }) => data);
