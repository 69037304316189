import {
  GET_FILTER_NAMES,
  OPPORTUNITIES_LIST_CREATE_SAVED_FILTER,
  OPPORTUNITIES_LIST_RESET_FILTERS,
  OPPORTUNITIES_LIST_SET_FILTERS_VALUE,
  OPPORTUNITIES_LIST_SET_ITEMS,
  OPPORTUNITIES_LIST_SET_PAGING,
  OPPORTUNITIES_LIST_SET_SAVED_FILTERS,
  OPPORTUNITIES_LIST_SET_SAVED_FILTER_ID,
  OPPORTUNITIES_LIST_SET_SEARCH,
  OPPORTUNITIES_LIST_SET_TOTAL,
  OPPORTUNITIES_LIST_TOGGLE_LOADER,
} from '../reducers/opportunities_list';
import { getDateTimeStringFromStamps } from '../utils/general';
import { OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING } from './opportunity_customers';
import { showWarning } from './layout';
import { getListShort, createOpportunity, updateOpportunity } from './api/opportunities';
import { updateLead } from './api/leads';
import { LeadStage, LeadStatus } from '~/constants/lead';
import { getDefaultCustomerFromCookies } from '~/helpers/cookies';
import { t } from '~/utils/lang';
import { OPPORTUNITY_SET_DATA } from './opportunity_new';
import { ucFirst } from '~/utils/string';
import {
  adaptOpportunityForClient,
  formatOpportunitiesShort,
} from '~/helpers/dataAdapters/opportunity';
import { createTableFilter, getTableFilters } from './api/tableFilters';
import { objectKeysPascalCaseToCamelCase } from '~/utils/object';
import { emptyString } from '~/constants';
import { FilterOperatorV2, SortOperator } from '~/constants/filter';

export const getSavedFilters =
  (tableName, filterId = emptyString) =>
  async (dispatch) => {
    const savedFilters = await getTableFilters({
      filter: {
        tableName: {
          value: tableName,
        },
      },
    });

    const adaptFilters = objectKeysPascalCaseToCamelCase(savedFilters);

    const tableFilter = adaptFilters.find(({ id }) => id === filterId) || {};

    const content = objectKeysPascalCaseToCamelCase(tableFilter?.content || {});

    dispatch({ type: OPPORTUNITIES_LIST_SET_SAVED_FILTERS, payload: adaptFilters });
    dispatch(setSavedFilterId(tableFilter?.id));
    dispatch(setFilters(content));
  };

export const getOpportunitiesListForGrid = () => async (dispatch) => {
  dispatch({ type: OPPORTUNITIES_LIST_TOGGLE_LOADER, payload: true });

  const params = {
    sort: {
      createdAt: SortOperator.desc,
    },
  };

  try {
    const records = await getListShort(params);

    const adaptRecords = objectKeysPascalCaseToCamelCase(
      (records || []).map(formatOpportunitiesShort),
    );

    dispatch({ type: OPPORTUNITIES_LIST_SET_ITEMS, payload: adaptRecords });
    dispatch({ type: OPPORTUNITIES_LIST_SET_TOTAL, payload: adaptRecords.length });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({ type: OPPORTUNITIES_LIST_TOGGLE_LOADER, payload: false });
  }
};

export const getOpportunitiesList = () => async (dispatch, getState) => {
  dispatch({ type: OPPORTUNITIES_LIST_TOGGLE_LOADER, payload: true });

  const {
    opportunitiesList: { paging, filters },
  } = getState();

  const params = {
    paging,
    filter: {
      ...filters,
      opportunityStage: {
        params: FilterOperatorV2.not,
        value: 'Closed',
      },
    },
    sort: {
      createdAt: SortOperator.desc,
    },
  };

  try {
    const { records = [], total } = await getListShort(params);

    const adaptRecords = objectKeysPascalCaseToCamelCase(records.map(formatOpportunitiesShort));

    dispatch({ type: OPPORTUNITIES_LIST_SET_ITEMS, payload: adaptRecords });
    dispatch({ type: OPPORTUNITIES_LIST_SET_TOTAL, payload: total });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({ type: OPPORTUNITIES_LIST_TOGGLE_LOADER, payload: false });
  }
};

export const createSavedFilter = (filter) => async (dispatch) => {
  try {
    const newFilter = await createTableFilter(filter);

    const adaptFilter = objectKeysPascalCaseToCamelCase(newFilter);

    dispatch({ type: OPPORTUNITIES_LIST_CREATE_SAVED_FILTER, payload: adaptFilter });
    dispatch({ type: OPPORTUNITIES_LIST_SET_SAVED_FILTER_ID, payload: adaptFilter.id });
  } catch (error) {
    console.error(error);
  }
};

export const setSavedFilterId = (savedFilterId) => (dispatch) => {
  dispatch({ type: OPPORTUNITIES_LIST_SET_SAVED_FILTER_ID, payload: savedFilterId });
};

export const setFilters =
  (filters = {}) =>
  (dispatch) => {
    dispatch({ type: OPPORTUNITIES_LIST_SET_FILTERS_VALUE, payload: filters });
  };

export const resetFilters = () => (dispatch) => {
  dispatch({ type: OPPORTUNITIES_LIST_RESET_FILTERS });
};

export const setSearch = (value) => (dispatch) => {
  dispatch({ type: OPPORTUNITIES_LIST_SET_SEARCH, payload: value });
};

export const setPage = (page) => (dispatch) => {
  const payload = {
    page: page,
  };

  dispatch({ type: OPPORTUNITIES_LIST_SET_PAGING, payload });
};

export const setPageSize = (count) => (dispatch) => {
  const payload = {
    count: count,
  };

  dispatch({ type: OPPORTUNITIES_LIST_SET_PAGING, payload });
};

export const getFilterNamesAction = (type) => (dispatch, getState) => {
  const { filteredData } = getState().filters;
  const payload = filteredData
    .filter((item) => item.type === type)
    .map((item) => ({ value: item.id, label: item.name }));

  dispatch({ type: GET_FILTER_NAMES, payload });
};

export const opportunitiesListGetFilterCounter = (filterValues) => (dispatch, getState) => {
  const { items } = getState().opportunitiesList;

  return items?.length || 0;
};

const getActualOpportunityKey = (key) => {
  switch (key) {
    case 'amount':
      return 'PotentialAmount';
    case 'dueDate':
    case 'dueTime':
      return 'ExpectedCloseDate';
    case 'startDate':
    case 'startTime':
      return 'CreationDate';
    case 'source':
      return 'Source';
    case 'brand':
      return 'Brand';
    default:
      return ucFirst(key);
  }
};

export const changeOpportunityField = (props) => async (dispatch, getState) => {
  const { key, value, id } = props;
  const currentOpportunity = getState().opportunity.new;
  const actualKey = getActualOpportunityKey(key);
  const actualValue =
    actualKey === 'ExpectedCloseDate'
      ? getDateTimeStringFromStamps(currentOpportunity.dueDate, currentOpportunity.dueTime)
      : actualKey === 'CreationDate'
      ? getDateTimeStringFromStamps(currentOpportunity.startDate, currentOpportunity.startTime)
      : value;

  const data = {
    [`${actualKey}`]: actualValue,
  };

  try {
    const newOpportunity = await updateOpportunity(id, data);

    const payload = {
      ...currentOpportunity,
      ...newOpportunity,
    };

    const adaptPayload = adaptOpportunityForClient(payload);

    dispatch({ type: OPPORTUNITY_SET_DATA, payload: adaptPayload });

    return adaptPayload;
  } catch (error) {
    return console.error(error);
  }
};

export const changeManyOpportunityFields = (props) => async (dispatch, getState) => {
  const { id, data } = props;
  const currentOpportunity = getState().opportunity.new;

  try {
    const newOpportunity = await updateOpportunity(id, data);
    const payload = {
      ...currentOpportunity,
      ...newOpportunity,
    };

    const adaptPayload = adaptOpportunityForClient(payload);

    dispatch({ type: OPPORTUNITY_SET_DATA, payload: adaptPayload });

    return adaptPayload;
  } catch (error) {
    return console.error(error);
  }
};

export const changeOpportunityStage = (props) => async () => {
  const { value, id } = props;

  try {
    const data = {
      OpportunityStage: value,
    };

    const opportunity = await updateOpportunity(id, data);

    return opportunity;
  } catch (error) {
    return console.error(error);
  }
};

export const createNewOpportunity = (props) => async (dispatch) => {
  dispatch({ type: OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING, payload: true });

  try {
    const data = {
      CustomerCode: props.customerCode,
    };

    const opportunity = await createOpportunity(data);

    dispatch({ type: OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING, payload: false });

    return opportunity;
  } catch (error) {
    dispatch({ type: OPPORTUNITY_CUSTOMERS_TOGGLE_LOADING, payload: false });
    return console.error(error);
  }
};

export const createNewOpportunityOfLead =
  ({ customerId, leadData }) =>
  async (dispatch) => {
    try {
      const mainSource = leadData?.Sources?.find(({ Main }) => Main) || {};

      if (!customerId) {
        const defaultCustomer = getDefaultCustomerFromCookies();
        if (!defaultCustomer) {
          dispatch(showWarning(t.t('opportunity_page.customers.no_default_customer_error')));
          return;
        }
        customerId = defaultCustomer;
      }

      const data = {
        CustomerCode: customerId,
        LinkedLeadCode: leadData.Id,
        Source: {
          ...mainSource,
        },
        SalesAgent: leadData.SalesAgent?.Rid,
        Manager: leadData.Manager?.Rid,
      };

      const opportunity = await createOpportunity(data);

      // TODO: this is server-side logic!
      await updateLead(leadData.Id, {
        LeadStatus: LeadStatus.Closed,
        LeadStage: LeadStage.Converted,
      });

      return opportunity;
    } catch (error) {
      return console.error(error);
    }
  };
