import data from '../data/layout.json';
import {
  LAYOUT_CHANGE_BACK_BUTTON_VISIBLE,
  ADD_NOTIFICATION,
  HIDE_NOTIFICATION,
  HIDE_ALL_NOTIFICATIONS,
} from '../actions/layout';
const initialState = data;

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: new Date().getTime(),
            type: action.notificationType,
            headline: action.headline,
            message: action.message,
          },
        ],
      };
    }

    case HIDE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification, i) => notification !== action.notification,
        ),
      };
    }

    case HIDE_ALL_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [],
      };
    }
    case LAYOUT_CHANGE_BACK_BUTTON_VISIBLE:
      return action.payload;
    default:
      return state;
  }
}
