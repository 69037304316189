import initialState from '../data/model';
import {
  CHANGE_SELECTED_MODEL,
  MODEL_CHANGE_LOADER,
  MODEL_GET_DICTIONARIES,
  CHANGE_FIELD_SELECTED_MODEL,
  RESET_MODEL_DATA
} from '../actions/model';

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_SELECTED_MODEL: {
      return {
        ...state,
        model: action.payload,
      };
    }

    case MODEL_CHANGE_LOADER: {
      return {
        ...state,
        [action.typeLoader]: action.payload,
      };
    }

    case MODEL_GET_DICTIONARIES: {
      return {
        ...state,
        dicts: {
          ...state.dicts,
          ...action.payload,
        },
      };
    }

    case CHANGE_FIELD_SELECTED_MODEL: {
      return {
        ...state,
        model: {
          ...state.model,
          ...action.payload,
        },
      };
    }

    case RESET_MODEL_DATA: {
      return { ...state, model: {} }
    }

    default: {
      return state;
    }
  }
}
