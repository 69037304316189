/* eslint no-restricted-globals:"off"*/
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import axiosInstance from './axios';

export const hasAccess = (resourceToCheck) => {
  const token = window.localStorage.getItem('__SALES_roleToken');
  const role = token ? jwtDecode(token) : null;
  setParamsForLogin();

  if (role) {
    if (
      role.type === 'SUPERUSER' ||
      (role._permissions && role._permissions[resourceToCheck] === 1)
    ) {
      return true;
    }
  }
  return false;
};

const setParamsForLogin = () => {
  const token = Cookies.get('__SALES_token');
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  //TODO NEED API for load info user

  // const userInfo = jwtDecode(token);
  // dispatch({ type: LOGGED_IN, payload: data.branches });
  // dispatch({
  //   type: PROFILE_FETCH_USER,
  //   user: userInfo,
  // });
  // t.language = userInfo.language;
};

export const hasSalesAccess = (resourceToCheck) => {
  const token = window.localStorage.getItem('__SALES_roleToken');
  const role = token ? jwtDecode(token) : null;
  if (
    role &&
    (role.type === 'SUPERUSER' ||
      (role._permissionsSales && role._permissionsSales[resourceToCheck] === 1))
  ) {
    return true;
  }
  return false;
};

export const getCompanyParams = () => {
  try {
    return JSON.parse(window.localStorage.getItem('__SALES_parameters')) || {};
  } catch (e) {
    return {};
  }
};

export const getCurrentUserInfo = () => {
  try {
    const token = Cookies.get('__SALES_token');
    const userInfo = jwtDecode(token);

    return userInfo;
  } catch (e) {
    return null;
  }
};

export const getBranchesList = () =>
  JSON.parse(localStorage.getItem('branches')).map((branch) => ({
    label: branch.branchName,
    value: branch.branch,
  }));
